import { ComplexThemeType, SubThemesOf, ThemeType } from '../../../services/calculs/theme';

export type FilterThemePreference = { [k in ComplexThemeType]: boolean } & { "Economie d'énergie/Photovoltaïque": true };

// TODO : make it private
export const defaultFilterThemePreference: FilterThemePreference = {
    "Economie d'énergie/Photovoltaïque": true,
    "Economie d'énergie/Batterie": true,
    "Economie d'énergie/Chauffage": true,
    "Economie d'énergie/Eau chaude sanitaire": true,
    "Economie d'énergie/Isolation": true,
    "Economie d'énergie/Ventilation": true,
    "Economie d'énergie/Changement comportemental": true,
    "S'agrandir/Veranda": true,
    "S'agrandir/Pergola": true,
    "S'agrandir/Ma piéce en plus": true,
    "S'agrandir/Carport": true,
    'Toiture/Nettoyage-refection': true,
    "Services/Mon contrat d'énergie": true,
    'Services/Assurance habitation': true,
    'Services/Alarme et sécurité': true,
    'Services/Garantie de revenu solaire': true,
    'Mobilité/Voiture électrique': true,
    'Mobilité/Moto électrique': true,
    'Mobilité/Borne de recharge': true,
    'Confort/Piscine': true,
    'Confort/Portail': true,
    'Confort/Eclairage exterieur': true,
};
// Si on voulait avoir un affichage par themes / sous themes
export type advancedFilterThemePReference = { [THEME in ThemeType]: { [SUB in SubThemesOf<THEME>]: boolean } } & {
    "Economie d'énergie": { Photovoltaïque: true };
};

// TODO : make it private
export const defaultAdvancedFilterThemePreference: advancedFilterThemePReference = {
    "Economie d'énergie": {
        Photovoltaïque: true,
        Batterie: true,
        Chauffage: true,
        'Eau chaude sanitaire': true,
        Isolation: true,
        Ventilation: true,
        'Changement comportemental': true,
    },
    "S'agrandir": { Veranda: true, Pergola: true, 'Ma piéce en plus': true, Carport: true },
    Toiture: { 'Nettoyage-refection': true },
    Services: { "Mon contrat d'énergie": true, 'Assurance habitation': true, 'Alarme et sécurité': true, 'Garantie de revenu solaire': true },
    Mobilité: { 'Voiture électrique': true, 'Moto électrique': true, 'Borne de recharge': true },
    Confort: { Piscine: true, Portail: true, 'Eclairage exterieur': true },
};

// const toAdvancedFilterThemePreference = (pref: FilterThemePreference): advancedFilterThemePReference => {
//     return {
//         "Economie d'énergie": {
//             Photovoltaïque: pref["Economie d'énergie/Photovoltaïque"],
//             Chauffage: pref["Economie d'énergie/Chauffage"],
//             'Eau chaude sanitaire': pref["Economie d'énergie/Eau chaude sanitaire"],
//             Isolation: pref["Economie d'énergie/Isolation"],
//             Ventilation: pref["Economie d'énergie/Ventilation"],
//             'Changement comportemental': pref["Economie d'énergie/Changement comportemental"],
//         },
//         "S'agrandir": {
//             Veranda: pref["S'agrandir/Veranda"],
//             Pergola: pref["S'agrandir/Pergola"],
//             'Ma piéce en plus': pref["S'agrandir/Ma piéce en plus"],
//             Carport: pref["S'agrandir/Carport"],
//         },
//         Toiture: { 'Nettoyage-refection': pref['Toiture/Nettoyage-refection'] },
//         Services: {
//             "Mon contrat d'énergie": pref["Services/Mon contrat d'énergie"],
//             'Assurance habitation': pref['Services/Assurance habitation'],
//             'Alarme et sécurité': pref['Services/Alarme et sécurité'],
//             'Garantie de revenu solaire': pref['Services/Garantie de revenu solaire'],
//         },
//         Mobilité: {
//             'Voiture électrique': pref['Mobilité/Voiture électrique'],
//             'Moto électrique': pref['Mobilité/Moto électrique'],
//             'Borne de recharge': pref['Mobilité/Borne de recharge'],
//         },
//         Confort: { Piscine: pref['Confort/Piscine'], Portail: pref['Confort/Portail'], 'Eclairage exterieur': pref['Confort/Eclairage exterieur'] },
//     };
// };

// const toFilterThemePreference = (pref: advancedFilterThemePReference): FilterThemePreference => {
//     return {
//         "Economie d'énergie/Photovoltaïque": pref["Economie d'énergie"]['Photovoltaïque'],
//         "Economie d'énergie/Chauffage": pref["Economie d'énergie"]['Chauffage'],
//         "Economie d'énergie/Eau chaude sanitaire": pref["Economie d'énergie"]['Eau chaude sanitaire'],
//         "Economie d'énergie/Isolation": pref["Economie d'énergie"]['Isolation'],
//         "Economie d'énergie/Ventilation": pref["Economie d'énergie"]['Ventilation'],
//         "Economie d'énergie/Changement comportemental": pref["Economie d'énergie"]['Changement comportemental'],
//         "S'agrandir/Veranda": pref["S'agrandir"]['Veranda'],
//         "S'agrandir/Pergola": pref["S'agrandir"]['Pergola'],
//         "S'agrandir/Ma piéce en plus": pref["S'agrandir"]['Ma piéce en plus'],
//         "S'agrandir/Carport": pref["S'agrandir"]['Carport'],
//         'Toiture/Nettoyage-refection': pref['Toiture']['Nettoyage-refection'],
//         "Services/Mon contrat d'énergie": pref['Services']["Mon contrat d'énergie"],
//         'Services/Assurance habitation': pref['Services']['Assurance habitation'],
//         'Services/Alarme et sécurité': pref['Services']['Alarme et sécurité'],
//         'Services/Garantie de revenu solaire': pref['Services']['Garantie de revenu solaire'],
//         'Mobilité/Voiture électrique': pref['Mobilité']['Voiture électrique'],
//         'Mobilité/Moto électrique': pref['Mobilité']['Moto électrique'],
//         'Mobilité/Borne de recharge': pref['Mobilité']['Borne de recharge'],
//         'Confort/Piscine': pref['Confort']['Piscine'],
//         'Confort/Portail': pref['Confort']['Portail'],
//         'Confort/Eclairage exterieur': pref['Confort']['Eclairage exterieur'],
//     };
// };
