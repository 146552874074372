import * as auditHelper from '../../tools/auditTools';
import { filterMethod } from '../filterPreconisation';
import { PackageData } from '../package';
import { isCategories } from '../products';
import { SubTheme } from '../theme';

export const filterIsolation: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    let packMurs: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && (p.mainProduct.categorie === 'Isolation murs' || p.mainProduct.categorie === 'Murs');
    });
    let packPlanchers: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && (p.mainProduct.categorie === 'Isolation Plancher' || p.mainProduct.categorie === 'Planchers');
    });
    let packComble: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && (p.mainProduct.categorie === 'Isolation Comble /Rampant' || p.mainProduct.categorie === 'Combles');
    });

    packMurs = filterIsolationMurs(audit, packMurs);
    packPlanchers = filterIsolationPlancher(audit, packPlanchers);
    packComble = filterIsolationComble(audit, packComble);

    const result = new Array<PackageData>();
    result.push(...packMurs);
    result.push(...packPlanchers);
    result.push(...packComble);
    subTheme.packages = result;

    return subTheme;
};
/**
 * filtrer les packages inutiles du theme 'isolation des comble'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être isolation des comble. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationComble = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;
    // La règle :
    // si ( pas d'isolation des combles  OU  isolation < 20cm )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // Si on ne sait pas, ou
    // Si pas d'isolation des comble, on prend tout
    if (audit.ceilingInsulationPresence === undefined || audit.ceilingInsulationPresence.value === false) preconiser = true;

    // SI l'epaissuer n'existe pas
    // si l'epaisseur est < 20 cm
    if (audit.ceilingInsulationThickness === undefined || +audit.ceilingInsulationThickness.value < 20) preconiser = true;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on va retourner tous les packages
    // on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfacePlancherComble(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)
    packages[0].applicable = true;

    return packages;
};
/**
 * filtrer les packages inutiles du theme 'isolation des murs'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être 'isolation des murs'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationMurs = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    //console.log('passed in filterisolationMurs');
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;
    // La règle :
    // si ( pas isolation des murs  OU  isolation < 10cm )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // si les murs ne sont aps isolés
    if (audit.exteriorWallInsulationPresence === undefined || audit.exteriorWallInsulationPresence.value === false) preconiser = true;

    // SI l'epaissuer n'existe pas ou si l'epaisseur est < 10 cm
    if (audit.exteriorWallInsulationThickness === undefined || +audit.exteriorWallInsulationThickness.value < 10) preconiser = true;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfaceDesMurs(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on ne selecitonne rien par défaut.

    return packages;
};
/**
 * filtrer les packages inutiles du theme 'isolation des planchers'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être 'isolation des planchers'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationPlancher = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    //console.log('passed in filterIsolationPlancher');
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;
    // La règle :
    // si ( pas d'isolation des plancher  ET  il existe un sous-sol )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // On utilise la variable floorTypeList du fichier src/pages/envelope-informations/EnvelopeInformationsEnums.ts

    // si On ne connait pas la nature du sol, on préconise
    if (audit.floorType === undefined || audit.floorInsulationPresence === undefined) preconiser = true;

    // SI présence de vide sanitaire et/ou sous-sol
    if (
        (+audit.floorType.value === 2 || +audit.floorType.value === 3) &&
        // ET que les planchers ne sont pas isolés
        audit.floorInsulationPresence !== undefined &&
        audit.floorInsulationPresence.value === false
    )
        preconiser = true;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfacePlancher(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)
    packages[0].applicable = true;

    return packages;
};
