import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Aide, AIDE_ECO_PUBLICITAIRE_NAME, AidesLocalesList } from '../../services/calculs/ticket';
import { currencyFormat } from '../../services/tools/TypeHelper';
import * as themesService from '../../services/calculs/theme';
import { ROUTE_SIM_BON_DE_COMMANDE, ROUTE_SIM_DEVIS } from '../../routing/paths';
import { useHistory } from 'react-router-dom';
import * as storageService from '../../services/localStorageService';

import * as api from '../../services/apiParticulierService';
import * as devisPdfService from '../../services/calculs/devisPdf';

import * as bdc from '../../pages/audit-simulator/parcoursBdcCommmons';

import { validateDevis } from '../../services/apiParticulierService';
import { BonDeCommandeContexte, initialPdfState } from '../../pages/audit-simulator/parcoursBdcCommmons';
import { AnnexeDetail } from '../../pages/audit-simulator/bon-de-commande/annexe/Annexe';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ModalCounter } from '../ModalCounter/ModalCounter';
import { ticketStateAtom, ticketLoaderAtom, themesStateAtom, aidesLocalesListStateAtom } from '../../services/Recoil/Atom/Themes.atom';
import { canInstallPV } from '../../services/tools/auditTools';
import { filterAL, filterPreconisations, filterTva } from '../../services/calculs/filterPreconisation';

// Style
import './TicketContainer.scss';

// Icons
import { ReactComponent as IconBulb } from '../../assets/icons/synthese/icon-eco-bulb.svg';
import { ReactComponent as IconEuro } from '../../assets/icons/synthese/icon-ticket-euro.svg';

export type EcopubSave = { checked: boolean; value: number };

const EcoTicket: React.FC = () => {
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);

    const saveEcoPub = (ecopub: EcopubSave) => {
        // first simply save ecopub.
        storageService.setSimValue('ecopub', ecopub);

        // secondary save for annexe screen
        // read annexe,
        const designationDetails = storageService.getSim().designationDetails;
        let annexeDetailFromLocalStorage: AnnexeDetail[];
        if (!designationDetails) {
            annexeDetailFromLocalStorage = Array<AnnexeDetail>();
        } else {
            annexeDetailFromLocalStorage = designationDetails.value as [];
        }
        const indexOfEcopub = annexeDetailFromLocalStorage.findIndex((item) => item.id === '999');
        if (indexOfEcopub === -1) {
            //il n'y avait aps d'écopub, éventuellement, on l'ajoute
            if (ecopub.checked)
                annexeDetailFromLocalStorage.push({
                    designation: 'Contrat éco-publicitaire',
                    prix: ecopub.value + '',
                    id: '999',
                    lines: '1',
                });
        } else {
            // Il y avait une écopub,
            // soit on  met à jour, soit on supprime.
            if (ecopub.checked) annexeDetailFromLocalStorage[indexOfEcopub].prix = ecopub.value + '';
            else annexeDetailFromLocalStorage.splice(indexOfEcopub, 1);
        }
        //re-savegarde.
        storageService.setSimValue('designationDetails', annexeDetailFromLocalStorage);
    };

    // use `currency` to get input value
    const changeAideValue = (value: number): void => {
        const checked = aidesLocales !== undefined && aidesLocales[AIDE_ECO_PUBLICITAIRE_NAME] !== undefined;

        saveEcoPub({ checked, value });

        const newAidesLocales: AidesLocalesList = {
            ...aidesLocales,
            [AIDE_ECO_PUBLICITAIRE_NAME]: {
                titre: AIDE_ECO_PUBLICITAIRE_NAME,
                product_name: '',
                product_id: '',
                montant_lbl: currencyFormat(value, true),
                montant: value,
                shouldAppearsInAnnexe: true,
            },
        };
        setAidesLocales(newAidesLocales);
        //if (value === 0) setEcoCheck(false);
    };

    // Toggle eco state
    const setEcoCheck = (hasPub: boolean) => {
        console.log('ecopub value = ' + hasPub);
        const montant = aidesLocales?.[AIDE_ECO_PUBLICITAIRE_NAME]?.montant ?? 0;
        saveEcoPub({ checked: hasPub, value: montant }); // might be 0, a total non sense, agree.
        const ecopub: Aide = {
            titre: AIDE_ECO_PUBLICITAIRE_NAME,
            product_name: '',
            product_id: '',
            montant_lbl: currencyFormat(montant, true),
            montant: montant,
            shouldAppearsInAnnexe: true,
        };

        const newAidesLocales: AidesLocalesList = {
            ...aidesLocales,
            [AIDE_ECO_PUBLICITAIRE_NAME]: hasPub ? ecopub : undefined,
        };

        setAidesLocales(newAidesLocales);
    };

    // Cet input va envoyer la requete au bout d'une seconde apres que le dernier caractere ait été tappé
    const InputWithTimeout = () => {
        let [value, setValue] = useState<number>(aidesLocales?.[AIDE_ECO_PUBLICITAIRE_NAME]?.montant ?? 0);
        let [timerId, setTimerId] = useState<string | number | NodeJS.Timeout | undefined>(undefined);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            let val = +e.currentTarget.value;
            setValue(val);
            setTimerId(
                setTimeout(async () => {
                    changeAideValue(val);
                }, 1000)
            );
        };

        return (
            <input
                type="number"
                name="adContrat"
                id="adContract"
                className="input-ticket"
                onChange={handleChange}
                value={value}
                disabled={aidesLocales?.[AIDE_ECO_PUBLICITAIRE_NAME] === undefined}
            />
        );
    };

    return (
        <div className="ticket-eco">
            <div className="ticket-title">
                <IconBulb fill="#434d5d" />
                Programme Eco Publicitaire
            </div>

            <div className="ticket-content">
                <p>Je souhaite adhérer au :</p>
                <ul>
                    <li>
                        Programme éco publicitaire
                        <div className="custom-checkbox">
                            <label htmlFor="eco">
                                <input
                                    type="checkbox"
                                    name="eco"
                                    id="eco"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setEcoCheck(e.currentTarget.checked);
                                    }}
                                    checked={aidesLocales?.[AIDE_ECO_PUBLICITAIRE_NAME] !== undefined}
                                />
                                <div className="input-display"></div>
                            </label>
                        </div>
                    </li>
                </ul>

                <div className="form-group mt-3">
                    <label htmlFor="adContrat">Montant du contrat publicitaire</label>
                    <div className="position-relative">
                        <InputWithTimeout />
                        <span data-currency="€"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BecomePartnerComponent: React.FC = () => {
    // Input checkbox
    const [becomePartner, setBecomePartner] = useState<boolean>(false);

    // Toggle ad state
    const handleCheckedChanged = () => {
        setBecomePartner(!becomePartner);
        // first simply save ecopub.
        storageService.setSimValue('becomePartner', !becomePartner);
    };

    return (
        <div className="ticket-partner">
            <div className="ticket-title">
                <IconEuro fill="#434d5d" />
                Devenir partenaire SOGYS
            </div>

            <div className="ticket-content">
                <p>Je souhaite adhérer au :</p>

                <ul>
                    <li>
                        Réseau SOGYS
                        <div className="custom-checkbox">
                            <label htmlFor="ad">
                                <input type="checkbox" name="ad" id="ad" onChange={handleCheckedChanged} />
                                <div className="input-display"></div>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const TicketComponent: React.FC = () => {
    const [themes] = useRecoilState(themesStateAtom);
    const AlertSwal = withReactContent(Swal);
    const [ticket] = useRecoilState(ticketStateAtom);
    //const [aidesLocales, setAidesLocales] = useRecoilState<AidesLocalesList>(aidesLocalesListStateAtom);
    const [, setTicketIsLoading] = useRecoilState(ticketLoaderAtom); // MayBeToRemove

    const emptyAuditId = !localStorage.getItem('auditId')!;
    const auditNotSent = storageService.getAudit().auditSent !== true;

    // Update aidesLocalesListStateAtom
    // useEffect(() => {
    //     if (ticket.aides) {
    //         const photovoltaicAide = ticket.aides.find((aide) => aide.titre === "Photovoltaïque prime à l'investissement");
    //         const existingAide = aidesLocales ? aidesLocales[AIDE_PANNEAU_SOLAIRE_NAME] : undefined;
    //         // Prevent infinite loop
    //         if (photovoltaicAide && (!existingAide || existingAide.montant !== photovoltaicAide.montant)) {
    //             setAidesLocales((prevAides) => ({
    //                 ...prevAides,
    //                 [AIDE_PANNEAU_SOLAIRE_NAME]: photovoltaicAide,
    //             }));
    //         }
    //     }
    // }, [ticket.aides, aidesLocales, setAidesLocales]);

    // Lorsqu'on tape l'url en direct on peut arriver ici alors que l'audit n'est pas correct, ca génère des défauts d'affichage qui ne sont pas des bugs.
    // Mais ils sont percu comme tel.
    // on se protège en affichant un message, en bas du ticket.
    // if (emptyAuditId) console.log("L'auditId est vide !! ");
    // if (auditNotSent) console.log("L'audit n'a pas été envoyé !! ");

    const buildCartArticles = (): Array<JSX.Element> | undefined => {
        const articles = new Array<JSX.Element>();

        for (const theme of themes) {
            for (const subTheme of theme.subThemes!) {
                for (const article of subTheme.packages)
                    if (article.applicable) {
                        articles.push(
                            <li key={subTheme.subThemeType + article.id + article.mainProduct.product_id}>
                                <span>
                                    x{article.mainProduct.quantite} {article.mainProduct.nom}
                                </span>
                                <span>{currencyFormat(article.price, true)}</span>
                            </li>
                        );
                    }
            }
        }
        if (articles.length === 0) {
            return undefined;
        }
        return articles;
    };

    const buildAides = (): Array<JSX.Element> | undefined => {
        // TODO, ask Somebody how to implement this
        //manageHardCodedAide();
        // console.log(JSON.stringify(ticket.aides));
        return ticket.aides?.map((aide: Aide) => (
            <li key={aide.product_id + aide.titre}>
                <span>{aide.titre}</span>
                <span>-{aide.montant_lbl}</span>
            </li>
        ));
    };

    const client = (): string => {
        const client = storageService.getClientIdentity();
        if (!client) return '';
        return client.userCivility + ' ' + client.userFirstname + ' ' + client.userLastname;
    };

    const agent = (): string => {
        const agent = storageService.getLocalStorageAgent();
        if (!agent) return '';
        return agent.prenom_agent + ' ' + agent.nom_agent;
    };

    const emptyAuditIdStyle = { color: 'red', 'font-size': 12 };

    const warningEmptyDevis = () => {
        if (!emptyAuditId && !auditNotSent) return undefined;

        return (
            <div style={emptyAuditIdStyle}>
                {' '}
                {emptyAuditId && (
                    <i>
                        L'auditId est vide
                        <br />
                    </i>
                )}
                {auditNotSent && (
                    <i>
                        L'audit n'a pas été envoyé.
                        <br />
                    </i>
                )}
                <i>Le devis ne peut pas être calculé</i>
            </div>
        );
    };
    const { push } = useHistory();

    const validateAndNavigate = async (): Promise<void> => {
        setTicketIsLoading(true); // MayBeToRemove
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        const flowId = localStorage.getItem('flowId')!;

        const packages = themesService.getSelectedPackagesWithQty(allThemesClone);

        try {
            // Il faut envoyer le devis :
            const devisId = await validateDevis(flowId, packages, themes); // may crash
            localStorage.setItem('devisId', devisId);
            // To make it crash : (remove lines)
            //await validateDevis(+auditId, [{ package_id: '0', produits: [{ product_id: 0, quantite: '-100', surface: '-100' }] }]);
            const context: BonDeCommandeContexte = { themes, ticket, pdfState: initialPdfState };
            //console.log('le contexte ' + JSON.stringify(context.pdfState));
            setTicketIsLoading(false); // MayBeToRemove
            push(ROUTE_SIM_BON_DE_COMMANDE, context);
        } catch (err) {
            console.log((err as any).response.message);

            setTicketIsLoading(false); // MayBeToRemove
        }
    };

    // On créé le 'devis pdf' en mode 'simple'
    const createDevis = async (context: bdc.BonDeCommandeContexte) => {
        const auditIdStr = localStorage.getItem('auditId');
        if (auditIdStr === null) {
            console.log('auditId is null');
            return;
        }
        const auditId = +auditIdStr;
        const { audit, clientIdentity } = storageService.stepListToAuditAndClient();
        // Si appartement, on envoie pas de devis du tout :
        if (!canInstallPV(audit)) {
            return;
        }
        const simulationSimple = await devisPdfService.createSimulationSimple(audit);
        let themesClone = JSON.parse(JSON.stringify(context.themes));
        // Check si pas de PV selectionner remettre celui qui était selectionné apr défaut au départ de la simu.

        const first = themesService.getFirstPanneau(themesClone);
        if (first === undefined) {
            // Cas particulier.
            // Ici on essaye de vendre du panneau.
            // Si aucun panneau n'est selectionné, on refait la selection des packages et
            // on met le panneau qui était selectionné au debut de la simu.
            let themes = await themesService.getThemes();
            if (!themes || themes.length === 0) {
                return;
            }
            await filterAL(themes);
            await filterTva(audit, themes);
            //themes = await filterByUserPref(prefThemeFilter, themes);
            themesClone = await filterPreconisations(audit, themes);
            themesService.getFirstPanneau(themesClone);
        }

        const simuAvecPreco = await devisPdfService.createSimulationCompleteAndPv('Simple', auditId, audit, themesClone);
        await api.createDevisPdf('Simple', +auditId, {
            clientIdentity,
            // audit,
            simulationSimple,
            ...simuAvecPreco,
        });
    };

    const receiveDevis = async (): Promise<void> => {
        try {
            setTicketIsLoading(true); // MayBeToRemove
            AlertSwal.fire({
                title: 'Envoi du devis en cours',
                html: <ModalCounter timer={45} />,
                width: 600,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Il faut envoyer le devis :
            const context: BonDeCommandeContexte = { themes, ticket, pdfState: initialPdfState };
            // creation du devis avant de naviguer
            await createDevis(context);
            console.log('create devis did not crash 2');
        } catch (err) {
            console.log((err as any).response.message);
        } finally {
            setTicketIsLoading(false); // MayBeToRemove
            AlertSwal.close();
            push(ROUTE_SIM_DEVIS);
        }
    };
    return (
        <div className="ticket-sticky">
            <div className="ticket">
                <div className="ticket-header">
                    <h2>Ticket client</h2>
                </div>

                <div className="ticket-body">
                    <div className="client-detail">
                        <ul className="client-detail-list">
                            <li key="Client_8ea27de7-3882-4bca-b99b-97dba909488c">Client: {client()}</li>
                            <li key="Conseiller_8ea27de7-3882-4bca-b99b-97dba909488c">Conseiller: {agent()}</li>
                        </ul>
                    </div>

                    <div className="command-recap">
                        <h3 className="command-recap-title">Commande</h3>

                        <ul className="command-recap-list">
                            {buildCartArticles()}
                            <li key="sous_total_2f6ede10-3357-4bce-a8f6-ac0f2009a796">
                                <span>Sous-total</span>
                                <span>{ticket.montant_vente}</span>
                            </li>
                        </ul>

                        <div className="command-recap-aide">
                            <h3 className="command-recap-title">Aides</h3>
                            <ul className="command-recap-list">{buildAides()}</ul>
                        </div>

                        <div className="command-recap-total">
                            <ul className="command-recap-list">
                                <li key="total_aide_d6a39b84-4da0-464a-be2f-666636be3945">
                                    <span>Total des aides</span>
                                    <span>-{ticket.montant_aides_cumul}</span>
                                </li>
                                <li key="reste_a_charge_841ccd5d-a217-4f53-83bf-2a7a6035049f">
                                    <span>Total</span>
                                    <span>{ticket.reste_a_charge}</span>
                                </li>
                            </ul>
                        </div>
                        {warningEmptyDevis()}
                        <button className="btn btn-valid" onClick={(e) => validateAndNavigate()}>
                            Valider
                        </button>
                        <button className="btn btn-valid" onClick={(e) => receiveDevis()}>
                            Exit
                        </button>
                    </div>
                </div>
            </div>
            <EcoTicket />
            <BecomePartnerComponent />
        </div>
    );
};

export default TicketComponent;
