import React, { useEffect, useState } from 'react';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import PhotoComponent from './photoComponent';

import * as Yup from 'yup';
import { Formik } from 'formik';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { postJsonData } from '../../services/apiParticulierService';
import { ROUTE_AUDIT_AL, ROUTE_AUDIT_RAC, ROUTE_SIM_SYNTHESE } from '../../routing/paths';
import { NoticeProps } from '../../services/tools/TypeHelper';

import './Photos.scss';

// Images
import { ReactComponent as IconStep8 } from '../../assets/icons/icon-step8.svg';
import { useStepNavigation } from '../../routing/StepNavigationContext';

export type PhotosProps = {
    [key: string]: string;
};

const Photos: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState<boolean>(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const heater: string = storageService.getAudit().step3.heaterType.value;
    const heater2: string = storageService.getAudit().step3.heaterType2.value;

    const clientMail: string = storageService.getAudit().step1.userEmail.value;

    const checkHeaterType = (type: string, type2: string) => {
        if (['4'].includes(type) || ['4'].includes(type2)) {
            return false;
        } else {
            return true;
        }
    };

    const flowId = localStorage.getItem('flowId');
    const AlertSwal = withReactContent(Swal);

    /**
     * Fonction pour déclencher l'ouverture de la modal avec l'image courante
     * @param image (image en base64)
     */
    const openImageModal = (image: string) => {
        AlertSwal.fire({
            imageUrl: image,
            imageWidth: 800,
            showClass: {
                popup: 'photo',
                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
            },
            showCloseButton: true,
            showConfirmButton: false,
        });
    };

    /**
     * 1 - Maison Individuelle
     * 2 - Appartemment
     * 3 - Immeuble Collectif
     * 4 - Bâtiment
     */
    const buildingType = storageService.getAudit().step2.projectType.value;

    const initialValues: PhotosProps = {
        buildingPictureGeneral: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureGeneral',
            `Présentation générale`,
            PropertyType['photo']
        ),
        buildingPictureNorth: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureNorth', `Face nord`, PropertyType['photo']),
        buildingPictureEast: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureEast', `Fac est`, PropertyType['photo']),
        buildingPictureSouth: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureSouth', `Fac sud`, PropertyType['photo']),
        buildingPictureWest: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureWest', `Fac ouest`, PropertyType['photo']),
        buildingPictureRoofing: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureRoofing', `Toiture`, PropertyType['photo']),
        buildingPictureOther: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureOther', `Autre`, PropertyType['photo']),
        buildingPictureBalcony: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureBalcony', `Présence d'un balcon`, PropertyType['photo']),
        buildingPicturesSolarMask: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesSolarMask',
            `Photo présence d'une masque solaire`,
            PropertyType['photo']
        ),
        buildingPicturesheaterGeneratorPower: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesheaterGeneratorPower',
            `Photo de la puissance energetique`,
            PropertyType['photo']
        ),
        buildingPicturesheaterFeature: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesheaterFeature',
            `Photo du type d'émetteur`,
            PropertyType['photo']
        ),
        buildingPicturesenergyWater: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesenergyWater',
            `Photo de Eau chaude sanitaire`,
            PropertyType['photo']
        ),
        buildingPicturesPathology1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology1',
            `Photo du manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs`,
            PropertyType['photo']
        ),
        buildingPicturesPathology2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology2',
            `Photo de moisissures dans l'habitation`,
            PropertyType['photo']
        ),
        buildingPicturesPathology3: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology3',
            `Photo de moisissures dans l'isolation thermique éxterieur`,
            PropertyType['photo']
        ),
        buildingPicturesPathology4: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology4',
            `Photo de fissure dans l'isolation thermique éxterieur (ITE)`,
            PropertyType['photo']
        ),
        buildingPicturesPathology5: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology5',
            `Photo de moisissures dans l'islation thermique éxterieur (ITE)`,
            PropertyType['photo']
        ),
        buildingPicturesPathology6: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology6',
            `Photo de la qualité de l'air dégradé`,
            PropertyType['photo']
        ),
        buildingPicturesPathology7: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology7',
            `Photo de la condensation`,
            PropertyType['photo']
        ),
        buildingPictureCeilingInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureCeilingInsulationPresence',
            `Photo des caractéristiques des plafonds`,
            PropertyType['photo']
        ),
        buildingPictureFloorInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureFloorInsulationPresence',
            ``,
            PropertyType['photo']
        ),
        buildingPictureExteriorWall: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureExteriorWall',
            `Photo des murs extérieurs`,
            PropertyType['photo']
        ),
        buildingPictureUnheatedRoom: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureUnheatedRoom',
            `Photo des murs sur local non-chauffé`,
            PropertyType['photo']
        ),
        buildingPictureDoor1: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureDoor1', `Photo porte principale`, PropertyType['photo']),
        buildingPictureDoor2: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureDoor2', `Photo porte secondaire`, PropertyType['photo']),
        buildingPictureDoorUnheatedRoom1Presence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureDoorUnheatedRoom1Presence',
            `Photo porte sur local non-chauffé`,
            PropertyType['photo']
        ),
        buildingPictureBuildingWindows1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureBuildingWindows1',
            `Photo typologie de fenêtre 1`,
            PropertyType['photo']
        ),
        buildingPictureBuildingWindows2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureBuildingWindows2',
            `Photo typologie de fenêtre 2`,
            PropertyType['photo']
        ),
        buildingPictureDoorWindows1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureDoorWindows1',
            `Photo des portes-fenêtres`,
            PropertyType['photo']
        ),
        energyCostsElectricInvoice1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice1',
            `Facture d'électrecité 1`,
            PropertyType['photo']
        ),
        energyCostsElectricInvoice2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice2',
            `Facture d'électrecité 2`,
            PropertyType['photo']
        ),
        energyCostsElectricInvoice3: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice3',
            `Facture d'électrecité 3`,
            PropertyType['photo']
        ),
        energyCostsGasInvoice1: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice1', `Facture de gaz 1`, PropertyType['photo']),
        energyCostsGasInvoice2: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice2', `Facture de gaz 2`, PropertyType['photo']),
        energyCostsGasInvoice3: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice3', `Facture de gaz 3`, PropertyType['photo']),
    };

    const validationSchema = Yup.object().shape({
        buildingPictureGeneral: Yup.string().required(`La photo de présentation générale est obligatoire`),
        buildingPictureNorth: Yup.string().required(`La photo de la face Nord est obligatoire`),
        buildingPictureEast: Yup.string().required(`La photo de la face Est est obligatoire`),
        buildingPictureSouth: Yup.string().required(`La photo de la face sud est obligatoire`),
        buildingPictureWest: Yup.string().required(`La photo de la face ouest est obligatoire`),
        buildingPictureRoofing: Yup.string().notRequired(),
        buildingPictureOther: Yup.string().notRequired(),
        buildingPictureBalcony: Yup.string().notRequired(),
        buildingPicturesSolarMask: Yup.string().notRequired(),
        buildingPicturesheaterGeneratorPower: Yup.string().required(`La photo du type de générateur est obligatoire`),
        buildingPicturesheaterFeature: Yup.string().required(`La photo du type de générateur (puissance) est obligatoire`),
        buildingPicturesenergyWater: Yup.string().required(`La photo eau chaude sanitaire est obligatoire`),
        buildingPicturesPathology1: Yup.string().notRequired(),
        buildingPicturesPathology2: Yup.string().notRequired(),
        buildingPicturesPathology3: Yup.string().notRequired(),
        buildingPicturesPathology4: Yup.string().notRequired(),
        buildingPicturesPathology5: Yup.string().notRequired(),
        buildingPicturesPathology6: Yup.string().notRequired(),
        buildingPicturesPathology7: Yup.string().notRequired(),
        buildingPictureCeilingInsulationPresence: Yup.string().notRequired(),
        buildingPictureFloorInsulationPresence: Yup.string().notRequired(),
        buildingPictureExteriorWall: Yup.string().notRequired(),
        buildingPictureUnheatedRoom: Yup.string().notRequired(),
        buildingPictureDoor1: Yup.string().required(`La photo de la porte principale est obligatoire`),
        buildingPictureDoor2: Yup.string().notRequired(),
        buildingPictureDoorUnheatedRoom1Presence: Yup.string().notRequired(),
        buildingPictureBuildingWindows1: Yup.string().required(`La photo typologique de la fenêtre 1 est obligatoire`),
        buildingPictureBuildingWindows2: Yup.string().notRequired(),
        buildingPictureDoorWindows1: Yup.string().notRequired(),
        energyCostsElectricInvoice1: Yup.string().required(`La photo d'une facture d'électricité est obligatoire`),
        energyCostsElectricInvoice2: Yup.string().notRequired(),
        energyCostsElectricInvoice3: Yup.string().notRequired(),
        energyCostsGasInvoice1:
            storageService.getAudit().step3.heaterType.value === '4' || storageService.getAudit().step3.heaterType2.value === '4'
                ? Yup.string().required(`La photo d'une facture de gaz est obligatoire`)
                : Yup.string().notRequired(),
        energyCostsGasInvoice2: Yup.string().notRequired(),
        energyCostsGasInvoice3: Yup.string().notRequired(),
    });

    /**
     * Fonction pour confirmer la suppression de l'image (Autre forme)
     */
    const confirmDeleteImage = async () => {
        return await AlertSwal.fire({
            html: `Confirmez-vous la suppression de cette image ?`,
            width: 550,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-red-border min-width',
                cancelButton: 'btn btn-primary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Disable push button
     */
    const checkStepsValidity = () => {
        if (
            storageService.getAudit().step1.isValid &&
            storageService.getAudit().step2.isValid &&
            storageService.getAudit().step3.isValid &&
            storageService.getAudit().step4.isValid &&
            storageService.getAudit().step5.isValid &&
            storageService.getAudit().step6.isValid &&
            storageService.getAudit().step7.isValid &&
            storageService.getAudit().step8.isValid
        ) {
            return false;
        }

        return true;
    };

    const nocticeType: NoticeProps = 'audit';
    const sendAwaitedPhotos = async (email: string) => {
        if (email) {
            try {
                await postJsonData(`flow/${flowId}/send-notice/${nocticeType}`);
                AlertSwal.fire({
                    title: '',
                    html: <p>L'email Notice de prise de photos sur l'audit a bien été envoyé.</p>,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-primary min-width',
                    },
                });
            } catch (error) {
                console.error(error);
                AlertSwal.fire({
                    title: 'Erreur',
                    html: <p>Une erreur est survenue lors de l'envoi de votre mail.</p>,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-primary min-width',
                    },
                });
            }
        } else {
            AlertSwal.fire({
                title: 'Erreur',
                html: <p>Aucun email n'a été renseigné dans l'étape 1.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    return (
        <Formik onSubmit={() => {}} initialValues={initialValues} isInitialValid={storageService.getAudit().step8.isValid} validationSchema={validationSchema}>
            {({ values, isValid, setFieldValue }) => {
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (8 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                storageService.setAuditStepValidity(8, isValid);
                return (
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="main-title-mini">8. Ajouter des photos de l’habitation</h1>
                            <button type="button" className="btn btn-secondary" onClick={() => sendAwaitedPhotos(clientMail)} disabled={auditSent}>
                                Envoyer la liste des photos attendues
                            </button>
                        </div>
                        <h2 className="main-subtitle">Ajouter ou prenez des photos de l’habitation</h2>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconStep8 width={40} height={40} fill="#76ca4f" />
                                <h2>Photos obligatoires</h2>
                            </div>

                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row">
                                        <PhotoComponent
                                            title={'Présentation générale'}
                                            text={'Photo de la maison ou du bâtiment en pied'}
                                            fieldValue={'buildingPictureGeneral'}
                                            url={storageService.getAudit().step8['buildingPictureGeneral']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Nord'}
                                            text={'Photo de la façade Nord de la maison'}
                                            fieldValue={'buildingPictureNorth'}
                                            url={storageService.getAudit().step8['buildingPictureNorth']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Est'}
                                            text={'Photo de la façade Est de la maison'}
                                            fieldValue={'buildingPictureEast'}
                                            url={storageService.getAudit().step8['buildingPictureEast']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Sud'}
                                            text={'Photo de la façade Sud de la maison'}
                                            fieldValue={'buildingPictureSouth'}
                                            url={storageService.getAudit().step8['buildingPictureSouth']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Ouest'}
                                            text={'Photo de la façade Ouest de la maison'}
                                            fieldValue={'buildingPictureWest'}
                                            url={storageService.getAudit().step8['buildingPictureWest']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Porte principale'}
                                            text={'Photo de la porte principale de la maison'}
                                            fieldValue={'buildingPictureDoor1'}
                                            url={storageService.getAudit().step8['buildingPictureDoor1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Typologie de fenêtre 1'}
                                            text={'Photo du premier type de fenêtres'}
                                            fieldValue={'buildingPictureBuildingWindows1'}
                                            url={storageService.getAudit().step8['buildingPictureBuildingWindows1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        {heater !== '999' && (
                                            <>
                                                <PhotoComponent
                                                    title={'Générateurs'}
                                                    text={'Photo du générateur (chaudière, convecteur, pompe à chaleur, etc..)'}
                                                    fieldValue={'buildingPicturesheaterGeneratorPower'}
                                                    url={storageService.getAudit().step8['buildingPicturesheaterGeneratorPower']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={'base64Image'}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                    required={true}
                                                />

                                                <PhotoComponent
                                                    title={'Emetteurs'}
                                                    text={'Photo des émetteurs de chaleur (radiateurs, convecteurs, splits, etc…)'}
                                                    fieldValue={'buildingPicturesheaterFeature'}
                                                    url={storageService.getAudit().step8['buildingPicturesheaterFeature']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={'base64Image'}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                    required={true}
                                                />
                                            </>
                                        )}

                                        <PhotoComponent
                                            title={'Eau chaude sanitaire'}
                                            text={"Photo du ballon d'eau chaude sanitaire"}
                                            fieldValue={'buildingPicturesenergyWater'}
                                            url={storageService.getAudit().step8['buildingPicturesenergyWater']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={`Facture d'électricité 1`}
                                            text={"Photo de votre facture d'électricité de l'année dernière "}
                                            fieldValue={'energyCostsElectricInvoice1'}
                                            url={storageService.getAudit().step8['energyCostsElectricInvoice1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        {!checkHeaterType(heater, heater2) && (
                                            <PhotoComponent
                                                title={'Facture de gaz 1'}
                                                text={"Photo de votre facture de gaz de l'année dernière "}
                                                fieldValue={'energyCostsGasInvoice1'}
                                                url={storageService.getAudit().step8['energyCostsGasInvoice1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconStep8 width={40} height={40} fill="#76ca4f" />
                                <h2>Photos non obligatoires (recommandées)</h2>
                            </div>

                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row">
                                        {storageService.getAudit().step2.balcony.value && (
                                            <PhotoComponent
                                                title={'Présence d’un balcon'}
                                                text={'Photo des balcons'}
                                                fieldValue={'buildingPictureBalcony'}
                                                url={storageService.getAudit().step8['buildingPictureBalcony']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoor2Presence.value && (
                                            <PhotoComponent
                                                title={'Porte secondaire'}
                                                text={'Photo de la porte secondaire de la maison'}
                                                fieldValue={'buildingPictureDoor2'}
                                                url={storageService.getAudit().step8['buildingPictureDoor2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoorUnheatedRoom1Presence.value && (
                                            <PhotoComponent
                                                title={'Porte sur local non-chauffé'}
                                                text={'Photo de la porte sur local non chauffé de la maison'}
                                                fieldValue={'buildingPictureDoorUnheatedRoom1Presence'}
                                                url={storageService.getAudit().step8['buildingPictureDoorUnheatedRoom1Presence']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingWindows2Presence.value && (
                                            <PhotoComponent
                                                title={'Typologie de fenêtre 2'}
                                                text={'Photo du deuxième type de fenêtres'}
                                                fieldValue={'buildingPictureBuildingWindows2'}
                                                url={storageService.getAudit().step8['buildingPictureBuildingWindows2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoorWindows1Presence.value && (
                                            <PhotoComponent
                                                title={'Caractéristiques des portes-fenêtres'}
                                                text={"Photo d'une des portes-fenêtres de la maison"}
                                                fieldValue={'buildingPictureDoorWindows1'}
                                                url={storageService.getAudit().step8['buildingPictureDoorWindows1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step2.solarMask.value && (
                                            <PhotoComponent
                                                title={'Masque solaire'}
                                                text={"Photo d'horizon depuis la meilleure exposition de la maison"}
                                                fieldValue={'buildingPicturesSolarMask'}
                                                url={storageService.getAudit().step8['buildingPicturesSolarMask']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {['1', '4'].includes(buildingType) && (
                                            <PhotoComponent
                                                title={'Exposition de la toiture'}
                                                text={'Photo de la toiture de votre maison (la meilleure exposition)'}
                                                fieldValue={'buildingPictureRoofing'}
                                                url={storageService.getAudit().step8['buildingPictureRoofing']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        <PhotoComponent
                                            title={'Combles'}
                                            text={'Photo des combles'}
                                            fieldValue={'buildingPictureCeilingInsulationPresence'}
                                            url={storageService.getAudit().step8['buildingPictureCeilingInsulationPresence']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={'Caractéristiques des planchers'}
                                            text={'Photo du plafond du sous-sol ou du vide sanitaire'}
                                            fieldValue={'buildingPictureFloorInsulationPresence'}
                                            url={storageService.getAudit().step8['buildingPictureFloorInsulationPresence']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={'Caractéristiques des murs extérieurs'}
                                            text={"Photo des murs extérieurs en prenant si possible la tranche du mur de la porte d'entrée"}
                                            smallText={true}
                                            fieldValue={'buildingPictureExteriorWall'}
                                            url={storageService.getAudit().step8['buildingPictureExteriorWall']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        {['1', '4'].includes(buildingType) && (
                                            <PhotoComponent
                                                title={'Murs sur local non chauffée'}
                                                text={"Photo des murs sur local non chauffé en prenant si possible la tranche du mur de la porte d'entrée"}
                                                smallText={true}
                                                fieldValue={'buildingPictureUnheatedRoom'}
                                                url={storageService.getAudit().step8['buildingPictureUnheatedRoom']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology1.value && (
                                            <PhotoComponent
                                                title={'Problème de calfeutrage sur menuiserie'}
                                                text={"Photo de l'espace non calfeutré entre la maçonnerie et le châssis de la fenêtre"}
                                                fieldValue={'buildingPicturesPathology1'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology2.value && (
                                            <PhotoComponent
                                                title={'Moisissures dans l’habitation'}
                                                text={"Photo de l'endroit dans l'habitation ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology2'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology3.value && (
                                            <PhotoComponent
                                                title={'Moisissures (Isolation murs intérieur)'}
                                                text={"Photo de l'endroit sur le mur intérieur ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology3'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology3']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology5.value && (
                                            <PhotoComponent
                                                title={'Moisissures (Isolation murs extérieur)'}
                                                text={"Photo de l'endroit sur le mur extérieur ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology5'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology5']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology4.value && (
                                            <PhotoComponent
                                                title={'Fissure (sur isolation murs extérieur)'}
                                                text={"Photo de l'endroit où se trouve la fissure"}
                                                fieldValue={'buildingPicturesPathology4'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology4']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology6.value && (
                                            <PhotoComponent
                                                title={"Qualité de l'air intérieur"}
                                                text={"Photo de l'état des grilles sur les fenêtres"}
                                                fieldValue={'buildingPicturesPathology6'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology6']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology7.value && (
                                            <PhotoComponent
                                                title={'Condensation'}
                                                text={'Photo des endroits où se forme la condensation'}
                                                fieldValue={'buildingPicturesPathology7'}
                                                url={storageService.getAudit().step8['buildingPicturesPathology7']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={'base64Image'}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        <PhotoComponent
                                            title={`Facture d'électricité 2`}
                                            text={"Photo de votre facture d'électricité de l'avant dernière année "}
                                            fieldValue={'energyCostsElectricInvoice2'}
                                            url={storageService.getAudit().step8['energyCostsElectricInvoice2']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={`Facture d'électricité 3`}
                                            text={"Photo de votre facture d'électricité de l'avant dernière année "}
                                            fieldValue={'energyCostsElectricInvoice3'}
                                            url={storageService.getAudit().step8['energyCostsElectricInvoice3']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        {!checkHeaterType(heater, heater2) && (
                                            <>
                                                <PhotoComponent
                                                    title={'Facture de gaz 2'}
                                                    text={"Photo de votre facture de gaz de l'avant dernière année"}
                                                    fieldValue={'energyCostsGasInvoice2'}
                                                    url={storageService.getAudit().step8['energyCostsGasInvoice2']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={'base64Image'}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                />

                                                <PhotoComponent
                                                    title={'Facture de gaz 3'}
                                                    text={"Photo de votre facture de gaz de l'avant dernière année"}
                                                    fieldValue={'energyCostsGasInvoice3'}
                                                    url={storageService.getAudit().step8['energyCostsGasInvoice3']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={'base64Image'}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                />
                                            </>
                                        )}

                                        <PhotoComponent
                                            title={'Autre'}
                                            text={'Vous pouvez insérer à cette étape tout autre photo que vous souhaiteriez transmettre'}
                                            smallText={true}
                                            fieldValue={'buildingPictureOther'}
                                            url={storageService.getAudit().step8['buildingPictureOther']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={'base64Image'}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="navigation text-center">
                            <button
                                className={'btn btn-secondary maxi-min-width mx-2 mb-3'}
                                onClick={() => {
                                    returnToHome(8, '/audit');
                                }}
                            >
                                Retour au menu principal
                            </button>

                            <button
                                type="button"
                                disabled={checkStepsValidity()}
                                className="btn btn-primary maxi-min-width mx-2 mb-3"
                                onClick={async (e: any) => {
                                    e.preventDefault();

                                    let route;

                                    const campagne = storageService.getAuditCampagne();
                                    switch (campagne) {
                                        case 'action_logement':
                                            route = `${ROUTE_AUDIT_AL}/recapitulatif`;
                                            break;
                                        case 'reste_a_charge':
                                            route = `${ROUTE_AUDIT_RAC}/recapitulatif`;
                                            break;
                                        default:
                                            route = ROUTE_SIM_SYNTHESE;
                                            break;
                                    }
                                    validateThenRoute(8, route);
                                }}
                            >
                                {storageService.getAuditCampagne() ? <>Finaliser mon dossier</> : <>Accéder à ma synthèse</>}
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Photos;
