import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ROUTE_PV_VALIDATION, ROUTE_SIM_ENGAGEMENT_CLIENT } from '../../../routing/paths';
import { BonDeCommandeContexte } from '../parcoursBdcCommmons';
import * as api from '../../../services/apiParticulierService';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import * as devisPdfService from '../../../services/calculs/devisPdf';
import * as themesService from '../../../services/calculs/theme';
import { dummyInstallationPV } from '../../../services/calculs/installationPV';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
import { isAppartement } from '../../../services/tools/auditTools';

const PdfSignature: React.FC = () => {
    const { push } = useHistory();
    const signbookRef = useRef<any>(null);

    // Pdf context, with token, and client Id, just in case.
    const context = useLocation<BonDeCommandeContexte>().state;
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const companyLogo = Cookies.getJSON('Auth').companyLogo;
    const { audit, clientIdentity } = storageService.stepListToAuditAndClient();
    const AlertSwal = withReactContent(Swal);
    const flowId = localStorage.getItem('flowId')!;

    if (token === null || token === undefined || token === '') {
        console.error('Something went WRONG ! no token');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const initializeSignbook = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-new, no-undef
            signbookRef.current = new NthSignbook({
                iframeSelectorId: 'signbook',
                url: `${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page`,
                options: {
                    renderMode: 'pretty',
                },
                token,
                onExitEventMessage: onExitEventMessageFn,
                onErrorEventMessage: onErrorEventMessageFn,
                onClientFileEventMessage: onClientFileEventMessage,
            });
        };

        const destroySignbook = () => {
            if (signbookRef.current) {
                signbookRef.current.destroy();
            }
        };

        initializeSignbook();

        // Clean up event listeners when the component unmounts
        return () => {
            destroySignbook();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Retours event iFrame
    function onExitEventMessageFn(event: any) {
        //console.log('onExitEventMessageFn  ' + JSON.stringify(event));
        // Redirection vers la page Bon de commande - Engagement
        // Redirection vers la dernière page du parcours bon de commande - Engagement
        push(ROUTE_SIM_ENGAGEMENT_CLIENT, context);
    }

    const auditIdStr = localStorage.getItem('auditId');

    // On créé le 'devis pdf' en mode 'complete'
    const createDevis = async () => {
        if (auditIdStr === null) {
            return;
        }
        const auditId = +auditIdStr;

        // Si appartement, on envoie pas de devis du tout :
        if (isAppartement(audit)) {
            console.log('appartement => pas de devis !');
            return;
        }

        const simulationSimple = devisPdfService.createSimulationSimple(audit);
        const themesClone = JSON.parse(JSON.stringify(context.themes));
        const simuAvecPreco = await devisPdfService.createSimulationCompleteAndPv('Complete', auditId, audit, themesClone);
        await api.createDevisPdf('Complete', auditId, {
            clientIdentity,
            // audit,
            simulationSimple,
            ...simuAvecPreco,
        });
    };

    const onClientFileEventMessage = async (event: any) => {
        // Event coming from eKeynox SaaS with data concerning the state of the client file

        if (
            (event.changedState && event.changedState.from === 'PENDING' && (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED')) ||
            event.participantState === 'WAITING'
        ) {
            // LA c'est le OK, le client vient de signer son bon de commande.
            try {
                AlertSwal.fire({
                    title: 'Envoi des documents signés',
                    html: <ModalCounter timer={60} />,
                    width: 600,
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });

                // all docs are now signed, command is valid
                await api.commandSigned(flowId);

                const becomePartner: boolean = storageService.checkPropertyExistThenCreateOrRenderSimulator(
                    'becomePartner',
                    'Paiement au comptant',
                    PropertyType['boolean']
                );
                if (becomePartner) {
                    const email = storageService.getAudit().step1.userEmail.value;
                    api.sendPartnerMail(email);
                }

                // Envoi mma assurance si besoin.
                const installation = themesService.getFirstInstallation(context.themes) ?? dummyInstallationPV();
                const maxProd = installation.maxProd;

                const hasSolar = maxProd > 0;
                const hasInsurance: boolean = themesService.hasInsurance(context.themes);
                if (hasSolar && hasInsurance) {
                    sendMmaEmailToSogys();
                }

                // Envoi bon de commande + financement si besoin
                const simdata = storageService.getSim();
                const paiementAvecFinancement: boolean | undefined = simdata.paiementAvecFinancement?.value;
                if (paiementAvecFinancement) {
                    sendSignedBonDeCommande();
                }

                // ENVOI DU PDF DEVIS
                await createDevis();
            } catch (e) {
                console.error(e);
            } finally {
                AlertSwal.close();
                // Redirection vers la pré-visite
                context.openModal = true;
                push(ROUTE_PV_VALIDATION, context);
            }

            return;
        }

        switch (event.state) {
            case 'PENDING':
                if (event.context && event.context.type === 'signatureRefusal') {
                    // Redirection vers la dernière page du parcours bon de commande - Engagement
                    push(ROUTE_SIM_ENGAGEMENT_CLIENT, context);
                }
                break;
            case 'REJECTED':
                // Redirection vers la dernière page du parcours bon de commande - Engagement
                push(ROUTE_SIM_ENGAGEMENT_CLIENT, context);
                break;

            default:
                console.log('default event :', JSON.stringify(event));
                break;
        }
    };

    function onErrorEventMessageFn(event: any) {
        // Errors detected by the SignBook (server errors, invalid state, unsupported browser, etc.)
        if (event.cause === 'REJECTED_STATE' || event.cause === 'ACCEPTED_STATE' || event.cause === 'SUSPENDED_STATE' || event.cause === 'WAITING_STATE') {
            // Do not deal with these "errors", this is just for backwards compatibility purpose...
            return;
        }

        console.log('onErrorEventMessageFn  ' + JSON.stringify(event));
    }

    /**
     * Send "bon de commande" signed
     * both urls in this function are from audit info in prod (signed), context in preprod / dev / local (not signed), faked if nothing worked
     */
    const sendSignedBonDeCommande = async (): Promise<void> => {
        if (auditIdStr === null) {
            console.error('auditId is null or undefined');
            return;
        }
        const auditId = +auditIdStr;
        const unsignedBonDeCommandeUrl = context.pdfState['bon_de_commande'].output?.filePath;
        const unsignedFinancementSogysUrl = context.pdfState['financement_sogys'].output?.filePath;

        if (!unsignedBonDeCommandeUrl || !unsignedFinancementSogysUrl) {
            console.error("pdfState['bon_de_commande'] or pdfState['financement_sogys'] is null or undefined");
            return;
        }

        const funding = storageService.getFunding();
        const financoId = funding?.funding?.id as string | undefined;

        if (!financoId) {
            console.error('financoId is null or undefined');
            return;
        }
        api.sendSignedOrder({ unsignedBonDeCommandeUrl, unsignedFinancementSogysUrl, financoId, auditId });
    };

    /**
     * Send "mma contract" signed
     * both urls in this function are from audit info in prod (signed), context in preprod / dev / local (not signed), faked if nothing worked
     */
    const sendMmaEmailToSogys = async (): Promise<void> => {
        if (auditIdStr === null) {
            console.error('auditId is null or undefined');
            return;
        }
        const auditId = +auditIdStr;
        const unsignedMmaUrl = context.pdfState['assurance_mma'].output?.filePath;
        const unsignedSimulationUrl = context.pdfState['simulation'].output?.filePath;

        if (!unsignedMmaUrl || !unsignedSimulationUrl) {
            console.error("pdfState['assurance_mma'] or pdfState['simulation'] is null or undefined");
            return;
        }

        api.sendBackMmaContract({ unsignedMmaUrl, unsignedSimulationUrl, auditId });
    };

    return (
        <div className="container py-5">
            <div className="container py-5">
                <div className="signature-logos mt-3 mb-5">
                    <div className="main-logo">
                        <img src={companyLogo} alt="Logicoll" aira-hidden="true" />
                    </div>
                </div>

                <h1 className="mb-3">Espace de commande</h1>
                <p>
                    Bienvenue sur votre espace de commande. Vous recevrez d'ici quelques secondes un code par sms qu'il vous faudra renseigner dans la cellule «
                    Code secret », après avoir pris connaissance de chacun des documents.
                </p>
                <p className="mb-5">
                    Nos équipes se tiennent à votre disposition, 7 jours sur 7 de 8h à 22h au <a href="tel:0826046046">0826 046 046</a> ou par email à{' '}
                    <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
                </p>
            </div>

            <iframe id="signbook" title="signbook" width="99%" allow="microphone; camera" style={{ border: '0', overflow: 'hidden' }} />
        </div>
    );
};

export default PdfSignature;
