// Components
import { stepListToAuditAndClient } from '../localStorageService';
import * as themeService from './theme';
import * as pvService from './calculPhotovoltaique';
import * as pvInstallationService from './installationPV';
import { copyEnergyPrice, DepenseParPoste, getDepensesParPoste } from './bilanEnergieService';
import * as precoService from './applyPreconisation';
import { PRESQUE_ZERO } from '../tools/TypeHelper';
import { prettyLog } from '../tools/auditTools';

export const caculSimulationComplete = (
    themes: Array<themeService.Theme>,
    //panneau: pvService.PanneauPhotovoltaique | undefined
    installation: pvInstallationService.InstallationPV
): {
    depenseInitiale: DepenseParPoste;
    depenseFinaleApresTravaux: DepenseParPoste;
    depenseFinaleApresPV: DepenseParPoste | undefined;
    simuPv: pvService.SimulationPhotovoltaique | undefined;
    bilanPv: pvService.BilanPhotovoltaique | undefined;
} => {
    // Chargement de l'audit pour les calculs de preconisation
    const INPUT_AUDIT = stepListToAuditAndClient().audit;

    // re-ordonnnacement des themes, (car l'ordre d'affichage est différent de l'ordre d'application.)
    const themesClone = JSON.parse(JSON.stringify(themes));
    const reorderedThemes = themeService.reorderThemes(themesClone, themeService.calculationThemeOrder, themeService.caclulationOrder);

    // A partir des déclarations présente dans l'audit, on calcule le cout, par poste energetique.
    const depenseInitiale = getDepensesParPoste(INPUT_AUDIT);
    //depenseInitiale.logDetail('===> initial');

    // on applique toutes les précau dans l'ordre qu'il faut.
    let depenseFinaleApresTravaux = precoService.applyPrecoHorsPv(depenseInitiale, reorderedThemes);
    //depenseResult.logDetail('===> result');

    // On configure l'installation photovoltaique en fonction des données de l'audit
    // emplacement des panneaux, orientation etc ...

    //prettyLog('installation', { ...installation, PVcalcReport: undefined });

    // On instancie une SimulationPhotovoltaique...
    // On applique la simulation photovoltaique, a partir de la dépenses après les autres travaux.
    // C'est pour cela qu'on passe la depenseFinale (et non la dépense initiale)

    let simuPv: pvService.SimulationPhotovoltaique | undefined = new pvService.SimulationPhotovoltaique(depenseFinaleApresTravaux, installation);
    // prettyLog('simuPv', simuPv);
    // ... que l'on utilise pour calculer un bilan photovoltaique
    let bilanPv: pvService.BilanPhotovoltaique | undefined = simuPv.bilan();
    // prettyLog('bilanPv', bilanPv);

    // MAINTENANT :
    // on prends la partie autoconsomée, on va la retirer sur la dépense finale :
    let depenseFinaleApresPV = undefined;
    if (bilanPv !== undefined) {
        depenseFinaleApresPV = applyPrecoPV_calculsKwh(depenseFinaleApresTravaux, bilanPv); //, installation.panneau?.puissance === 3000);
        // prettyLog('depenseFinaleApresPV', depenseFinaleApresPV);
    }

    return { depenseInitiale, depenseFinaleApresTravaux, depenseFinaleApresPV, simuPv, bilanPv };
};

const applyPrecoPV_calculsKwh = (depenseFinale: DepenseParPoste, bilanPv: pvService.BilanPhotovoltaique | undefined): DepenseParPoste => {
    // On construit une dépense par source d'énergie : c'est plus simple qu'il n'y parait :
    //  - on initialise tous les abonnements
    //  - (mais) Tous les calculs sont fait hors abonnements.
    //  - On cherche la nouvelle dépense d'energie electrique
    //  - seleon le mix énergétique, on réaffecte les nouvelles dépenses énergétique par poste.
    //  - a la fin on réinjecte les abonnement SI nécessaire.
    //    (en toute logique les abonnements combustibles ne changent pas. Si l'electricité tombe à zéro, on arrête l'abonnement électricité)

    // La variable de calcul intermédiaire.
    //if (shouldLog) console.log('nouvelleDepElecHorsAbo ' + nouvelleDepElecHorsAbo + ' / ' + bilanPv?.coutHorsPhotovoltaiqueHorsAbo);
    const elec = bilanPv === undefined ? depenseFinale.consoElectricite() : bilanPv.consoHorsPhotovoltaique; // Grace au PV, on va changer la dépense d'energie électrique (uniquement.)
    const gaz = depenseFinale.consoGaz();
    const bois = depenseFinale.consoBois();
    const fioul = depenseFinale.consoFioul();

    // enfin on répartir les sources de dépense par poste de dépense :
    const NewResult = new DepenseParPoste();
    // on copie les abonnements (car les valeurs par défauts des abonnements sont absurdes.)
    NewResult.electricitePrice = copyEnergyPrice(depenseFinale.electricitePrice);
    NewResult.gazPrice = copyEnergyPrice(depenseFinale.gazPrice);
    NewResult.fioulPrice = copyEnergyPrice(depenseFinale.fioulPrice);
    NewResult.boisPrice = copyEnergyPrice(depenseFinale.boisPrice);

    // ICI, on a un nouveau cas, le cas du chauffage electrique avec eau chaude combustible.
    // Ce cas n'existe pas avant travaux, mais se produit si on met une pompe a chaleur,
    // a la place d'une chaudiere a gaz, mais qu'on garde le gaz pour l'eau chaude.
    // (cas théorique qui se produit lors de tests, mais ne devrait aps arriver dans la vraie vie,
    // car ca voudrait dire qu'on garde la chaudière au gaz que pour l'eau chaude).

    const eauchaudeCombustible = depenseFinale.eauChaude.totalCombustible() > PRESQUE_ZERO;
    const chauffageCombustible = depenseFinale.chauffage.totalCombustible() > PRESQUE_ZERO;

    if (!eauchaudeCombustible && !chauffageCombustible) {
        // Cas du mix tout electricite :
        // cas tout électrique, la répartition entre les poste reste la même.
        // l'electricité gagnée est gagnée partout de la même manière
        // les ratios sont conservés. Les totaux sont calculés grace aux ratio intiaux.
        NewResult.chauffage.electricite = depenseFinale.ratioConsoChauffage() * elec;
        NewResult.chauffage.fioul = depenseFinale.ratioConsoChauffage() * fioul;
        NewResult.chauffage.gaz = depenseFinale.ratioConsoChauffage() * gaz;
        NewResult.chauffage.bois = depenseFinale.ratioConsoChauffage() * bois;

        NewResult.eauChaude.electricite = depenseFinale.ratioConsoEauChaude() * elec;
        NewResult.eauChaude.fioul = depenseFinale.ratioConsoEauChaude() * fioul;
        NewResult.eauChaude.gaz = depenseFinale.ratioConsoEauChaude() * gaz;
        NewResult.eauChaude.bois = depenseFinale.ratioConsoEauChaude() * bois;

        // L'electricité autre, prend ce qu'il reste d'electricité
        NewResult.autres.electricite = depenseFinale.ratioConsoAutres() * elec;
        NewResult.autres.fioul = depenseFinale.ratioConsoAutres() * fioul;
        NewResult.autres.gaz = depenseFinale.ratioConsoAutres() * gaz;
        NewResult.autres.bois = depenseFinale.ratioConsoAutres() * bois;
    } else if (eauchaudeCombustible && chauffageCombustible) {
        // eau chaude combustible => Le gain PV ne s'applique que sur autre, (cas le plus simple !)

        // chauffage, rien ne change
        NewResult.chauffage.electricite = 0;
        NewResult.chauffage.fioul = depenseFinale.chauffage.fioul;
        NewResult.chauffage.gaz = depenseFinale.chauffage.gaz;
        NewResult.chauffage.bois = depenseFinale.chauffage.bois;

        // eau chaude, rien ne change
        NewResult.eauChaude.electricite = 0;
        NewResult.eauChaude.fioul = depenseFinale.eauChaude.fioul;
        NewResult.eauChaude.gaz = depenseFinale.eauChaude.gaz;
        NewResult.eauChaude.bois = depenseFinale.eauChaude.bois;

        // seule l'electricité est imapctée
        NewResult.autres.electricite = elec;
        NewResult.autres.fioul = 0;
        NewResult.autres.gaz = 0;
        NewResult.autres.bois = 0;
    } else if (!eauchaudeCombustible && chauffageCombustible) {
        // eau chaude electrique, chauffage combustilbe
        // troisième cas, eau cheaude électrique et chauffage combustible:
        // le chauffage n'est pas imapcté, le reste conserve les mêmes ratios

        // on a déjà retiré l'elec qu'il faut
        // elec contient le total eauchaude + autre.
        // il faut re-répartir la dépense d'electricité entre eau chaude et Autre, pour que le ratio eauchaude / Autre reste constant !
        const ratio = depenseFinale.eauChaude.electricite / depenseFinale.autres.electricite;

        // chauffage, rien ne change
        NewResult.chauffage.electricite = 0;
        NewResult.chauffage.fioul = fioul;
        NewResult.chauffage.gaz = gaz;
        NewResult.chauffage.bois = bois;

        NewResult.eauChaude.electricite = elec * ratio;
        NewResult.eauChaude.fioul = 0;
        NewResult.eauChaude.gaz = 0;
        NewResult.eauChaude.bois = 0;

        // L'electricité autre, prend ce qu'il reste d'electricité
        NewResult.autres.electricite = elec * (1 - ratio);
        NewResult.autres.fioul = 0;
        NewResult.autres.gaz = 0;
        NewResult.autres.bois = 0;
    } else {
        // Nouveau cas qui n'existait pas :
        // Eau chaude combustible, chauffage electrique.

        // on a déjà retiré l'elec qu'il faut
        // elec contient le total chaffauge + autre.
        // il faut re-répartir la dépense d'electricité entre eau chaude et Autre, pour que le ratio eauchaude / Autre reste constant !
        const ratio = depenseFinale.chauffage.electricite / depenseFinale.autres.electricite;

        // chauffage, rien ne change
        NewResult.chauffage.electricite = elec * ratio;
        NewResult.chauffage.fioul = 0;
        NewResult.chauffage.gaz = 0;
        NewResult.chauffage.bois = 0;

        NewResult.eauChaude.electricite = 0;
        NewResult.eauChaude.fioul = fioul;
        NewResult.eauChaude.gaz = gaz;
        NewResult.eauChaude.bois = bois;

        // L'electricité autre, prend ce qu'il reste d'electricité
        NewResult.autres.electricite = elec * (1 - ratio);
        NewResult.autres.fioul = 0;
        NewResult.autres.gaz = 0;
        NewResult.autres.bois = 0;
    }

    // si la dépense d'une énergie est devenue nulle, on annule l'abonnement.
    if (NewResult.prixElectriciteHorsAbo() < PRESQUE_ZERO) NewResult.electricitePrice.subscriptionPrice = 0;
    if (NewResult.prixGazHorsAbo() < PRESQUE_ZERO) NewResult.gazPrice.subscriptionPrice = 0;
    if (NewResult.prixFioulHorsAbo() < PRESQUE_ZERO) NewResult.fioulPrice.subscriptionPrice = 0;
    if (NewResult.prixBoisHorsAbo() < PRESQUE_ZERO) NewResult.boisPrice.subscriptionPrice = 0;

    return NewResult;
};

//#region Calculs en euros, laissé comme archive.
// /**
//  * Depenses d'energie, (issue directement de l'audit sans calcul)  En Euro
//  */
// export class DepenseParSource {
//     electricite: number = 0;
//     fioul: number = 0;
//     gaz: number = 0;
//     bois: number = 0;
//     total = (): number => {
//         return this.electricite + this.fioul + this.gaz + this.bois;
//     };
//     totalCombustible = (): number => {
//         return this.fioul + this.gaz + this.bois;
//     };
//     log = (indent = '  ') => {
//         console.log(indent + 'electricite = ' + this.electricite + ' €');
//         console.log(indent + 'fioul = ' + this.fioul + ' €');
//         console.log(indent + 'gaz = ' + this.gaz + ' €');
//         console.log(indent + 'bois = ' + this.bois + ' €');
//     };
//     copie = (): DepenseParSource => {
//         const result = new DepenseParSource();
//         result.electricite = this.electricite;
//         result.fioul = this.fioul;
//         result.gaz = this.gaz;
//         result.bois = this.bois;
//         return result;
//     };
//     energieName = (): string => {
//         let result = '';
//         if (this.totalCombustible() === 0) return 'Electricité';
//         if (this.gaz !== 0) result += 'Gaz & ';
//         if (this.bois !== 0) result += 'Bois & ';
//         if (this.fioul !== 0) result += 'Fioul & ';
//         if (this.electricite !== 0) result += 'Electricité & ';
//         result = result.slice(0, -2); // remove '& ';
//         return result;
//     };
// }
// const applyPrecoPV_calculsEuros = (depenseFinale: DepenseParPoste, bilanPv: pvService.BilanPhotovoltaique | undefined): DepenseParPoste => {
//     // On construit une dépense par source d'énergie : c'est plus simple qu'il n'y parait :
//     //  - Tous les calculs sont fait hors abonnements.
//     //  - On cherche la nouvelle dépense d'energie electrique
//     //  - seleon le mix énergétique, on réaffecte les nouvelles dépenses énergétique par poste.
//     //  - a la fin on réinjecte les abonnement SI nécessaire.
//     //    (en toute logique les abonnements combustibles ne changent pas. Si l'electricité tombe à zéro, on arrête l'abonnement électricité)

//     // La variable de calcul intermédiaire.
//     const nouvelleDepElecHorsAbo = bilanPv === undefined ? depenseFinale.prixElectriciteHorsAbo() : bilanPv.coutHorsPhotovoltaiqueHorsAbo; // Grace au PV, on va changer la dépense d'energie électrique (uniquement.)
//     //if (shouldLog) console.log('nouvelleDepElecHorsAbo ' + nouvelleDepElecHorsAbo + ' / ' + bilanPv?.coutHorsPhotovoltaiqueHorsAbo);

//     const depenseGlobaleResult = new DepenseParSource();
//     depenseGlobaleResult.electricite = nouvelleDepElecHorsAbo; // Grace au PV, on a changé la dépense d'energie.
//     depenseGlobaleResult.fioul = depenseFinale.prixFioulHorsAbo();
//     depenseGlobaleResult.gaz = depenseFinale.prixGazHorsAbo();
//     depenseGlobaleResult.bois = depenseFinale.prixBoisHorsAbo();

//     const elec = nouvelleDepElecHorsAbo;
//     const gaz = depenseGlobaleResult.gaz;
//     const bois = depenseGlobaleResult.bois;
//     const fioul = depenseGlobaleResult.fioul;

//     // enfin on répartir les sources de dépense par poste de dépense :
//     const NewResult = new DepenseParPoste();

//     // ICI, on a un nouveau cas, le cas du chauffage electrique avec eau chaude combustible.
//     // Ce cas n'existe pas avant travaux, mais se produit si on met une pompe a chaleur,
//     //a la place d'une chaudiere a gaz, mais qu'on garde le gaz pour 'leau chaude.

//     const eauchaudeCombustible = depenseFinale.eauChaude.totalCombustible() > PRESQUE_ZERO;
//     const chauffageCombustible = depenseFinale.chauffage.totalCombustible() > PRESQUE_ZERO;

//     // Cas du mix tout electricite :
//     if (!eauchaudeCombustible && !chauffageCombustible) {
//         // cas tout électrique, la répartition entre les poste reste la même.
//         // l'electricité gagnée est gagnée partout de la même manière
//         // les ratios sont conservés. Les totaux sont calculés grace aux ratio intiaux.
//         NewResult.chauffage.electricite = depenseFinale.ratioPrixChauffage() * elec;
//         NewResult.chauffage.fioul = depenseFinale.ratioPrixChauffage() * fioul;
//         NewResult.chauffage.gaz = depenseFinale.ratioPrixChauffage() * gaz;
//         NewResult.chauffage.bois = depenseFinale.ratioPrixChauffage() * bois;

//         NewResult.eauChaude.electricite = depenseFinale.ratioPrixEauChaude() * elec;
//         NewResult.eauChaude.fioul = depenseFinale.ratioPrixEauChaude() * fioul;
//         NewResult.eauChaude.gaz = depenseFinale.ratioPrixEauChaude() * gaz;
//         NewResult.eauChaude.bois = depenseFinale.ratioPrixEauChaude() * bois;

//         // L'electricité autre, prend ce qu'il reste d'electricité
//         NewResult.autres.electricite = depenseFinale.ratioPrixAutres() * elec;
//         NewResult.autres.fioul = depenseFinale.ratioPrixAutres() * fioul;
//         NewResult.autres.gaz = depenseFinale.ratioPrixAutres() * gaz;
//         NewResult.autres.bois = depenseFinale.ratioPrixAutres() * bois;
//     } else if (eauchaudeCombustible && chauffageCombustible) {
//         // eau chaude combustible => Le gain PV ne s'applique que sur autre, (cas le plus simple !)

//         // chauffage, rien ne change
//         NewResult.chauffage.electricite = 0;
//         NewResult.chauffage.fioul = depenseFinale.chauffage.fioul;
//         NewResult.chauffage.gaz = depenseFinale.chauffage.gaz;
//         NewResult.chauffage.bois = depenseFinale.chauffage.bois;

//         // eau chaude, rien ne change
//         NewResult.eauChaude.electricite = 0;
//         NewResult.eauChaude.fioul = depenseFinale.eauChaude.fioul;
//         NewResult.eauChaude.gaz = depenseFinale.eauChaude.gaz;
//         NewResult.eauChaude.bois = depenseFinale.eauChaude.bois;

//         // seule l'electricité est imapctée
//         NewResult.autres.electricite = nouvelleDepElecHorsAbo;
//         NewResult.autres.fioul = 0;
//         NewResult.autres.gaz = 0;
//         NewResult.autres.bois = 0;
//     }
//     // eau chaude electrique, chauffage combustilbe
//     else if (!eauchaudeCombustible && chauffageCombustible) {
//         // troisième cas, eau cheaude électrique et chauffage combustible:
//         // le chauffage 'nest pas imapcté, ler este conserve les mêmes ratios

//         // on a déjà retiré l'elec qu'il faut
//         // elec contient le total eauchaude + autre.
//         // il faut re-répartir la dépense d'electricité entre eau chaude et Autre, pour que le ratio eauchaude / Autre reste constant !
//         const ratio = depenseFinale.eauChaude.electricite / depenseFinale.autres.electricite;

//         // chauffage, rien ne change
//         NewResult.chauffage.electricite = 0;
//         NewResult.chauffage.fioul = fioul;
//         NewResult.chauffage.gaz = gaz;
//         NewResult.chauffage.bois = bois;

//         NewResult.eauChaude.electricite = elec * ratio;
//         NewResult.eauChaude.fioul = 0;
//         NewResult.eauChaude.gaz = 0;
//         NewResult.eauChaude.bois = 0;

//         // L'electricité autre, prend ce qu'il reste d'electricité
//         NewResult.autres.electricite = elec * (1 - ratio);
//         NewResult.autres.fioul = 0;
//         NewResult.autres.gaz = 0;
//         NewResult.autres.bois = 0;
//     } else {
//         // Nouveau cas qui n'existait aps :

//         // on a déjà retiré l'elec qu'il faut
//         // elec contient le total chaffauge + autre.
//         // il faut re-répartir la dépense d'electricité entre eau chaude et Autre, pour que le ratio eauchaude / Autre reste constant !
//         const ratio = depenseFinale.chauffage.electricite / depenseFinale.autres.electricite;

//         // chauffage, rien ne change
//         NewResult.chauffage.electricite = elec * ratio;
//         NewResult.chauffage.fioul = 0;
//         NewResult.chauffage.gaz = 0;
//         NewResult.chauffage.bois = 0;

//         NewResult.eauChaude.electricite = 0;
//         NewResult.eauChaude.fioul = fioul;
//         NewResult.eauChaude.gaz = gaz;
//         NewResult.eauChaude.bois = bois;

//         // L'electricité autre, prend ce qu'il reste d'electricité
//         NewResult.autres.electricite = elec * (1 - ratio);
//         NewResult.autres.fioul = 0;
//         NewResult.autres.gaz = 0;
//         NewResult.autres.bois = 0;
//     }

//     // si la dépense d'une énergie 'n'est pas nulle, on ajoute l'abonnement.

//     if (NewResult.prixElectriciteHorsAbo() > PRESQUE_ZERO) NewResult.electricitePrice = depenseFinale.electricitePrice;
//     if (NewResult.prixGazHorsAbo() > PRESQUE_ZERO) NewResult.gazPrice = depenseFinale.gazPrice;
//     if (NewResult.prixFioulHorsAbo() > PRESQUE_ZERO) NewResult.fioulPrice = depenseFinale.fioulPrice;
//     if (NewResult.prixBoisHorsAbo() > PRESQUE_ZERO) NewResult.boisPrice = depenseFinale.boisPrice;

//     return NewResult;
// };
// #endregion
