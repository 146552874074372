import React, { useRef } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as storageService from '../../services/localStorageService';
import { PhotosProps } from './Photos';

interface Props {
    title: string;
    text: string;
    smallText?: boolean;
    fieldValue: string;
    values: PhotosProps;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    isValid: boolean;
    openImageModal: (image: string) => void;
    base64Image: string;
    confirmDeleteImage: () => Promise<boolean>;
    auditSent: boolean;
    required?: boolean;
    url?: string;
}

const dragEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
};

async function rotateBase64Image90Degree(base64Image: string) {
    let canvas = document.createElement('canvas');
    let context: CanvasRenderingContext2D | null = null;
    context = canvas.getContext('2d');

    if (!context) {
        throw new Error('Failed to get 2D context');
    }

    let imageObj = new Image();

    return new Promise((resolve, reject) => {
        imageObj.src = base64Image;
        imageObj.onload = function () {
            canvas.width = imageObj.height;
            canvas.height = imageObj.width;
            context!.rotate((90 * Math.PI) / 180);
            context!.translate(0, -canvas.width);
            context!.drawImage(imageObj, 0, 0);

            // Conversion en dataUrl
            let dataUrl = canvas.toDataURL('image/jpeg');

            resolve(dataUrl);
        };
        imageObj.onerror = () => {
            reject(new Error('Failed to load image'));
        };
    });
}

const PhotoComponent: React.FC<Props> = ({
    title,
    text,
    smallText,
    fieldValue,
    values,
    setFieldValue,
    isValid,
    openImageModal,
    base64Image,
    confirmDeleteImage,
    auditSent,
    required,
    url,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const filledClass = values[fieldValue] !== '' ? ' filled' : '';
    const requiredClass = required ? 'drop-container required' : 'drop-container';
    const AlertSwal = withReactContent(Swal);

    const valuesOrUrl = (values: any, url?: string) => {
        if (values[fieldValue] !== '') {
            return values[fieldValue];
        } else if (url !== '') {
            return url;
        } else {
            return undefined;
        }
    };

    const openInputFile = () => {
        inputRef.current?.click();
    };

    async function resize(file: any, max_width: number, max_height: number) {
        return new Promise((resolve, reject) => {
            // Préparation du fileReader
            let fileLoader: any = new FileReader();
            let imageObj = new Image();
            fileLoader.onload = function () {
                imageObj.src = this.result;
            };

            let canvas = document.createElement('canvas');
            let context: any = null;

            // Creation d'un canvas caché por dessiner
            canvas.id = 'hiddenCanvas';
            canvas.style.visibility = 'hidden';

            // Gestion des cas avec abandon
            fileLoader.onabort = function () {
                reject(`Le chargement du fichier a été annulé`);
            };

            // Gestion des cas avec erreurs
            fileLoader.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement du fichier`);
            };

            // Lancement readAsDataURL
            fileLoader.readAsDataURL(file);

            // Dessin de la nouvelle image
            imageObj.onload = async (event: any) => {
                // Définition image avec vérification présence info (pour Safari ne respectant pas le standard HTML5)
                const img = event?.path ? event.path[0] : event.target.outerHTML;

                const MAX_WIDTH = max_width;
                const MAX_HEIGHT = max_height;
                // Définition taille de l'image avec vérification présence infos (pour Safari ne respectant pas le standard HTML5)
                let width = img?.width ? img.width : event.target.naturalWidth;
                let height = img?.height ? img.height : event.target.naturalHeight;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                document.body.appendChild(canvas);

                // Définition du context à utiliser
                context = canvas.getContext('2d');

                // Dessin
                context.drawImage(imageObj, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');

                // Retour
                resolve(dataUrl);
            };

            // Gestion des cas avec abandon
            imageObj.onabort = function () {
                reject(`Le chargement de l'image a été annulé`);
            };

            imageObj.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement de l'image`);
            };
        });
    }

    const validateFile = (file: File) => {
        return !!file.type.match('image.*');
    };

    const handleFiles = async (files: any, name: string, values: any, setFieldValue: any, isValid: boolean) => {
        if (validateFile(files[0])) {
            // Redimensionnement du fichier
            const resizedFile = await resize(files[0], 500, 500);

            if (resizedFile) {
                // Écriture dans le Formik
                setFieldValue(name, resizedFile);
                // Envoi dans le local storage
                storageService.setAuditValue(8, isValid, name, resizedFile);
            }
        } else {
            // Mauvais format
            await AlertSwal.fire({
                title: `Erreur de format`,
                html: `Le format du fichier chargé n'est pas supporté.<br/>Merci de charger une image au format <b>.jpeg</b>, <b>.png</b> ou <b>.webp</b>.`,
                icon: 'error',
                width: 550,
                confirmButtonText: 'Fermer',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    const fileClick = async (event: any, name: string, values: any, setFieldValue: any, isValid: boolean) => {
        event.preventDefault();
        if (event.target.files.length) {
            await handleFiles(event.target.files, name, values, setFieldValue, isValid);
        }
    };

    const fileDrop = async (event: any, name: string, values: any, setFieldValue: any, isValid: boolean) => {
        event.preventDefault();
        if (event.dataTransfer.files.length) {
            await handleFiles(event.dataTransfer.files, name, values, setFieldValue, isValid);
        }
    };

    return (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
            <div className="drag-and-drop">
                <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    onChange={(event) => fileClick(event, fieldValue, values, setFieldValue, isValid)}
                    data-name={fieldValue}
                />

                <div
                    onClick={() => (valuesOrUrl(values, url) === undefined ? openInputFile() : openImageModal(valuesOrUrl(values, url)))}
                    className={`${requiredClass}${filledClass}`}
                    style={
                        valuesOrUrl(values, url) !== ''
                            ? {
                                  backgroundImage: `url(${valuesOrUrl(values, url)})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                              }
                            : {}
                    }
                    onDragOver={dragEvent}
                    onDragEnter={dragEvent}
                    onDragLeave={dragEvent}
                    data-url={valuesOrUrl(values, url) !== '' ?? false}
                    onDrop={(event) => (!auditSent ? fileDrop(event, fieldValue, values, setFieldValue, isValid) : null)}
                >
                    {valuesOrUrl(values, url) === undefined && <span>Cliquer ou glisser-déposer pour ajouter une photo</span>}
                </div>

                <div className="bloc-title">
                    <label>
                        {title}
                        {required ? <sup>*</sup> : ''}
                    </label>
                    <p className={smallText ? 'smaller' : ''}>{text}</p>
                </div>

                {values[fieldValue] !== '' && !auditSent && (
                    <button
                        className="rotate"
                        type={'button'}
                        onClick={() => {
                            rotateBase64Image90Degree(values[fieldValue]).then((base64Image: any) => {
                                // Mise à jour Formik
                                setFieldValue(fieldValue, base64Image);
                                // Envoi dans le local storage
                                storageService.setAuditValue(8, isValid, fieldValue, base64Image);
                            });
                        }}
                    >
                        &nbsp;
                    </button>
                )}

                {values[fieldValue] !== '' && !auditSent && (
                    <button
                        className="remove"
                        type={'button'}
                        onClick={() => {
                            confirmDeleteImage().then((result: boolean) => {
                                if (result) {
                                    setFieldValue(fieldValue, '');
                                    storageService.removeAuditValue(8, fieldValue);
                                    storageService.setAuditStepValidity(8, false);
                                }
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PhotoComponent;
