import React from 'react';

import { useLocation } from 'react-router-dom';

import '../../assets/style/button.scss';

import TemplateRACFilled from '../../assets/json/template-audit-rac-filled.json';
import TemplateFilled from '../../assets/json/template-audit-filled.json';
import TemplateActionLogementFilledGood from '../../assets/json/template-audit-action-logement-filled-good.json';
import TemplateActionLogementFilledNotGood from '../../assets/json/template-audit-action-logement-filled-not-good.json';
import * as storageService from '../../services/localStorageService';

import Cookies from 'js-cookie';

import * as apiParticulierService from '../../services/apiParticulierService';
import * as apiFlow from '../../services/apiFlowService';

const AdminBar: React.FC = () => {
    const location = useLocation();
    // TODO : use localstorage getAuditCampagne and
    const agentCode = Cookies.getJSON('Auth').agentCode;
    const campagneFromCookie = Cookies.getJSON('Auth').campagne as storageService.Campagne;
    const auditType = localStorage.getItem('auditType');
    const flowId = localStorage.getItem('flowId');

    const showAdminBar =
        location.pathname.replace(/\/$/, '') === '/audit' &&
        (agentCode === process.env.REACT_APP_ICOLL_NAGIOS_AGENT_CODE || window.location.hostname === 'localhost');

    return (
        <>
            {showAdminBar && (
                <div className="navbar shadow-sm fixed-bottom bg-white" style={{ height: 'auto' }}>
                    <div className="container">
                        <div className="d-flex w-100 max-nav-bar-height">
                            <div className={'d-inline-flex ms-auto me-2'}>
                                <button
                                    className={campagneFromCookie === 'action_logement' ? 'btn btn-danger' : 'btn btn-success'}
                                    onClick={async () => {
                                        let filledTemplate = JSON.parse(
                                            JSON.stringify(campagneFromCookie === 'action_logement' ? TemplateActionLogementFilledNotGood : TemplateFilled)
                                        );
                                        storageService.setAudit(filledTemplate);

                                        if (!auditType || !apiFlow.isAuditType(auditType)) {
                                            throw new Error("Impossible de déterminer le type d'audit");
                                        }
                                        // Création de l'audit si pas encore existant
                                        if (!flowId) {
                                            await apiParticulierService.createAuditFlow();
                                        }

                                        // Mise à jour de l'audit en base
                                        await apiParticulierService.updateAuditDataService();
                                        // Rechargement de la page
                                        window.location.reload();
                                    }}
                                >
                                    <span>Remplir intégralement l'audit{campagneFromCookie === 'action_logement' ? ' action logement (invalide)' : ''}</span>
                                </button>
                                {campagneFromCookie === 'action_logement' && (
                                    <button
                                        className="btn btn-success ms-2"
                                        onClick={async () => {
                                            let filledTemplate = JSON.parse(JSON.stringify(TemplateActionLogementFilledGood));
                                            storageService.setAudit(filledTemplate);

                                            if (!auditType || !apiFlow.isAuditType(auditType)) {
                                                throw new Error("Impossible de déterminer le type d'audit");
                                            }
                                            // Création de l'audit si pas encore existant
                                            if (!flowId) {
                                                await apiParticulierService.createAuditFlow();
                                            }
                                            // Mise à jour de l'audit en base
                                            await apiParticulierService.updateAuditDataService();
                                            // Rechargement de la page
                                            window.location.reload();
                                        }}
                                    >
                                        <span>Remplir intégralement l'audit action logement (valide)</span>
                                    </button>
                                )}

                                {campagneFromCookie === 'reste_a_charge' && (
                                    <button
                                        className="btn btn-success ms-2"
                                        onClick={async () => {
                                            let filledTemplate = JSON.parse(JSON.stringify(TemplateRACFilled));
                                            storageService.setAudit(filledTemplate);

                                            if (!auditType || !apiFlow.isAuditType(auditType)) {
                                                throw new Error("Impossible de déterminer le type d'audit");
                                            }
                                            // Création de l'audit si pas encore existant
                                            if (!flowId) {
                                                await apiParticulierService.createAuditFlow();
                                            }

                                            // Mise à jour de l'audit en base
                                            await apiParticulierService.updateAuditDataService();
                                            // Rechargement de la page
                                            window.location.reload();
                                        }}
                                    >
                                        <span>Remplir intégralement l'audit Reste à charge</span>
                                    </button>
                                )}
                            </div>

                            {/* {!campagneFromCookie && (
                                <div className={'d-inline-flex me-2'}>
                                    <button
                                        className="btn btn-warning"
                                        onClick={() => {
                                            const template = storageService.getAudit();
                                            if (template?.auditSent) {
                                                delete template.auditSent;
                                            } else {
                                                template.auditSent = true;
                                            }
                                            storageService.setAudit(template);
                                            window.location.reload();
                                        }}
                                    >
                                        <span>Basculer statut Audit</span>
                                    </button>
                                </div>
                            )} */}
                        </div>
                        {/* {!campagneFromCookie && (
                            <div className="d-flex justify-content-end w-100 my-3">
                                <button className="btn btn-header icon-reload" onClick={() => resetAuditTemplate()}>
                                    <span>Réinitialiser les données</span>
                                </button>
                                <button className="btn btn-header icon-disconnect ms-2" onClick={() => disconnect()}>
                                    <span>Déconnexion</span>
                                </button>
                            </div>
                        )} */}
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminBar;
