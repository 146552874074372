import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTE_SIM_ANNEXE, ROUTE_SIM_RECOMMANDATION } from '../../../routing/paths';
import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import PdfModalContent from '../../../components/PdfModalContent/PdfModalContent';
import * as api from '../../../services/apiParticulierService';
import * as bdc from '../parcoursBdcCommmons';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';

// Style
import '../audit-simulator.scss';

// Icons
import { ReactComponent as IconBDC } from '../../../assets/icons/simulator/icon-recapitulatif.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';

// Read me : BonDeCommandeContext.
//
// Tous les écrans d parcours bon de commande sont faits de la même façon.
// 'context' contient la liste de toutes les entrées et de totues les sorties, pour tous les PDF potentiels a créer.
// Lorsqu'on clique sur le bouton "visualiser le(s) docuement(s)"
//    Si on avait pas encore téléchargé le(s) pdf(s) concerné(s) :
//       - on enrichit l'input du (des) document(s) conercerné(s), dans le contexte
//       - on télécharge le document
//       - on enrichit l'output du (des) document(s) conercerné(s), dans le context
//       - on set pdfContextInitialized = true.
//       - on affiche la liste des pdf.
//    Si on avait déjà encore téléchargé le(s) pdf(s) concerné(s) :
//      - on affiche la liste des pdf.
// Lorsqu'on clique sur le bouton "continuer" :
//    Si on avait aps encore initialisé l'input :
//       - on enrichit l'input du (des) document(s) conercerné(s), dans le contexte
//    - on passe le context enrichit aux écrans suivants.

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['bon_de_commande'];

const BonDeCommande: React.FC = () => {
    // Spinner: initiale state = false
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);
    const AlertSwal = withReactContent(Swal);
    const context = useLocation<bdc.BonDeCommandeContexte>().state;
    // const pdfState = context.pdfState; // NO NO NO NO

    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    // match default input[type='date'] format
    const currentDay = yyyy + '-' + mm + '-' + dd;

    const buildCartArticles = (): Array<JSX.Element> | undefined => {
        const articles = new Array<JSX.Element>();

        for (const theme of context.themes) {
            for (const subTheme of theme?.subThemes!) {
                for (const article of subTheme.packages)
                    if (article.applicable) {
                        articles.push(
                            <li key={subTheme.subThemeType + article.id + article.mainProduct.product_id}>
                                <span>
                                    x{article.mainProduct.quantite} {article.title}
                                </span>
                            </li>
                        );
                    }
            }
        }

        if (articles.length === 0) {
            return undefined;
        }
        return articles;
    };

    // Total input
    const total = context.ticket.montant_vente; //ticket.reste_a_charge;

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <PdfModalContent tabs={pdfList} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    // Est appelé lorsqu'on clqiue sur Visualiser.
    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setPdfLoader(false);
                setLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };

    const { push } = useHistory();

    // vide les contexte des pdf concernés par cet écran
    const clearPdfContext = (): void => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        //console.log('initialisePdfContext');

        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.
        const docName: api.DocNames = 'bon_de_commande';
        const input: api.DocParameters = { docName, parameters: undefined };
        context.pdfState[docName] = { input, output: undefined };

        return [input];
    };

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            if (context.pdfState[docname].input === undefined) {
                return false;
            }
        }
        return true;
    };
    arePdfInitialized();

    // Cette fonction est appelée lorsqu'on clique sur continuer.
    const validateAndNavigate = async (): Promise<void> => {
        setLoader(true);
        setPdfLoader(false);
        try {
            // si on a initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }
            // for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            //     console.log('Continuer ecran bdc, context = ' + JSON.stringify(context.pdfState[docname], null, 2));
            // }
            // puis on navigue.
            push(ROUTE_SIM_ANNEXE, context);
            setLoader(false);
        } catch (err) {
            //console.log((err as any).response.message);

            setLoader(false);
            setPdfLoader(false);

            // Modal contenant le message d'erreur
            AlertSwal.fire({
                title: 'Erreur',
                icon: 'error',
                html: <p>{api.ERROR_INDISPONIBLE}</p>,
                width: 600,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };

    return (
        <div className="container">
            <h1 className="main-title-mini">Bon de commande</h1>

            <div className="card card-audit-simulator bdc mb-5">
                <div className="card-header">
                    <IconBDC />
                    <h2>Récapitulatif commande</h2>
                </div>

                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-12 col-md-4 col-mb-4">
                            <div className="form-group">
                                <label htmlFor="date">Date de la commande</label>
                                <input type="date" id="date" defaultValue={currentDay} disabled={true} />
                            </div>
                        </div>

                        <div className="col-12 col-md-4"></div>

                        <div className="col-12 col-md-4 col-mb-4">
                            <div className="form-group">
                                <label htmlFor="total">Montant total TTC (hors aides)</label>
                                <input type="text" id="total" disabled={true} defaultValue={total} />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-start">
                        <h4 className="mb-4">Ref. &amp; quantité produit</h4>
                        <div className="col-12 mb-4">
                            <div className="custom-textarea">
                                <ul>{buildCartArticles()}</ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="btn-grp justify-content-end">
                <Link to={ROUTE_SIM_RECOMMANDATION} className="btn btn-retour">
                    Retour
                </Link>

                <div className={pdfLoader || loader ? 'not-allowed' : 'allowed'}>
                    <button type="button" className="btn btn-bdc-modal" onClick={pdfModal} disabled={pdfLoader || loader}>
                        Visualiser le document
                    </button>
                </div>

                <div className={loader || pdfLoader ? 'not-allowed' : 'allowed'}>
                    <button className="btn btn-continue" onClick={validateAndNavigate} disabled={loader || pdfLoader}>
                        {loader && <Loader />}
                        <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BonDeCommande;
