import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import Select from 'react-select';
import { LabelledString, NoticeProps } from '../../../services/tools/TypeHelper';
import { ROUTE_PV_PHOTOVOLTAIQUE } from '../../../routing/paths';
import CheckboxTriState from '../../../components/checkbox/TriStateCheckbox';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';
import {
    FormValues,
    panelStyles,
    plannedInstallationList,
    electricMeterLocalisationList,
    electricalTypeNetworkList,
    electricMeterOutsideList,
    circuitBreakerList,
    distanceList,
    siteAccessibilityList,
    roofShapeNames,
    RoofShape,
    RoofShapeName,
    Point,
    materialStorageList,
} from './PhotovoltaiqueEnums';
import classnames from 'classnames';
import { GetValidationSchema } from './schema/schema';
import UploadInput from '../../../components/Upload/UploadInput';
import Cover from './component/Cover';
import RoofSchema from './component/RoofSchema';
import { renderDefaultValueSelect } from '../../../services/tools/selectValue';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { currentRouteAtom, nextPackageRouteSelector } from '../../../services/Recoil/Atom/PackageContext.atom';
import { useHistory } from 'react-router';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Map from './component/map/Map';
import { CustomPolygon, GeoPosition, Path } from '../../audit-simulator/rendement/Rendement';
import { getLevelCount, randomKeyGen } from '../../../services/tools/auditTools';
import { DEFAULT_LAT, DEFAULT_LNG, createImplantation } from '../../../services/tools/mapTools';
import { postJsonData, getDataBlob } from '../../../services/apiParticulierService';
import * as api from '../../../services/apiParticulierService';
import {
    Dot,
    EndPointType,
    HistoryItem,
    IMG_HEIGHT,
    IMG_WIDTH,
    LineType,
    ModalArrowProps,
    ModalChimneyProps,
    ModalCircleProps,
    ModalDormerProps,
    ModalDrawProps,
    ModalObservationProps,
    ModalPanelProps,
    ModalPhotovoltaiqueProps,
    ModalTextProps,
    ModalType,
    ModalTypes,
    ModalVeluxProps,
    STAGE_HEIGHT,
    STAGE_HEIGHT_DP5,
    STAGE_WIDTH,
    STAGE_WIDTH_DP5,
    convertCanvasToGeo,
    convertGeoToCanvas,
    generateImage,
} from '../../../services/tools/konvaTools';
import { Modal } from '../../../components/Modal/Modal';
import HistoryMap from './component/history/HistoryMap';
import { MapProperties, MapStateProps, mapState } from '../../../services/Recoil/Atom/map.atom';
import Konva from 'konva';
import GoogleMapsDraw, { nonEditablepolygonOptions } from '../../../components/GoogleMaps/GoogleMapsDraw';
import { TextElement } from '../../../components/Text/Text';
import { DP5 } from './component/DP5/DP5';
import GoogleMapsStreetView, { GooglePov } from '../../../components/GoogleMaps/GoogleMapsStreetView';
import { blobToBase64 } from '../../../services/tools/base64Helper';
import HistoryDP5 from './component/historyDP5/HistoryDP5';
import { DP5HistoryItem } from './component/DP5/DP5Enums';
import { Image } from 'konva/lib/shapes/Image';
import { Title } from '../../../components/Title/Title';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { errorState } from '../../../services/Recoil/Atom/dp5Error.atom';

// Icons
// import { ReactComponent as IconPoubelle } from '../../../assets/icons/icon-remove.svg';
import { ReactComponent as IconPhotovoltaique } from '../../../assets/icons/simulator/icon-photovoltaique.svg';
import { ReactComponent as IconRoof } from '../../../assets/icons/icon-roof.svg';
import { ReactComponent as IconPhoto } from '../../../assets/icons/pre-visite/icon-photo.svg';
import { ReactComponent as IconFrame } from '../../../assets/icons/icon-picture-frame.svg';
import { ReactComponent as IconSchemaRoof } from '../../../assets/icons/pre-visite/icon-roof.svg';
import { ReactComponent as IconSchemaChemney } from '../../../assets/icons/pre-visite/icon-chemney.svg';
import { ReactComponent as IconSchemaSkylight } from '../../../assets/icons/pre-visite/icon-skylight.svg';
import { ReactComponent as IconSchemaDormer } from '../../../assets/icons/pre-visite/icon-dormer.svg';
import { ReactComponent as IconSchemaPanel } from '../../../assets/icons/pre-visite/icon-panel-h.svg';
import { ReactComponent as IconFullBlackH } from '../../../assets/icons/konva/solar-panel-h.svg';
import { ReactComponent as IconFullBlackV } from '../../../assets/icons/konva/solar-panel-v.svg';
import { ReactComponent as IconPanelH } from '../../../assets/icons/konva/icon-photovoltaique-panel-h.svg';
import { ReactComponent as IconPanelV } from '../../../assets/icons/konva/icon-photovoltaique-panel-v.svg';
import { ReactComponent as IconWarning } from '../../../assets/icons/konva/icon-warning.svg';
import { ReactComponent as IconPanel } from '../../../assets/icons/konva/icon-photovoltaique.svg';
import { ReactComponent as Loader } from '../../../assets/icons/konva/icon-loader.svg';
import { ReactComponent as IconPin } from '../../../assets/icons/pre-visite/icon-pin-person.svg';
import { ReactComponent as IconChevron } from '../../../assets/icons/pre-visite/icon-chevron.svg';
import { ReactComponent as IconDelete } from '../../../assets/icons/konva/icon-trash.svg';

export const colors = ['#DEB887', '#A52A2A', '#00A2FF', '#F8D034', '#4C4C4C'];

const BASE_POSITION = {
    x: 50,
    y: 50,
};

const DEFAULT_POV: GooglePov = {
    heading: 34,
    pitch: 10,
    zoom: 1,
};
/**
 * initialise le roofshape dans initialValues
 * @returns RoofShape
 */
const initRoofShapes = () =>
    Object.keys(roofShapeNames).reduce((acc, curr, index) => {
        return { ...acc, [roofShapeNames[curr as any]]: { color: colors[index], elements: [] } };
    }, {} as RoofShape);

const GeneralInformations: React.FC = () => {
    const { values, setValues, touched, errors } = useFormikContext<FormValues>();

    const handlePDLChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void,
        values: FormValues
    ) => {
        const valueWithoutLetters: string = event.target.value.replace(/\D/g, '');

        setValues({
            ...values,
            pdlNumber: valueWithoutLetters,
        });

        storageService.setPrevisitValue('pdlNumber', valueWithoutLetters);
    };

    const getFieldClasses = (fieldName: keyof FormValues, required = true) => {
        const fieldValue = values[fieldName];
        const fieldError = errors[fieldName];
        const fieldTouched = touched[fieldName];
        const defaultClass = 'form-control';

        return classnames(defaultClass, {
            'has-value': fieldValue !== undefined && fieldValue !== null && fieldValue !== '',
            'is-required': required,
            'is-error': fieldError && fieldTouched,
            'is-valid': !fieldError && fieldTouched,
        });
    };

    return (
        <div className="card card-audit-simulator photovoltaique mb-5">
            <div className="card-header">
                <IconPhotovoltaique fill="#FFFFFF" />
                <h2>Informations générales</h2>
            </div>

            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-12 col-md-4 mb-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="plannedInstallation">
                                L'installation est prévue sur<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(plannedInstallationList, values.plannedInstallation)}
                                options={plannedInstallationList}
                                isClearable={false}
                                isSearchable={false}
                                isMulti={false}
                                placeholder="L'installation est prévue sur"
                                id="plannedInstallation"
                                styles={panelStyles}
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            plannedInstallation: event.value,
                                        });

                                        storageService.setPrevisitValue('plannedInstallation', event.value, event.label);
                                    }
                                }}
                                className={
                                    'basic-single' +
                                    (values.plannedInstallation ? ' filled' : '') +
                                    (touched.plannedInstallation && errors.plannedInstallation ? ' invalid' : '') +
                                    (!values.plannedInstallation ? ' required' : '')
                                }
                            />
                            {errors.plannedInstallation && touched.plannedInstallation && (
                                <span className="invalid-feedback">{errors.plannedInstallation}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="pdlNumber">
                                N° de PDL (présent sur la facture d'électricité)<sup>*</sup>
                            </label>
                            <Field
                                type="text"
                                id="pdlNumber"
                                value={values.pdlNumber}
                                placeholder="N° de PDL"
                                maxLength={14}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handlePDLChange(event, setValues, values);
                                }}
                                className={getFieldClasses('pdlNumber')}
                            />
                            {errors.pdlNumber && touched.pdlNumber && <span className="invalid-feedback">{errors.pdlNumber}</span>}
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <CheckboxTriState
                                title="Compteur Linky"
                                name="linkyMeter"
                                emptyLabel={true}
                                style={{ height: '43px' }}
                                onCustomChange={(event: boolean) => {
                                    if (event === true) {
                                        setValues({
                                            ...values,
                                            linkyMeter: event,
                                            remoteReport: '',
                                            remoteReportLocalisation: '',
                                        });

                                        storageService.setPrevisitValue('linkyMeter', event);
                                        storageService.removePrevisitValue('remoteReport');
                                        storageService.removePrevisitValue('remoteReportLocalisation');
                                    }
                                    storageService.setPrevisitValue('linkyMeter', event);
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="electricMeterLocalisation">
                                Le compteur d’électricité se trouve<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(electricMeterLocalisationList, values.electricMeterLocalisation)}
                                options={electricMeterLocalisationList}
                                isClearable={false}
                                isSearchable={false}
                                isMulti={false}
                                placeholder={'Le compteur se trouve'}
                                styles={panelStyles}
                                id="electricMeterLocalisation"
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            electricMeterLocalisation: event.value,
                                        });

                                        storageService.setPrevisitValue('electricMeterLocalisation', event.value, event.label);
                                    }

                                    if (event && event.value !== 'exterieur' && event.value !== 'public') {
                                        setValues({
                                            ...values,
                                            electricMeterLocalisation: event.value,
                                            electricMeterOutside: '',
                                            electricMeter30m: '',
                                        });

                                        storageService.setPrevisitValue('electricMeterLocalisation', event.value);
                                        storageService.removePrevisitValue('electricMeterOutside', true);
                                        storageService.removePrevisitValue('electricMeter30m');
                                    }
                                }}
                                className={
                                    'basic-single' +
                                    (values.electricMeterLocalisation ? ' filled' : '') +
                                    (touched.electricMeterLocalisation && errors.electricMeterLocalisation ? ' invalid' : '') +
                                    (!values.electricMeterLocalisation ? ' required' : '')
                                }
                            />
                            {errors.electricMeterLocalisation && touched.electricMeterLocalisation && (
                                <span className="invalid-feedback">{errors.electricMeterLocalisation}</span>
                            )}
                        </div>
                    </div>

                    {(values.electricMeterLocalisation === 'exterieur' || values.electricMeterLocalisation === 'public') && (
                        <>
                            <div className="col-12 col-md-4 mb-3">
                                <div className="form-group">
                                    <label htmlFor="electricMeterOutside">
                                        Coffret électrique extérieur<sup>*</sup>
                                    </label>
                                    <Select
                                        value={renderDefaultValueSelect(electricMeterOutsideList, values.electricMeterOutside)}
                                        options={electricMeterOutsideList}
                                        isClearable={false}
                                        isSearchable={false}
                                        placeholder={'Coffret électrique extérieur'}
                                        styles={panelStyles}
                                        id="electricMeterOutside"
                                        onChange={(event: LabelledString | null) => {
                                            if (event) {
                                                setValues({
                                                    ...values,
                                                    electricMeterOutside: event.value,
                                                });

                                                storageService.setPrevisitValue('electricMeterOutside', event.value, event.label);
                                            }
                                        }}
                                        className={
                                            'basic-single' +
                                            (values.electricMeterOutside ? ' filled' : '') +
                                            (touched.electricMeterOutside && errors.electricMeterOutside ? ' invalid' : '') +
                                            (!values.electricMeterOutside ? ' required' : '')
                                        }
                                    />
                                    {errors.plannedInstallation && touched.plannedInstallation && (
                                        <span className="invalid-feedback">{errors.plannedInstallation}</span>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 col-md-4 mb-3">
                                <CheckboxTriState
                                    emptyLabel={true}
                                    title="Le coffret électrique est situé à 30m de ma maison"
                                    name="electricMeter30m"
                                    style={{ height: '43px' }}
                                    onCustomChange={(e: boolean) => {
                                        storageService.setPrevisitValue('electricMeter30m', e);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-12 col-md-4 mb-3">
                        <CheckboxTriState
                            emptyLabel={true}
                            title="Un espace d'au moins 1m x 1m à proximité de l’installation photovoltaïque ou du tableau électrique est disponible"
                            name="electricMeterSpace"
                            style={{ height: '43px' }}
                            onCustomChange={(e: boolean) => {
                                storageService.setPrevisitValue('electricMeterSpace', e);
                            }}
                        />
                    </div>

                    {!values.linkyMeter && values.linkyMeter !== '' && (
                        <div className="col-12 col-md-4 mb-3">
                            <div className="form-group">
                                <CheckboxTriState
                                    title="Présence d'un télé report"
                                    name="remoteReport"
                                    emptyLabel={true}
                                    style={{ height: '43px' }}
                                    onCustomChange={(event: boolean) => {
                                        if (event === false) {
                                            setValues({
                                                ...values,
                                                remoteReport: event,
                                                remoteReportLocalisation: '',
                                            });

                                            storageService.setPrevisitValue('remoteReport', event);
                                            storageService.setPrevisitValue('remoteReportLocalisation', '');
                                        }
                                        storageService.setPrevisitValue('remoteReport', event);
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {values.remoteReport && !values.linkyMeter && (
                        <div className="col-12 col-md-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="remoteReportLocalisation">Localisation</label>
                                <Field
                                    type="text"
                                    name="remoteReportLocalisation"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setValues({
                                            ...values,
                                            remoteReportLocalisation: e.target.value,
                                        });

                                        storageService.setPrevisitValue('remoteReportLocalisation', e.target.value);
                                    }}
                                    placeholder="Localisation"
                                    value={values.remoteReportLocalisation}
                                    className={getFieldClasses('remoteReportLocalisation')}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="circuitBreaker">
                                Le disjoncteur se trouve<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(circuitBreakerList, values.circuitBreaker)}
                                options={circuitBreakerList}
                                isClearable={false}
                                isSearchable={false}
                                placeholder={'Le disjoncteur se trouve'}
                                styles={panelStyles}
                                id="circuitBreaker"
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            circuitBreaker: event.value,
                                        });

                                        storageService.setPrevisitValue('circuitBreaker', event.value, event.label);
                                    }
                                }}
                                className={
                                    'basic-single' +
                                    (values.circuitBreaker ? ' filled' : '') +
                                    (touched.circuitBreaker && errors.circuitBreaker ? ' invalid' : '') +
                                    (!values.circuitBreaker ? ' required' : '')
                                }
                            />
                            {errors.circuitBreaker && touched.circuitBreaker && <span className="invalid-feedback">{errors.circuitBreaker}</span>}
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="distance">
                                Distance coupure/compteur de production (mètres)<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(distanceList, values.distance)}
                                options={distanceList}
                                isClearable={false}
                                isSearchable={false}
                                placeholder={'Distance coupure/compteur de prod'}
                                styles={panelStyles}
                                id="distance"
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            distance: event.value,
                                        });

                                        storageService.setPrevisitValue('distance', event.value, event.label);
                                    }
                                }}
                                className={
                                    'basic-single' +
                                    (values.distance ? ' filled' : '') +
                                    (touched.distance && errors.distance ? ' invalid' : '') +
                                    (!values.distance ? ' required' : '')
                                }
                            />
                            {errors.distance && touched.distance && <span className="invalid-feedback">{errors.distance}</span>}
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="electricalTypeNetwork">
                                Type de réseau électrique<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(electricalTypeNetworkList, values.electricalTypeNetwork)}
                                options={electricalTypeNetworkList}
                                isClearable={false}
                                isSearchable={false}
                                isMulti={false}
                                placeholder={'Type de réseau électrique'}
                                styles={panelStyles}
                                id="electricalTypeNetwork"
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            electricalTypeNetwork: event.value,
                                        });

                                        storageService.setPrevisitValue('electricalTypeNetwork', event.value, event.label);
                                    }
                                }}
                                className={
                                    'basic-single' +
                                    (values.electricalTypeNetwork ? ' filled' : '') +
                                    (touched.electricalTypeNetwork && errors.electricalTypeNetwork ? ' invalid' : '') +
                                    (!values.electricalTypeNetwork ? ' required' : '')
                                }
                            />
                            {errors.electricalTypeNetwork && touched.electricalTypeNetwork && (
                                <span className="invalid-feedback">{errors.electricalTypeNetwork}</span>
                            )}
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <CheckboxTriState
                                title="Savez-vous si le tableau électrique de la maison est correctement relié à la terre (présence d’un câble vert/jaune) ?"
                                name="groundConnection"
                                emptyLabel={true}
                                onCustomChange={(e: boolean) => {
                                    if (e) {
                                        setValues({
                                            ...values,
                                            groundConnection: e,
                                            groundConnectionLocalisation: '',
                                        });
                                        storageService.setPrevisitValue('groundConnection', e);
                                        storageService.removePrevisitValue('groundConnectionLocalisation');
                                    }

                                    storageService.setPrevisitValue('groundConnection', e);
                                }}
                                style={{ height: '43px' }}
                            />
                        </div>
                    </div>
                    {values.groundConnection && (
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="groundConnectionLocalisation">
                                    Localisation<sup>*</sup>
                                </label>
                                <Field
                                    type="text"
                                    name="groundConnectionLocalisation"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setValues({
                                            ...values,
                                            groundConnectionLocalisation: e.target.value,
                                        });

                                        storageService.setPrevisitValue('groundConnectionLocalisation', e.target.value);
                                    }}
                                    placeholder="Localisation"
                                    value={values.groundConnectionLocalisation}
                                    className={getFieldClasses('groundConnectionLocalisation')}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <h4 className="card-body--inner-title mt-5 mb-3">Repérage</h4>
                <div className="row mb-3">
                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="materialStorage">
                                Possibilité de stockage de matériel chez le client<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(materialStorageList, values.materialStorage)}
                                options={materialStorageList}
                                name={'materialStorage'}
                                id={'materialStorage'}
                                placeholder="Possibilité de stockage de matériel chez le client"
                                isClearable={false}
                                isSearchable={false}
                                isMulti={false}
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            materialStorage: event.value,
                                        });

                                        storageService.setPrevisitValue('materialStorage', event.value, event.label);
                                    }
                                }}
                                styles={panelStyles}
                                className={
                                    'basic-single' +
                                    (values.materialStorage ? ' filled' : '') +
                                    (touched.materialStorage && errors.materialStorage ? ' invalid' : '') +
                                    (!values.materialStorage ? ' required' : '')
                                }
                            />
                            {touched.materialStorage && errors.materialStorage && <span className="invalid-feedback">{errors.materialStorage}</span>}
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <CheckboxTriState
                            emptyLabel={true}
                            name="distanceBelow3KmFromCoastline"
                            title="L'installation est-elle à proximité du littoral (à moins de 3 km) ?"
                            style={{ height: '43px' }}
                            onCustomChange={(e: boolean) => {
                                storageService.setPrevisitValue('distanceBelow3KmFromCoastline', e);
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-4 mb-3">
                        <div className="form-group">
                            <label htmlFor="siteAccessibility">
                                Accessibilité du site / type de camion<sup>*</sup>
                            </label>
                            <Select
                                value={renderDefaultValueSelect(siteAccessibilityList, values.siteAccessibility)}
                                options={siteAccessibilityList}
                                name="siteAccessibility"
                                id="siteAccessibility"
                                onChange={(event: LabelledString | null) => {
                                    if (event) {
                                        setValues({
                                            ...values,
                                            siteAccessibility: event.value,
                                        });

                                        storageService.setPrevisitValue('siteAccessibility', event.value, event.label);
                                    }
                                }}
                                isMulti={false}
                                isSearchable={false}
                                isClearable={false}
                                placeholder="Accessibilité du site / type de camion"
                                styles={panelStyles}
                                className={
                                    'basic-single' +
                                    (values.siteAccessibility ? ' filled' : '') +
                                    (touched.siteAccessibility && errors.siteAccessibility ? ' invalid' : '') +
                                    (!values.siteAccessibility ? ' required' : '')
                                }
                            />
                            {touched.siteAccessibility && errors.siteAccessibility && <span className="invalid-feedback">{errors.siteAccessibility}</span>}
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <div className="form-group">
                            <label htmlFor="gutterHeight">
                                Hauteur du sol à la gouttière<sup>*</sup>
                            </label>
                            <div className="input-group">
                                <Field
                                    type="text"
                                    name="gutterHeight"
                                    id="gutterHeight"
                                    className={getFieldClasses('gutterHeight')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setValues({
                                            ...values,
                                            gutterHeight: e.target.value,
                                        });

                                        storageService.setPrevisitValue('gutterHeight', e.target.value);
                                    }}
                                    placeholder="Hauteur du sol à la gouttière"
                                />
                                <span className="input-group-text">m</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <CheckboxTriState
                                emptyLabel={true}
                                name="immediatePavement"
                                title="Façade de la maison construite au abord immédiat d’un trottoir ou d'une route du domaine public"
                                style={{ height: '43px' }}
                                onCustomChange={(e: boolean) => {
                                    storageService.setPrevisitValue('immediatePavement', e);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <div className="form-group">
                            <CheckboxTriState
                                title="Une connexion internet est présente"
                                name="internet"
                                emptyLabel={true}
                                style={{ height: '43px' }}
                                onCustomChange={(e: boolean) => {
                                    if (!e) {
                                        setValues({
                                            ...values,
                                            internet: e,
                                            fai: '',
                                        });
                                        storageService.setPrevisitValue('internet', e);
                                        storageService.setPrevisitValue('fai', '');
                                    }
                                    storageService.setPrevisitValue('internet', e);
                                }}
                            />
                        </div>
                    </div>
                    {values.internet && (
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fai">
                                    Fournisseur<sup>*</sup>
                                </label>
                                <Field
                                    type="text"
                                    name="fai"
                                    placeholder="Fournisseur"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setValues({
                                            ...values,
                                            fai: e.target.value,
                                        });

                                        storageService.setPrevisitValue('fai', e.target.value);
                                    }}
                                    value={values.fai}
                                    className={getFieldClasses('fai')}
                                />
                                {touched.fai && errors.fai && <span className="invalid-feedback">{errors.fai}</span>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const CoverComponent: React.FC = () => {
    const { values, setValues } = useFormikContext<FormValues>();

    return (
        <div className="card card-audit-simulator photovoltaique mb-5">
            <div className="card-header">
                <IconRoof width="60" height="60" fill="white" />
                <h2>Couverture</h2>
            </div>

            <div className="card-body">
                <Cover setValues={setValues} values={values} />

                {/* <h4 className="card-body--inner-title mt-5 mb-4">
                                    Toiture et emplacement des modules <span>(vue du dessus)</span>
                                </h4>

                                <div className="row align-items-stretch mt-3">
                                     <div className="col-12 col-md-7">
                                        <div className="map">{panelPicture && <img src={panelPicture} title="Panneaux solaires" alt="" />}</div>
                                    </div>

                                    <div className="col-12 col-md-5 d-flex flex-column flex-wrap">
                                        <div className="map-legend mb-3">
                                            <span>
                                                Panneaux
                                                <br />
                                                photovoltaïques
                                            </span>
                                        </div>

                                        <ul className="ps-0">
                                            {solarEnergyPanels?.map((polygon: CustomPolygon, key: number) => (
                                                <li key={polygon.id}>
                                                    {polygon.area && (
                                                        <div className="row mb-3 d-flex align-items-center">
                                                            <div className="col col-8 d-flex align-items-center">
                                                                <label htmlFor="direction" className="col-3 m-0">{`Zone ${key + 1}`}</label>
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <input
                                                                            type="text"
                                                                            name={polygon.id}
                                                                            id={polygon.id}
                                                                            value={Math.round(polygon.area)}
                                                                            disabled={true}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="input-group-text">m²</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul> 
                                    </div>
                                </div>*/}
            </div>
        </div>
    );
};

type PanelProps = {
    selectedElementIndex: number | null;
    setSelectedElementIndex: React.Dispatch<React.SetStateAction<number | null>>;
};

type PanelDP5Props = {
    setSelectedElementIndex: React.Dispatch<React.SetStateAction<number | null>>;
    stageRef: React.RefObject<Konva.Stage>;
};

const Panels: React.FC<PanelProps> = ({ selectedElementIndex, setSelectedElementIndex }) => {
    const { values, setValues, touched, errors, setFieldValue } = useFormikContext<FormValues>();
    const [disableModule, setDisableModule] = useState<boolean>(false);
    const [error, setError] = useRecoilState(errorState);
    const formikRef = useRef<FormikProps<FormValues>>(null);
    const { step1 } = storageService.getAudit();
    const preVisite = storageService.getPrevisit();
    const stageRef = useRef<Konva.Stage>(null);
    const layerRef = useRef<Konva.Layer>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const drawSchemaState: boolean = preVisite.drawSchema.value;
    const [schemaVisible, setSchemaVisible] = useState<{ isVisible: boolean | undefined; label: string }>({
        isVisible: drawSchemaState,
        label: "Ajouter un schéma de l'installation",
    });
    const [canDraw, setCanDraw] = useState<boolean>(false);

    const [map, setMap] = useRecoilState<MapStateProps>(mapState);
    const [, setModalData] = useState<ModalTypes | null>(null);
    const [imgPosition, setImgPosition] = useState<{ x: number; y: number }>({ x: (STAGE_WIDTH - IMG_WIDTH) / 2, y: (STAGE_HEIGHT - IMG_HEIGHT) / 2 });
    const [cadastreError, setCadastreError] = useState<boolean>(false);
    const [moduleSelected, setModuleSelected] = useState<RoofShapeName>();

    // History
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentModalType, setCurrentModalType] = useState<ModalType>('');
    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [currentParagraph, setCurrentParagraph] = useState<string>('');

    // Google Earth
    const [googleMapState, setGoogleMapState] = useState<{ isVisible: boolean; label: 'Google Earth' | 'Vue cadastrale' }>({
        isVisible: false,
        label: 'Google Earth',
    });

    const handleGoogleMap = () => {
        setGoogleMapState((prevState) => ({ isVisible: !prevState.isVisible, label: prevState.label === 'Google Earth' ? 'Vue cadastrale' : 'Google Earth' }));
    };

    const center: GeoPosition = {
        lat: Number(step1.userAddressLatitude?.value) ?? DEFAULT_LAT,
        lng: Number(step1.userAddressLongitude?.value) ?? DEFAULT_LNG,
    };

    const openModal = (type: ModalType) => {
        setCurrentModalType(type);

        if (type === 'text') {
            setCurrentTitle('Afficher un texte');
            setCurrentParagraph('Saisissez le texte que vous voulez intégrer à la carte.');
        }

        if (type === 'draw') {
            setCurrentTitle(`Dessiner sur l'image`);
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'arrow') {
            setCurrentTitle('Ajouter une flèche');
            setCurrentParagraph('Veuillez sélectionner le type de flèche.');
        }

        if (type === 'observation') {
            setCurrentTitle('Associer un intitulé de photo à un point d’observation');
            setCurrentParagraph('Merci de remplir l’intitulé de la photo à laquelle sera associée ce point d’observation.');
        }

        if (type === 'velux') {
            setCurrentTitle('Ajouter un velux');
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'chimney') {
            setCurrentTitle('Ajouter une cheminée');
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'dormer') {
            setCurrentTitle('Ajouter un chien assis');
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'circle') {
            setCurrentTitle('Ajouter un cercle');
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'panel') {
            setCurrentTitle('Panneau photovoltaïque');
            setCurrentParagraph('Veuillez préciser le nombre de panneaux de la zone à dessiner.');
        }

        setShowModal(true);
    };

    // Toggle schema
    const handleSchema = (values: FormValues, setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void) => {
        const newVisibilityState = !schemaVisible.isVisible;

        setSchemaVisible({
            isVisible: newVisibilityState,
            label: !newVisibilityState ? "Ajouter le schéma de l'installation" : "Masquer le schéma de l'installation",
        });

        setValues({
            ...values,
            drawSchema: newVisibilityState,
        });

        const shouldSetDrawSchema = newVisibilityState || (values.schemaScale && values.schemaScale.length > 0);
        storageService.setPrevisitValue('drawSchema', shouldSetDrawSchema);
    };

    const canvasRef = useRef<HTMLCanvasElement>(null!);
    const elementToScroll = useRef<HTMLDivElement>(null);

    const onClickAddModule = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { currentTarget } = e;
        setModuleSelected(currentTarget.id as RoofShapeName);
        setDisableModule(true);
    };

    // fonction qui s'execute lorsque qu'on a terminé le tracé d'une shape
    const onCanvasPathOver = (points: Point[]) => {
        setModuleSelected(undefined);
        const allModules = {
            ...values.roofShape,
            [moduleSelected as RoofShapeName]: {
                ...values.roofShape[moduleSelected as RoofShapeName],
                elements: [...values.roofShape[moduleSelected as RoofShapeName].elements, points],
            },
        };

        setValues({
            ...values,
            roofShape: allModules,
        });
        // setLocalStoragePreVisiteValue('roofShape', allModules);
    };

    // fonction de suppression d'une shape
    const onClickRemoveModule = (e: React.MouseEvent<HTMLButtonElement>) => {
        const {
            currentTarget: { value, id },
        } = e;
        const moduleElemetsCopy = [...values.roofShape[id as RoofShapeName].elements];
        moduleElemetsCopy.splice(parseInt(value), 1);
        const allModules = {
            ...values.roofShape,
            [id as RoofShapeName]: {
                ...values.roofShape[id as RoofShapeName],
                elements: moduleElemetsCopy,
            },
        };

        setValues({
            ...values,
            roofShape: allModules,
        });
        // canvasRef.current.onchange = () => {
        //     setLocalStoragePreVisiteValue('roofShapePicture', canvasRef.current.toDataURL());
        // };
        // setLocalStoragePreVisiteValue('roofShape', allModules);
    };

    const filterKeyNames = (key: string) => {
        const removeUnderscore = key.replace('_', ' ');
        const cleanedKey = removeUnderscore.charAt(0).toUpperCase() + removeUnderscore.slice(1);

        return cleanedKey;
    };

    const getFieldClasses = (fieldName: keyof FormValues, required = true) => {
        const fieldValue = values[fieldName];
        const fieldError = errors[fieldName];
        const fieldTouched = touched[fieldName];
        const defaultClass = 'form-control';

        return classnames(defaultClass, {
            'has-value': fieldValue !== undefined && fieldValue !== null && fieldValue !== '',
            'is-required': required,
            'is-error': fieldError && fieldTouched,
            'is-valid': !fieldError && fieldTouched,
        });
    };

    // Format exemple : "5 rue de l'église, 92100 Boulogne-Billancourt"
    const address: string = `${step1.userAddress.value}, ${step1.userZipCode.value} ${step1.userLocality.value}`;
    const width: number = IMG_WIDTH;
    const height: number = IMG_HEIGHT;
    const zoom: number = 500;
    const threshold: number = 0.6;

    const updateParcelNumber = async (parcel?: string) => {
        let newHistory: HistoryItem[] = [];
        let parcelCode: string = '';

        try {
            setLoader(true);

            const data = await api.dpMap({ address, zoom, width, height, parcel, threshold });
            setMap(data);
            const dots: Dot[] = [];
            const multiPolygon = data.parcel ? data.parcel.geometry.coordinates[0] : [];

            // array of canvas points
            for (const ring of multiPolygon) {
                for (const coord of ring) {
                    const canvasCoord = convertGeoToCanvas(coord[1], coord[0], data.map.bbox, width, height);
                    dots.push({ x: canvasCoord.x, y: canvasCoord.y, isHovered: false });
                }
            }

            parcelCode = data.parcel.properties.numero;
            newHistory = [
                {
                    id: randomKeyGen(),
                    message: 'Parcelle',
                    type: 'draw',
                    deleted: false,
                    color: '#FF0000', // red
                    position: { x: 0, y: 0 },
                    drawing: {
                        dots: dots.map((dot: Dot) => ({ x: dot.x, y: dot.y, isHovered: false })),
                        completed: true,
                    },
                },
            ];

            if (newHistory.length > 0) {
                setFieldValue('history', newHistory);
                setFieldValue('parcel', parcelCode);
            }

            setCadastreError(false);
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des données :", error);
            setCadastreError(true);
            setLoader(false);

            // @Nicolas : Pourquoi que dans ce cas là ?
            // Si on est la c'est qu'on a un crash quelconque, c'est une erreur.
            // Genre une erreur 500, ou une défaut d'authentification 401 ou 403 ?
            setError(true);

            // // If address not found from cadastre.gouv
            // if (typeof error === 'object' && error !== null && 'statusCode' in error) {
            //     console.log('Erreur & statusCode');
            //     const statusCode = (error as { statusCode: number }).statusCode;
            //     if (statusCode === 404) {
            //         console.log('Erreur 404');
            //         setError(true);
            //     }
            // }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        updateParcelNumber();
    }, []);

    // Check if preVisit.roofShape.value.elements[] are empty
    useEffect(() => {
        const roofShape: RoofShape = preVisite.roofShape.value;
        const isAllEmpty = Object.values(roofShape).every((obj) => obj.elements.length === 0);
        if (isAllEmpty) {
            setSchemaVisible({ isVisible: false, label: "Ajouter le schéma de l'installation" });
            storageService.setPrevisitValue('drawSchema', false);
            if (formikRef.current) {
                formikRef.current.setFieldValue('drawSchema', false);
            }
        } else {
            setSchemaVisible({ isVisible: true, label: "Masquer le schéma de l'installation" });
            storageService.setPrevisitValue('drawSchema', true);
            if (formikRef.current) {
                formikRef.current.setFieldValue('drawSchema', true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // leave empty, need to be checked onLoad only

    const handleDrawValues = async (modalValues: ModalDrawProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'draw',
            opacity: modalValues.opacity,
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            drawing: { dots: [], completed: false },
            deleted: false,
        };

        setCanDraw(true);

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleTextValues = async (modalValues: ModalTextProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'text',
            message: modalValues.message,
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleArrowValues = async (modalValues: ModalArrowProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'arrow',
            message: 'Flèche',
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            arrow: { lineType: modalValues.lineType?.value as LineType, endPointType: modalValues.endPointType?.value as EndPointType },
            drawing: {
                dots: [
                    { x: 50, y: 50, isHovered: false },
                    { x: 150, y: 150, isHovered: false },
                ],
                completed: true,
            },
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handeObservationValues = async (modalValues: ModalObservationProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            position: BASE_POSITION,
            type: 'observation',
            message: modalValues.message,
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleVeluxValues = async (modalValues: ModalVeluxProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            position: BASE_POSITION,
            type: 'velux',
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color?.value : '#4C4C4C',
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleChimneyValues = async (modalValues: ModalVeluxProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'chimney',
            position: BASE_POSITION,
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color?.value : '#4C4C4C',
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleDormerValues = async (modalValues: ModalVeluxProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            position: BASE_POSITION,
            type: 'dormer',
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color?.value : '#4C4C4C',
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleCircleValues = async (modalValues: ModalVeluxProps) => {
        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            position: BASE_POSITION,
            type: 'circle',
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color?.value : '#4C4C4C',
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handlePanelValues = async (modalValues: ModalPanelProps) => {
        const iconToDisplay = modalValues.orientation?.value === 'landscape' ? <IconPanelH /> : <IconPanelV />;

        const canvaImage = await generateImage(
            modalValues.color?.value,
            modalValues.columns?.value,
            modalValues.lines?.value,
            'panel',
            modalValues.orientation?.value as 'landscape' | 'portrait',
            iconToDisplay
        );

        const imgHeight = canvaImage?.height ?? 0;
        const imgWidth = canvaImage?.width ?? 0;

        const x = 0;
        const y = 0;
        const dots: Dot[] = [
            { x: x, y: y },
            { x: x, y: y + imgHeight },
            { x: x + imgWidth, y: y + imgHeight },
            { x: x + imgWidth, y: y },
        ];

        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'panel',
            message: 'Panneaux photovoltaïque',
            image: canvaImage?.image ?? '',
            color: modalValues.color?.value ? modalValues.color?.value : '#4C4C4C',
            rect: {
                dots,
                attrs: {
                    x,
                    y,
                    width: imgWidth,
                    height: imgHeight,
                    offsetX: 0,
                    offsetY: 0,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                    skewX: 0,
                    skewY: 0,
                },
            },
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handlePhotovoltaiqueValues = async (modalValues: ModalPhotovoltaiqueProps) => {
        let iconToDisplay: JSX.Element;

        if (modalValues.color?.label === 'Full Black') {
            iconToDisplay = modalValues.orientation.value === 'landscape' ? <IconFullBlackH /> : <IconFullBlackV />;
        } else {
            iconToDisplay = modalValues.orientation.value === 'landscape' ? <IconPanelH /> : <IconPanelV />;
        }

        const canvaImage = await generateImage(
            modalValues.color?.value,
            modalValues.columns.value,
            modalValues.lines.value,
            'photovoltaique',
            modalValues.orientation.value,
            iconToDisplay
        );

        const imgHeight = canvaImage?.height ?? 0;
        const imgWidth = canvaImage?.width ?? 0;

        const ltX = (STAGE_WIDTH - imgWidth) / 2;
        const rtX = ltX + imgWidth;
        const rbX = ltX + imgWidth;
        const ltY = (STAGE_HEIGHT - imgHeight) / 2;
        const lbY = ltY + imgHeight;
        const rbY = ltY + imgHeight;

        const dots: Dot[] = [
            { x: ltX, y: ltY, isHovered: false },
            { x: ltX, y: lbY, isHovered: false },
            { x: rbX, y: rbY, isHovered: false },
            { x: rtX, y: ltY, isHovered: false },
        ];

        const newHistoryItem: HistoryItem = {
            id: randomKeyGen(),
            type: 'photovoltaique',
            message: 'Panneaux photovoltaïques',
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            image: canvaImage?.image ?? '',
            drawing: { dots, completed: true },
            deleted: false,
        };

        setValues({
            ...values,
            history: [...values.history, newHistoryItem],
        });
    };

    const handleModalValues = async (values: ModalTypes) => {
        setModalData(values);
        setShowModal(false);

        if (!values) return;

        switch (currentModalType) {
            case 'draw':
                await handleDrawValues(values as ModalDrawProps);
                break;
            case 'text':
                await handleTextValues(values as ModalTextProps);
                break;
            case 'arrow':
                await handleArrowValues(values as ModalArrowProps);
                break;
            case 'observation':
                await handeObservationValues(values as ModalObservationProps);
                break;
            case 'velux':
                await handleVeluxValues(values as ModalVeluxProps);
                break;
            case 'chimney':
                await handleChimneyValues(values as ModalChimneyProps);
                break;
            case 'dormer':
                await handleDormerValues(values as ModalDormerProps);
                break;
            case 'circle':
                await handleCircleValues(values as ModalCircleProps);
                break;
            case 'panel':
                await handlePanelValues(values as ModalPanelProps);
                break;

            default:
                break;
        }
    };

    const polygons: CustomPolygon[] = useMemo(() => {
        const items: CustomPolygon[] = [];
        if (!map || !map.map || !map.map.bbox || !values.history) return items;

        for (const item of values.history) {
            let coordinates: GeoPosition[] = [];
            if (item?.drawing?.dots) {
                coordinates = item?.drawing?.dots.map((dot) => {
                    return convertCanvasToGeo(dot.x, dot.y, (map.map as MapProperties).bbox, width, height);
                });
            }
            if (item.rect?.dots) {
                coordinates = item?.rect.dots.map((dot) => {
                    return convertCanvasToGeo(dot.x, dot.y, (map.map as MapProperties).bbox, width, height);
                });
            }

            const newInstance = new google.maps.Polygon({
                ...nonEditablepolygonOptions,
                fillColor: item.color,
                strokeColor: item.color,
                paths: coordinates,
            });

            const polygon: CustomPolygon = {
                paths: coordinates,
                id: randomKeyGen(),
                instance: newInstance,
            };
            items.push(polygon);
        }

        return items;
    }, [values.history, map]);

    const handleSchemaScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        // Vérifier si la valeur est numérique
        if (/^\d*$/.test(newValue)) {
            setValues({
                ...values,
                schemaScale: newValue,
            });

            storageService.setPrevisitValue('schemaScale', newValue);
        }
    };

    const schemaComponentsDropdown: React.ReactNode[] = [
        <button id="toiture" className="btn btn-schema" onClick={onClickAddModule} key={0}>
            Toiture
        </button>,
        <button id="cheminee" className="btn btn-schema" onClick={onClickAddModule} key={1}>
            Cheminée
        </button>,
        <button id="velux" className="btn btn-schema" onClick={onClickAddModule} key={2}>
            Velux
        </button>,
        <button id="chien_assis" className="btn btn-schema" onClick={onClickAddModule} key={3}>
            Chien assis
        </button>,
        <button id="panneaux_photovoltaiques" className="btn btn-schema btn-schema--smaller" onClick={onClickAddModule} key={4}>
            Panneaux photovoltaïques
        </button>,
    ];

    const iconToDisplay = (type: string) => {
        switch (type) {
            case 'velux':
                return (
                    <TextElement className="history-icon" style={{ backgroundColor: '#00a2ff' }}>
                        <IconSchemaSkylight fill="white" />
                    </TextElement>
                );
            case 'chien_assis':
                return (
                    <TextElement className="history-icon" style={{ backgroundColor: '#f8d034' }}>
                        <IconSchemaDormer fill="white" />
                    </TextElement>
                );
            case 'panneaux_photovoltaiques':
                return (
                    <TextElement className="history-icon" style={{ backgroundColor: '#4c4c4c' }}>
                        <IconSchemaPanel fill="white" />
                    </TextElement>
                );
            case 'cheminee':
                return (
                    <TextElement className="history-icon" style={{ backgroundColor: '#a52a2a' }}>
                        <IconSchemaChemney fill="white" />
                    </TextElement>
                );
            case 'toiture':
                return (
                    <TextElement className="history-icon" style={{ backgroundColor: '#deb887' }}>
                        <IconSchemaRoof fill="white" />
                    </TextElement>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="card card-audit-simulator photos mb-5" ref={elementToScroll}>
                <div className="card-header">
                    <IconPanel width="60" height="60" fill="white" />
                    <h2>Installation des panneaux</h2>
                </div>
                <div className="card-body">
                    <p className="pose-text">
                        Afin de générer automatiquement vos déclarations préalable, merci d'indiquer sur le plan suivant l'emplacement de vos installations
                        photovoltaïques.
                    </p>

                    <div className="row mt-4">
                        <div className="col-12 col-md-7">
                            <div className="map">
                                {loader && (
                                    <div className="loader">
                                        <p>Parcelle en cours de chargement, veuillez patienter</p>
                                        <Loader fill="#5189b3" width={60} />
                                    </div>
                                )}
                                {map && map.map && !loader && (
                                    <div className="google-earth">
                                        <button type="button" className="btn btn-earth" onClick={handleGoogleMap}>
                                            {googleMapState.label}
                                        </button>
                                    </div>
                                )}
                                {map && map.map && map.map.url && !googleMapState.isVisible && (
                                    <Map
                                        url={map.map.url}
                                        stageRef={stageRef}
                                        layerRef={layerRef}
                                        selectedElementIndex={selectedElementIndex}
                                        setSelectedElementIndex={setSelectedElementIndex}
                                        imgPosition={imgPosition}
                                        setImgPosition={setImgPosition}
                                        canDraw={canDraw}
                                        setCanDraw={setCanDraw}
                                        style={!googleMapState.isVisible ? { display: 'block' } : { display: 'none' }}
                                        setValues={setValues}
                                        values={values}
                                    />
                                )}
                                {googleMapState.isVisible && (
                                    <GoogleMapsDraw
                                        withDrawing={false}
                                        center={center}
                                        googleMapProps={{ mapContainerStyle: { height: 744 }, options: { zoom: 19 } }}
                                        // polygons={polygons}
                                    />
                                )}

                                <div className="cadastre-error" style={cadastreError ? { display: 'flex' } : { display: 'none' }}>
                                    <TextElement as="p" className="mb-0 text-center">
                                        Parcelle non reconnue
                                        <br />
                                        Le numéro de parcelle n'est pas valide ou introuvable.
                                    </TextElement>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <HistoryMap
                                setSelectedElementIndex={setSelectedElementIndex}
                                openModal={openModal}
                                parcelNumber={map?.parcel?.properties.numero}
                                updateParcel={updateParcelNumber}
                                disabled={loader || googleMapState.isVisible || cadastreError}
                                disabledCadastre={loader || googleMapState.isVisible || error}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        {googleMapState.isVisible && (
                            <div className="d-flex align-items-center gap-2 google-map-warning mb-3">
                                <IconWarning fill="#5189b3" width={20} />
                                <TextElement as="p">
                                    La position des panneaux photovoltaïque est indicative. Seule la position visible sur le plan cadastrale fait foi.
                                </TextElement>
                            </div>
                        )}
                        <div className="d-flex align-items-center gap-4">
                            <p className="pose-text mb-0">
                                Souhaitez vous apporter plus de précisions sur l'emplacement des panneaux
                                <br />
                                en dessinant l'installation.
                            </p>
                            <div className="btn-grp justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-dropdown btn-dropdown--icon"
                                    data-state={schemaVisible.isVisible ? 'open' : 'close'}
                                    // disabled={loader}
                                    onClick={() => handleSchema(values, setValues)}
                                >
                                    <IconChevron fill="#FFF" />
                                    {schemaVisible.label}
                                </button>
                            </div>
                        </div>

                        {schemaVisible.isVisible && (
                            <>
                                <h4 className="card-body--inner-title mt-5 mb-2">
                                    Schéma de la toiture et emplacement des modules <span>(vue du dessus)</span>
                                </h4>
                                <p className="schema-buttons-laius">Sélectionnez le composant que vous souhaitez ajouter sur le plan</p>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-7">
                                        <RoofSchema canvasRef={canvasRef} onCanvasPathOver={onCanvasPathOver} shapeModule={moduleSelected} />
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="history history-schema">
                                            <div className="parcelle">
                                                <TextElement as="p">
                                                    Echelle (longueur d'un carreau du schéma)<sup>*</sup>
                                                </TextElement>
                                                <div className="input-group">
                                                    <Field
                                                        type="text"
                                                        name="schemaScale"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSchemaScaleChange(e)}
                                                        className={getFieldClasses('schemaScale')}
                                                    />
                                                    <span className="input-group-text">cm</span>
                                                </div>
                                            </div>
                                            {touched.schemaScale && errors.schemaScale && <span className="invalid-feedback">{errors.schemaScale}</span>}

                                            <div className="history-content">
                                                <div className="history-title">
                                                    <Title as="h2">Composants</Title>
                                                    <div className="history-dropdown">
                                                        <Dropdown label="Ajout composant" items={schemaComponentsDropdown} />
                                                    </div>
                                                </div>
                                                <div className="history-list">
                                                    {Object.values(values.roofShape).every((obj) => obj.elements.length === 0) ? (
                                                        <TextElement as="p" className="no-component text-center mb-0">
                                                            Aucun composant ajouté
                                                        </TextElement>
                                                    ) : (
                                                        <ul className="history-list-content">
                                                            {Object.keys(values.roofShape).map((key) =>
                                                                Object.values(
                                                                    values.roofShape[key as RoofShapeName].elements.map((_, index) => (
                                                                        <li key={index}>
                                                                            <TextElement as="p">
                                                                                {iconToDisplay(key)}
                                                                                <span className="text-overflow">
                                                                                    {filterKeyNames(key)} {index + 1}
                                                                                </span>
                                                                            </TextElement>
                                                                            <button
                                                                                id={`${key}`}
                                                                                className="btn btn-remove"
                                                                                value={index}
                                                                                onClick={onClickRemoveModule}
                                                                            >
                                                                                <IconDelete fill="#cbd7ef" />
                                                                            </button>
                                                                        </li>
                                                                    ))
                                                                )
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal
                    title={currentTitle}
                    paragraph={currentParagraph}
                    modalType={currentModalType}
                    show={showModal}
                    setShow={setShowModal}
                    onConfirm={handleModalValues}
                />
            )}
        </>
    );
};

const PanelsDP5: React.FC<PanelDP5Props> = ({ setSelectedElementIndex, stageRef }) => {
    const { values, setValues } = useFormikContext<FormValues>();
    const [googleStreetView, setGoogleStreetView] = useState<boolean>(true);
    const [googleStreetViewButton, setGoogleStreetViewButton] = useState<string>('Utiliser cette image de Street View');
    const [photoButton, setPhotoButton] = useState<string>('Insérer une photo');
    const streetViewRef = useRef<google.maps.StreetViewPanorama | null>(null);
    const { step1 } = storageService.getAudit();
    const center: GeoPosition = {
        lat: Number(step1.userAddressLatitude?.value) ?? DEFAULT_LAT,
        lng: Number(step1.userAddressLongitude?.value) ?? DEFAULT_LNG,
    };
    const layerRef = useRef<Konva.Layer>(null);
    const [canDraw, setCanDraw] = useState<boolean>(false);
    const error = useRecoilValue(errorState);

    // History
    const [, setModalData] = useState<ModalTypes | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentModalType, setCurrentModalType] = useState<ModalType>('');
    const [currentTitle, setCurrentTitle] = useState<string>('');
    const [currentParagraph, setCurrentParagraph] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const acceptedFiles: string = 'image/jpg, image/jpeg, image/png, image/webp';

    const takePicture = useCallback(async () => {
        if (!streetViewRef.current) return;
        const pov = streetViewRef.current.getPov() as GooglePov;
        const position = streetViewRef.current.getPosition();
        const fov = 180 / Math.pow(1.85, pov.zoom);
        const lat = position?.lat();
        const lng = position?.lng();
        const url = `https://maps.googleapis.com/maps/api/streetview?size=716x475&location=${lat},${lng}&heading=${pov.heading}&pitch=${pov.pitch}&fov=${fov}&key=AIzaSyDxohMYOWq_Sb_bkcw38ooqqPVCHtqFdiI`;

        // console.log({ pov, lat, lng, url });
        const data = await getDataBlob(url);
        const myFile = new File([data], 'dp5.jpeg', { type: data.type });
        const base64 = await blobToBase64(myFile);

        storageService.setPrevisitValue('singleStoreyHousePicture', base64, 'dp5.jpeg');

        setValues({
            ...values,
            singleStoreyHousePicture: base64,
            historyDP5: [],
        });

        setGoogleStreetViewButton('Modifier cette image de Street View');
        setPhotoButton('Insérer une photo');

        setGoogleStreetView(false);
        setDisabled(false);
    }, [streetViewRef.current, values]);

    const manualPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result as string;

                setValues({
                    ...values,
                    singleStoreyHousePicture: base64,
                    historyDP5: [],
                });

                storageService.setPrevisitValue('singleStoreyHousePicture', base64, 'DP5.jpeg');
            };
            reader.readAsDataURL(file);
        }

        setGoogleStreetViewButton('Utiliser une image de Street View');
        setPhotoButton('Modifier cette photo');
        setGoogleStreetView(false);
        setDisabled(false);
    };

    const backToStreetView = () => {
        setValues({
            ...values,
            singleStoreyHousePicture: '',
            historyDP5: [],
        });

        storageService.setPrevisitValue('singleStoreyHousePicture', '', '');

        setGoogleStreetViewButton('Utiliser cette image de Street View');
        setPhotoButton('Insérer une photo');

        setGoogleStreetView(true);
        setDisabled(true);
    };

    const openModal = (type: ModalType) => {
        setCurrentModalType(type);
        if (type === 'photovoltaique') {
            setCurrentTitle('Panneaux photovoltaïques');
            setCurrentParagraph('Veuillez préciser le nombre de panneaux de la zone à dessiner.');
        }

        if (type === 'draw') {
            setCurrentTitle(`Dessiner sur l'image`);
            setCurrentParagraph('Merci de saisir un intitulé et une couleur au composant que vous allez créer.');
        }

        if (type === 'text') {
            setCurrentTitle('Afficher un texte');
            setCurrentParagraph('Saisissez le texte que vous voulez intégrer à la carte.');
        }

        if (type === 'arrow') {
            setCurrentTitle('Ajouter une flèche');
            setCurrentParagraph('Veuillez sélectionner le type de flèche.');
        }

        setShowModal(true);
    };

    const handleDrawValues = async (modalValues: ModalDrawProps) => {
        const newHistoryItem: DP5HistoryItem = {
            id: randomKeyGen(),
            type: 'draw',
            opacity: modalValues.opacity,
            message: modalValues.message,
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            drawingElements: { dots: [], completed: false },
            deleted: false,
        };

        setCanDraw(true);

        setValues({
            ...values,
            historyDP5: [...values.historyDP5, newHistoryItem],
        });
    };

    const handleTextValues = async (modalValues: ModalTextProps) => {
        const newHistoryItem: DP5HistoryItem = {
            id: randomKeyGen(),
            type: 'text',
            message: modalValues.message,
            drawingElements: { dots: [], completed: true },
            deleted: false,
        };

        setValues({
            ...values,
            historyDP5: [...values.historyDP5, newHistoryItem],
        });
    };

    const handleArrowValues = async (modalValues: ModalArrowProps) => {
        const newHistoryItem: DP5HistoryItem = {
            id: randomKeyGen(),
            type: 'arrow',
            message: 'Flèche',
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            arrow: { lineType: modalValues.lineType?.value as LineType, endPointType: modalValues.endPointType?.value as EndPointType },
            drawingElements: {
                dots: [
                    { x: 50, y: 50, isHovered: false },
                    { x: 150, y: 150, isHovered: false },
                ],
                completed: true,
            },
            deleted: false,
        };

        setValues({
            ...values,
            historyDP5: [...values.historyDP5, newHistoryItem],
        });
    };

    const handlePhotovoltaiqueValues = async (modalValues: ModalPhotovoltaiqueProps) => {
        const iconToDisplay: JSX.Element = modalValues.orientation.value === 'landscape' ? <IconPanelH /> : <IconPanelV />;

        const canvaImage = await generateImage(
            modalValues.color?.value,
            modalValues.columns.value,
            modalValues.lines.value,
            'photovoltaique',
            modalValues.orientation.value,
            iconToDisplay
        );

        const imgHeight = canvaImage?.height ?? 0;
        const imgWidth = canvaImage?.width ?? 0;

        const ltX = (STAGE_WIDTH_DP5 - imgWidth) / 2;
        const rtX = ltX + imgWidth;
        const rbX = ltX + imgWidth;
        const ltY = (STAGE_HEIGHT_DP5 - imgHeight) / 2;
        const lbY = ltY + imgHeight;
        const rbY = ltY + imgHeight;

        const dots: Dot[] = [
            { x: ltX, y: ltY, isHovered: false },
            { x: ltX, y: lbY, isHovered: false },
            { x: rbX, y: rbY, isHovered: false },
            { x: rtX, y: ltY, isHovered: false },
        ];

        const newHistoryItem: DP5HistoryItem = {
            id: randomKeyGen(),
            type: 'photovoltaique',
            message: 'Panneaux photovoltaïques',
            color: modalValues.color?.value ? modalValues.color.value : '#4C4C4C',
            image: canvaImage?.image ?? '',
            drawingElements: { dots, completed: true },
            deleted: false,
        };

        setValues({
            ...values,
            historyDP5: [...values.historyDP5, newHistoryItem],
        });
    };

    const handleModalValues = async (values: ModalTypes) => {
        setModalData(values);
        setShowModal(false);

        if (!values) return;

        switch (currentModalType) {
            case 'draw':
                await handleDrawValues(values as ModalDrawProps);
                break;
            case 'text':
                await handleTextValues(values as ModalTextProps);
                break;
            case 'arrow':
                await handleArrowValues(values as ModalArrowProps);
                break;
            case 'photovoltaique':
                await handlePhotovoltaiqueValues(values as ModalPhotovoltaiqueProps);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className="card card-audit-simulator photos mb-5">
                <div className="card-header">
                    <IconFrame width="60" height="60" fill="white" />
                    <h2>Vue de plain-pied</h2>
                </div>
                <div className="card-body">
                    <TextElement as="p" className="pose-text">
                        Afin de générer automatiquement votre déclaration mairie, merci de dessiner sur la maison l'emplacement de l'installation
                        photovoltaïque, en sélectionnant une vue sur Street View ou en chargeant une photo de plain-pied de l'habitation.
                    </TextElement>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div className="dp5">
                                {googleStreetView && !error ? (
                                    <GoogleMapsStreetView
                                        center={center}
                                        streetViewRef={streetViewRef}
                                        panoramaOptions={{
                                            pov: {
                                                heading: DEFAULT_POV.heading,
                                                pitch: DEFAULT_POV.pitch,
                                            },
                                            zoom: DEFAULT_POV.zoom,
                                        }}
                                        mapContainerStyle={{ width: '100%', height: '529px', borderRadius: '10px' }}
                                    />
                                ) : (
                                    <DP5
                                        background={values.singleStoreyHousePicture || ''}
                                        setSelectedElementIndex={setSelectedElementIndex}
                                        stageRef={stageRef}
                                        layerRef={layerRef}
                                    />
                                )}

                                <div
                                    className="cadastre-error"
                                    style={error && values.singleStoreyHousePicture === '' ? { display: 'flex' } : { display: 'none' }}
                                >
                                    <TextElement as="p" className="mb-0 text-center">
                                        Merci de charger une photo de la maison de plain pied.
                                    </TextElement>
                                </div>
                            </div>

                            <div className="btn-grp pt-3">
                                {googleStreetView && !error ? (
                                    <button
                                        type="button"
                                        className="btn btn-submit btn-submit--xl"
                                        onClick={takePicture}
                                        disabled={error}
                                        style={error ? { display: 'none' } : {}}
                                    >
                                        <IconPin height={30} fill="#FFF" />
                                        {googleStreetViewButton}
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-submit btn-submit--xl"
                                        onClick={backToStreetView}
                                        disabled={error}
                                        style={error ? { display: 'none' } : {}}
                                    >
                                        <IconPin height={30} fill="#FFF" />
                                        {googleStreetViewButton}
                                    </button>
                                )}

                                <div className="btn btn-submit btn-submit--xl">
                                    <input type="file" name="picture-dp5" onChange={manualPicture} accept={acceptedFiles} />
                                    <IconFrame height={30} fill="#FFF" />
                                    {photoButton}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <HistoryDP5 openModal={openModal} setSelectedElementIndex={setSelectedElementIndex} disabled={disabled} />
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal
                    title={currentTitle}
                    paragraph={currentParagraph}
                    modalType={currentModalType}
                    show={showModal}
                    setShow={setShowModal}
                    onConfirm={handleModalValues}
                />
            )}
        </>
    );
};

const Photos: React.FC = () => {
    const { setFieldValue, values } = useFormikContext<FormValues>();
    const preVisite = storageService.getPrevisit();
    const flowId = localStorage.getItem('flowId')!;
    const AlertSwal = withReactContent(Swal);
    const nocticeType: NoticeProps = 'previsit';

    // Pictures
    const edfPictureFileName = preVisite.edfMeterPicture.value_label;
    const electricMeterPictureFileName = preVisite.electricMeterPicture.value_label;
    const circuitBreakerPictureFileName = preVisite.circuitBreakerPicture.value_label;
    const inverterOrEnergyMeterRoomPictureFileName = preVisite.inverterOrEnergyMeterRoomPicture.value_label;
    // const singleStoreyHousePictureFileName = preVisite.singleStoreyHousePicture.value_label;
    const houseRearFacePictureFileName = preVisite.houseRearFacePicture.value_label;
    const panoramicBackPicture1FileName = preVisite.panoramicBackPicture1.value_label;
    const panoramicBackPicture2FileName = preVisite.panoramicBackPicture2.value_label;
    const panoramicBackPicture3FileName = preVisite.panoramicBackPicture3.value_label;
    const panoramicFacePicture1FileName = preVisite.panoramicFacePicture1.value_label;
    const panoramicFacePicture2FileName = preVisite.panoramicFacePicture2.value_label;
    const panoramicFacePicture3FileName = preVisite.panoramicFacePicture3.value_label;

    const sendAwaitedPhotos = async () => {
        try {
            await postJsonData(`flow/${flowId}/send-notice/${nocticeType}`);
            AlertSwal.fire({
                title: '',
                html: <p>L'email Notice de prise de photos sur la pré-visite a bien été envoyé.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        } catch (error) {
            console.error(error);
            AlertSwal.fire({
                title: 'Erreur',
                html: <p>Une erreur est survenue lors de l'envoi de votre mail.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    return (
        <div className="card card-audit-simulator photos mb-5">
            <div className="card-header">
                <IconPhoto width="60" height="60" fill="white" />
                <h2>Photos</h2>

                <button type="button" className="btn btn-secondary ms-auto" onClick={sendAwaitedPhotos}>
                    Envoyer la liste des photos attendues
                </button>
            </div>
            <div className="card-body">
                <h5 className="card-body--inner-title mb-3">Général</h5>
                <div className="row mb-4">
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="edfMeterPicture"
                            label="Compteur du fournisseur d'électricité"
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={edfPictureFileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="electricMeterPicture"
                            label="Tableau électrique"
                            setFieldValue={setFieldValue}
                            pictureFileName={electricMeterPictureFileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="circuitBreakerPicture"
                            label="Disjoncteur"
                            setFieldValue={setFieldValue}
                            pictureFileName={circuitBreakerPictureFileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="inverterOrEnergyMeterRoomPicture"
                            label="Local ou sera installé l'onduleur ou le compteur d'énergie"
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={inverterOrEnergyMeterRoomPictureFileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="singleStoreyHousePicture"
                            label={`Photo de la maison de plain pied ou se fera l'installation`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={values.singleStoreyHousePicture ? 'dp5.jpeg' : undefined}
                            style={{ pointerEvents: 'none', userSelect: 'none' }}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <UploadInput
                            name="houseRearFacePicture"
                            label={"Photo de la maison de plain pied face arrière ou se fera l'installation"}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={houseRearFacePictureFileName}
                        />
                    </div>
                </div>
                <h5 className="card-body--inner-title mb-3">Maison dans son dos pour voir son horizon</h5>
                <div className="row mb-5">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <UploadInput
                            name="panoramicFacePicture1"
                            label={`Panoramique de la maison à 180°\nPhoto 1`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicFacePicture1FileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <UploadInput
                            name="panoramicFacePicture2"
                            label={`Panoramique de la maison à 180°\nPhoto 2`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicFacePicture2FileName}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <UploadInput
                            name="panoramicFacePicture3"
                            label={`Panoramique de la maison à 180°\nPhoto 3`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicFacePicture3FileName}
                        />
                    </div>
                </div>
                <h5 className="card-body--inner-title mb-3">Maison face à soi pour voir la maison dans son environnement</h5>
                <div className="row">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <UploadInput
                            name="panoramicBackPicture1"
                            label={`Panoramique de la maison à 180°\nPhoto 1`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicBackPicture1FileName}
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <UploadInput
                            name="panoramicBackPicture2"
                            label={`Panoramique de la maison à 180°\nPhoto 2`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicBackPicture2FileName}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <UploadInput
                            name="panoramicBackPicture3"
                            label={`Panoramique de la maison à 180°\nPhoto 3`}
                            smallerLabel={true}
                            setFieldValue={setFieldValue}
                            pictureFileName={panoramicBackPicture3FileName}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Photovoltaique: React.FC = () => {
    const formikRef = useRef<FormikProps<FormValues>>(null);
    const [nextStepLoader, setNextStepLoader] = useState<boolean>(false);
    // Values from Audit
    const audit = storageService.stepListToAuditAndClient().audit;
    const { step1, step2, step4 } = storageService.getAudit();
    const preVisite = storageService.getPrevisit();
    const bdc = storageService.getSim();
    const setCurrent = useSetRecoilState(currentRouteAtom);
    const nextRoute = useRecoilValue(nextPackageRouteSelector);
    const history = useHistory();
    const solarEnergyPanels = bdc.solarEnergyPanels.value;
    const [, setPanelPicture] = useState<string | null>(null);
    const gutterHeightResult: number = getLevelCount(audit, 'horsSShorsCA') * 3;
    const [selectedElementIndex, setSelectedElementIndex] = useState<number | null>(null);
    const { push } = useHistory();
    const map = useRecoilValue<MapStateProps>(mapState);
    const dp5StageRef = useRef<Konva.Stage>(null);
    const error = useRecoilValue(errorState);

    const pdl: string = step4.pdlNumber?.value ?? '';
    const houseAge: string = step2.houseAge.value;
    // prepare data to display / store
    useEffect(() => {
        if (!preVisite.coverConstructionYear.value) {
            storageService.setPrevisitValue('coverConstructionYear', houseAge);
        }

        if (!preVisite.pdlNumber.value) {
            storageService.setPrevisitValue('pdlNumber', pdl);
        }

        storageService.setPrevisitValue('gutterHeight', gutterHeightResult);

        const panelPolygons: Path[] = solarEnergyPanels?.map(({ paths }: { paths: Path }) => paths);
        const center: GeoPosition = {
            lat: Number(step1.userAddressLatitude?.value) ?? DEFAULT_LAT,
            lng: Number(step1.userAddressLongitude?.value) ?? DEFAULT_LNG,
        };
        const implantation = createImplantation(center, panelPolygons);
        storageService.setPrevisitValue('solarPanelsPicture', implantation);
        setPanelPicture(implantation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [solarEnergyPanels]);

    const initialValues: FormValues = {
        plannedInstallation: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'plannedInstallation',
            "L'installation est prévue sur",
            PropertyType['basic']
        ),
        electricMeterLocalisation: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'electricMeterLocalisation',
            'Le compteur d’électricité se trouve',
            PropertyType['basic']
        ),
        electricalTypeNetwork: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'electricalTypeNetwork',
            'Type de réseau électrique',
            PropertyType['basic']
        ),
        electricMeterOutside: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'electricMeterOutside',
            'Coffret électrique extérieur',
            PropertyType['basic']
        ),
        circuitBreaker: storageService.checkPropertyExistThenCreateOrRenderPreVisite('circuitBreaker', 'Le disjoncteur se trouve', PropertyType['basic']),
        distance: storageService.checkPropertyExistThenCreateOrRenderPreVisite('distance', 'Distance coupure/compteur de prod', PropertyType['basic']),
        pdlNumber: !preVisite.pdlNumber.value
            ? pdl
            : storageService.checkPropertyExistThenCreateOrRenderPreVisite(
                  'pdlNumber',
                  "N° de PDL (présent sur la facture d'électricité)",
                  PropertyType['basic']
              ),
        electricMeter30m: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'electricMeter30m',
            'Le coffret électrique est situé à 30m de ma maison',
            PropertyType['boolean']
        ),
        electricMeterSpace: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'electricMeterSpace',
            "Un espace d'au moins 1m x 1m à proximité du panneau ou coffret est disponible",
            PropertyType['boolean']
        ),
        remoteReport: storageService.checkPropertyExistThenCreateOrRenderPreVisite('remoteReport', "Présence d'un télé report", PropertyType['boolean']),
        remoteReportLocalisation: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'remoteReportLocalisation',
            'Localisation',
            PropertyType['basic']
        ),
        groundConnection: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'groundConnection',
            'Savez-vous si le tableau électrique de la maison est correctement relié à la terre ?',
            PropertyType['boolean']
        ),
        groundConnectionLocalisation: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'groundConnectionLocalisation',
            'Localisation',
            PropertyType['basic']
        ),
        linkyMeter: storageService.checkPropertyExistThenCreateOrRenderPreVisite('linkyMeter', 'Compteur Linky', PropertyType['boolean']),
        gutterHeight: gutterHeightResult.toString(),
        internet: storageService.checkPropertyExistThenCreateOrRenderPreVisite('internet', 'Une connexion internet est présente', PropertyType['boolean']),
        fai: storageService.checkPropertyExistThenCreateOrRenderPreVisite('fai', 'Fournisseur', PropertyType['basic']),
        materialStorage: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'materialStorage',
            'Possibilité de stockage de matériel chez le client',
            PropertyType['basic']
        ),
        siteAccessibility: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'siteAccessibility',
            'Accessibilité du site / type de camion',
            PropertyType['basic']
        ),
        immediatePavement: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'immediatePavement',
            "Façade de la maison construite au bord immédiat trottoir ou d'une route du domaine public",
            PropertyType['boolean']
        ),
        coverType: storageService.checkPropertyExistThenCreateOrRenderPreVisite('coverType', 'Type de couverture', PropertyType['basic']),
        coverTilesDetail: storageService.checkPropertyExistThenCreateOrRenderPreVisite('coverTilesDetail', "Préciser si 'Autre'", PropertyType['basic']),
        coverConstructionYear: !preVisite.coverConstructionYear.value
            ? houseAge
            : storageService.checkPropertyExistThenCreateOrRenderPreVisite(
                  'coverConstructionYear',
                  'Année de construction de la couverture',
                  PropertyType['basic']
              ),
        tilesState: storageService.checkPropertyExistThenCreateOrRenderPreVisite('tilesState', 'Etat des éléments de couverture', PropertyType['basic']),
        fullRenovation: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'fullRenovation',
            'Rénovation du restant du pan de la toiture à prévoir',
            PropertyType['boolean']
        ),
        distanceBelow3KmFromCoastline: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'distanceBelow3KmFromCoastline',
            'Distance inférieure à 3 km du littoral',
            PropertyType['boolean']
        ),
        roofShape:
            storageService.checkPropertyExistThenCreateOrRenderPreVisite(
                'roofShape',
                'Schéma de la toiture et emplacement des modules',
                PropertyType['roofShape']
            ) || initRoofShapes(),
        drawSchema: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'drawSchema',
            'Schéma de la toiture et emplacement des modules',
            PropertyType['boolean']
        ),
        schemaScale: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'schemaScale',
            "Echelle (longueur d'un carreau du schéma)",
            PropertyType['basic']
        ),
        roofShapePicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite('roofShapePicture', 'Image du schéma', PropertyType['basic']) || '',
        edfMeterPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'edfMeterPicture',
            'Compteur du fournisseur d’électricité',
            PropertyType['basic']
        ),
        electricMeterPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite('electricMeterPicture', 'Tableau électrique', PropertyType['basic']),
        circuitBreakerPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite('circuitBreakerPicture', 'Disjoncteur', PropertyType['basic']),
        inverterOrEnergyMeterRoomPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'inverterOrEnergyMeterRoomPicture',
            "Local ou sera installé l'onduleur ou le compteur d'énergie",
            PropertyType['basic']
        ),
        singleStoreyHousePicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'singleStoreyHousePicture',
            'Photo de la maison de plain pied ou se fera l’installation',
            PropertyType['basic']
        ),
        houseRearFacePicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'houseRearFacePicture',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicBackPicture1: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicBackPicture1',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicBackPicture2: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicBackPicture2',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicBackPicture3: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicBackPicture3',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicFacePicture1: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicFacePicture1',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicFacePicture2: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicFacePicture2',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        panoramicFacePicture3: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'panoramicFacePicture3',
            'Photo de la maison de plain pied face arrière ou se fera l’installation',
            PropertyType['basic']
        ),
        history: storageService.checkPropertyExistThenCreateOrRenderPreVisite('history', 'Historique des panneaux', PropertyType['array']),
        historyDP5: [],
        parcel: storageService.checkPropertyExistThenCreateOrRenderPreVisite('parcel', 'Parcelle', PropertyType['basic']),
    };

    const validationSchema = GetValidationSchema();

    // Scroll top & set current package
    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrent(ROUTE_PV_PHOTOVOLTAIQUE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Promisify the Konva Image process to simplify integration
    const buildKonvaImageFromURL = async (imageUrl: string): Promise<Image> => {
        return new Promise((resolve, reject) => {
            try {
                Konva.Image.fromURL(
                    imageUrl,
                    (node) => {
                        resolve(node);
                    },
                    (error) => reject(error)
                );
            } catch (error) {
                reject(error);
            }
        });
    };

    // Create an url (base64) of a DP image + components for given Dp params
    const createDpImageUrl = async (params: api.DpParams, history: HistoryItem[]) => {
        // Peut crasher, mais c'est attrappé par l'appelant.
        const dp = await api.dpMap(params);
        const width = params.width;
        const height = params.height;

        const div = document.createElement('div');
        document.body.appendChild(div);
        const id = `konva-container-${randomKeyGen()}`;
        div.id = id;

        const stage = new Konva.Stage({
            container: id,
            width: width,
            height: height,
        });
        const layer = new Konva.Layer();
        stage.add(layer);

        let img = null;
        let retries = 0;
        const maxRetries = 3;

        // We try to create img with map url but it can fail as it depends on external api so we try a few times
        while (img === null && retries < maxRetries) {
            try {
                img = await buildKonvaImageFromURL(dp.map.url);

                img.setAttrs({
                    width,
                    height,
                });
                layer.add(img);
            } catch (e) {
                console.error(e);
                retries += 1;
            }
        }

        // Iterate through history elements to add them to canvas
        for (const elem of history) {
            if (elem.type === 'draw' && elem.drawing) {
                const coordinates = elem.drawing.dots.map((dot) => {
                    // Convert base history item elements to geo (lat/lng) elements
                    const baseGeo = convertCanvasToGeo(dot.x, dot.y, (map.map as MapProperties).bbox, width, height);
                    // Convert these geo elements to the desired format
                    return convertGeoToCanvas(baseGeo.lat, baseGeo.lng, dp.map.bbox, width, height);
                });
                const line = new Konva.Line({
                    points: coordinates.flatMap((dot) => [dot.x, dot.y]),
                    stroke: elem.color,
                    fill: elem.color + '50',
                    closed: true,
                });
                layer.add(line);
            }

            if (elem.type === 'panel' && elem.rect) {
                const coordinates = elem.rect.dots.map((dot) => {
                    // Convert base history item elements to geo (lat/lng) elements
                    const baseGeo = convertCanvasToGeo(dot.x, dot.y, (map.map as MapProperties).bbox, width, height);
                    // Convert these geo elements to the desired format
                    return convertGeoToCanvas(baseGeo.lat, baseGeo.lng, dp.map.bbox, width, height);
                });
                const line = new Konva.Line({
                    points: coordinates.flatMap((dot) => [dot.x, dot.y]),
                    stroke: elem.color,
                    fillPatternImage: elem.rect.attrs.fillPatternImage,
                    fillPatternRotation: elem.rect.attrs.rotation,
                    fillPatternScaleX: elem.rect.attrs.scaleX,
                    fillPatternScaleY: elem.rect.attrs.scaleY,
                    closed: true,
                });
                layer.add(line);
            }
        }

        // Convert to base64 string image
        const dataURL = stage.toDataURL({ mimeType: 'image/jpeg' });
        document.body.removeChild(div);
        return dataURL;
    };

    // Construct 3 dp images for each size & zoom and add them to local storage
    const onSubmit = async (values: FormValues) => {
        try {
            setNextStepLoader(true);
            if (!error) {
                const address: string = `${step1.userAddress.value}, ${step1.userZipCode.value} ${step1.userLocality.value}`;
                const width: number = IMG_WIDTH;
                const height: number = IMG_HEIGHT;
                const parcel = map?.parcel?.properties?.numero;

                const dp1 = await createDpImageUrl({ address, zoom: 5000, width, height, parcel, threshold: 0.6 }, values.history);
                const dp2 = await createDpImageUrl({ address, zoom: 500, width, height, parcel, threshold: 0.6 }, values.history);
                const dp2b = await createDpImageUrl({ address, zoom: 200, width, height, parcel, threshold: 0.6 }, values.history);

                storageService.setPrevisitValue('dp1Picture', dp1, 'dp1_devis_id');
                storageService.setPrevisitValue('dp2Picture', dp2, 'dp2_devis_id');
                storageService.setPrevisitValue('dp2bPicture', dp2b, 'dp2bis_devis_id');
            }
            // Error or not, send DP5 !
            const dp5 = dp5StageRef.current?.toDataURL({ mimeType: 'image/jpeg' });
            storageService.setPrevisitValue('dp5Picture', dp5, 'dp5_devis_id');

            setNextStepLoader(false);
            push(nextRoute);
        } catch (error) {
            console.error(error);
            setNextStepLoader(false);
        }
    };

    return (
        <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} validateOnMount onSubmit={() => {}}>
            {(props) => {
                // Debug
                // console.log('Values:', props.values);
                // console.log('Errors:', props.errors);
                return (
                    <div className="container">
                        <h1 className="main-title-mini">Photovoltaïque</h1>

                        <GeneralInformations />

                        <CoverComponent />

                        <Panels selectedElementIndex={selectedElementIndex} setSelectedElementIndex={setSelectedElementIndex} />

                        <PanelsDP5 setSelectedElementIndex={setSelectedElementIndex} stageRef={dp5StageRef} />

                        <Photos />

                        <div className="btn-grp justify-content-end pb-5">
                            <button type="button" onClick={() => history.goBack()} className="btn btn-retour">
                                Retour
                            </button>
                            <button
                                type="button"
                                onClick={() => onSubmit(props.values)}
                                className={`btn btn-continue ${Object.entries(props.errors).length > 0 || nextStepLoader ? 'disabled' : ''}`}
                            >
                                {nextStepLoader ? <Loader fill="#fff" /> : <>Continuer</>}
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Photovoltaique;
