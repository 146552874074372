import { PackagesEntries } from '../apiParticulierService';
import { currencyFormat, parseCurrency } from '../tools/TypeHelper';
import * as api from '../apiParticulierService';
import * as themesService from '../../services/calculs/theme';
import { packIsMMA, packIsPV, updatePackagePrice } from './package';
import * as storageService from '../../services/localStorageService';
import { addDays, isBefore, parse } from 'date-fns';
import { fr } from 'date-fns/locale';

export type Aide = {
    titre: string;
    titre_long?: string;
    product_name: string;
    product_id: string;
    montant_lbl: string;
    montant: number;
    shouldAppearsInAnnexe: boolean;
};

export type TicketData = {
    aides: Array<Aide>;
    montant_aides_cumul: string;
    montant_vente: string;
    reste_a_charge: string;

    /** do not use */
    montant_vente_ht: string;
    /** do not use */
    montant_vente_tva: string;
};

export const EMPTY_TICKET: TicketData = {
    aides: [],
    montant_aides_cumul: '0€',
    montant_vente: '0€',
    reste_a_charge: '0€',
    montant_vente_ht: '0€',
    montant_vente_tva: '0€',
};

// Short names
export const AIDE_PANNEAU_SOLAIRE_NAME = "Photovoltaïque prime à l'investissement";
export const AIDE_ECO_PUBLICITAIRE_NAME = 'Aide Eco-publicitaire' as const;
export const AIDE_REMISE_MMA_NAME = 'Remise MMA' as const;
export const AIDE_REMISE_COMMERCIALE_NAME = 'Remise commerciale' as const;
export const AIDE_REMISE_PREVISITE = 'Remise prévisite' as const;

// Long names
export const AIDE_PANNEAU_SOLAIRE_LONG = "Aide à l'autoconsommation";
export const AIDE_ECO_PUBLICITAIRE_LONG = 'Contrat Eco-publicitaire' as const;
export const AIDE_REMISE_MMA_LONG = `Remise Assurance MMA "Garantie de Revenue Solaire"` as const;
export const AIDE_REMISE_COMMERCIALE_LONG = 'Remise commerciale "Nouveaux producteur"' as const;
export const AIDE_REMISE_PREVISITE_LONG = 'Remise pour pré-visite' as const;

export const localAidesLongNames = {
    [AIDE_ECO_PUBLICITAIRE_NAME]: AIDE_ECO_PUBLICITAIRE_LONG,
    [AIDE_REMISE_MMA_NAME]: AIDE_REMISE_MMA_LONG,
    [AIDE_REMISE_COMMERCIALE_NAME]: AIDE_REMISE_COMMERCIALE_LONG,
    [AIDE_REMISE_PREVISITE]: AIDE_REMISE_PREVISITE_LONG,
    [AIDE_PANNEAU_SOLAIRE_NAME]: AIDE_PANNEAU_SOLAIRE_LONG,
};

export const localAidesNames = [
    AIDE_ECO_PUBLICITAIRE_NAME,
    AIDE_REMISE_MMA_NAME,
    AIDE_REMISE_COMMERCIALE_NAME,
    AIDE_REMISE_PREVISITE,
    AIDE_PANNEAU_SOLAIRE_NAME,
];

export type LocaleAideNames = (typeof localAidesNames)[number];

export type AidesLocalesList = { [key in LocaleAideNames]?: Aide };

export const isDeadLineAideOk = (): boolean => {
    const recoAppointement = storageService.getRecoAppointment();
    if (recoAppointement === null) return false;

    const now = new Date();
    // On prend les 10 premier, comme ca on est a minuit.
    let recoDate = parse(recoAppointement.date.slice(0, 10), 'yyyy-MM-dd', now, { locale: fr });

    // le soir du RDV à minuit.
    recoDate = addDays(recoDate, 1);
    return isBefore(now, recoDate);
};

export const getAppointmentDetails = (): { isAppointmentToday: boolean; recoAppointmentDate: Date | null } => {
    const recoAppointment = storageService.getRecoAppointment();
    if (recoAppointment === null) return { isAppointmentToday: false, recoAppointmentDate: null };

    const now = new Date();
    let recoDate = parse(recoAppointment.date.slice(0, 10), 'yyyy-MM-dd', now, { locale: fr });

    const recoDateWithExtraDay = addDays(recoDate, 1);
    const isAppointmentToday = isBefore(now, recoDateWithExtraDay);
    return { isAppointmentToday, recoAppointmentDate: isAppointmentToday ? recoDate : null };
};

export const remiseCoValue = (pref: api.UserPreferences | undefined | null): number => {
    return !pref ||
        pref.remiseCommerciale === null ||
        pref.remiseCommerciale === undefined ||
        typeof pref.remiseCommerciale !== 'number' ||
        isNaN(pref.remiseCommerciale)
        ? 0
        : pref.remiseCommerciale;
};

// #region create local aide functions

export const createAideReventeSurplus = async (themes: Array<themesService.Theme>): Promise<Aide | undefined> => {
    // ici on rajoute à la dure une aide pour l'autoconsommation avec revente en surplus
    const packPv = themesService.getFirstPackPV(themes);
    // vérifie si on a déjà compté cette aide :

    if (!packPv) return undefined;

    const montantAide = packPv.installationPV.modeConsommation === 'ReventeSurplus' ? packPv.potentialAideInvestissement : 0;
    if (montantAide === 0) return undefined;
    // on doit ajouter une aide.
    const aideReventeSurplus: Aide = {
        titre: AIDE_PANNEAU_SOLAIRE_NAME,
        product_name: packPv.mainProduct.nom,
        product_id: packPv.mainProduct.product_id.toString(),
        montant_lbl: currencyFormat(montantAide, true),
        montant: montantAide,
        shouldAppearsInAnnexe: false,
    };
    return aideReventeSurplus;
};

export const createAideMMA = async (themes: Array<themesService.Theme>): Promise<Aide | undefined> => {
    const deadLineOK = isDeadLineAideOk();
    const allRemises = (await api.getCustomerFlowParameters()).operationsCommerciales;
    const mmaActivated = allRemises.remiseMMA;

    let aideMma: Aide | undefined;
    if (mmaActivated && deadLineOK) {
        const packmma = themesService.getFirstPackMMA(themes);
        if (packmma) {
            aideMma = {
                titre: AIDE_REMISE_MMA_NAME,
                product_name: packmma.mainProduct.nom,
                product_id: packmma.mainProduct.product_id.toString(),
                montant_lbl: currencyFormat(packmma.price, true),
                montant: packmma.price,
                shouldAppearsInAnnexe: true,
            };
        }
    }
    return aideMma;
};

export const createAidePrevisit = async (): Promise<Aide | undefined> => {
    const allRemises = (await api.getCustomerFlowParameters()).operationsCommerciales;

    if (allRemises.remisePrevisite === 0) return undefined;
    const aidePrevisite: Aide = {
        titre: AIDE_REMISE_PREVISITE,
        product_name: '',
        product_id: '',
        montant_lbl: currencyFormat(allRemises.remisePrevisite, true),
        montant: allRemises.remisePrevisite,
        shouldAppearsInAnnexe: false,
    };
    return aidePrevisite;
};

export const createAideCommerciale = async (): Promise<Aide | undefined> => {
    if (!isDeadLineAideOk()) return undefined;

    const pref = (await api.getMyself()).preferences;

    const value = remiseCoValue(pref);

    if (value === 0) return undefined;

    const aideCo: Aide = {
        titre: AIDE_REMISE_COMMERCIALE_NAME,
        product_name: '',
        product_id: '',
        montant_lbl: currencyFormat(value, true),
        montant: value,
        shouldAppearsInAnnexe: true,
    };
    return aideCo;
};

//#endregion

export const simulateDevis = async (auditId: string, packages: PackagesEntries): Promise<TicketData> => {
    try {
        if (auditId === undefined || auditId === '' || packages.length <= 0) {
            // here i get a strange comportement in some case.
            // hard to reproduce, in stand alone demo project.
            // result returned is readonly sometimes.
            //return EMPTY_TICKET;
            //SO updated to this :
            return JSON.parse(JSON.stringify(EMPTY_TICKET)) as TicketData;
        }

        // const devis: Devis = response.response.data as Devis;
        const devis = await api.simulateDevis(+auditId, packages);

        let aides = new Array<Aide>();

        if (devis['aides-details'] !== undefined)
            aides = devis['aides-details'].map((a) => {
                return {
                    titre: a.titre,
                    product_name: a.product_name,
                    product_id: a.product_id.toString(),
                    montant_lbl: a.montant,
                    montant: parseCurrency(a.montant),
                } as Aide;
            });

        const ticket: Partial<TicketData> = {
            montant_vente: devis.devis.montant_vente,
            aides,
            montant_aides_cumul: devis.aides.montant_aides_cumul,
            reste_a_charge: devis.total.reste_a_charge,
            montant_vente_ht: devis.devis.montant_vente_ht,
            montant_vente_tva: devis.devis.montant_vente_tva,
        };

        return ticket as TicketData;
    } catch (error) {
        console.error(error);
        //throw error;
        return EMPTY_TICKET;
    }
};

/** Chaque carte de package contient une aide qu'on ne connait pas à l'avance
 *  une fois recu le ticket il faut ajouter les aides dans la carte de package, concernée.
 *  Cette fonction permet de le faire.
 *
 * Défaut connu :
 *  les packages contiennent des produits. les aides s'appliquent à des produits, pas à des packages.
 *  Lorsqu'on redistribue les aides, on sait a quel produit elles s'appliquent mais pas à quel package.
 *  Ca Pourrait poser un problème pour les PV. car je crée une aide par l'id. la plupart des packages sont juste une quantité différente du même produit.
 *  L'aide devrait donc s'appliquer sur tous les produits PV. ce qui est le cas.
 *  Mais comme cette aide est calculée après coup, si d'autres aides viennent du ticket sur les PV, on va les écraser. (ou doubler les autres.)
 */

export const populateAllPackageHelp = async (ticket: TicketData, allThemesClone: Array<themesService.Theme>): Promise<void> => {
    const deadLineOK = isDeadLineAideOk();
    const mmaActivated = (await api.getCustomerFlowParameters()).operationsCommerciales.remiseMMA;
    // pour chaque aide du ticket, on cherche le package concerné et on l'applique dessus
    for (const aide of ticket.aides) {
        for (const theme of allThemesClone) {
            for (const subTheme of theme.subThemes!) {
                for (const pack of subTheme.packages) {
                    if (packIsPV(pack)) {
                        const aideMontant = pack.installationPV.modeConsommation === 'ReventeSurplus' ? pack.potentialAideInvestissement : 0;
                        if (aideMontant !== 0) updatePackagePrice(pack, aideMontant);
                    } else if (packIsMMA(pack)) {
                        if (mmaActivated && deadLineOK) updatePackagePrice(pack, pack.price);
                    } else if (pack.mainProduct.product_id.toString() === aide.product_id.toString()) {
                        // en cas de plusieurs aides sur le produit. (équivalent d'un +=)
                        updatePackagePrice(pack, pack.totalHelp + aide.montant);
                    }
                }
            }
        }
    }
};

/**
 * Cette fonction calcule les aides qui n'existe pas dans le ticket retourné par le WS.
 * Elle les calcules et les ajoute dans le ticket.
 */
export const manageLocaleAide = async (ticket: TicketData, allThemesClone: Array<themesService.Theme>, localeAides: AidesLocalesList): Promise<void> => {
    // on ajoute toutes les aides dans le ticket.
    // On retire toutes les aides locales existantes.
    ticket.aides.removeAll((a) => {
        return (localAidesNames as string[]).includes(a.titre);
    });

    for (const name of localAidesNames) {
        const aide = localeAides[name];
        if (aide !== undefined) ticket.aides.push(aide);
    }
};

export const recalculTicket = (ticket: TicketData): void => {
    let totalaide = 0;
    ticket.aides.forEach((e) => {
        totalaide += e.montant;
    });
    let total = parseCurrency(ticket.montant_vente);
    // Here sometimes it could crash.
    // strange comportment hard to reproduce, when getDevis has returned EMPTY_TICKET
    // Soo add a try catch
    try {
        ticket.montant_aides_cumul = currencyFormat(totalaide, true);
        ticket.reste_a_charge = currencyFormat(total - totalaide, true);
    } catch (e) {
        // When happens, check if audit id exists.
        console.log('AuditSimulator, Something gone very very bad here.');
    }
};
