/**
* Fonction pour retrouver la valeur de défaut d'un champ via une liste d'éléments
* @param propertyList
* @param value
*/

export const renderDefaultValueSelect = (propertyList: any, value: any) => {
   return propertyList.filter((property: any) => {
       return property.value === value;
   });
};

/**
 * How To Use
 * 
 * <Select
 *  defaultValue={renderDefaultValueSelect(optionsList, values.propertyName)}
 *  options={optionsList}
 *  ...
 * />
 */
