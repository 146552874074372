import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as storageService from '../../../services/localStorageService';

import * as apiParticulierService from '../../../services/apiParticulierService';

import { heaterTypeCorrespondance, heaterFeatureCorrespondance } from '../../../services/tools/correspondancesRecap';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import Cookies from 'js-cookie';

import '../../action-logement-recap/recap.scss';

// Images
import Pump1 from '../../../assets/images/recapitulatif/1_PAC_AIR_EAU.png';
import Pump2 from '../../../assets/images/recapitulatif/2_PAC_AIR_EAU_BALLON.png';
import Pump3 from '../../../assets/images/recapitulatif/3_PAC_AIR_AIR_BALLON_Split.png';
//import Pump4 from '../../../assets/images/recapitulatif/4_ISOLATION.png';
import Pump5 from '../../../assets/images/recapitulatif/5_PHOTOVOLTAIQUE.png';
import Pump6 from '../../../assets/images/recapitulatif/pv-ballon-brasseur.jpg';
import Pump7 from '../../../assets/images/recapitulatif/pv-brasseur.jpg';
import { ReactComponent as LoaderSVG } from '../../../assets/icons/loader.svg';
import { dataURLtoFile } from '../../../services/tools/base64Helper';
import { ROUTE_AUDIT_AL_SIGNATURE } from '../../../routing/paths';

const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

// Display HTML + counter
const SwalComponent = () => {
    const counter = useCounter(45);

    return (
        <div>
            Merci de patienter, cette opération peut prendre plusieurs dizaines de secondes
            <div className="swal2-loader mt-3 mx-auto d-flex"></div>
            <p className="mt-3">{counter}</p>
        </div>
    );
};

const ResteAChargeRecap: React.FC = () => {
    const counter = useCounter(15);

    const AlertSwal = withReactContent(Swal);

    const { push } = useHistory();

    const [actionLogementJSON, setActionLogementJSON] = useState();

    const [ineligible, setIneligible] = useState(false);

    const [recapitulatif, setRecapitulatif] = useState('');

    const whichPumpToDisplay = (reference: string) => {
        let image = '';
        // TODO : obsolète références.
        switch (reference) {
            // Image 1
            case 'AL-AIREAU-8':
            case 'AL-AIREAU-11':
            case 'AL-AIREAU-14':
            case 'AL-AIREAU-16':
            case 'AL PAC Air/Eau 11kW':
                image = Pump1;
                break;
            // Image 2
            case 'AL-AIREAU-8-BALLON':
            case 'AL-AIREAU-11-BALLON':
            case 'AL-AIREAU-14-BALLON':
            case 'AL-AIREAU-16-BALLON':
                image = Pump2;
                break;
            // Image 3
            case 'AL PAC Air/Air 7 Splits + BALLON':
            case 'AL-AIRAIR-4-BALLON':
            case 'AL-AIRAIR-5-BALLON':
            case 'AL-AIRAIR-6-BALLON':
            case 'AL-AIRAIR-7-BALLON':
            case 'AL PAC Air/Air 6 Splits + BALLON':
            case 'AL PAC Air/Air 4 Splits + BALLON':
            case 'AL PAC Air/Air 7 Splits + BALLON - AL PAC Air/Eau 11kW - BALLON (Hors package) CET CHAPPEE TD ECO 180 AI-E - Isolation des combles':
                image = Pump3;
                break;
            // Image 5
            case 'AL-PV-10-BALLON':
                image = Pump5;
                break;
            // Image 6
            case 'AL-DOM-PV':
                image = Pump6;
                break;
            // Image 7
            case 'AL-PV-SB':
                image = Pump7;
                break;
            // Par défaut
            default:
                image = Pump1;
        }

        return image;
    };

    /**
     * Fonction pour l'envoi de la validation
     */
    const launchActionLogementValidateRequest = async () => {
        // Affichage écran de chargement
        AlertSwal.fire({
            title: 'Génération du bon de commande',
            html: <SwalComponent />,
            // didOpen: () => {
            //     Swal.showLoading();
            // },
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        try {
            const responseActionLogementValidate = await apiParticulierService.postActionLogementValidateData(actionLogementJSON);
            // Fermeture écran de chargement
            setTimeout(() => {
                Swal.close();
            }, 1000);
            // Redirection vers la route de signature
            push(ROUTE_AUDIT_AL_SIGNATURE + '?token=' + responseActionLogementValidate?.response?.data?.accessToken);
            return responseActionLogementValidate?.response?.data?.accessToken;
        } catch (error) {
            throw error;
        }
    };

    const companyName = Cookies.getJSON('Auth').companyName;

    useEffect(() => {
        window.scrollTo(0, 0);

        const agentCode = Cookies.getJSON('Auth').agentCode;
        const agentPassword = Cookies.getJSON('Auth').agentPassword;

        /**
         * Fonction pour envoyer les images des plans et réceptionner des URL après téléversement
         */
        const sendBuildingPlans = async () => {
            try {
                // Obtention de la liste de plans
                const buildingPlans = storageService.getAudit().step2.buildingPlans.value;

                // Création de l'objet à envoyer
                let uploadJson: any = [];
                if (buildingPlans.length > 0) {
                    buildingPlans.forEach((buildingPlan: any, index: number) => {
                        if (buildingPlan?.base64 && buildingPlan.base64 !== '') {
                            uploadJson = [...uploadJson, dataURLtoFile(buildingPlan.base64, `buildingPlan-${index}`, Cookies.getJSON('Auth').agentCode)];
                        }
                    });
                }

                if (Object.entries(uploadJson).length !== 0) {
                    // Envoi de la requête avec les photos
                    let response = await apiParticulierService.postFile(uploadJson);
                    if (!response) {
                        throw new Error();
                    } else {
                        // Retour de la réponse de l'API
                        return response;
                    }
                } else {
                    return {};
                }
            } catch (error) {
                console.error(error);
            }
        };

        /**
         * Fonction pour envoyer les images et réceptionner des URL après téléversement
         */
        const sendPictures = async () => {
            try {
                // Obtention de la liste de photos
                const photos = storageService.getAudit().step8;
                // Suppression de la valeur isValid
                delete photos.isValid;

                // Création de l'objet à envoyer
                let uploadJson: any = {};
                if (Object.entries(photos).length !== 0) {
                    Object.keys(photos).forEach((item: any) => {
                        if (photos[item]?.value && photos[item].value !== '') {
                            uploadJson[item] = dataURLtoFile(photos[item].value, item, Cookies.getJSON('Auth').agentCode);
                        }
                    });
                }

                if (Object.entries(uploadJson).length !== 0) {
                    // Envoi de la requête avec les photos
                    let response = await apiParticulierService.postFile(uploadJson);
                    if (!response) {
                        throw new Error();
                    } else {
                        // Retour de la réponse de l'API
                        return response;
                    }
                } else {
                    return {};
                }
            } catch (error) {
                console.error(error);
            }
        };

        /**
         * Fonction pour remplir le local storage avec les URL des images
         * @param response
         */
        const fillBuildingPlansIntoLocalStorage = async (response: any) => {
            // Obtention de la liste des plans
            const buildingPlans = storageService.getAudit().step2.buildingPlans.value;

            // Écriture dans objet temporaire
            response.forEach((item: { fileKey: string; url: string }, index: number) => {
                buildingPlans[index].img = item.url;
                buildingPlans[index].base64 = item.url;
            });
            // Récupération template global
            const newTemplate = storageService.getAudit();

            // injection des nouvelles données dans l'étape 8
            newTemplate.step2.buildingPlans.value = buildingPlans;

            // Injection dans local storage
            storageService.setAudit(newTemplate);
        };

        /**
         * Lancement de la requête pour les images des plans
         */
        sendBuildingPlans().then((response: any) => {
            if (response) {
                if (response.success === undefined) fillBuildingPlansIntoLocalStorage(response);
                else fillBuildingPlansIntoLocalStorage(response.success);
            }
        });

        /**
         * Fonction pour remplir le local storage avec les URL des images
         * @param response
         */
        const fillPicturesIntoLocalStorage = async (response: any) => {
            // Obtention de la liste de photos
            const photos = storageService.getAudit().step8;

            // Écriture dans objet temporaire
            response.forEach((item: { fileKey: string; url: string }) => {
                photos[item.fileKey].url = item.url;
                //photos[item.fileKey].value = item.url
            });
            // Récupération template global
            const newTemplate = storageService.getAudit();

            // injection des nouvelles données dans l'étape 8
            newTemplate.step8 = photos;

            // Injection dans local storage
            storageService.setAudit(newTemplate);
        };

        /**
         * Lancement de la requête pour les images
         */
        sendPictures().then((response: any) => {
            if (response) {
                if (response.success === undefined) fillPicturesIntoLocalStorage(response);
                else fillPicturesIntoLocalStorage(response.success);
            }
        });

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const sendAudit = async () => {
            try {
                // const auditJson = storageService.stepListToAuditAndClient();
                const flowId = localStorage.getItem('flowId');

                if (!flowId) {
                    throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
                }
                // Lancement de la requête
                return apiParticulierService.sendAudit(flowId);
            } catch (error) {
                console.error(error);
                throw error;
            }
        };

        // OLD WAY
        // const launchAuditRequest = async () => {
        //     try {
        //         const auditJson = storageService.stepListToAuditAndClient();
        //         // Lancement de la requête
        //         return apiParticulierService.postAuditData(auditJson);
        //     } catch (error) {
        //         console.error(error);
        //         throw error;
        //     }
        // };

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const requestRdvNoSignature = async () => {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }
            // Préparation du formData pour la requête
            let rdvJson: any = {
                rdv: {
                    startDate: date.toISOString().slice(0, 10),
                    startTime: date.toISOString().slice(11, 16),
                },
            };

            // Lancement de la requête
            try {
                return await apiParticulierService.requestRdvNoSignature(flowId, rdvJson);
            } catch (error) {
                throw error;
            }
        };
        // OLD WAY
        // const launchRdvRequest = async (auditId: string) => {
        //     let date = new Date();
        //     date.setDate(date.getDate() + 1);

        //     // Préparation du formData pour la requête
        //     let rdvJson: any = {
        //         clientIdentity,
        //         rdv: {
        //             startDate: date.toISOString().slice(0, 10),
        //             startTime: date.toISOString().slice(11, 16),
        //         },
        //         auditId: parseInt(auditId),
        //     };

        //     // Lancement de la requête
        //     try {
        //         return await apiParticulierService.postRdvData(rdvJson);
        //     } catch (error) {
        //         throw error;
        //     }
        // };

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const launchActionLogementRecapRequest = async (auditId: string) => {
            // Tableau de conversion type d'énergie - Type de chauffage principal
            const heaterTypeCorrespondances = heaterTypeCorrespondance;

            // Tableau de conversion - Type d'émetteur
            const heaterFeatureCorrespondances = heaterFeatureCorrespondance;

            // Exemple fonctionnel
            // let actionLogementJSON: any = {
            //     "choix-amo":"1",
            //     "isolation-murs":"1",
            //     "type-energie":"1",
            //     "type-chauffage":"3",
            //     "nbr-piece-chauffage":"3",
            //     "surface":"115",
            //     "annee-construction":"2002",
            //     "chaudiere-marque":"Atlantic",
            //     "chaudiere-puissance":"25",
            //     "chaudiere-condensation":"1",
            //     "combles":"2",
            //     "isolation-surface":"120",
            //     "auditId": parseInt(auditId)
            // }

            // Préparation du formData pour la requête
            let actionLogementJSON: any = {
                'choix-amo': '1', // Le laisser à 1
                'isolation-murs': storageService.getAudit().step6?.exteriorWallInsulationAge?.value !== '' ? '1' : '2',
                'type-energie':
                    storageService.getAudit().step3?.heaterSubscriptionGas.value === '42'
                        ? '3'
                        : heaterTypeCorrespondances[storageService.getAudit().step3?.heaterType?.value], // Type de chauffage principal
                'type-chauffage': heaterFeatureCorrespondances[storageService.getAudit().step3?.heaterFeature?.value], // Type d'émetteur
                'nbr-piece-chauffage': storageService.getAudit().step2?.generalOptionsMain?.value.toString(), // Nombre de pièce principal (hors cuisine)
                surface: storageService.getAudit().step2?.SHab?.value.toString(),
                'annee-construction': storageService.getAudit().step2?.houseAge?.value,
                'chaudiere-marque': storageService.getAudit().step3?.heaterBrand?.value,
                'chaudiere-puissance': storageService.getAudit().step3?.heaterGeneratorPower?.value.toString(),
                'chaudiere-condensation': storageService.getAudit().step3.condensingHeater.value === '1' ? '1' : '2',
                combles: storageService.getAudit().step6.ceilingType.value === '2' ? '1' : '2',
                'isolation-surface': '40',
                auditId: parseInt(auditId),
            };

            // Injection de l'audit dans la variable pour utlisation ultèrieure
            setActionLogementJSON(actionLogementJSON);

            // Lancement de la requête
            try {
                return await apiParticulierService.postActionLogementRecapData(actionLogementJSON);
            } catch (error) {
                console.error(error);
                throw error;
            }
        };

        /**
         * Balance la purée mec !
         */
        async function loadRequested() {
            if (recapitulatif === '') {
                try {
                    // Reconnexion
                    await apiParticulierService.identify(agentCode, agentPassword);
                    // Envoi des données de l'audit
                    await apiParticulierService.updateAuditDataService();
                    // Envoi de l'audit
                    const responseAudit = await sendAudit();
                    // Cas client éligible
                    if (responseAudit?.response?.data?.eligibiliteAL) {
                        // Envoi du RDV
                        await requestRdvNoSignature();
                        // Envoi demande de récapitulatif Action Logement
                        const response = await launchActionLogementRecapRequest(responseAudit?.response?.data?.auditId);
                        // Client conforme
                        if (response.status === 'OK') {
                            setRecapitulatif(response.response.data);
                            // Client non conforme (ex: isolation)
                        } else {
                            setIneligible(true);
                        }
                        // Cas client non-éligible
                    } else {
                        setIneligible(true);
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            }
        }

        loadRequested();
    }, [recapitulatif]);

    return (
        <>
            {!recapitulatif && !ineligible && (
                <div className="container text-center" id="loading">
                    <h1 className="main-title-mini">Envoi de l'audit en cours</h1>
                    <p>Merci de patienter le temps que vos données saisies soient analysées</p>
                    <LoaderSVG width={80} height={80} />
                    {counter > 0 && <p className="countdown">{counter}</p>}
                </div>
            )}

            {recapitulatif && (
                <div className="container">
                    <h1 className="main-title-mini">Récapitulatif</h1>
                    <h2 className="main-subtitle">Votre dossier est sur le point d’être finalisé</h2>

                    <div className="row my-4">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card card-recap">
                                <div className="card-title">
                                    <h2>Materiel recommandé</h2>
                                    <p>Selon les données renseignées dans votre audit, vous trouverez ci-dessous l’installation recommandée.</p>
                                </div>

                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-6">
                                            <div className="recap-pompe-title">
                                                <p>Pompe à chaleur</p>
                                                <em>{(recapitulatif as any)['Référence']}</em>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="recap-pompe">
                                                <img src={whichPumpToDisplay((recapitulatif as any)['Référence'])} alt={(recapitulatif as any)['Référence']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card card-recap">
                                <div className="card-title">
                                    <h2>Devis et aides éligibles</h2>
                                </div>

                                <div className="card-body">
                                    <div className="invoice-main">
                                        Prix devis TTC <span>{(recapitulatif as any)['Prix devis TTC']}</span>
                                    </div>
                                    <div className="invoice-main">
                                        Montant des aides cumulées <span>{(recapitulatif as any)['Montant des aides cumulées']}</span>
                                    </div>

                                    <ul className="invoice-list">
                                        {(recapitulatif as any)['Montant Prime CEE'] !== 'N/A' ? (
                                            <>
                                                <li>Montant Prime CEE</li>
                                                <li>{(recapitulatif as any)['Montant Prime CEE']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Prime CEE'] !== 'N/A' ? (
                                            <>
                                                <li>Montant total de l’aide MaPrimerénov’</li>
                                                <li>{(recapitulatif as any)['Montant Aide Action Logement']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Prime CEE Ballon'] !== 'N/A' ? (
                                            <>
                                                <li>Montant total des contrats publicitaires “Partenaire fabricants”</li>
                                                <li>{(recapitulatif as any)['Montant Prime CEE Ballon']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Prime Coup de Pouce'] !== 'N/A' ? (
                                            <>
                                                <li>Montant Prime Coup de Pouce</li>
                                                <li>{(recapitulatif as any)['Montant Prime Coup de Pouce']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}
                                    </ul>

                                    <div className="invoice-total">
                                        Net à payer <span>{(recapitulatif as any)['Net à payer']}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-3 mb-lg-0">
                            <div className="card card-recap">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="card-title min-h-80">
                                                <h2>Liste des documents à préparer et à charger à l’étape suivante</h2>
                                            </div>

                                            <div className="card-body">
                                                <div className="recap-list-container">
                                                    <ul className="recap-list recap-list-column">
                                                        {(recapitulatif as any)['Docs à insérer']?.map((item: any) => (
                                                            <li key={item}>{item}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="card-title min-h-80">
                                                <h2>Liste des documents à générer à l'étape suivante</h2>
                                            </div>

                                            <div className="card-body">
                                                <ul className="recap-list">
                                                    {(recapitulatif as any)['Docs à générer']?.map((item: any) => (
                                                        <li key={item}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="navigation text-center pt-4 my-4">
                        <button onClick={launchActionLogementValidateRequest} className="btn btn-primary btn-maxi">
                            Finaliser mon dossier
                        </button>
                    </div>
                </div>
            )}

            {/* Inéligible et isolation */}
            {ineligible && storageService.getAudit().step6?.exteriorWallInsulationAge?.value !== '' && (
                <div className="container" id="not-eligible">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <h1 className="main-title-mini">Votre dossier n'est pas éligible au programme Action Logement</h1>
                            <p>
                                Les informations que vous avez transmis dans le cadre de votre Audit Energétique ne vous permettent malheureusement pas de
                                bénéficier du programme Action Logement.
                            </p>
                            <p>
                                Cependant une multitude d'autre aide sont à votre disposition. Notre service client ne manquera pas de prendre contact avec vous
                                dans les plus bref délai afin d'évaluer avec vous toutes les dispositions auxquelles vous auriez accès.
                            </p>
                            <p>L'équipe {companyName}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Inéligible et pas d'isolation */}
            {ineligible && storageService.getAudit().step6?.exteriorWallInsulationAge?.value === '' && (
                <div className="container" id="not-eligible">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <h1 className="main-title-mini">Votre dossier est eligible au programme action logement</h1>
                            <p>
                                Les informations que vous avez transmis dans le cadre de votre Audit Energétique indique que les murs de votre maison ne sont
                                pas isolé. Ni par l'intérieur et ni par l'éxtérieur.
                            </p>
                            <p>
                                Cela nécessite une étude plus approfondie et devons déplacer un technicien à votre domicile afin de réaliser une pré visite
                                technique. Notre service technique ne manquera pas de prendre contact avec vous dans les jours à venir afin de convenir d'une
                                date.
                            </p>
                            <p>L'équipe {companyName}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResteAChargeRecap;
