import React from 'react';
import { BrowserRouter as Router, Route, withRouter, Switch, Redirect } from 'react-router-dom';

import { CookiesProvider } from 'react-cookie';

import PrivateRoute from './PrivateRoute';

import Login from '../pages/login/Login';
import ActionLogement from '../pages/action-logement/ActionLogement';
import Audit from '../pages/audit/Audit';
import ClientInformations from '../pages/client-informations/ClientInformations';
import HomeInformations from '../pages/home-informations/HomeInformations';
import EnergySystemInformations from '../pages/energy-system-informations/EnergySystemInformations';
import EnergyCostInformations from '../pages/energy-cost-informations/EnergyCostInformations';
import CollectionsReportsInformations from '../pages/collections-reports-informations/CollectionsReports';
import EnvelopeInformations from '../pages/envelope-informations/EnvelopeInformations';
import Openings from '../pages/openings/Openings';
import Photos from '../pages/photos/Photos';
import RendezVous from '../pages/rendez-vous/RendezVous';
import Recap from '../pages/action-logement-recap/Recap';
import Signature from '../pages/action-logement-signature/Signature';
import FinalisationAL from '../pages/finalisation/finalisation';
import MentionsLegales from '../pages/mentions-legales/mention-legales';
import SyntheseEnergetique from '../pages/simulator/syntheseEnergetique';
import ResteACharge from '../pages/reste-a-charge/ResteACharge';
import ResteAChargeRecap from '../pages/reste-a-charge/recap/ResteAChargeRecap';
import FinalisationRAC from '../pages/reste-a-charge/finalisation/ResteAChargeFinalisation';
import { RecapIneligiblePhotovoltaique } from '../pages/action-logement-recap/RecapIneligiblePhotovoltaique';
import { RecapIneligible } from '../pages/action-logement-recap/RecapIneligible';
import AuditSimulator from '../pages/audit-simulator/audit-simulator';
import PompeAChaleur from '../pages/audit-simulator/pompe-a-chaleur/Pompe';
import Photovoltaique from '../pages/pre-visite/photovoltaique/Photovoltaique';
import BonDeCommande from '../pages/audit-simulator/bon-de-commande/BonDeCommande';
import Annexe from '../pages/audit-simulator/bon-de-commande/annexe/Annexe';
import EngagementClient from '../pages/audit-simulator/engagement-client/EngagementClient';
import Financement from '../pages/audit-simulator/financement/Financement';
import TauxDeTva from '../pages/audit-simulator/taux-de-tva/TauxDeTva';
import Rendement from '..//pages/audit-simulator/rendement/Rendement';
import FinalisationSansRdv from '../pages/rendez-vous/FinalisationSansRdv';
import FinalisationSansBDC from '../pages/audit-simulator/devis/FinalisationSansBDC';
import RdvSignature from '../pages/rendez-vous/RdvSignature';
import PdfSignature from '../pages/audit-simulator/signer/signer';
import PagePDFOrder from '../pages/audit-simulator/PagePDF/PagePDF';

// Pre visite
import Validation from '../pages/pre-visite/validation/Validation';
import PompeAChaleurPV from '../pages/pre-visite/PompeAChaleur/PompeAChaleur';
import Thermodynamique from '../pages/pre-visite/Thermodynamique/Thermodynamique';
import Forecast from '../pages/pre-visite/Forecast/Forecast';
import Planning from '../pages/pre-visite/Planning/Planning';
import FinDeCommande from '../pages/pre-visite/FinDeCommande/FinDeCommande';
import Finalisation from '../pages/pre-visite/Finalisation/Finalisation';
import PagePDF from '../pages/pre-visite/PagePDF/PagePDF';

// Espace agent
import EspaceAgent from '../pages/espace-agent/EspaceAgent';
import ListAudit from '../pages/espace-agent/list-audit/ListAudit';
import CreationAudit from '../pages/espace-agent/creation-audit/CreationAudit';
import EspaceFormation from '../pages/espace-agent/EspaceFormation/EspaceFormation';
import Detail from '../pages/espace-agent/Detail/Detail';
import MonCompte from '../pages/espace-agent/mon-compte/MonCompte';
import MonReseau from '../pages/espace-agent/monReaseau/MonReseau';
import NetworkDetail from '../pages/espace-agent/monReaseau/detail/Detail';
import CreateAgent from '../pages/espace-agent/monReaseau/create-agent/CreateAgent';
import Marketing from '../pages/espace-agent/marketing/Marketing';

import { NavigationProvider } from './StepNavigationContext';

import * as path from './paths';

const Container = () => {
    return (
        <CookiesProvider>
            <Router>
                <NavigationProvider>
                    <Switch>
                        <PrivateRoute exact path={path.ROUTE_ROOT} render={() => <Redirect to="/audit" />} />
                        <Route exact path={path.ROUTE_LOGIN} component={Login} />
                        <Route exact path={path.ROUTE_AUDIT_AL} component={ActionLogement} />
                        <Route exact path={path.ROUTE_AUDIT_RAC} component={ResteACharge} />
                        <Route exact path={path.ROUTE_MENTIONS_LEGALES} component={MentionsLegales} />
                        <PrivateRoute exact path={path.ROUTE_AUDIT} component={Audit} name="audit" />
                        {/* <NavigationProvider>
                        <Switch> */}
                        <PrivateRoute exact path={path.ROUTE_AUDIT_INFO_CLIENT} component={ClientInformations} name="client-informations" step={1} />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_INFO_HABITATION} component={HomeInformations} name="home-informations" step={2} />
                        <PrivateRoute
                            exact
                            path={path.ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES}
                            component={EnergySystemInformations}
                            name="energy-system-informations"
                            step={3}
                        />
                        <PrivateRoute
                            exact
                            path={path.ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE}
                            component={EnergyCostInformations}
                            name="energy-cost-informations"
                            step={4}
                        />
                        <PrivateRoute
                            exact
                            path={path.ROUTE_AUDIT_INFO_RECUEIL_CONSTAT}
                            component={CollectionsReportsInformations}
                            name="collections-reports-informations"
                            step={5}
                        />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_INFO_EVELOPPE} component={EnvelopeInformations} name="envelope-informations" step={6} />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_OUVRANT} component={Openings} name="openings" step={7} />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_PHOTOS} component={Photos} name="photos" step={8} />
                        {/* </Switch>
                    </NavigationProvider> */}

                        <PrivateRoute exact path={path.ROUTE_AUDIT_RDV} component={RendezVous} name="rendez-vous" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_FINALISATION_SANS_RDV} component={FinalisationSansRdv} name="finalisation-rendez-vous" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_RDV_SIGNATURE} component={RdvSignature} name="rendez-vous-singature" />

                        <PrivateRoute exact path={path.ROUTE_AUDIT_AL_RECAP} component={Recap} name="recap" />
                        <PrivateRoute
                            exact
                            path={path.ROUTE_AUDIT_AL_RECAP_INELIGIBLE_PV}
                            component={RecapIneligiblePhotovoltaique}
                            name="recapitulatif-ineligible-photovoltaique"
                        />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_AL_RECAP_INELIGIBLE} component={RecapIneligible} name="recapitulatif-ineligible" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_RAC_RECAP} component={ResteAChargeRecap} name="recap" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_AL_SIGNATURE} component={Signature} name="signature" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_AL_FINALISATION} component={FinalisationAL} name="finalisation" />
                        <PrivateRoute exact path={path.ROUTE_AUDIT_RAC_FINALISATION} component={FinalisationRAC} name="finalisation" />
                        <PrivateRoute exact path={path.ROUTE_SIM_SYNTHESE} component={SyntheseEnergetique} name="synthese" />
                        <PrivateRoute exact path={path.ROUTE_SIM_RECOMMANDATION} component={AuditSimulator} name="audit-simulator" />
                        <PrivateRoute exact path={path.ROUTE_SIM_PAC} component={PompeAChaleur} name="pompe-a-chaleur" />
                        <PrivateRoute exact path={path.ROUTE_SIM_BON_DE_COMMANDE} component={BonDeCommande} name="bon-de-commande" />
                        <PrivateRoute exact path={path.ROUTE_SIM_DEVIS} component={FinalisationSansBDC} name="recevoir-un-devis" />
                        <PrivateRoute exact path={path.ROUTE_SIM_ANNEXE} component={Annexe} name="annexe" />
                        <PrivateRoute exact path={path.ROUTE_SIM_ENGAGEMENT_CLIENT} component={EngagementClient} name="engagement-client" />
                        <PrivateRoute exact path={path.ROUTE_SIM_FINANCEMENT} component={Financement} name="financement" />
                        <PrivateRoute exact path={path.ROUTE_SIM_TVA} component={TauxDeTva} name="tva" />
                        <PrivateRoute exact path={path.ROUTE_SIM_RENDEMENT} component={Rendement} name="rendement" />
                        <PrivateRoute exact path={path.ROUTE_SIM_SIGN} component={PdfSignature} name="signer" />
                        <PrivateRoute exact path={path.ROUTE_SIM_PDF} component={PagePDFOrder} name="espace-agent" />

                        {/* Pré visite */}
                        <PrivateRoute exact path={path.ROUTE_PV_VALIDATION} component={Validation} name="validation" />
                        <PrivateRoute exact path={path.ROUTE_PV_POMPE_A_CHALEUR} component={PompeAChaleurPV} name="pre-visite" />
                        <PrivateRoute exact path={path.ROUTE_PV_PHOTOVOLTAIQUE} component={Photovoltaique} name="photovoltaique" />
                        <PrivateRoute exact path={path.ROUTE_PV_THERMODYNAMIQUE} component={Thermodynamique} name="thermodynamique" />
                        <PrivateRoute exact path={path.ROUTE_PV_PREVISION} component={Forecast} name="prevision" />
                        <PrivateRoute exact path={path.ROUTE_PV_PLANNING} component={Planning} name="planning" />
                        <PrivateRoute exact path={path.ROUTE_PV_FIN_DE_COMMANDE} component={FinDeCommande} name="fin-de-commande" />
                        <PrivateRoute exact path={path.ROUTE_PV_FINALISATION} component={Finalisation} name="finalisation" />
                        <PrivateRoute exact path={path.ROUTE_PV_PDF} component={PagePDF} name="espace-agent" />

                        {/* Espace Agent */}
                        <PrivateRoute exact path={path.ROUTE_EA_INDEX} component={EspaceAgent} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_LIST} component={ListAudit} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_DETAIL} component={Detail} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_CREATION} component={CreationAudit} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_FORMATION} component={EspaceFormation} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_COMPTE} component={MonCompte} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_NETWORK} component={MonReseau} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_NETWORK_DETAIL} component={NetworkDetail} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_CREATE} component={CreateAgent} name="espace-agent" />
                        <PrivateRoute exact path={path.ROUTE_EA_MARKETING} component={Marketing} name="espace-agent-marketing" />
                    </Switch>
                </NavigationProvider>
            </Router>
        </CookiesProvider>
    );
};

export default withRouter(Container);
