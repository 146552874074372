import React, { forwardRef, useRef, useEffect, useState, useMemo } from 'react';
import { ROUTE_EA_CREATION } from '../../../routing/paths';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as routes from '../../../routing/paths';
import { FlowOutputRow, convertToRow, tableStatus } from './ListAuditEnums';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    flexRender,
    createColumnHelper,
    SortingState,
    getSortedRowModel,
    PaginationState,
} from '@tanstack/react-table';
import { Labelled } from '../../../services/tools/TypeHelper';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectedAudit } from '../../../services/Recoil/Atom/SelectedAudit.atom';
import * as apiFlow from '../../../services/apiFlowService';
import { Back } from '../../../components/Back/Back';

// Icons
import { ReactComponent as IconEye } from '../../../assets/icons/espace-agent/icon-eye.svg';
import { ReactComponent as IconArrow } from '../../../assets/icons/espace-agent/icon-arrow.svg';
import { ReactComponent as IconComment } from '../../../assets/icons/espace-agent/icon-comment.svg';
import { ReactComponent as IconRenovation } from '../../../assets/icons/icon-renovation.svg';

// Style
import './ListAudit.scss';

interface IndeterminateCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    indeterminate: boolean;
}

interface SortingInfo {
    id: string;
    desc: boolean;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = (ref as React.MutableRefObject<HTMLInputElement>) || defaultRef;

    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return (
        <span className="d-flex align-items-center justify-content-center w-100 h-100">
            <input type="checkbox" className="cursor-pointer" ref={resolvedRef} {...rest} />
        </span>
    );
});

type Table = {
    data: FlowOutputRow[];
    setData: React.Dispatch<React.SetStateAction<FlowOutputRow[]>>;
    fetch: boolean;
    setFetch: React.Dispatch<React.SetStateAction<boolean>>;

    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    handleSort: (columnId: string, desc: boolean) => void;

    pagination: PaginationState;
    setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
    totalPages: number;
    pageIndex: number;
    setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
};

const prepareParameters = (params: {
    pagination: {
        pageIndex: number;
        pageSize: number;
    };
    sorting?: {
        id: string;
        desc: boolean;
    };
    filters?: apiFlow.GetMyFlowsFilters;
}) => {
    const pagination = {
        pageIndex: params.pagination.pageIndex,
        elementsPerPage: params.pagination.pageSize,
    };

    const orderBy = params.sorting
        ? {
              order: params.sorting.desc ? ('desc' as apiFlow.OrderName) : ('asc' as apiFlow.OrderName),
              key: params.sorting.id as apiFlow.FlowsOrderbyKeys,
          }
        : undefined;

    const filters = params.filters;

    return { pagination, orderBy, filters };
};

const FlowTable: React.FC<Table> = (params: Table) => {
    const [rowSelection, setRowSelection] = useRecoilState(SelectedAudit);
    const columnHelper = createColumnHelper<FlowOutputRow>();
    const { push } = useHistory();

    const filterStatusName = (status: apiFlow.FlowStatusFilter) => {
        switch (status) {
            case 'notSent':
                return 'Non envoyé';

            case 'sentNoRdv':
                return 'Envoyé sans rdv';

            case 'sentWithRdv':
                return 'Envoyé avec rdv';

            case 'orderCompleted':
                return 'Commande finalisée';

            case 'preVisitCompleted':
                return 'Pré-visite finalisée';

            default:
                return '';
        }
    };

    const handleHeaderClick = (columnId: string, isSortable: boolean) => {
        if (isSortable) {
            // Utilisez une fonction anonyme pour passer les arguments requis à handleSort
            const isAlreadySorted = params.sorting.some((sortInfo) => sortInfo.id === columnId && !sortInfo.desc);
            params.handleSort(columnId, isAlreadySorted);

            params.setFetch(true);
        }
    };

    // Define each table columns
    const columns = [
        columnHelper.accessor('createdAt', {
            header: () => 'Date',
            cell: (createdAt) => createdAt.getValue(),
        }),
        columnHelper.accessor('type', {
            header: () => 'Type audit',
            cell: (type) => type.getValue(),
        }),
        columnHelper.accessor('customerName', {
            header: () => 'Client',
            cell: (customerName) => customerName.getValue(),
        }),
        columnHelper.accessor('state', {
            header: () => 'Statut',
            cell: (state) => filterStatusName(state.getValue()),
            enableSorting: false,
        }),
        columnHelper.accessor('select', {
            header: () => '',
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                        id: `row-${row.id}`,
                    }}
                />
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('url', {
            header: () => '',
            cell: () => <IconEye width={20} fill="#BDBDBD" title="Voir le détail" />,
            enableSorting: false,
        }),
        columnHelper.accessor('comment', {
            header: () => '',
            cell: (comment) => comment.getValue() && <IconComment width={20} fill="#BDBDBD" title="Commentaire" />,
            enableSorting: false,
        }),
        columnHelper.accessor('mar', {
            header: () => '',
            cell: (mar) => mar.getValue() && <IconRenovation width={20} fill="#BDBDBD" title="MAR" />,
            enableSorting: false,
        }),
    ];

    const getRowId = (originalRow: FlowOutputRow): string => {
        return originalRow.id;
    };

    const handlePaginationChange = (paginationState: PaginationState) => {
        params.setPagination(paginationState); // Mettre à jour l'état de la pagination
        params.setCurrentPageIndex(paginationState.pageIndex); // Mettre à jour l'index de la page courante

        // Update API
        params.setFetch(true);
    };

    const dataTable = useReactTable({
        data: params.data,
        columns,
        state: {
            sorting: params.sorting,
            rowSelection,
            pagination: params.pagination,
        },
        getRowId,
        manualSorting: true,
        manualPagination: true,
        enableMultiSort: false,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: params.setPagination,
        onSortingChange: params.setSorting,
        getSortedRowModel: getSortedRowModel(),
        pageCount: params.totalPages,

        debugTable: false,
    });

    // Remove '_' from cell.id
    const idFilter = (id: string) => {
        return id.split('_')[1];
    };

    // Redirect to cell.url
    const trRedirect = (url: string) => {
        push(url);
    };

    return (
        <>
            <table className="table table-list-audit">
                <thead>
                    {dataTable.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    data-name={header.id}
                                    colSpan={header.colSpan}
                                    className={header.column.getCanSort() ? 'cursor-pointer' : 'pe-none'}
                                    style={
                                        header.id === 'select' || header.id === 'url' || header.id === 'comment' || header.id === 'mar'
                                            ? { width: '4%' }
                                            : undefined
                                    }
                                    // onClick={header.column.getToggleSortingHandler()}
                                    onClick={() => handleHeaderClick(header.id, header.column.getCanSort())}
                                >
                                    <div className="position-relative d-inline-block">
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getCanSort() ? (
                                            header.column.getIsSorted() ? (
                                                {
                                                    asc: (
                                                        <span className="btn btn-table-sort" data-state={'asc'}>
                                                            <span className="tri"></span>
                                                        </span>
                                                    ),
                                                    desc: (
                                                        <span className="btn btn-table-sort" data-state={'desc'}>
                                                            <span className="tri"></span>
                                                        </span>
                                                    ),
                                                }[(header.column.getIsSorted() as string) ?? null]
                                            ) : (
                                                <span className="btn btn-table-sort" data-state={'default'}>
                                                    <span className="tri"></span>
                                                </span>
                                            )
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {dataTable.getRowModel().rows.map((row) => (
                        <tr key={row.id} onClick={() => trRedirect(row.getValue('url'))} data-selected={row.getIsSelected()}>
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    align="center"
                                    data-name={idFilter(cell.id)}
                                    onClick={idFilter(cell.id) === 'select' ? (e) => e.stopPropagation() : undefined}
                                    className={idFilter(cell.id) !== 'select' ? 'cursor-pointer' : undefined}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="table-pagination d-flex align-items-center justify-content-end">
                <div>Élements par page :</div>
                <div className="pagination-select">
                    <select
                        value={dataTable.getState().pagination.pageSize}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            dataTable.setPageSize(Number(e.target.value));
                            params.setFetch(true);
                        }}
                    >
                        {[10, 20, 40].map((pageSize: number) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    {dataTable.getState().pagination.pageIndex + 1} - {dataTable.getPageCount()}
                </div>
                <button
                    type="button"
                    className="btn-previous"
                    onClick={() => handlePaginationChange({ ...params.pagination, pageIndex: params.pagination.pageIndex - 1 })}
                    disabled={!dataTable.getCanPreviousPage()}
                >
                    <IconArrow fill="grey" />
                </button>
                <button
                    type="button"
                    className="btn-next"
                    onClick={() => handlePaginationChange({ ...params.pagination, pageIndex: params.pagination.pageIndex + 1 })}
                    disabled={!dataTable.getCanNextPage()}
                >
                    <IconArrow fill="grey" />
                </button>
            </div>
        </>
    );
};

const ListAudit: React.FC = () => {
    const [data, setData] = useState<Array<FlowOutputRow>>(() => []);
    const animatedComponents = makeAnimated();
    const [fetch, setFetch] = useState<boolean>(true);
    const [formFilter, setFormFilter] = useState<apiFlow.GetMyFlowsFilters>();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [sorting, setSorting] = useState<SortingInfo[]>([{ id: 'createdAt', desc: true }]);
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    // Selected rows from table
    const [rowSelection, setRowSelection] = useRecoilState(SelectedAudit);
    const AlertSwal = withReactContent(Swal);

    // Remove elements from Array with specific index
    const handleRemove = async () => {
        const indexesToRemove = Object.keys(rowSelection);
        await apiFlow.deleteFlows(indexesToRemove);

        // Update datas
        setFetch(true);

        // Clear inputs
        setRowSelection({});
    };

    const handleRemoveModal = (elements: number) => {
        let content = `Êtes-vous sûr(e) de confirmer la suppression de ${elements === 1 ? 'cette étude' : 'ces études'} ?`;

        AlertSwal.fire({
            title: 'Confirmer la suppression',
            html: <p>{content}</p>,
            width: 550,
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-suppr',
                cancelButton: 'btn btn-retour',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleRemove();
            }
        });
    };

    const parameters = prepareParameters({ pagination, sorting: sorting[0], filters: formFilter });

    const handleSort = (columnId: string, desc: boolean) => {
        // Mise à jour des informations de tri
        const newSorting: SortingInfo[] = [{ id: columnId, desc }];

        // Mettre à jour le state pour déclencher l'appel à l'API avec le tri côté serveur
        setSorting(newSorting);
    };

    // Fetch data from API
    useEffect(() => {
        if (!fetch) return;

        apiFlow
            .getMyFlows({
                ...parameters,
            })
            .then((result) => {
                if (result.totalElements === 0) {
                    setData([]);
                } else {
                    setData(convertToRow(result.elements));
                }
                setTotalPages(result.totalPage);
                setFetch(false);
            })
            .catch((e) => {
                console.error(e);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetch]);

    // Reset localStorage
    useEffect(() => {
        localStorage.removeItem('flowId');
        localStorage.removeItem('auditId');
        localStorage.removeItem('devisId');
        localStorage.removeItem('previsitDocUrl');
        localStorage.removeItem('installationDate');
        localStorage.removeItem('flowState');
        localStorage.removeItem('auditType');
    }, []);

    const filterStateToApi = (state: string | undefined) => {
        switch (state) {
            case 'notSent':
                return ['Created', 'AuditSent', 'RdvRequested'];

            case 'sentNoRdv':
                return ['WithoutRdv'];

            case 'sentWithRdv':
                return ['RdvSigned', 'WithFundingRejected', 'WithoutDevis', 'WithoutCommand', 'DevisSent'];

            case 'orderCompleted':
                return ['CommandSigned', 'WithFunding', 'WithoutPrevisit'];

            case 'preVisitCompleted':
                return [
                    'WithoutInstallation',
                    'WithInstallation',
                    'WithFundingAccepted',
                    'WithFundingAcceptedWithoutPrevisit',
                    'WithFundingAcceptedWithPrevisitWithoutRdv',
                    'WithFundingAcceptedWithPrevisitWithRdv',
                ];

            default:
                return undefined;
        }
    };

    return (
        <div className="container list-audit">
            <div className="list-audit-header d-flex justify-content-between align-items-center w-100 py-4 mb-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
                <div className="btn-grp justify-content-end">
                    <button
                        type="button"
                        className="btn btn-list remove"
                        disabled={Object.keys(rowSelection).length === 0}
                        onClick={() => handleRemoveModal(Object.keys(rowSelection).length)}
                    >
                        Supprimer
                    </button>
                    <Link to={ROUTE_EA_CREATION} className="btn btn-list study">
                        Nouvelle étude
                    </Link>
                </div>
            </div>

            <div className="container-creation p-4">
                <div className="row align-items-center justify-content-end mb-4">
                    <div className="col-auto p-0">
                        <label className="mb-0" htmlFor="tableStatus">
                            Filtrer par statut
                        </label>
                    </div>
                    <div className="col-6 col-lg-3">
                        <Select
                            options={tableStatus}
                            closeMenuOnSelect={false}
                            id="tableStatus"
                            isSearchable={false}
                            isMulti={true}
                            onChange={(selectedOptions) => {
                                if (!selectedOptions) return;

                                setFormFilter((prev) => ({
                                    ...prev,
                                    state: selectedOptions.map((option: Labelled<apiFlow.FlowStatusFilter>) => filterStateToApi(option.value)).flat(),
                                }));
                                // Update API
                                setFetch(true);
                            }}
                            components={animatedComponents}
                            placeholder="Filtrer par statut"
                        />
                    </div>
                </div>

                <FlowTable
                    data={data}
                    setData={setData}
                    fetch={fetch}
                    setFetch={setFetch}
                    sorting={sorting}
                    setSorting={setSorting}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleSort={handleSort}
                    totalPages={totalPages}
                    setCurrentPageIndex={setCurrentPageIndex}
                    pageIndex={currentPageIndex}
                />
            </div>
        </div>
    );
};

export default ListAudit;
