import React, { useEffect, useState, useRef } from 'react';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';

import CheckboxService from '../../components/checkbox/checkboxService';
import {
    buildingWindows1Before1990,
    buildingWindows2Presence,
    buildingDoorWindows1Presence,
    buildingWindows2Before1990,
    buildingDoor2Presence,
    ventilationProtectionMotorized,
    buildingDoorUnheatedRoom1Presence,
    ventilationProtection,
    windows,
} from './services/handleCheckbox';

import { domTomCheck } from '../../services/tools/domTomServices';

import * as Yup from 'yup';
import { Formik } from 'formik';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Select, { components, OptionProps } from 'react-select';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as IconWindows } from '../../assets/icons/icon-opening.svg';
import { ReactComponent as IconWindow } from '../../assets/icons/icon-window.svg';
import { ReactComponent as IconWindow2 } from '../../assets/icons/icon-window2.svg';
import { ReactComponent as IconDoorWindows } from '../../assets/icons/icon-door-window.svg';
import { ReactComponent as IconDoors } from '../../assets/icons/icon-door.svg';
import { ReactComponent as IconDoor } from '../../assets/icons/icon-door-main.svg';
import { ReactComponent as IconDoor2 } from '../../assets/icons/icon-door2.svg';
import { ReactComponent as IconDoor3 } from '../../assets/icons/icon-door3.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';
import {
    buildingWindows1List,
    buildingWindows1glazingList,
    buildingWindows1glazingSizeList,
    buildingDoor1List,
    buildingDoor1TypeList,
    orientationList,
    buildingDoorWindows1glazingList,
} from './OpeningsEnums';
import { ROUTE_AUDIT_PHOTOS } from '../../routing/paths';
import { useStepNavigation } from '../../routing/StepNavigationContext';

type MandatoryPhotosProps = {
    name: string;
    errorMessage: string;
};

const Openings: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    // Current year
    const currentYearMinus = (year: number) => {
        return new Date().getFullYear() - year;
    };

    const buildingPictureDoor1 = useRef(null);
    const buildingPictureDoor2 = useRef(null);
    const buildingPictureDoorUnheatedRoom1Presence = useRef(null);
    const buildingPictureBuildingWindows1 = useRef(null);
    const buildingPictureBuildingWindows2 = useRef(null);
    const buildingPictureDoorWindows1 = useRef(null);

    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const AlertSwal = withReactContent(Swal);

    /**
     * Pour le changement de couleurs du react-select
     */
    const colourStyles = {
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#76ca4f' : null,
                color: isFocused ? '#ffffff' : '#1F2F3D',
            };
        },
    };

    /**
     * Fonction pour retrouver la valeur de défaut d'un champ via une liste d'éléments
     * @param propertyList
     * @param value
     */
    const renderDefaultValueSelect = (propertyList: any, value: any) => {
        return propertyList.filter((property: any) => {
            return property.value === value;
        });
    };

    const initialValues = {
        windows: storageService.checkPropertyExistThenCreateOrRender(7, 'windows', `Présence de fenêtre`, PropertyType['boolean']),
        ventilationProtection: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'ventilationProtection',
            `Présence de Protection`,
            PropertyType['boolean']
        ),
        ventilationProtectionMotorized: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'ventilationProtectionMotorized',
            `Les protections sont-elles motorisées`,
            PropertyType['boolean']
        ),
        //Typologie de fenêtre 1
        buildingWindows1: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingWindows1', `Châssis`, PropertyType['withLabel']),
        buildingWindows1glazing: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingWindows1glazing', `Châssis`, PropertyType['withLabel']),
        buildingWindows1glazingSize: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows1glazingSize',
            `Épaisseur du vitrage`,
            PropertyType['withLabel']
        ),
        buildingWindows1details: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows1details',
            `Détails des fenêtres`,
            PropertyType['window']
        ),
        buildingWindows1Before1990: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows1Before1990',
            `L’installation des fenêtres a été effectuée avant ${currentYearMinus(30)} ?`,
            PropertyType['boolean']
        ),
        //Typologie de fenêtre 2
        buildingWindows2Presence: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows2Presence',
            `Présence de fenêtres secondaires`,
            PropertyType['boolean']
        ),
        buildingWindows2: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingWindows2', `Châssis`, PropertyType['withLabel']),
        buildingWindows2glazing: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingWindows2glazing', `Châssis`, PropertyType['withLabel']),
        buildingWindows2glazingSize: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows2glazingSize',
            `Épaisseur du vitrage`,
            PropertyType['withLabel']
        ),
        buildingWindows2details: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows2details',
            `Détails des fenêtres`,
            PropertyType['window']
        ),
        buildingWindows2Before1990: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingWindows2Before1990',
            `L’installation des fenêtres a été effectuée avant ${currentYearMinus(30)} ?`,
            PropertyType['boolean']
        ),
        //Caractéristiques des Porte-fenêtres
        buildingDoorWindows1Presence: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorWindows1Presence',
            `Présence de portes-fenêtres`,
            PropertyType['boolean']
        ),
        buildingDoorWindows1: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoorWindows1', `Châssis`, PropertyType['withLabel']),
        buildingDoorWindows1glazing: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorWindows1glazing',
            `Châssis`,
            PropertyType['withLabel']
        ),
        buildingDoorWindows1glazingSize: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorWindows1glazingSize',
            `Épaisseur du vitrage`,
            PropertyType['withLabel']
        ),
        buildingDoorWindows1details: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorWindows1details',
            `Détails des porte-fenêtres`,
            PropertyType['window']
        ),
        //Porte principale
        buildingDoor1: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoor1', `Nature`, PropertyType['withLabel']),
        buildingDoor1Type: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoor1Type', `Type`, PropertyType['withLabel']),
        //Portes secondaires
        buildingDoor2Presence: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoor2Presence',
            `Présence de portes secondaires`,
            PropertyType['boolean']
        ),
        buildingDoor2: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoor2', `Nature`, PropertyType['withLabel']),
        buildingDoor2Type: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoor2Type', `Type`, PropertyType['withLabel']),
        //Portes sur local non-chauffé
        buildingDoorUnheatedRoom1Presence: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorUnheatedRoom1Presence',
            `Présence de portes sur local non-chauffé`,
            PropertyType['boolean']
        ),
        buildingDoorUnheatedRoom1: storageService.checkPropertyExistThenCreateOrRender(7, 'buildingDoorUnheatedRoom1', `Nature`, PropertyType['withLabel']),
        buildingDoorUnheatedRoom1Type: storageService.checkPropertyExistThenCreateOrRender(
            7,
            'buildingDoorUnheatedRoom1Type',
            `Type`,
            PropertyType['withLabel']
        ),
        // Calcul du Shab (limite de 10% à 20% du Shab)
        SHabAreaLimit: storageService.checkPropertyExistThenCreateOrRender(7, 'SHabAreaLimit', `Valeur des surfaces des fenêtres`, PropertyType['number']),

        buildingPictureDoor1: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureDoor1', `Photo porte principale`, PropertyType['photo']),
        buildingPictureDoor2: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureDoor2', `Photo porte secondaire`, PropertyType['photo']),
        buildingPictureDoorUnheatedRoom1Presence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureDoorUnheatedRoom1Presence',
            `Photo porte sur local non-chauffé`,
            PropertyType['photo']
        ),
        buildingPictureBuildingWindows1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureBuildingWindows1',
            `Photo typologie de fenêtre 1`,
            PropertyType['photo']
        ),
        buildingPictureBuildingWindows2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureBuildingWindows2',
            `Photo typologie de fenêtre 2`,
            PropertyType['photo']
        ),
        buildingPictureDoorWindows1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureDoorWindows1',
            `Photo des portes-fenêtres`,
            PropertyType['photo']
        ),
    };

    // DOM TOM
    const currentZipCode: string = storageService.getAudit().step1.userZipCode.value;

    const mapRules = (map: any, rule: any) => Object.keys(map).reduce((newMap: {}, key: string) => ({ ...newMap, [key]: rule }), {});

    const validationSchema = Yup.object()
        .shape({
            windows: Yup.boolean().required('La présence de fenêtre est obligatoire'),
            ventilationProtection: Yup.boolean()
                .notRequired()
                .when('windows', {
                    is: true,
                    then: Yup.boolean().required(`Le choix de présence de protection est obligatoire`),
                }),
            ventilationProtectionMotorized: Yup.boolean().when('ventilationProtection', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si les protections des fenêtres sont motorisées`)
                    .test('ventilationProtectionMotorized', `Vous n'avez pas précisé si les protections des fenêtres sont motorisées`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),

            buildingWindows1: Yup.string().when('windows', {
                is: true,
                then: Yup.string().required(`La composition des fenêtres principales est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows1glazing: Yup.string().when('windows', {
                is: true,
                then: Yup.string().required(`Le type de vitrage des fenêtres principales est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows1glazingSize: Yup.string().when(['windows', 'buildingWindows1glazing'], {
                is: (windows: boolean, buildingWindows1glazing: string) => windows && ['1', '3'].includes(buildingWindows1glazing),
                then: Yup.string().required(`Le choix de l'épaisseur des fenêtres principales est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows1details: Yup.object().when('windows', {
                is: true,
                then: Yup.lazy((map) =>
                    Yup.object(
                        mapRules(
                            map,
                            Yup.object({
                                count: Yup.number()
                                    .min(1, `Le nombre minimal de fenêtres principales est de 1`)
                                    .typeError(`Le nombre de fenêtres principales est obligatoire`)
                                    .required(`Le nombre de fenêtres principales est obligatoire`),
                                surface: Yup.number()
                                    .positive()
                                    .min(1, `La surface minimale de fenêtres principales est de 1`)
                                    .typeError(`La surface des fenêtres principales est obligatoire`)
                                    .required(`La surface des fenêtres principales est obligatoire`),
                            })
                        )
                    )
                        .nullable()
                        .test('buildingWindows1details', 'Le détail des fenêtres principales est obligatoire', (value) => {
                            return Object.keys(value as any).length !== 0;
                        })
                ),
                otherwise: Yup.object(),
            }),
            buildingWindows1Before1990: Yup.boolean().when(['windows', storageService.getAudit().step2.houseAge.value], {
                is: (windows: boolean, year: string) => windows && parseInt(year) < currentYearMinus(30),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l’installation des fenêtres 1 a été effectuée avant ${currentYearMinus(30)}`)
                    .test(
                        'buildingWindows1Before1990',
                        `Vous n'avez pas précisé si l’installation des fenêtres 1 a été effectuée avant ${currentYearMinus(30)}`,
                        (value) => {
                            return [true, false].includes(value as boolean);
                        }
                    ),
                otherwise: Yup.boolean(),
            }),

            buildingWindows2Presence: Yup.boolean().when('windows', {
                is: true,
                then: Yup.boolean().required(`Vous n'avez pas précisé si l'habitation comporte des fenêtres secondaires`),
                otherwise: Yup.boolean(),
            }),
            buildingWindows2: Yup.string().when(['windows', 'buildingWindows2Presence'], {
                is: (windows: boolean, buildingWindows2Presence: boolean) => windows && buildingWindows2Presence,
                then: Yup.string().required(`La composition des fenêtres secondaires est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows2glazing: Yup.string().when(['windows', 'buildingWindows2Presence'], {
                is: (windows: boolean, buildingWindows2Presence: boolean) => windows && buildingWindows2Presence,
                then: Yup.string().required(`Le type de vitrage des fenêtres secondaires est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows2glazingSize: Yup.string().when(['windows', 'buildingWindows2glazing'], {
                is: (windows: boolean, buildingWindows2glazing: string) => windows && ['1', '3'].includes(buildingWindows2glazing),
                then: Yup.string().required(`Le choix de l'épaisseur des fenêtres secondaires est obligatoire`),
                otherwise: Yup.string(),
            }),
            buildingWindows2details: Yup.object().when(['windows', 'buildingWindows2Presence'], {
                is: (windows: boolean, buildingWindows2Presence: boolean) => windows && buildingWindows2Presence,
                then: Yup.lazy((map) =>
                    Yup.object(
                        mapRules(
                            map,
                            Yup.object({
                                count: Yup.number()
                                    .min(1, `Le nombre minimal de fenêtres secondaires est de 1`)
                                    .typeError(`Le nombre de fenêtres secondaires est obligatoire`)
                                    .required(`Le nombre de fenêtres secondaires est obligatoire`),
                                surface: Yup.number()
                                    .min(1, `La surface minimale de fenêtres secondaires est de 1`)
                                    .typeError(`La surface des fenêtres secondaires est obligatoire`)
                                    .required(`La surface des fenêtres secondaires est obligatoire`),
                            })
                        )
                    )
                        .nullable()
                        .test('buildingWindows2details', 'Le détail des fenêtres secondaires est obligatoire', (value) => {
                            return Object.keys(value as any).length !== 0;
                        })
                ),
                otherwise: Yup.object(),
            }),
            buildingWindows2Before1990: Yup.boolean().when(['windows', 'buildingWindows2Presence', storageService.getAudit().step2.houseAge.value], {
                is: (windows: boolean, buildingWindows2Presence: boolean, year: string) =>
                    windows && buildingWindows2Presence && parseInt(year) < currentYearMinus(30),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l’installation des fenêtres 2 a été effectuée avant ${currentYearMinus(30)}`)
                    .test(
                        'buildingWindows2Before1990',
                        `Vous n'avez pas précisé si l’installation des fenêtres 2 a été effectuée avant ${currentYearMinus(30)}`,
                        (value) => {
                            return [true, false].includes(value as boolean);
                        }
                    ),
                otherwise: Yup.boolean(),
            }),
            //Caractéristiques des Porte-fenêtres
            buildingDoorWindows1Presence: Yup.boolean()
                .required(`Vous n'avez pas précisé si l'habitation comporte des portes-fenêtres`)
                .test('buildingDoorWindows1Presence', `Vous n'avez pas précisé si l'habitation comporte des portes-fenêtres`, (value) => {
                    return [true, false].includes(value as boolean);
                }),
            buildingDoorWindows1: Yup.string()
                .notRequired()
                .when('buildingDoorWindows1Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`La composition des porte-fenêtres est obligatoire`),
                }),
            buildingDoorWindows1glazing: Yup.string()
                .notRequired()
                .when('buildingDoorWindows1Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`Le type de vitrage des porte-fenêtres est obligatoire`),
                }),
            buildingDoorWindows1glazingSize: Yup.string().when('buildingDoorWindows1glazing', {
                is: (value: string) => ['1', '3'].includes(value),
                then: Yup.string().required(`Le choix de l'épaisseur des porte-fenêtres est obligatoire`),
            }),
            buildingDoorWindows1details: Yup.lazy((map) =>
                Yup.object(
                    mapRules(
                        map,
                        Yup.object({
                            count: Yup.number()
                                .min(1, `Le nombre minimal de porte-fenêtres est de 1`)
                                .typeError(`Le nombre de porte-fenêtres est obligatoire`)
                                .required(`Le nombre de porte-fenêtres est obligatoire`),
                            surface: Yup.number()
                                .min(1, `La surface minimale de porte-fenêtres est de 1`)
                                .typeError(`La surface des porte-fenêtres est obligatoire`)
                                .required(`La surface des porte-fenêtres est obligatoire`),
                        })
                    )
                )
                    .nullable()
                    .test('buildingDoorWindows1details', 'Le détail des porte-fenêtres est obligatoire', (value) => {
                        if (storageService.getAudit().step7.buildingDoorWindows1Presence.value === false) {
                            return true;
                        }
                        return Object.keys(value as any).length !== 0;
                    })
            ),
            //Porte principale
            buildingDoor1: Yup.string().required(`La composition de la porte principale est obligatoire`),
            buildingDoor1Type: Yup.string().required(`Le type de la porte principale est obligatoire`),
            //Portes secondaires
            buildingDoor2Presence: Yup.boolean()
                .required(`Vous n'avez pas précisé si l'habitation comporte des portes secondaires`)
                .test('buildingDoor2Presence', `Vous n'avez pas précisé si l'habitation comporte des portes secondaires`, (value) => {
                    return [true, false].includes(value as boolean);
                }),
            buildingDoor2: Yup.string()
                .notRequired()
                .when('buildingDoor2Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`La composition des portes secondaires est obligatoire`),
                }),
            buildingDoor2Type: Yup.string()
                .notRequired()
                .when('buildingDoor2Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`Le type des portes secondaires est obligatoire`),
                }),
            //Portes sur local non-chauffé
            buildingDoorUnheatedRoom1Presence:
                domTomCheck(currentZipCode) !== undefined
                    ? Yup.boolean().notRequired()
                    : Yup.boolean()
                          .notRequired()
                          .when(storageService.getAudit().step2.projectType.value, {
                              is: (value: string = storageService.getAudit().step2.projectType.value) => ['1', '4'].includes(value),
                              then: Yup.boolean()
                                  .required(`Vous n'avez pas précisé si l'habitation comporte des portes sur local non-chauffé ?`)
                                  .test(
                                      'buildingDoorUnheatedRoom1Presence',
                                      `Vous n'avez pas précisé si l'habitation comporte des portes sur local non-chauffé ?`,
                                      (value) => {
                                          return [true, false].includes(value as boolean);
                                      }
                                  ),
                          }),
            buildingDoorUnheatedRoom1: Yup.string()
                .notRequired()
                .when('buildingDoorUnheatedRoom1Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`La composition des portes sur local non-chauffé est obligatoire`),
                }),
            buildingDoorUnheatedRoom1Type: Yup.string()
                .notRequired()
                .when('buildingDoorUnheatedRoom1Presence', {
                    is: (value: string) => ['1', true, 'true'].includes(value),
                    then: Yup.string().required(`Le type des portes sur local non-chauffé est obligatoire`),
                }),
            // Calcul du SHab (limite de 10% à 20% du SHab)
            SHabAreaLimit: Yup.number().nullable().notRequired(),
            buildingPictureDoor1: Yup.string(),
            buildingPictureDoor2: Yup.string(),
            buildingPictureDoorUnheatedRoom1Presence: Yup.string(),
            buildingPictureBuildingWindows1: Yup.string(),
            buildingPictureBuildingWindows2: Yup.string(),
            buildingPictureDoorWindows1: Yup.string(),
        })
        .test('windowOrDoor', 'Vous devez sélectionner soit une fenêtre, soit une porte-fenêtre', function (value) {
            if (value.windows === true || value.buildingDoorWindows1Presence === true) {
                return true;
            } else {
                return this.createError({
                    path: 'windows',
                    message: 'Vous devez sélectionner soit une fenêtre, soit une porte-fenêtre',
                });
            }
        });

    /**
     * Liste de choix de la composition de fenêtre de Type 2
     */
    const buildingWindows2List: { label: string; value: string }[] = buildingWindows1List;
    const buildingWindows2glazingList: { label: string; value: string }[] = buildingWindows1glazingList;
    const buildingWindows2glazingSizeList: { label: string; value: string }[] = buildingWindows1glazingSizeList;

    /**
     * Liste de choix de la composition des porte-fenêtres
     */
    const buildingDoorWindows1List: { label: string; value: string }[] = buildingWindows1List;

    const buildingDoorWindows1glazingSizeList: { label: string; value: string }[] = buildingWindows1glazingSizeList;

    /**
     * Liste de choix de la composition des portes secondaires
     */
    const buildingDoor2List: { label: string; value: string }[] = buildingDoor1List;
    const buildingDoor2TypeList: { label: string; value: string }[] = buildingDoor1TypeList;

    /**
     * Liste de choix de la composition des portes sur local non-chauffé
     */
    const buildingDoorUnheatedRoom1List: { label: string; value: string }[] = buildingDoor1List;
    const buildingDoorUnheatedRoom1TypeList: { label: string; value: string }[] = buildingDoor1TypeList;

    const orientationWindows1List: { label: string; value: string }[] = orientationList;
    const orientationWindows2List: { label: string; value: string }[] = orientationList;
    const orientationDoorWindows1List: { label: string; value: string }[] = orientationList;

    /**
     * Fonction pour calculer si la surface de toutes les fenêtres entre dans le cadre des 10% à 20% du SHab
     */
    const updateSHabAreaLimit = (values: any, setValues: any, isValid: boolean) => {
        let SHabAreaLimit = 0;
        let buildingWindowsList: string[] = ['buildingWindows1details', 'buildingWindows2details', 'buildingDoorWindows1details'];

        buildingWindowsList.forEach((detail: string) => {
            if (Object.entries(values[detail]).length !== 0) {
                Object.keys(values[detail]).forEach((orientation: string) => {
                    SHabAreaLimit = SHabAreaLimit + values[detail][orientation].count * values[detail][orientation].surface;
                });
            }
        });

        // Mise à jour de Formik
        setValues({
            ...values,
            SHabAreaLimit,
        });
        // Injection dans local storage
        storageService.setAuditValue(7, isValid, 'SHabAreaLimit', SHabAreaLimit);
    };

    /**
     * Fonction pour confirmer la surface total (10% à 20% du SHab)
     */
    const confirmSHabAreaLimit = async (currentValue: string, limit: { start: number; end: number }) => {
        return await AlertSwal.fire({
            title: `Surfaces des fenêtres`,
            html: `La valeur des surfaces des fenêtres est incohérente.<br/>
La surface totale du vitrage devrait théoriquement être comprise entre <b>10% du SHab</b> (soit <b>${limit?.start.toFixed(
                2
            )}m²</b>) et <b>20% du SHab</b> (soit <b>${limit?.end.toFixed(2)}m²</b>) or cette valeur est actuellement de <b>${parseFloat(currentValue).toFixed(
                2
            )}m²</b><br/>
Si cette information est erronée elle risque de fausser totalement votre Audit.<br/><br/>
Confirmez vous votre choix ou souhaitez vous la modifier ?`,
            width: 550,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Modifier',
            allowOutsideClick: false,
            allowEscapeKey: false,
            reverseButtons: true,
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-primary min-width',
                cancelButton: 'btn btn-red-border min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Fonction pour tester le remplissage d'une propriété
     * @param propertyValue
     */
    const checkBuildingDetailsAllFilled = (propertyValue: any): any => {
        if (Object.entries(propertyValue).length === 0) {
            return false;
        }
        for (let key in propertyValue) {
            if (
                propertyValue[key].count === null ||
                propertyValue[key].count === 0 ||
                !propertyValue[key].count ||
                propertyValue[key].surface === null ||
                propertyValue[key].surface === 0 ||
                !propertyValue[key].surface
            ) {
                return true;
            }
        }
    };

    // Photos obligatoires, mais en fait pas tellement...
    const mandatoryPhotos = (values: any): MandatoryPhotosProps[] => [
        {
            name: 'buildingPictureDoor1',
            errorMessage: 'photo de la porte principale',
        },
        ...((values.windows as boolean)
            ? [
                  {
                      name: 'buildingPictureBuildingWindows1',
                      errorMessage: 'photo de la typologie de fenêtre 1',
                  },
              ]
            : []),
    ];

    const checkPhotos = (values: any) => {
        let photoCheck = false;

        const currentMandatoryPhotos = mandatoryPhotos(values);
        currentMandatoryPhotos.forEach((photo: MandatoryPhotosProps) => {
            if (values[photo.name] === '') {
                photoCheck = true;
            }
        });
        return photoCheck;
    };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            isInitialValid={storageService.getAudit().step7.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
        >
            {({ values, isValid, touched, errors, handleBlur, setValues, setFieldValue }) => {
                console.log('Erreurs:', JSON.stringify(errors, null, 2));
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(7, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (8 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                return (
                    <div className="container">
                        <h1 className="main-title-mini">7. Les ouvrants</h1>
                        <h2 className="main-subtitle">Caractéristiques des ouvertures (fenêtres, portes…)</h2>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconWindows fill="#76ca4f" width={40} height={40} className="icon" />
                                <div className="d-flex flex-column">
                                    <h2>Caractéristiques des fenêtres</h2>
                                    <p>
                                        Si toutes les fenêtres de l’habitation sont identiques, renseigner uniquement la typologie 1. Sinon, renseigner la
                                        deuxième typologie de fenêtre.
                                    </p>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={windows}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            propName={values.windows}
                                            name="windows"
                                            title="Présence de fenêtres"
                                            border={true}
                                            disabled={auditSent}
                                        />
                                    </div>
                                </div>
                                {values.windows && (
                                    <fieldset className="w-100" disabled={auditSent}>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={ventilationProtection}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'ventilationProtection'}
                                                    propName={values.ventilationProtection}
                                                    title={`Présence de protection (volet roulant, persienne, ect…)`}
                                                    border={true}
                                                />
                                                {touched.ventilationProtection && errors.ventilationProtection && (
                                                    <div className="invalid-feedback">{errors.ventilationProtection}</div>
                                                )}
                                            </div>
                                            {
                                                /**
                                                 * Afficher ce champs si `ventilationProtection` est sur `oui`
                                                 */
                                                values.ventilationProtection && (
                                                    <div className="col-12 col-md-6">
                                                        <CheckboxService
                                                            handleCheckbox={ventilationProtectionMotorized}
                                                            values={values}
                                                            isValid={isValid}
                                                            setValues={setValues}
                                                            name={'ventilationProtectionMotorized'}
                                                            propName={values.ventilationProtectionMotorized}
                                                            title={`Les protections sont-elles motorisées ?`}
                                                            border={true}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-xl-6 mb-3 mb-xl-0">
                                                <div className="card without-shadow border-grey h-100">
                                                    <div className="card-title">
                                                        <IconWindow fill="#76ca4f" width={40} height={40} className="icon" />
                                                        <h2>Typologie de fenêtre 1</h2>

                                                        <AddStepPhotos
                                                            prop={buildingPictureBuildingWindows1}
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            fieldValue={'buildingPictureBuildingWindows1'}
                                                            required={true}
                                                            disabled={auditSent}
                                                        />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="buildingWindows1" className="form-label">
                                                                Composition des fenêtres principales<sup>*</sup>
                                                            </label>
                                                            <Select
                                                                value={renderDefaultValueSelect(buildingWindows1List, values.buildingWindows1)}
                                                                options={buildingWindows1List}
                                                                isClearable={false}
                                                                isSearchable={false}
                                                                isMulti={false}
                                                                isDisabled={auditSent}
                                                                inputId="buildingWindows1"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    storageService.setAuditValue(
                                                                        7,
                                                                        isValid,
                                                                        'buildingWindows1glazingSize',
                                                                        event.value,
                                                                        event.label
                                                                    );
                                                                    setValues({
                                                                        ...values,
                                                                        buildingWindows1: event.value,
                                                                    });
                                                                }}
                                                                className={
                                                                    'basic-single' +
                                                                    (values.buildingWindows1 ? ' filled' : '') +
                                                                    (touched.buildingWindows1 && errors.buildingWindows1 ? ' invalid' : '') +
                                                                    (!values.buildingWindows1 ? ' required' : '')
                                                                }
                                                                placeholder={`Choisissez la composition`}
                                                                styles={colourStyles}
                                                            />
                                                            {touched.buildingWindows1 && errors.buildingWindows1 && (
                                                                <div className="invalid-feedback">{errors.buildingWindows1}</div>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="buildingWindows1glazing" className="form-label">
                                                                Type de vitrage des fenêtres principales<sup>*</sup>
                                                            </label>
                                                            <Select
                                                                value={renderDefaultValueSelect(buildingWindows1glazingList, values.buildingWindows1glazing)}
                                                                options={buildingWindows1glazingList}
                                                                isClearable={false}
                                                                isSearchable={false}
                                                                isMulti={false}
                                                                isDisabled={auditSent}
                                                                inputId="buildingWindows1glazing"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    setValues({
                                                                        ...values,
                                                                        buildingWindows1glazing: event.value,
                                                                        buildingWindows1glazingSize: '',
                                                                    });
                                                                    storageService.setAuditValue(
                                                                        7,
                                                                        isValid,
                                                                        'buildingWindows1glazing',
                                                                        event.value,
                                                                        event.label
                                                                    );
                                                                    // Mise à jour des champs associés
                                                                    storageService.removeAuditValue(7, 'buildingWindows1glazingSize', true);
                                                                }}
                                                                className={
                                                                    'basic-single' +
                                                                    (values.buildingWindows1glazing ? ' filled' : '') +
                                                                    (touched.buildingWindows1glazing && errors.buildingWindows1glazing ? ' invalid' : '') +
                                                                    (!values.buildingWindows1glazing ? ' required' : '')
                                                                }
                                                                placeholder={`Choisissez le type de vitrage`}
                                                                styles={colourStyles}
                                                            />
                                                            {touched.buildingWindows1glazing && errors.buildingWindows1glazing && (
                                                                <div className="invalid-feedback">{errors.buildingWindows1glazing}</div>
                                                            )}
                                                        </div>
                                                        {['1', '3'].includes(values.buildingWindows1glazing) && (
                                                            <div className="mb-3">
                                                                <label htmlFor="buildingWindows1glazingSize" className="form-label">
                                                                    Épaisseur du vitrage (mm)<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={renderDefaultValueSelect(
                                                                        buildingWindows1glazingSizeList,
                                                                        values.buildingWindows1glazingSize
                                                                    )}
                                                                    options={buildingWindows1glazingSizeList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    isDisabled={auditSent}
                                                                    inputId="buildingWindows1glazingSize"
                                                                    onBlur={handleBlur}
                                                                    onChange={(event) => {
                                                                        storageService.setAuditValue(
                                                                            7,
                                                                            isValid,
                                                                            'buildingWindows1glazingSize',
                                                                            event.value,
                                                                            event.label
                                                                        );
                                                                        setValues({
                                                                            ...values,
                                                                            buildingWindows1glazingSize: event.value,
                                                                        });
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingWindows1glazingSize ? ' filled' : '') +
                                                                        (touched.buildingWindows1glazingSize && errors.buildingWindows1glazingSize
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingWindows1glazingSize ? ' required' : '')
                                                                    }
                                                                    placeholder={`Choisissez le type de vitrage`}
                                                                    styles={colourStyles}
                                                                />
                                                                {touched.buildingWindows1glazingSize && errors.buildingWindows1glazingSize && (
                                                                    <div className="invalid-feedback">{errors.buildingWindows1glazingSize}</div>
                                                                )}
                                                            </div>
                                                        )}
                                                        {parseInt(storageService.getAudit().step2.houseAge.value) < currentYearMinus(30) && (
                                                            <div className="mb-3">
                                                                <CheckboxService
                                                                    handleCheckbox={buildingWindows1Before1990}
                                                                    values={values}
                                                                    isValid={isValid}
                                                                    setValues={setValues}
                                                                    name={'buildingWindows1Before1990'}
                                                                    propName={values.buildingWindows1Before1990}
                                                                    title={`L’installation des fenêtres a été effectuée avant ${currentYearMinus(30)} ?`}
                                                                    border={true}
                                                                />
                                                            </div>
                                                        )}
                                                        <div>
                                                            <label htmlFor="buildingWindows1details" className="form-label">
                                                                Détails des fenêtres principales<sup>*</sup>
                                                                <div className="help-text" data-tip data-for="helpBuildingWindows1details">
                                                                    <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                </div>
                                                            </label>
                                                            {Object.keys(values.buildingWindows1details).map((item: any) => (
                                                                <div className="d-flex flex-row justify-content-between mb-3" key={item}>
                                                                    <div className="form-group">
                                                                        <div className="col-4">
                                                                            <small className="label-small">&nbsp;</small>
                                                                            {item === 'tmp' && (
                                                                                <Select
                                                                                    options={orientationWindows1List}
                                                                                    isClearable={false}
                                                                                    isDisabled={
                                                                                        Object.keys(values.buildingWindows1details).length ===
                                                                                            orientationWindows1List.length + 1 || auditSent
                                                                                    }
                                                                                    isSearchable={false}
                                                                                    isMulti={false}
                                                                                    inputId={'orientationWindows1'}
                                                                                    onChange={(event) => {
                                                                                        let tmpBuildingWindows1details = values.buildingWindows1details;
                                                                                        if (tmpBuildingWindows1details.tmp) {
                                                                                            tmpBuildingWindows1details[event.value] = {
                                                                                                count: tmpBuildingWindows1details.tmp.count,
                                                                                                surface: tmpBuildingWindows1details.tmp.surface,
                                                                                            };
                                                                                            delete tmpBuildingWindows1details.tmp;
                                                                                            setValues({
                                                                                                ...values,
                                                                                                buildingWindows1details: tmpBuildingWindows1details,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    className={
                                                                                        'basic-single basic-orientation' +
                                                                                        (Object.keys(values.buildingWindows1details).length === 0
                                                                                            ? ' required'
                                                                                            : '')
                                                                                    }
                                                                                    placeholder={`Orientation`}
                                                                                    styles={colourStyles}
                                                                                    components={{
                                                                                        Option: (props: OptionProps<any, any>) => (
                                                                                            <components.Option
                                                                                                {...props}
                                                                                                className={
                                                                                                    'custom-react-select' +
                                                                                                    (Object.keys(values.buildingWindows1details).includes(
                                                                                                        props.data.value
                                                                                                    ) ||
                                                                                                    !props.data.included.includes(
                                                                                                        storageService.getAudit()?.step2?.buildingPlans
                                                                                                            ?.value[0]?.orientation
                                                                                                    )
                                                                                                        ? ' d-none'
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                {props.label}
                                                                                            </components.Option>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {item !== 'tmp' && (
                                                                                <input
                                                                                    type="text"
                                                                                    readOnly={true}
                                                                                    className={'form-control opacity-text border-radius-right-0'}
                                                                                    placeholder="Orientation"
                                                                                    defaultValue={
                                                                                        renderDefaultValueSelect(orientationWindows1List, item)[0]?.label
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="col">
                                                                            <small className="label-small">Nombre de fenêtre</small>
                                                                            <input
                                                                                type="number"
                                                                                min={1}
                                                                                disabled={item === 'tmp'}
                                                                                className={
                                                                                    'form-control border-radius-0' +
                                                                                    (values.buildingWindows1details[item]?.count ? ' filled' : '') +
                                                                                    ((touched as any)['countWindows1-' + item] &&
                                                                                    !values.buildingWindows1details[item]?.count
                                                                                        ? ' invalid'
                                                                                        : '') +
                                                                                    (!values.buildingWindows1details[item]?.count ? ' required' : '')
                                                                                }
                                                                                placeholder="Nombre"
                                                                                id={'countWindows1-' + item}
                                                                                onBlur={(event) => {
                                                                                    handleBlur(event);
                                                                                    updateSHabAreaLimit(values, setValues, isValid);
                                                                                }}
                                                                                value={values.buildingWindows1details[item].count || ''}
                                                                                onChange={(event) => {
                                                                                    let updatedObject = values.buildingWindows1details;
                                                                                    updatedObject[item].count = parseInt(event.target.value);
                                                                                    setValues({
                                                                                        ...values,
                                                                                        buildingWindows1details: updatedObject,
                                                                                    });
                                                                                    // Mise à jour du local storage
                                                                                    storageService.setAuditValue(
                                                                                        7,
                                                                                        isValid,
                                                                                        'buildingWindows1details',
                                                                                        updatedObject
                                                                                    );
                                                                                    // Mise à jour du SHabAreaLimit
                                                                                    updateSHabAreaLimit(values, setValues, isValid);
                                                                                }}
                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                            />
                                                                        </div>
                                                                        <div className="col">
                                                                            <small className="label-small">
                                                                                Surface vitrée par fenêtre
                                                                                <div className="help-text" data-tip data-for="helpFenetre1">
                                                                                    <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                                </div>
                                                                            </small>

                                                                            <ReactTooltip id="helpFenetre1" place="bottom" type={'light'} effect={'solid'}>
                                                                                <p>Veuillez indiquer en m2 la moyenne de surface vitrée par fenêtre</p>
                                                                            </ReactTooltip>
                                                                            <div className="input-group">
                                                                                <input
                                                                                    type="number"
                                                                                    min={1}
                                                                                    disabled={item === 'tmp'}
                                                                                    className={
                                                                                        'form-control border-radius-left-0' +
                                                                                        (values.buildingWindows1details[item]?.surface ? ' filled' : '') +
                                                                                        ((touched as any)['surfaceWindows1-' + item] &&
                                                                                        !values.buildingWindows1details[item]?.surface
                                                                                            ? ' invalid'
                                                                                            : '') +
                                                                                        (!values.buildingWindows1details[item]?.surface ? ' required' : '')
                                                                                    }
                                                                                    placeholder="Surface"
                                                                                    id={'surfaceWindows1-' + item}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        updateSHabAreaLimit(values, setValues, isValid);

                                                                                        if (event.target.value) {
                                                                                            let updatedObject = values.buildingWindows1details;
                                                                                            updatedObject[item].surface =
                                                                                                parseFloat(event.target.value) % 1 !== 0
                                                                                                    ? parseFloat(event.target.value).toFixed(2)
                                                                                                    : parseInt(event.target.value);

                                                                                            setValues({
                                                                                                ...values,
                                                                                                buildingWindows1details: updatedObject,
                                                                                            });

                                                                                            // Mise à jour du local storage
                                                                                            storageService.setAuditValue(
                                                                                                7,
                                                                                                isValid,
                                                                                                'buildingWindows1details',
                                                                                                updatedObject
                                                                                            );
                                                                                            // Mise à jour du SHabAreaLimit
                                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                                        }
                                                                                    }}
                                                                                    value={values.buildingWindows1details[item].surface || ''}
                                                                                    onChange={(event) => {
                                                                                        let updatedObject = values.buildingWindows1details;
                                                                                        updatedObject[item].surface = parseFloat(event.target.value);
                                                                                        setValues({
                                                                                            ...values,
                                                                                            buildingWindows1details: updatedObject,
                                                                                        });
                                                                                        // Mise à jour du local storage
                                                                                        storageService.setAuditValue(
                                                                                            7,
                                                                                            isValid,
                                                                                            'buildingWindows1details',
                                                                                            updatedObject
                                                                                        );
                                                                                        // Mise à jour du SHabAreaLimit
                                                                                        updateSHabAreaLimit(values, setValues, isValid);
                                                                                    }}
                                                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                                />
                                                                                <span className="input-group-text">m²</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        className={'btn icon-remove icon-remove-red'}
                                                                        onClick={() => {
                                                                            let updatedObject = values.buildingWindows1details;
                                                                            delete updatedObject[item];
                                                                            setValues({
                                                                                ...values,
                                                                                buildingWindows1details: updatedObject,
                                                                            });
                                                                            // Mise à jour du local storage
                                                                            storageService.setAuditValue(7, isValid, 'buildingWindows1details', updatedObject);
                                                                            // Mise à jour SHabAreaLimit
                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                        }}
                                                                        type={'button'}
                                                                    ></button>
                                                                </div>
                                                            ))}
                                                            <div className="row mb-3">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className={
                                                                            'btn btn-green icon-add btn-square w-100' +
                                                                            (values.buildingWindows1details?.tmp ||
                                                                            Object.keys(values.buildingWindows1details).length ===
                                                                                orientationWindows1List.length
                                                                                ? ' disabled'
                                                                                : '')
                                                                        }
                                                                        disabled={
                                                                            checkBuildingDetailsAllFilled(values.buildingWindows1details) ||
                                                                            Object.keys(values.buildingWindows1details).length ===
                                                                                orientationWindows1List.length
                                                                        }
                                                                        onClick={() => {
                                                                            let updatedObject = values.buildingWindows1details;
                                                                            updatedObject['tmp'] = {
                                                                                count: null,
                                                                                surface: null,
                                                                            };
                                                                            setValues({
                                                                                ...values,
                                                                                buildingWindows1details: updatedObject,
                                                                            });
                                                                            // Mise à jour du local storage
                                                                            storageService.setAuditValue(7, isValid, 'buildingWindows1details', updatedObject);
                                                                        }}
                                                                        type={'button'}
                                                                    >
                                                                        Ajouter une fenêtre
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <ReactTooltip id="helpBuildingWindows1details" place="bottom" type={'light'} effect={'solid'}>
                                                                <h3>Détails des fenêtres :</h3>
                                                                <p>
                                                                    Pour renseigner les différentes fenêtres de l’habitation cliquez sur le bouton “ + “ afin
                                                                    d’ajouter une nouvelle fenêtre. Vous pouvez ensuite indiquer le nombre de fenêtre identique
                                                                    à l’orientation sélectionnée ainsi que la superficie totale de ces fenêtres. en cas
                                                                    d’erreur, vous pouvez supprimer une fenêtre via l’icône “Corbeille”.
                                                                </p>
                                                                <p>
                                                                    Attention : en règle générale, la superficie totale des fenêtres est comprise entre 10% et
                                                                    20% de la surface habitable (SHab).
                                                                </p>
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <div className="card without-shadow border-grey h-100">
                                                    <div className="card-title">
                                                        <IconWindow2 fill="#76ca4f" width={40} height={40} className="icon" />
                                                        <h2>Typologie de fenêtre 2</h2>

                                                        {values.buildingWindows2Presence && (
                                                            <AddStepPhotos
                                                                prop={buildingPictureBuildingWindows2}
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                fieldValue={'buildingPictureBuildingWindows2'}
                                                                disabled={auditSent}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <CheckboxService
                                                                handleCheckbox={buildingWindows2Presence}
                                                                values={values}
                                                                isValid={isValid}
                                                                setValues={setValues}
                                                                name={'buildingWindows2Presence'}
                                                                propName={values.buildingWindows2Presence}
                                                                title={'Présence de fenêtres secondaires ?'}
                                                                border={true}
                                                            />
                                                        </div>
                                                        {['1', true, 'true'].includes(values.buildingWindows2Presence) && (
                                                            <div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="buildingWindows2" className="form-label">
                                                                        Composition des fenêtres secondaires<sup>*</sup>
                                                                    </label>
                                                                    <Select
                                                                        value={renderDefaultValueSelect(buildingWindows2List, values.buildingWindows2)}
                                                                        options={buildingWindows2List}
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        isMulti={false}
                                                                        inputId="buildingWindows2"
                                                                        onBlur={handleBlur}
                                                                        isDisabled={auditSent}
                                                                        onChange={(event) => {
                                                                            storageService.setAuditValue(
                                                                                7,
                                                                                isValid,
                                                                                'buildingWindows2',
                                                                                event.value,
                                                                                event.label
                                                                            );
                                                                            setValues({
                                                                                ...values,
                                                                                buildingWindows2: event.value,
                                                                            });
                                                                        }}
                                                                        className={
                                                                            'basic-single' +
                                                                            (values.buildingWindows2 ? ' filled' : '') +
                                                                            (touched.buildingWindows2 && errors.buildingWindows2 ? ' invalid' : '') +
                                                                            (!values.buildingWindows2 ? ' required' : '')
                                                                        }
                                                                        placeholder={`Choisissez la composition`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {touched.buildingWindows2 && errors.buildingWindows2 && (
                                                                        <div className="invalid-feedback">{errors.buildingWindows2}</div>
                                                                    )}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="buildingWindows2glazing" className="form-label">
                                                                        Type de vitrage des fenêtres secondaires<sup>*</sup>
                                                                    </label>
                                                                    <Select
                                                                        value={renderDefaultValueSelect(
                                                                            buildingWindows2glazingList,
                                                                            values.buildingWindows2glazing
                                                                        )}
                                                                        options={buildingWindows2glazingList}
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        isMulti={false}
                                                                        isDisabled={auditSent}
                                                                        inputId="buildingWindows2glazing"
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            setValues({
                                                                                ...values,
                                                                                buildingWindows2glazing: event.value,
                                                                                buildingWindows2glazingSize: '',
                                                                            });
                                                                            storageService.setAuditValue(
                                                                                7,
                                                                                isValid,
                                                                                'buildingWindows2glazing',
                                                                                event.value,
                                                                                event.label
                                                                            );
                                                                            // Mise à jour des champs associés
                                                                            storageService.removeAuditValue(7, 'buildingWindows2glazingSize', true);
                                                                        }}
                                                                        className={
                                                                            'basic-single' +
                                                                            (values.buildingWindows2glazing ? ' filled' : '') +
                                                                            (touched.buildingWindows2glazing && errors.buildingWindows2glazing
                                                                                ? ' invalid'
                                                                                : '') +
                                                                            (!values.buildingWindows2glazing ? ' required' : '')
                                                                        }
                                                                        placeholder={`Choisissez le type de vitrage`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {touched.buildingWindows2glazing && errors.buildingWindows2glazing && (
                                                                        <div className="invalid-feedback">{errors.buildingWindows2glazing}</div>
                                                                    )}
                                                                </div>
                                                                {['1', '3'].includes(values.buildingWindows2glazing) && (
                                                                    <div className="mb-3">
                                                                        <label htmlFor="buildingWindows2glazingSize" className="form-label">
                                                                            Épaisseur du vitrage (mm)<sup>*</sup>
                                                                        </label>
                                                                        <Select
                                                                            value={renderDefaultValueSelect(
                                                                                buildingWindows2glazingSizeList,
                                                                                values.buildingWindows2glazingSize
                                                                            )}
                                                                            options={buildingWindows2glazingSizeList}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            isMulti={false}
                                                                            isDisabled={auditSent}
                                                                            inputId="buildingWindows2glazingSize"
                                                                            onBlur={handleBlur}
                                                                            onChange={(event) => {
                                                                                storageService.setAuditValue(
                                                                                    7,
                                                                                    isValid,
                                                                                    'buildingWindows2glazingSize',
                                                                                    event.value,
                                                                                    event.label
                                                                                );
                                                                                setValues({
                                                                                    ...values,
                                                                                    buildingWindows2glazingSize: event.value,
                                                                                });
                                                                            }}
                                                                            className={
                                                                                'basic-single' +
                                                                                (values.buildingWindows2glazingSize ? ' filled' : '') +
                                                                                (touched.buildingWindows2glazingSize && errors.buildingWindows2glazingSize
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.buildingWindows2glazingSize ? ' required' : '')
                                                                            }
                                                                            placeholder={`Choisissez le type de vitrage`}
                                                                            styles={colourStyles}
                                                                        />
                                                                        {touched.buildingWindows2glazingSize && errors.buildingWindows2glazingSize && (
                                                                            <div className="invalid-feedback">{errors.buildingWindows2glazingSize}</div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {parseInt(storageService.getAudit().step2.houseAge.value) < currentYearMinus(30) && (
                                                                    <div className="mb-3">
                                                                        <CheckboxService
                                                                            handleCheckbox={buildingWindows2Before1990}
                                                                            values={values}
                                                                            isValid={isValid}
                                                                            setValues={setValues}
                                                                            name={'buildingWindows2Before1990'}
                                                                            propName={values.buildingWindows2Before1990}
                                                                            title={`L’installation des fenêtres a été effectuée avant ${currentYearMinus(
                                                                                30
                                                                            )} ?`}
                                                                            border={true}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <label htmlFor="buildingWindows2details" className="form-label">
                                                                        Détails des fenêtres secondaires<sup>*</sup>
                                                                        <div className="help-text" data-tip data-for="helpBuildingWindows2details">
                                                                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                        </div>
                                                                    </label>
                                                                    {Object.keys(values.buildingWindows2details).map((item: any) => (
                                                                        <div className="d-flex flex-row justify-content-between mb-3" key={item}>
                                                                            <div className="form-group">
                                                                                <div className="col-4">
                                                                                    <small className="label-small">&nbsp;</small>
                                                                                    {item === 'tmp' && (
                                                                                        <Select
                                                                                            options={orientationWindows2List}
                                                                                            isClearable={false}
                                                                                            isDisabled={
                                                                                                Object.keys(values.buildingWindows2details).length ===
                                                                                                    orientationWindows2List.length + 1 || auditSent
                                                                                            }
                                                                                            isSearchable={false}
                                                                                            isMulti={false}
                                                                                            inputId={'orientationWindows2'}
                                                                                            onChange={(event) => {
                                                                                                let tmpBuildingWindows2details = values.buildingWindows2details;
                                                                                                if (tmpBuildingWindows2details.tmp) {
                                                                                                    tmpBuildingWindows2details[event.value] = {
                                                                                                        count: tmpBuildingWindows2details.tmp.count,
                                                                                                        surface: tmpBuildingWindows2details.tmp.surface,
                                                                                                    };
                                                                                                    delete tmpBuildingWindows2details.tmp;
                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        buildingWindows2details: tmpBuildingWindows2details,
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            className={
                                                                                                'basic-single basic-orientation' +
                                                                                                (Object.keys(values.buildingWindows2details).length === 0
                                                                                                    ? ' required'
                                                                                                    : '')
                                                                                            }
                                                                                            placeholder={`Orientation`}
                                                                                            styles={colourStyles}
                                                                                            components={{
                                                                                                Option: (props: OptionProps<any, any>) => (
                                                                                                    <components.Option
                                                                                                        {...props}
                                                                                                        className={
                                                                                                            'custom-react-select' +
                                                                                                            (Object.keys(
                                                                                                                values.buildingWindows2details
                                                                                                            ).includes(props.data.value) ||
                                                                                                            !props.data.included.includes(
                                                                                                                storageService.getAudit()?.step2?.buildingPlans
                                                                                                                    ?.value[0]?.orientation
                                                                                                            )
                                                                                                                ? ' d-none'
                                                                                                                : '')
                                                                                                        }
                                                                                                    >
                                                                                                        {props.label}
                                                                                                    </components.Option>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {item !== 'tmp' && (
                                                                                        <input
                                                                                            type="text"
                                                                                            readOnly={true}
                                                                                            className={'form-control opacity-text border-radius-right-0'}
                                                                                            placeholder="Orientation"
                                                                                            defaultValue={
                                                                                                renderDefaultValueSelect(orientationWindows2List, item)[0]
                                                                                                    ?.label
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className="col">
                                                                                    <small className="label-small">Nombre de fenêtre</small>
                                                                                    <input
                                                                                        type="number"
                                                                                        min={1}
                                                                                        disabled={item === 'tmp'}
                                                                                        className={
                                                                                            'form-control border-radius-0' +
                                                                                            (values.buildingWindows2details[item]?.count ? ' filled' : '') +
                                                                                            ((touched as any)['countWindows2-' + item] &&
                                                                                            !values.buildingWindows2details[item]?.count
                                                                                                ? ' invalid'
                                                                                                : '') +
                                                                                            (!values.buildingWindows2details[item]?.count ? ' required' : '')
                                                                                        }
                                                                                        placeholder="Nombre"
                                                                                        id={'countWindows2-' + item}
                                                                                        onBlur={(event) => {
                                                                                            handleBlur(event);
                                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                                        }}
                                                                                        value={values.buildingWindows2details[item].count || ''}
                                                                                        onChange={(event) => {
                                                                                            let updatedObject = values.buildingWindows2details;
                                                                                            updatedObject[item].count = parseInt(event.target.value);
                                                                                            setValues({
                                                                                                ...values,
                                                                                                buildingWindows2details: updatedObject,
                                                                                            });
                                                                                            // Mise à jour du local storage
                                                                                            storageService.setAuditValue(
                                                                                                7,
                                                                                                isValid,
                                                                                                'buildingWindows2details',
                                                                                                updatedObject
                                                                                            );
                                                                                            // Mise à jour du SHabAreaLimit
                                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                                        }}
                                                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                            e.currentTarget.blur()
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="col">
                                                                                    <small className="label-small">
                                                                                        Surface vitrée par fenêtre
                                                                                        <div className="help-text" data-tip data-for="helpFenetre2">
                                                                                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                                        </div>
                                                                                    </small>

                                                                                    <ReactTooltip
                                                                                        id="helpFenetre2"
                                                                                        place="bottom"
                                                                                        type={'light'}
                                                                                        effect={'solid'}
                                                                                    >
                                                                                        <p>Veuillez indiquer en m2 la moyenne de surface vitrée par fenêtre</p>
                                                                                    </ReactTooltip>

                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            min={1}
                                                                                            disabled={item === 'tmp'}
                                                                                            className={
                                                                                                'form-control border-radius-left-0' +
                                                                                                (values.buildingWindows2details[item]?.surface
                                                                                                    ? ' filled'
                                                                                                    : '') +
                                                                                                ((touched as any)['surfaceWindows2-' + item] &&
                                                                                                !values.buildingWindows2details[item]?.surface
                                                                                                    ? ' invalid'
                                                                                                    : '') +
                                                                                                (!values.buildingWindows2details[item]?.surface
                                                                                                    ? ' required'
                                                                                                    : '')
                                                                                            }
                                                                                            placeholder="Surface"
                                                                                            id={'surfaceWindows2-' + item}
                                                                                            onBlur={(event) => {
                                                                                                handleBlur(event);
                                                                                                updateSHabAreaLimit(values, setValues, isValid);

                                                                                                if (event.target.value) {
                                                                                                    let updatedObject = values.buildingWindows2details;
                                                                                                    updatedObject[item].surface =
                                                                                                        parseFloat(event.target.value) % 1 !== 0
                                                                                                            ? parseFloat(event.target.value).toFixed(2)
                                                                                                            : parseInt(event.target.value);

                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        buildingWindows2details: updatedObject,
                                                                                                    });

                                                                                                    // Mise à jour du local storage
                                                                                                    storageService.setAuditValue(
                                                                                                        7,
                                                                                                        isValid,
                                                                                                        'buildingWindows2details',
                                                                                                        updatedObject
                                                                                                    );
                                                                                                    // Mise à jour du SHabAreaLimit
                                                                                                    updateSHabAreaLimit(values, setValues, isValid);
                                                                                                }
                                                                                            }}
                                                                                            value={values.buildingWindows2details[item].surface || ''}
                                                                                            onChange={(event) => {
                                                                                                let updatedObject = values.buildingWindows2details;
                                                                                                updatedObject[item].surface = parseFloat(event.target.value);
                                                                                                setValues({
                                                                                                    ...values,
                                                                                                    buildingWindows2details: updatedObject,
                                                                                                });
                                                                                                // Mise à jour du local storage
                                                                                                storageService.setAuditValue(
                                                                                                    7,
                                                                                                    isValid,
                                                                                                    'buildingWindows2details',
                                                                                                    updatedObject
                                                                                                );
                                                                                                // Mise à jour du SHabAreaLimit
                                                                                                updateSHabAreaLimit(values, setValues, isValid);
                                                                                            }}
                                                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                e.currentTarget.blur()
                                                                                            }
                                                                                        />
                                                                                        <span className="input-group-text">m²</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                className={'btn icon-remove icon-remove-red'}
                                                                                onClick={() => {
                                                                                    let updatedObject = values.buildingWindows2details;
                                                                                    delete updatedObject[item];
                                                                                    setValues({
                                                                                        ...values,
                                                                                        buildingWindows2details: updatedObject,
                                                                                    });
                                                                                    // Mise à jour du local storage
                                                                                    storageService.setAuditValue(
                                                                                        7,
                                                                                        isValid,
                                                                                        'buildingWindows2details',
                                                                                        updatedObject
                                                                                    );
                                                                                }}
                                                                                type={'button'}
                                                                            ></button>
                                                                        </div>
                                                                    ))}
                                                                    <div className="row mb-3">
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className={
                                                                                    'btn btn-green icon-add btn-square w-100' +
                                                                                    (values.buildingWindows2details?.tmp ||
                                                                                    Object.keys(values.buildingWindows2details).length ===
                                                                                        orientationWindows2List.length
                                                                                        ? ' disabled'
                                                                                        : '')
                                                                                }
                                                                                disabled={
                                                                                    checkBuildingDetailsAllFilled(values.buildingWindows2details) ||
                                                                                    Object.keys(values.buildingWindows2details).length ===
                                                                                        orientationWindows2List.length
                                                                                }
                                                                                onClick={() => {
                                                                                    let updatedObject = values.buildingWindows2details;
                                                                                    updatedObject['tmp'] = {
                                                                                        count: null,
                                                                                        surface: null,
                                                                                    };
                                                                                    setValues({
                                                                                        ...values,
                                                                                        buildingWindows2details: updatedObject,
                                                                                    });
                                                                                    // Mise à jour du local storage
                                                                                    storageService.setAuditValue(
                                                                                        7,
                                                                                        isValid,
                                                                                        'buildingWindows2details',
                                                                                        updatedObject
                                                                                    );
                                                                                }}
                                                                                type={'button'}
                                                                            >
                                                                                Ajouter une fenêtre
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <ReactTooltip
                                                                        id="helpBuildingWindows2details"
                                                                        place="bottom"
                                                                        type={'light'}
                                                                        effect={'solid'}
                                                                    >
                                                                        <h3>Détails des fenêtres :</h3>
                                                                        <p>
                                                                            Pour renseigner les différentes fenêtres de l’habitation cliquez sur le bouton “ + “
                                                                            afin d’ajouter une nouvelle fenêtre. Vous pouvez ensuite indiquer le nombre de
                                                                            fenêtre identique à l’orientation sélectionnée ainsi que la superficie totale de ces
                                                                            fenêtres. en cas d’erreur, vous pouvez supprimer une fenêtre via l’icon “Corbeille”.
                                                                        </p>
                                                                        <p>
                                                                            Attention : en règle générale, la superficie totale des fenêtres est comprise entre
                                                                            10% et 20% de la surface habitable (SHab).
                                                                        </p>
                                                                    </ReactTooltip>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                )}
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconDoorWindows fill="#76ca4f" width={40} height={40} className="icon" />
                                <div className="d-flex flex-column">
                                    <h2>Caractéristiques des portes-fenêtres</h2>
                                </div>

                                {values.buildingDoorWindows1Presence && (
                                    <AddStepPhotos
                                        prop={buildingPictureDoorWindows1}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        fieldValue={'buildingPictureDoorWindows1'}
                                        disabled={auditSent}
                                    />
                                )}
                            </div>
                            <div className="card-body">
                                <div className={values.buildingDoorWindows1Presence ? 'row mb-3' : 'row'}>
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={buildingDoorWindows1Presence}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'buildingDoorWindows1Presence'}
                                            propName={values.buildingDoorWindows1Presence}
                                            title={'Présence de portes-fenêtres ?'}
                                            border={true}
                                            disabled={auditSent}
                                        />
                                    </div>
                                </div>
                                {['1', true, 'true'].includes(values.buildingDoorWindows1Presence) && (
                                    <fieldset className="w-100" disabled={auditSent}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card without-shadow border-grey h-100">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 col-xl-6 mb-3 mb-xl-0">
                                                                <div className="mb-3">
                                                                    <label htmlFor="buildingDoorWindows1" className="form-label">
                                                                        Composition des porte-fenêtres<sup>*</sup>
                                                                    </label>
                                                                    <Select
                                                                        value={renderDefaultValueSelect(buildingDoorWindows1List, values.buildingDoorWindows1)}
                                                                        options={buildingDoorWindows1List}
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        isMulti={false}
                                                                        isDisabled={auditSent}
                                                                        inputId="buildingDoorWindows1"
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            storageService.setAuditValue(
                                                                                7,
                                                                                isValid,
                                                                                'buildingDoorWindows1',
                                                                                event.value,
                                                                                event.label
                                                                            );
                                                                            setValues({
                                                                                ...values,
                                                                                buildingDoorWindows1: event.value,
                                                                            });
                                                                        }}
                                                                        className={
                                                                            'basic-single' +
                                                                            (values.buildingDoorWindows1 ? ' filled' : '') +
                                                                            (touched.buildingDoorWindows1 && errors.buildingDoorWindows1 ? ' invalid' : '') +
                                                                            (!values.buildingDoorWindows1 ? ' required' : '')
                                                                        }
                                                                        placeholder={`Choisissez la composition`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {touched.buildingDoorWindows1 && errors.buildingDoorWindows1 && (
                                                                        <div className="invalid-feedback">{errors.buildingDoorWindows1}</div>
                                                                    )}
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label htmlFor="buildingDoorWindows1glazing" className="form-label">
                                                                        Type de vitrage des porte-fenêtres<sup>*</sup>
                                                                    </label>
                                                                    <Select
                                                                        value={renderDefaultValueSelect(
                                                                            buildingDoorWindows1glazingList,
                                                                            values.buildingDoorWindows1glazing
                                                                        )}
                                                                        options={buildingDoorWindows1glazingList}
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        isMulti={false}
                                                                        isDisabled={auditSent}
                                                                        inputId="buildingDoorWindows1glazing"
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            setValues({
                                                                                ...values,
                                                                                buildingDoorWindows1glazing: event.value,
                                                                                buildingDoorWindows1glazingSize: '',
                                                                            });
                                                                            storageService.setAuditValue(
                                                                                7,
                                                                                isValid,
                                                                                'buildingDoorWindows1glazing',
                                                                                event.value,
                                                                                event.label
                                                                            );
                                                                            // Mise à jour des champs associés
                                                                            storageService.removeAuditValue(7, 'buildingDoorWindows1glazingSize', true);
                                                                        }}
                                                                        className={
                                                                            'basic-single' +
                                                                            (values.buildingDoorWindows1glazing ? ' filled' : '') +
                                                                            (touched.buildingDoorWindows1glazing && errors.buildingDoorWindows1glazing
                                                                                ? ' invalid'
                                                                                : '') +
                                                                            (!values.buildingDoorWindows1glazing ? ' required' : '')
                                                                        }
                                                                        placeholder={`Choisissez le type de vitrage`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {touched.buildingDoorWindows1glazing && errors.buildingDoorWindows1glazing && (
                                                                        <div className="invalid-feedback">{errors.buildingDoorWindows1glazing}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-xl-6">
                                                                <div className="col-12 mb-3" aria-hidden="true" style={{ minHeight: '40px' }}></div>
                                                                {['1', '3'].includes(values.buildingDoorWindows1glazing) && (
                                                                    <div className="mb-3">
                                                                        <label htmlFor="buildingDoorWindows1glazingSize" className="form-label">
                                                                            Épaisseur du vitrage (mm)<sup>*</sup>
                                                                        </label>
                                                                        <Select
                                                                            value={renderDefaultValueSelect(
                                                                                buildingDoorWindows1glazingSizeList,
                                                                                values.buildingDoorWindows1glazingSize
                                                                            )}
                                                                            options={buildingDoorWindows1glazingSizeList}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            isMulti={false}
                                                                            isDisabled={auditSent}
                                                                            inputId="buildingDoorWindows1glazingSize"
                                                                            onBlur={handleBlur}
                                                                            onChange={(event) => {
                                                                                storageService.setAuditValue(
                                                                                    7,
                                                                                    isValid,
                                                                                    'buildingDoorWindows1glazingSize',
                                                                                    event.value,
                                                                                    event.label
                                                                                );
                                                                                setValues({
                                                                                    ...values,
                                                                                    buildingDoorWindows1glazingSize: event.value,
                                                                                });
                                                                            }}
                                                                            className={
                                                                                'basic-single' +
                                                                                (values.buildingDoorWindows1glazingSize ? ' filled' : '') +
                                                                                (touched.buildingDoorWindows1glazingSize &&
                                                                                errors.buildingDoorWindows1glazingSize
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.buildingDoorWindows1glazingSize ? ' required' : '')
                                                                            }
                                                                            placeholder={`Choisissez le type de vitrage`}
                                                                            styles={colourStyles}
                                                                        />
                                                                        {touched.buildingDoorWindows1glazingSize && errors.buildingDoorWindows1glazingSize && (
                                                                            <div className="invalid-feedback">{errors.buildingDoorWindows1glazingSize}</div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <label htmlFor="buildingDoorWindows1details" className="form-label">
                                                                        Détails des porte-fenêtres<sup>*</sup>
                                                                        <div className="help-text" data-tip data-for="helpBuildingDoorWindows1details">
                                                                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                        </div>
                                                                    </label>
                                                                    {Object.keys(values.buildingDoorWindows1details).map((item: any) => (
                                                                        <div className="d-flex flex-row justify-content-between mb-3" key={item}>
                                                                            <div className="form-group">
                                                                                <div className="col-4">
                                                                                    <small className="label-small">&nbsp;</small>
                                                                                    {item === 'tmp' && (
                                                                                        <Select
                                                                                            options={orientationDoorWindows1List}
                                                                                            isClearable={false}
                                                                                            isDisabled={
                                                                                                Object.keys(values.buildingDoorWindows1details).length ===
                                                                                                    orientationDoorWindows1List.length + 1 || auditSent
                                                                                            }
                                                                                            isSearchable={false}
                                                                                            isMulti={false}
                                                                                            inputId={'orientationWindows1'}
                                                                                            onChange={(event) => {
                                                                                                let tmpBuildingDoorWindows1details =
                                                                                                    values.buildingDoorWindows1details;
                                                                                                if (tmpBuildingDoorWindows1details.tmp) {
                                                                                                    tmpBuildingDoorWindows1details[event.value] = {
                                                                                                        count: tmpBuildingDoorWindows1details.tmp.count,
                                                                                                        surface: tmpBuildingDoorWindows1details.tmp.surface,
                                                                                                    };
                                                                                                    delete tmpBuildingDoorWindows1details.tmp;
                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        buildingDoorWindows1details:
                                                                                                            tmpBuildingDoorWindows1details,
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            className={
                                                                                                'basic-single basic-orientation' +
                                                                                                (Object.keys(values.buildingDoorWindows1details).length === 0
                                                                                                    ? ' required'
                                                                                                    : '')
                                                                                            }
                                                                                            placeholder={`Orientation`}
                                                                                            styles={colourStyles}
                                                                                            components={{
                                                                                                Option: (props: OptionProps<any, any>) => (
                                                                                                    <components.Option
                                                                                                        {...props}
                                                                                                        className={
                                                                                                            'custom-react-select' +
                                                                                                            (Object.keys(
                                                                                                                values.buildingDoorWindows1details
                                                                                                            ).includes(props.data.value) ||
                                                                                                            !props.data.included.includes(
                                                                                                                storageService.getAudit()?.step2?.buildingPlans
                                                                                                                    ?.value[0]?.orientation
                                                                                                            )
                                                                                                                ? ' d-none'
                                                                                                                : '')
                                                                                                        }
                                                                                                    >
                                                                                                        {props.label}
                                                                                                    </components.Option>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {item !== 'tmp' && (
                                                                                        <input
                                                                                            type="text"
                                                                                            readOnly={true}
                                                                                            className={'form-control opacity-text border-radius-right-0'}
                                                                                            placeholder="Orientation"
                                                                                            defaultValue={
                                                                                                renderDefaultValueSelect(orientationDoorWindows1List, item)[0]
                                                                                                    ?.label
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className="col">
                                                                                    <small className="label-small">Nombre de fenêtre</small>
                                                                                    <input
                                                                                        type="number"
                                                                                        min={1}
                                                                                        disabled={item === 'tmp'}
                                                                                        className={
                                                                                            'form-control border-radius-0' +
                                                                                            (values.buildingDoorWindows1details[item]?.count ? ' filled' : '') +
                                                                                            ((touched as any)['countDoorWindows1-' + item] &&
                                                                                            !values.buildingDoorWindows1details[item]?.count
                                                                                                ? ' invalid'
                                                                                                : '') +
                                                                                            (!values.buildingDoorWindows1details[item]?.count
                                                                                                ? ' required'
                                                                                                : '')
                                                                                        }
                                                                                        placeholder="Nombre"
                                                                                        id={'countDoorWindows1-' + item}
                                                                                        onBlur={(event) => {
                                                                                            handleBlur(event);
                                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                                        }}
                                                                                        value={values.buildingDoorWindows1details[item].count || ''}
                                                                                        onChange={(event) => {
                                                                                            let updatedObject = values.buildingDoorWindows1details;
                                                                                            updatedObject[item].count = parseInt(event.target.value);
                                                                                            setValues({
                                                                                                ...values,
                                                                                                buildingDoorWindows1details: updatedObject,
                                                                                            });
                                                                                            // Mise à jour du local storage
                                                                                            storageService.setAuditValue(
                                                                                                7,
                                                                                                isValid,
                                                                                                'buildingDoorWindows1details',
                                                                                                updatedObject
                                                                                            );
                                                                                            // Mise à jour du SHabAreaLimit
                                                                                            updateSHabAreaLimit(values, setValues, isValid);
                                                                                        }}
                                                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                            e.currentTarget.blur()
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="col">
                                                                                    <small className="label-small">
                                                                                        Surface vitrée par fenêtre
                                                                                        <div className="help-text" data-tip data-for="helpFenetre3">
                                                                                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                                        </div>
                                                                                    </small>

                                                                                    <ReactTooltip
                                                                                        id="helpFenetre3"
                                                                                        place="bottom"
                                                                                        type={'light'}
                                                                                        effect={'solid'}
                                                                                    >
                                                                                        <p>Veuillez indiquer en m2 la moyenne de surface vitrée par fenêtre</p>
                                                                                    </ReactTooltip>

                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            min={1}
                                                                                            disabled={item === 'tmp'}
                                                                                            className={
                                                                                                'form-control border-radius-left-0' +
                                                                                                (values.buildingDoorWindows1details[item]?.surface
                                                                                                    ? ' filled'
                                                                                                    : '') +
                                                                                                ((touched as any)['surfaceDoorWindows1-' + item] &&
                                                                                                !values.buildingDoorWindows1details[item]?.surface
                                                                                                    ? ' invalid'
                                                                                                    : '') +
                                                                                                (!values.buildingDoorWindows1details[item]?.surface
                                                                                                    ? ' required'
                                                                                                    : '')
                                                                                            }
                                                                                            placeholder="Surface"
                                                                                            id={'surfaceDoorWindows1-' + item}
                                                                                            onBlur={(event) => {
                                                                                                handleBlur(event);
                                                                                                updateSHabAreaLimit(values, setValues, isValid);

                                                                                                if (event.target.value) {
                                                                                                    let updatedObject = values.buildingDoorWindows1details;
                                                                                                    updatedObject[item].surface =
                                                                                                        parseFloat(event.target.value) % 1 !== 0
                                                                                                            ? parseFloat(event.target.value).toFixed(2)
                                                                                                            : parseInt(event.target.value);

                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        buildingDoorWindows1details: updatedObject,
                                                                                                    });

                                                                                                    // Mise à jour du local storage
                                                                                                    storageService.setAuditValue(
                                                                                                        7,
                                                                                                        isValid,
                                                                                                        'buildingDoorWindows1details',
                                                                                                        updatedObject
                                                                                                    );
                                                                                                    // Mise à jour du SHabAreaLimit
                                                                                                    updateSHabAreaLimit(values, setValues, isValid);
                                                                                                }
                                                                                            }}
                                                                                            value={values.buildingDoorWindows1details[item].surface || ''}
                                                                                            onChange={(event) => {
                                                                                                let updatedObject = values.buildingDoorWindows1details;
                                                                                                updatedObject[item].surface = parseFloat(event.target.value);
                                                                                                setValues({
                                                                                                    ...values,
                                                                                                    buildingDoorWindows1details: updatedObject,
                                                                                                });
                                                                                                // Mise à jour du local storage
                                                                                                storageService.setAuditValue(
                                                                                                    7,
                                                                                                    isValid,
                                                                                                    'buildingDoorWindows1details',
                                                                                                    updatedObject
                                                                                                );
                                                                                                // Mise à jour du SHabAreaLimit
                                                                                                updateSHabAreaLimit(values, setValues, isValid);
                                                                                            }}
                                                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                e.currentTarget.blur()
                                                                                            }
                                                                                        />
                                                                                        <span className="input-group-text">m²</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button
                                                                                className={'btn icon-remove icon-remove-red'}
                                                                                onClick={() => {
                                                                                    let updatedObject = values.buildingDoorWindows1details;
                                                                                    delete updatedObject[item];
                                                                                    setValues({
                                                                                        ...values,
                                                                                        buildingDoorWindows1details: updatedObject,
                                                                                    });
                                                                                    // Mise à jour du local storage
                                                                                    storageService.setAuditValue(
                                                                                        7,
                                                                                        isValid,
                                                                                        'buildingDoorWindows1details',
                                                                                        updatedObject
                                                                                    );
                                                                                    // Mise à jour SHabAreaLimit
                                                                                    updateSHabAreaLimit(values, setValues, isValid);
                                                                                }}
                                                                                type={'button'}
                                                                            ></button>
                                                                        </div>
                                                                    ))}
                                                                    <div className="row mb-3">
                                                                        <div className="col-auto">
                                                                            <button
                                                                                className={
                                                                                    'btn btn-green icon-add btn-square w-100' +
                                                                                    (values.buildingDoorWindows1details?.tmp ||
                                                                                    Object.keys(values.buildingDoorWindows1details).length ===
                                                                                        orientationDoorWindows1List.length
                                                                                        ? ' disabled'
                                                                                        : '')
                                                                                }
                                                                                disabled={
                                                                                    checkBuildingDetailsAllFilled(values.buildingDoorWindows1details) ||
                                                                                    Object.keys(values.buildingDoorWindows1details).length ===
                                                                                        orientationDoorWindows1List.length
                                                                                }
                                                                                onClick={() => {
                                                                                    let updatedObject = values.buildingDoorWindows1details;
                                                                                    updatedObject['tmp'] = {
                                                                                        count: null,
                                                                                        surface: null,
                                                                                    };
                                                                                    setValues({
                                                                                        ...values,
                                                                                        buildingDoorWindows1details: updatedObject,
                                                                                    });
                                                                                    // Mise à jour du local storage
                                                                                    storageService.setAuditValue(
                                                                                        7,
                                                                                        isValid,
                                                                                        'buildingDoorWindows1details',
                                                                                        updatedObject
                                                                                    );
                                                                                }}
                                                                                type={'button'}
                                                                            >
                                                                                Ajouter une porte-fenêtre
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <ReactTooltip
                                                                        id="helpBuildingDoorWindows1details"
                                                                        place="bottom"
                                                                        type={'light'}
                                                                        effect={'solid'}
                                                                    >
                                                                        <h3>Détails des fenêtres :</h3>
                                                                        <p>
                                                                            Pour renseigner les différentes fenêtres de l’habitation cliquez sur le bouton “ + “
                                                                            afin d’ajouter une nouvelle porte-fenêtre. Vous pouvez ensuite indiquer le nombre de
                                                                            fenêtre identique à l’orientation sélectionnée ainsi que la superficie totale de ces
                                                                            fenêtres. en cas d’erreur, vous pouvez supprimer une fenêtre via l’icon “Corbeille”.
                                                                        </p>
                                                                        <p>
                                                                            Attention : en règle générale, la superficie totale des fenêtres est comprise entre
                                                                            10% et 20% de la surface habitable (Shab).
                                                                        </p>
                                                                    </ReactTooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                )}
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconDoors fill="#76ca4f" width={40} height={40} className="icon" />
                                <div className="d-flex flex-column">
                                    <h2>Caractéristiques des portes</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                                            <div className="card without-shadow border-grey h-100">
                                                <div className="card-title">
                                                    <IconDoor fill="#76ca4f" width={40} height={40} className="icon" />
                                                    <div className="d-flex flex-column">
                                                        <h2>Porte principale</h2>
                                                    </div>
                                                    <AddStepPhotos
                                                        prop={buildingPictureDoor1}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPictureDoor1'}
                                                        required={true}
                                                        disabled={auditSent}
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="buildingDoor1" className="form-label">
                                                            Composition de la porte principale<sup>*</sup>
                                                        </label>
                                                        <Select
                                                            value={renderDefaultValueSelect(buildingDoor1List, values.buildingDoor1)}
                                                            options={buildingDoor1List}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            isDisabled={auditSent}
                                                            inputId="buildingDoor1"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                storageService.setAuditValue(7, isValid, 'buildingDoor1', event.value, event.label);
                                                                setValues({
                                                                    ...values,
                                                                    buildingDoor1: event.value,
                                                                });
                                                            }}
                                                            className={
                                                                'basic-single' +
                                                                (values.buildingDoor1 ? ' filled' : '') +
                                                                (touched.buildingDoor1 && errors.buildingDoor1 ? ' invalid' : '') +
                                                                (!values.buildingDoor1 ? ' required' : '')
                                                            }
                                                            placeholder={`Choisissez la composition`}
                                                            styles={colourStyles}
                                                        />
                                                        {touched.buildingDoor1 && errors.buildingDoor1 && (
                                                            <div className="invalid-feedback">{errors.buildingDoor1}</div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="buildingDoor1Type" className="form-label">
                                                            Type de la porte principale<sup>*</sup>
                                                        </label>
                                                        <Select
                                                            value={renderDefaultValueSelect(buildingDoor1TypeList, values.buildingDoor1Type)}
                                                            options={buildingDoor1TypeList}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            isDisabled={auditSent}
                                                            inputId="buildingDoor1Type"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                storageService.setAuditValue(7, isValid, 'buildingDoor1Type', event.value, event.label);
                                                                setValues({
                                                                    ...values,
                                                                    buildingDoor1Type: event.value,
                                                                });
                                                            }}
                                                            className={
                                                                'basic-single' +
                                                                (values.buildingDoor1Type ? ' filled' : '') +
                                                                (touched.buildingDoor1Type && errors.buildingDoor1Type ? ' invalid' : '') +
                                                                (!values.buildingDoor1Type ? ' required' : '')
                                                            }
                                                            placeholder={`Choisissez la composition`}
                                                            styles={colourStyles}
                                                        />
                                                        {touched.buildingDoor1Type && errors.buildingDoor1Type && (
                                                            <div className="invalid-feedback">{errors.buildingDoor1Type}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="card without-shadow border-grey h-100">
                                                <div className="card-title">
                                                    <IconDoor2 fill="#76ca4f" width={40} height={40} className="icon" />
                                                    <div className="d-flex flex-column">
                                                        <h2>Portes secondaires</h2>
                                                    </div>

                                                    {values.buildingDoor2Presence && (
                                                        <AddStepPhotos
                                                            prop={buildingPictureDoor2}
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            fieldValue={'buildingPictureDoor2'}
                                                            disabled={auditSent}
                                                        />
                                                    )}
                                                </div>
                                                <div className="card-body">
                                                    <div className={values.buildingDoor2Presence ? 'mb-3' : ''}>
                                                        <CheckboxService
                                                            handleCheckbox={buildingDoor2Presence}
                                                            values={values}
                                                            isValid={isValid}
                                                            setValues={setValues}
                                                            name={'buildingDoor2Presence'}
                                                            propName={values.buildingDoor2Presence}
                                                            title={'Présence de portes secondaires ?'}
                                                            border={true}
                                                        />
                                                    </div>
                                                    {['1', true, 'true'].includes(values.buildingDoor2Presence) && (
                                                        <div>
                                                            <div className="mb-3">
                                                                <label htmlFor="buildingDoor2" className="form-label">
                                                                    Composition des portes secondaires<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={renderDefaultValueSelect(buildingDoor2List, values.buildingDoor2)}
                                                                    options={buildingDoor2List}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    isDisabled={auditSent}
                                                                    inputId="buildingDoor2"
                                                                    onBlur={handleBlur}
                                                                    onChange={(event) => {
                                                                        storageService.setAuditValue(7, isValid, 'buildingDoor2', event.value, event.label);
                                                                        setValues({
                                                                            ...values,
                                                                            buildingDoor2: event.value,
                                                                        });
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingDoor2 ? ' filled' : '') +
                                                                        (touched.buildingDoor2 && errors.buildingDoor2 ? ' invalid' : '') +
                                                                        (!values.buildingDoor2 ? ' required' : '')
                                                                    }
                                                                    placeholder={`Choisissez la composition`}
                                                                    styles={colourStyles}
                                                                />
                                                                {touched.buildingDoor2 && errors.buildingDoor2 && (
                                                                    <div className="invalid-feedback">{errors.buildingDoor2}</div>
                                                                )}
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="buildingDoor2Type" className="form-label">
                                                                    Type de portes secondaires<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={renderDefaultValueSelect(buildingDoor2TypeList, values.buildingDoor2Type)}
                                                                    options={buildingDoor2TypeList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    isDisabled={auditSent}
                                                                    inputId="buildingDoor2Type"
                                                                    onBlur={handleBlur}
                                                                    onChange={(event) => {
                                                                        storageService.setAuditValue(7, isValid, 'buildingDoor2Type', event.value, event.label);
                                                                        setValues({
                                                                            ...values,
                                                                            buildingDoor2Type: event.value,
                                                                        });
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingDoor2Type ? ' filled' : '') +
                                                                        (touched.buildingDoor2Type && errors.buildingDoor2Type ? ' invalid' : '') +
                                                                        (!values.buildingDoor2Type ? ' required' : '')
                                                                    }
                                                                    placeholder={`Choisissez le type`}
                                                                    styles={colourStyles}
                                                                />
                                                                {touched.buildingDoor2Type && errors.buildingDoor2Type && (
                                                                    <div className="invalid-feedback">{errors.buildingDoor2Type}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(storageService.getAudit().step2.projectType.value === '1' || storageService.getAudit().step2.projectType.value === '4') &&
                                        !domTomCheck(currentZipCode) && (
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div className="card without-shadow border-grey h-100">
                                                        <div className="card-title">
                                                            <IconDoor3 fill="#76ca4f" width={40} height={40} className="icon" />
                                                            <div className="d-flex flex-column">
                                                                <h2>Porte sur local non-chauffé</h2>
                                                            </div>

                                                            {values.buildingDoorUnheatedRoom1Presence && (
                                                                <AddStepPhotos
                                                                    prop={buildingPictureDoorUnheatedRoom1Presence}
                                                                    values={values}
                                                                    setFieldValue={setFieldValue}
                                                                    fieldValue={'buildingPictureDoorUnheatedRoom1Presence'}
                                                                    disabled={auditSent}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={values.buildingDoorUnheatedRoom1Presence ? 'mb-3' : ''}>
                                                                <CheckboxService
                                                                    handleCheckbox={buildingDoorUnheatedRoom1Presence}
                                                                    values={values}
                                                                    isValid={isValid}
                                                                    setValues={setValues}
                                                                    name={'buildingDoorUnheatedRoom1Presence'}
                                                                    propName={values.buildingDoorUnheatedRoom1Presence}
                                                                    title={'Présence de portes sur local non-chauffé ?'}
                                                                    border={true}
                                                                    disabled={auditSent}
                                                                />
                                                            </div>
                                                            {['1', true, 'true'].includes(values.buildingDoorUnheatedRoom1Presence) && (
                                                                <div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="buildingDoorUnheatedRoom1" className="form-label">
                                                                            Composition de la porte sur local non-chauffé<sup>*</sup>
                                                                        </label>
                                                                        <Select
                                                                            value={renderDefaultValueSelect(
                                                                                buildingDoorUnheatedRoom1List,
                                                                                values.buildingDoorUnheatedRoom1
                                                                            )}
                                                                            options={buildingDoorUnheatedRoom1List}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            isMulti={false}
                                                                            isDisabled={auditSent}
                                                                            inputId="buildingDoorUnheatedRoom1"
                                                                            onBlur={handleBlur}
                                                                            onChange={(event) => {
                                                                                storageService.setAuditValue(
                                                                                    7,
                                                                                    isValid,
                                                                                    'buildingDoorUnheatedRoom1',
                                                                                    event.value,
                                                                                    event.label
                                                                                );
                                                                                setValues({
                                                                                    ...values,
                                                                                    buildingDoorUnheatedRoom1: event.value,
                                                                                });
                                                                            }}
                                                                            className={
                                                                                'basic-single' +
                                                                                (values.buildingDoorUnheatedRoom1 ? ' filled' : '') +
                                                                                (touched.buildingDoorUnheatedRoom1 && errors.buildingDoorUnheatedRoom1
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.buildingDoorUnheatedRoom1 ? ' required' : '')
                                                                            }
                                                                            placeholder={`Choisissez la composition`}
                                                                            styles={colourStyles}
                                                                        />
                                                                        {touched.buildingDoorUnheatedRoom1 && errors.buildingDoorUnheatedRoom1 && (
                                                                            <div className="invalid-feedback">{errors.buildingDoorUnheatedRoom1}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="buildingDoorUnheatedRoom1Type" className="form-label">
                                                                            Type de la porte sur local non-chauffé*<sup>*</sup>
                                                                        </label>
                                                                        <Select
                                                                            value={renderDefaultValueSelect(
                                                                                buildingDoorUnheatedRoom1TypeList,
                                                                                values.buildingDoorUnheatedRoom1Type
                                                                            )}
                                                                            options={buildingDoorUnheatedRoom1TypeList}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            isMulti={false}
                                                                            isDisabled={auditSent}
                                                                            inputId="buildingDoorUnheatedRoom1Type"
                                                                            onBlur={handleBlur}
                                                                            onChange={(event) => {
                                                                                storageService.setAuditValue(
                                                                                    7,
                                                                                    isValid,
                                                                                    'buildingDoorUnheatedRoom1Type',
                                                                                    event.value,
                                                                                    event.label
                                                                                );
                                                                                setValues({
                                                                                    ...values,
                                                                                    buildingDoorUnheatedRoom1Type: event.value,
                                                                                });
                                                                            }}
                                                                            className={
                                                                                'basic-single' +
                                                                                (values.buildingDoorUnheatedRoom1Type ? ' filled' : '') +
                                                                                (touched.buildingDoorUnheatedRoom1Type && errors.buildingDoorUnheatedRoom1Type
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.buildingDoorUnheatedRoom1Type ? ' required' : '')
                                                                            }
                                                                            placeholder={`Choisissez le type`}
                                                                            styles={colourStyles}
                                                                        />
                                                                        {touched.buildingDoorUnheatedRoom1Type && errors.buildingDoorUnheatedRoom1Type && (
                                                                            <div className="invalid-feedback">{errors.buildingDoorUnheatedRoom1Type}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </fieldset>
                            </div>
                        </div>
                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(7, '/audit')}>
                                Retour au menu principal
                            </button>

                            <button
                                type="button"
                                onClick={(event: any) => {
                                    // Amazingly no typed
                                    event.preventDefault();
                                    if (Object.entries(errors).length !== 0) {
                                        // Préparation liste d'étapes en erreur
                                        let errorsList: string[] = [];
                                        Object.keys(errors).forEach((field: any) => {
                                            // @ts-ignore
                                            if (typeof errors[field] === 'object') {
                                                // @ts-ignore
                                                if (Array.isArray(errors[field])) {
                                                    // @ts-ignore
                                                    errors[field].forEach((element: any) => {
                                                        for (let key in element) {
                                                            errorsList = [...errorsList, element[key]];
                                                        }
                                                    });
                                                } else {
                                                    // @ts-ignore
                                                    for (let key in errors[field]) {
                                                        // @ts-ignore
                                                        for (let subkey in errors[field][key]) {
                                                            // @ts-ignore
                                                            errorsList = [
                                                                ...errorsList,
                                                                // @ts-ignore
                                                                errors[field][key][subkey] +
                                                                    (field.includes('Windows')
                                                                        ? ` (${
                                                                              renderDefaultValueSelect(orientationList, key)[0]
                                                                                  ? renderDefaultValueSelect(orientationList, key)[0].label
                                                                                  : 'Orientation indéfinie'
                                                                          })`
                                                                        : ''),
                                                            ];
                                                        }
                                                    }
                                                }
                                            } else {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[field]}`];
                                            }
                                        });
                                        // Affichage message
                                        AlertSwal.fire({
                                            title: `Champs manquants`,
                                            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                        <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                            cancelButtonText: 'Continuer la saisie',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        });
                                    } else {
                                        // Vérification de la valeur Limite SHab saisie & affichage modal confirmation si nécessaire (si inférieur à 10% ou supérieur à 20% du SHab)
                                        if (
                                            values.SHabAreaLimit < (storageService.getAudit().step2.SHab.value / 100) * 10 ||
                                            values.SHabAreaLimit > (storageService.getAudit().step2.SHab.value / 100) * 20
                                        ) {
                                            confirmSHabAreaLimit(values?.SHabAreaLimit, {
                                                start: (storageService.getAudit().step2.SHab.value / 100) * 10,
                                                end: (storageService.getAudit().step2.SHab.value / 100) * 20,
                                            }).then((response: boolean) => {
                                                if (response && !checkPhotos(values)) {
                                                    validateThenRoute(7, ROUTE_AUDIT_PHOTOS);
                                                } else if (response && checkPhotos(values)) {
                                                    // Préparation liste des photos en erreur
                                                    let errorsList: string[] = [];

                                                    const currentMandatoryPhotos = mandatoryPhotos(values);
                                                    currentMandatoryPhotos.forEach((photo: MandatoryPhotosProps) => {
                                                        // @ts-ignore
                                                        if (values[photo.name].length === 0) {
                                                            // @ts-ignore
                                                            errorsList = [...errorsList, `${photo.errorMessage}`];
                                                        }
                                                    });

                                                    AlertSwal.fire({
                                                        title: `Photos manquantes`,
                                                        html: `<p class="mb-0">Les photos suivantes n’ont pas été insérées :<br/>
                                                    <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span>
                                                    <br/>
                                                    Vous pouvez poursuivre le remplissage de l’audit mais il sera nécessaire de les ajouter à l’étape 8 pour sa validation finale
                                                    </p>`,
                                                        cancelButtonText: 'Rester sur l’étape',
                                                        confirmButtonText: 'Accepter',
                                                        showConfirmButton: true,
                                                        showCancelButton: true,
                                                        reverseButtons: true,
                                                        focusCancel: true,
                                                        showClass: {
                                                            popup: 'errors',
                                                            backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                                        },
                                                        customClass: {
                                                            cancelButton: 'btn btn-primary maxi-min-width',
                                                            confirmButton: 'btn btn-secondary maxi-min-width',
                                                        },
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            validateThenRoute(7, ROUTE_AUDIT_PHOTOS);
                                                        }
                                                    });
                                                } else {
                                                    return null;
                                                }
                                            });
                                        } else {
                                            if (checkPhotos(values)) {
                                                // Préparation liste des photos en erreur
                                                let errorsList: string[] = [];

                                                const currentMandatoryPhotos = mandatoryPhotos(values);
                                                currentMandatoryPhotos.forEach((photo: MandatoryPhotosProps) => {
                                                    // @ts-ignore
                                                    if (values[photo.name].length === 0) {
                                                        // @ts-ignore
                                                        errorsList = [...errorsList, `${photo.errorMessage}`];
                                                    }
                                                });

                                                // Affichage message
                                                AlertSwal.fire({
                                                    title: `Photos manquantes`,
                                                    html: `<p class="mb-0">Les photos suivantes n’ont pas été insérées :<br/>
                                                <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span>
                                                <br/>
                                                Vous pouvez poursuivre le remplissage de l’audit mais il sera nécessaire de les ajouter à l’étape 8 pour sa validation finale
                                                </p>`,
                                                    cancelButtonText: 'Rester sur l’étape',
                                                    confirmButtonText: 'Accepter',
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    reverseButtons: true,
                                                    focusCancel: true,
                                                    showClass: {
                                                        popup: 'errors',
                                                        backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                                    },
                                                    customClass: {
                                                        cancelButton: 'btn btn-primary maxi-min-width',
                                                        confirmButton: 'btn btn-secondary maxi-min-width',
                                                    },
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        validateThenRoute(7, ROUTE_AUDIT_PHOTOS);
                                                    }
                                                });
                                            } else {
                                                validateThenRoute(7, ROUTE_AUDIT_PHOTOS);
                                            }
                                        }
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Openings;
