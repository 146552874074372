import qs from 'qs';
import * as api from './apiParticulierService';
import { Theme } from './calculs/theme';
import { LabelledString } from './tools/TypeHelper';
import { RenovationDAmpleur } from './calculs/aides/aideRenovationDAmpleur';

export type AuditType = 'Thermique' | 'Expert';

export const isAuditType = (value: string): value is AuditType => {
    return value === 'Thermique' || value === 'Expert';
};

export interface PaginatedResponse<T> {
    pageIndex: number;
    totalPage: number;
    elementsPerPage: number;
    totalElements: number;
    prevPageUrl?: string;
    nextPageUrl?: string;
    elements: Array<T>;
}

export const createFlow = async (type: AuditType): Promise<string> => {
    let response = await api.postJsonData(`flow/${type}`, null);
    console.log('post flow response = ' + JSON.stringify(response));
    console.log('post flow response.id = ' + JSON.stringify(response.id));
    if (!response.id) {
        throw response.response;
    }

    return response.id;
};
export const FullFlowStatusNames = [
    'Created',
    'AuditSent',
    'RdvRequested',
    'RdvSigned',
    'WithoutRdv',
    'WithoutDevis',
    'DevisSent',
    'WithoutCommand',
    'CommandSigned',
    'WithoutPrevisit',
    'WithoutInstallation',
    'WithInstallation',
] as const;

export type FullFlowStatus = (typeof FullFlowStatusNames)[number];

export const fundingType = ['Loan', 'Cash'] as const;

export type FundingType = (typeof fundingType)[number];

export type FlowStatusFilter = 'notSent' | 'sentNoRdv' | 'sentWithRdv' | 'orderCompleted' | 'preVisitCompleted' | undefined;
export type FlowOutput = {
    createdAt: string;
    updatedAt?: string;
    id: string;
    state: FullFlowStatus;
    auditType: AuditType;
    customer?: Customer;
    comment?: string | null;
    mar?: Mar | null;
};
export type Customer = Partial<{
    userFirstname: string | null;
    userLastname: string | null;
    userAddress: string | null;
    userZipCode: string | null;
    userLocality: string | null;
    userHomeTelephone: string | null;
    userTelephone: string | null;
    userEmail: string | null;
}>;

export type ApiDocument = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    url: string;
    fileName: string;
    extension: string;
};

export type AppointmentOutput = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    date: string;
    latitude: number | null;
    longitude: number | null;
    address: string;
};

type FlowQuotationDoc = {
    createdAt: string;
    extension: string;
    fileName: string;
    filePath: string;
    id: string;
    thirdPartStorageData: string | null;
    updatedAt: string;
    url: string;
};

type EnedisConsent = {
    id: string;
    createdAt: string;
    pdl: string;
    expireAt: string;
    customer: Customer;
    document: api.Document;
};

export type Mar = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    company: string;
    lastname: string;
    firstname: string;
    email: string;
    phoneNumber: string;
    address: string | null;
    city: string | null;
    zipcode: string | null;
    targetDepartments: string[];
};

export type FlowOutputDetail = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    state: FullFlowStatus;
    agentCode: string;
    auditType: AuditType;
    audit: unknown;
    auditId: number | null;
    recoAppointmentId: string | null;
    recoAppointment: AppointmentOutput | null;
    packages: unknown;
    mar: Mar | null;
    marId: string | null;
    themes: Array<Theme>;
    devis: unknown;
    devisId: number | null;
    quotationNumber: string | null;
    quotationType: api.DevisPdfType | null;
    quotationExpirationDate: Date | null;
    quotationDocId: string | null;
    quotationDoc: FlowQuotationDoc;
    fundingType: FundingType;
    fundingOrganism: string | null;
    funding: Record<string, unknown>;
    fundingUrl: string | null;
    fundingState: string | null;
    previsit: unknown;
    previsitDoc: ApiDocument | null;
    previsitDocId: string | null;
    previsitAppointmentId: string | null;
    installationAppointmentId: string | null;
    installationAppointment: AppointmentOutput | null;
    customer: Customer | null;
    enedisConsent: EnedisConsent | null;
    comment: string | undefined | null;
    renoDampleurParams: RenovationDAmpleur;
};

export type PaginatedRequest = {
    pageIndex: number;
    elementsPerPage: number;
};

export type GetMyFlowsFilters = {
    state: FlowStatusFilter[];
    createdbefore?: Date;
    createdafter?: Date;
    updatedbefore?: Date;
    updatedafter?: Date;
    ids?: string[];
};

export const FlowsOrderbyKeysNames = ['customerName', 'createdAt', 'updatedAt', 'state'] as const;
export type FlowsOrderbyKeys = (typeof FlowsOrderbyKeysNames)[number];

export type OrderName = 'asc' | 'desc';

export type GetMyFlowsRequest = {
    pagination?: PaginatedRequest;
    orderBy?: { key: FlowsOrderbyKeys; order: OrderName };
    filters?: GetMyFlowsFilters;
};

export type UrlSearchParamsType =
    | string
    | URLSearchParams
    | Record<string, string | readonly string[]>
    | Iterable<[string, string]>
    | readonly [string, string][];

export const getMyFlows = async (params?: GetMyFlowsRequest): Promise<PaginatedResponse<FlowOutput>> => {
    const queryParams = qs.stringify({
        filters: JSON.stringify(params?.filters),
        pagination: JSON.stringify(params?.pagination),
        orderby: JSON.stringify(params?.orderBy),
    });

    const url = `flow/mine?${queryParams}`;
    // if (params) {
    //     url = Object.entries(params).reduce((acc, curr) => {
    //         if (curr[1]) {
    //             return acc + '&' + curr[0] + '=' + encodeURI(JSON.stringify(curr[1]));
    //         } else {
    //             return acc;
    //         }
    //     }, url + '?');
    // }

    let response = await api.getData(url);

    return response;
};

export const getFlow = async (id: string): Promise<FlowOutputDetail> => {
    let response = await api.getData(`flow/${id}`);

    return response;
};

export const getFlowStatus = async (): Promise<Array<LabelledString>> => {
    return await api.getData(`flow/state/dropdown`);
};

export const deleteFlows = async (ids: string[]) => {
    await api.deleteData(`flow`, { ids });
};

// Bon de commande documents
const docNames = [
    'bon_de_commande',
    'annexe_bdc',
    'financement_sogys',
    'assurance_mma',
    'contrat_pub',
    'engagement_client_sogys',
    'simulation',
    'attestation_tva',
    'mandat_enedis',
    'mandat_special_client_sogys',
    'mandat_anah',
    'doc_solar_noir',
] as const;

export type DocNames = (typeof docNames)[number];

export type SignedDocs = Partial<Record<DocNames, string>>;

export const getFlowDocuments = async (flowId: string): Promise<SignedDocs> => {
    return await api.getData(`flow/${flowId}/preco/signed-documents`);
};
