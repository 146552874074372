/**
 * Fonction pour convertir un dataUrl en fichier
 * @param base64 (base64 image)
 * @param fileName (nom image)
 * @param fileExtension (extension du fichier)
 */
export const dataURLtoFile = (base64: string, fileName: string, agentCode: string, fileExtension?: string): File | undefined => {
    let arr = base64.split(',');

    const matches = arr[0].match(/:(.*?);/);
    if (!matches || matches.length <= 1 || arr.length <= 1) return undefined;
    // @ts-ignore
    let mime = matches[1]; // this might crash if base64 is not a base 64 string.
    let bstr = atob(arr[1]); // same
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const date = new Date().toISOString().split('.')[0].replace(/[:_]/g, '-');
    // retour du fichier converti avec le code agent, le nom et la date
    return new File([u8arr], `${agentCode}-${fileName}-${date}.${fileExtension ? fileExtension : 'jpeg'}`, { type: mime });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};
