import React, { useEffect, useState } from 'react';
import { useRecoilState, SetterOrUpdater, useSetRecoilState } from 'recoil';
// Simulator
import TemplateSimulator from '../../assets/json/template-simulator.json';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import * as api from '../../services/apiParticulierService';
import * as apiService from '../../services/apiFlowService';
import * as apiFlow from '../../services/apiFlowService';
import * as storageService from '../../services/localStorageService';
import * as ticketService from '../../services/calculs/ticket';
import * as themesService from '../../services/calculs/theme';
import { SubThemeContainerComponent } from '../../components/recommandations/SubThemeContainer';
import SimulatorContainer from '../../components/recommandations/SimulatorContainer';
import { filterAL, filterByUserPref, filterPreconisations, filterTva } from '../../services/calculs/filterPreconisation';
import { updatePackagePrice } from '../../services/calculs/package';
import { ReactComponent as SVGLoader } from '../../assets/icons/loader.svg';
import * as aideRenoDAmpleur from '../../services/calculs/aides/aideRenovationDAmpleur';
import withReactContent from 'sweetalert2-react-content';
import { applyPackageSelectorSpecialRules } from '../../services/calculs/packageSelectorSpecialRules';

// Components
import TicketComponent from '../../components/recommandations/TicketContainer';
import Cookies from 'js-cookie';
import {
    aideRenovationDAmpleurStateAtom,
    ticketStateAtom,
    aidesLocalesListStateAtom,
    currentThemeIndexAtom,
    ticketLoaderAtom,
    themesStateAtom,
} from '../../services/Recoil/Atom/Themes.atom';
import { FilterThemePreference, defaultFilterThemePreference } from '../espace-agent/mon-compte/preference';
import { Countdown } from './countdown/Countdown';

// Style
import './audit-simulator.scss';
import { getAppointmentDetails, isDeadLineAideOk } from '../../services/calculs/ticket';

// READ ME
//
// fonctionnel de l'écran :
// - on télécharge des packages (WS)
// - on les filtre et on les trie avant de les afficher
// - on selectionne des packages,
// - a chaque selection, on reconstruit le ticket (WS)
// - on modifie le ticket (téléchargé) ici, pour ajouter des aides qui ne sont pas du ressort de icoll.
//
// Les aides :
// - Les aides ne sont connue que lorsque on a recu un ticket du Web service.
// - on ne connait que les aides applicables avec la combinaison ed package passée en paramètres à la route.
// - on ajoute après coup (sans appel WS) les aides que icoll n'a pas a connaitre : l'aide à la consommation photo voltaique, et l'écopub.

/** Chaque carte de package contient une aide qu'on ne connait pas à l'avance
 *  une fois recu le ticket il faut ajouter les aides dans la carte de package, concernée.
 *  Cette function permet d'annuler les aides déjà appliquées à certaines cartes de package lors d'un précédent ticket.
 */

const clearAllPackageHelp = (allThemesClone: Array<themesService.Theme>): void => {
    // ré-initialise toutes les aides lcoales des package à 0, avant prendre en compte les aides du ticket.
    for (const theme of allThemesClone) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                updatePackagePrice(pack);
            }
        }
    }
};

/**
 * Cette fonction permet de déclencher le re-render de la apge complète depuis les composants sous-jacents.
 * @param allThemesClone un clone writable (et à jour) de la collection de theme
 * @param setThemes la fonction setThemes de l'atome themes
 * @param setTicket la fonction setTicket de l'atome ticket
 * @param aidesLocales la variable contenant les aides locales (dont éco pub)
 * @param localTicket la variable contenant le ticket en local. Si elle est passée la fonction updateTicket ne rappellera pas l'api et opérera les changement en utilisant les données locales
 */
export const updateTicket = async (
    allThemesClone: Array<themesService.Theme>,
    setThemes: SetterOrUpdater<Array<themesService.Theme>>,
    setTicket: SetterOrUpdater<ticketService.TicketData>,
    aidesLocales: ticketService.AidesLocalesList,
    localTicket?: ticketService.TicketData
) => {
    // efface les aides qui existait déjà
    clearAllPackageHelp(allThemesClone);
    // Obtient le ticket du WS.
    const auditId = localStorage.getItem('auditId')!;
    const packages = themesService.getSelectedPackagesWithQty(allThemesClone);
    // on récupère le ticket local si on n'a pas besoin de faire un appel a l'api.
    let ticket = localTicket ?? (await ticketService.simulateDevis(auditId, packages));
    // A partir de la on connait les aides applicable sur _ce_ ticket.
    // On complete avec les aides locales, et on recalcule le ticket.

    await ticketService.manageLocaleAide(ticket, allThemesClone, aidesLocales);
    await ticketService.populateAllPackageHelp(ticket, allThemesClone);
    ticketService.recalculTicket(ticket);

    // puis on set les atomes, pour déclencher tous les re-render.
    setTicket(ticket);
    setThemes(allThemesClone);
};

const ThemesMenu: React.FC<{ currentThemeIndex: number; themes: themesService.Theme[]; changeThemes: (index: number) => void }> = ({
    currentThemeIndex,
    themes,
    changeThemes,
}) => {
    const tabsNumber = { '--tabsLength': themes.length } as React.CSSProperties;

    return (
        <ul className="card-tabs" style={tabsNumber}>
            {themes.map((theme, index) => {
                return (
                    <li
                        key={theme.themeType}
                        onClick={() => {
                            changeThemes(index);
                        }}
                        data-active={currentThemeIndex === index ?? false}
                        className="card-tabs-item"
                    >
                        {theme.themeType}
                    </li>
                );
            })}
        </ul>
    );
};

const SubThemesPanel: React.FC<{ themes: themesService.Theme[]; currentThemeIndex: number }> = ({ themes, currentThemeIndex }) => {
    if (!themes || themes.length === 0) return <></>;

    const subThemes = themes[currentThemeIndex].subThemes!;

    return (
        <div className="card-body p-0">
            {subThemes.map((theme) => (
                <SubThemeContainerComponent {...theme} key={theme.subThemeType}></SubThemeContainerComponent>
            ))}
        </div>
    );
};

const Themes: React.FC<{ currentThemeIndex: number; themes: themesService.Theme[]; changeThemes: (index: number) => void }> = ({
    currentThemeIndex,
    themes,
    changeThemes,
}) => {
    return (
        <>
            <div className="tabs">
                <ThemesMenu currentThemeIndex={currentThemeIndex} themes={themes} changeThemes={changeThemes} />
            </div>
            <SubThemesPanel themes={themes} currentThemeIndex={currentThemeIndex} />
        </>
    );
};

/**
 * README:
 *
 * Ce composant est le parent de TOUTE la page.
 * Son cycle de vie se comporte en deux temps.
 *
 * 1- Lorsqu'il est monté, il appel la route getThemes afin d'obtenir la liste des packages. (useEffect n° 1)
 * 2- Une fois que les packages ont été chargés, il appelle la fonction update ticket qui va générer les données du ticket (useEffect n° 2).
 *
 */

const AuditSimulator: React.FC = () => {
    const agentCode: string = Cookies.getJSON('Auth').agentCode;
    const auditSimulator: string | null = localStorage.getItem(`${agentCode}-SIM`);
    const [prefThemeFilter, setPrefThemeFilter] = useState<FilterThemePreference | undefined>(undefined); // Yes undefined
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const [renoDAmpleur, setRenoDAmpleur] = useRecoilState(aideRenovationDAmpleurStateAtom);
    const [renoDAmpleurShown, setRenoDAmpleurShown] = useState<boolean>(false);
    const setTicket = useSetRecoilState(ticketStateAtom);
    const [themesLoaded, setThemesLoaded] = useState(false);
    const [currentThemeIndex, setCurrentThemeIndex] = useRecoilState(currentThemeIndexAtom);
    const [ticketIsLoading, setTicketIsLoading] = useRecoilState(ticketLoaderAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);
    const { recoAppointmentDate } = getAppointmentDetails();

    const AlertSwal = withReactContent(Swal);
    const flowId = localStorage.getItem('flowId');

    if (!auditSimulator) {
        storageService.setSim(TemplateSimulator);
    }

    // useEffect N° 1,
    // on charge les prefs de l'utilisateur
    useEffect(() => {
        // Set user pref (or keep default.)
        (async () => {
            const aidePrevisite: ticketService.Aide | undefined = await ticketService.createAidePrevisit();
            const aideCo: ticketService.Aide | undefined = await ticketService.createAideCommerciale();

            // Si on les fait une par une ca marche pas !
            // Car On repart du _même_ ...aidesLocales
            // faut les faire d'un coup.
            setAidesLocales({ ...aidesLocales, [ticketService.AIDE_REMISE_COMMERCIALE_NAME]: aideCo, [ticketService.AIDE_REMISE_PREVISITE]: aidePrevisite });

            // Puis des préférences qui vont déclencher le prochain use effect.
            // on veut que la remise co soit déjà présente.
            const pref = (await api.getMyself()).preferences;
            if (pref && pref.themeFilter) {
                setPrefThemeFilter(pref.themeFilter as FilterThemePreference);
            } else setPrefThemeFilter(defaultFilterThemePreference);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect N° 2
    // On attends que les préférences soient chargées avant de faire les filtrage
    useEffect(() => {
        if (prefThemeFilter)
            (async () => {
                //console.time('chargement useEffect 2');
                // Chargement de l'audit pour les calculs de PreconisationChauffage
                const audit = storageService.stepListToAuditAndClient().audit;

                const renoGlobale = aideRenoDAmpleur.isElligible(audit);

                setRenoDAmpleur(renoGlobale);

                let themes = await themesService.getThemes();
                if (!themes || themes.length === 0) {
                    showMessages([{ message: 'Aucun Packages disponibles', title: 'Erreur' }]);
                    setThemesLoaded(true);
                    return;
                }

                await filterAL(themes);
                await filterTva(audit, themes);
                themes = await filterByUserPref(prefThemeFilter, themes);
                themes = await filterPreconisations(audit, themes);

                // On créé une eventuelle aide mma selon les packages sélectionnés. Qui est peut être undefined.
                const aideMma: ticketService.Aide | undefined = await ticketService.createAideMMA(themes);
                const aidePv: ticketService.Aide | undefined = await ticketService.createAideReventeSurplus(themes);
                setAidesLocales({ ...aidesLocales, [ticketService.AIDE_REMISE_MMA_NAME]: aideMma, [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: aidePv });

                setCurrentThemeIndex(0);
                setThemes(themes);

                setThemesLoaded(true);
                //console.timeEnd('chargement useEffect 2');
            })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefThemeFilter]);

    // Check response from agent
    const loadMarResponse = async () => {
        try {
            if (flowId) {
                const response: apiFlow.FlowOutputDetail = await apiService.getFlow(flowId);
                return response.renoDampleurParams?.marShouldContactCustomer as boolean | undefined;
            } else {
                return undefined;
            }
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect N° 3
    useEffect(() => {
        // on attend que les packages soient chargés avant d'appeler l'api pour créer le ticket
        if (themesLoaded) {
            (async () => {
                setTicketIsLoading(true);
                // initialise le ticket
                const allThemeClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
                await updateTicket(allThemeClone, setThemes, setTicket, aidesLocales);
                setTicketIsLoading(false);

                const marResponseValue = await loadMarResponse();

                if (renoDAmpleur.elligible && marResponseValue === undefined) {
                    // console.log("ELLIGIBLE A L'AIDE RENOVATION GLOBALE  !!!");
                    UpdatePackageForRenoAmpleur();
                } else {
                    // console.log("PAS ELLIGIBLE A L'AIDE RENOVATION GLOBALE    :( :( :( ");
                }
            })();
        }
        // on passe aide dans le tableau de dépendances car il faut refaire un appel d'api lorsque l'input / checkbox d'aide est trigger (Voir la fonction EcoTicket)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aidesLocales, themesLoaded]);

    const changeThemes = (themeIndex: number) => {
        setCurrentThemeIndex(themeIndex);
    };

    const isLoading = () => !themesLoaded || ticketIsLoading;

    const UpdatePackageForRenoAmpleur = async (): Promise<void> => {
        if (!renoDAmpleur || renoDAmpleur.elligible === false) return;
        if (renoDAmpleurShown) return;

        setRenoDAmpleurShown(true);

        setTicketIsLoading(true);
        // On va afficher une popup pour dire que le client est elligible.

        // EN MEME TEMPS, on va désactiver tous les packages qu'il faut.
        // si le context n'est pas rempli, il y a eu une erreur Financo, l'agent devra créer l'étude financo à la main

        const content = (
            <>
                <p>
                    Vous pouvez bénéficier d'une prise en charge de <span className="fw-bold">{renoDAmpleur.renoTextes.priseEnCharge}</span> des dépenses (
                    <span className="fw-bold">{renoDAmpleur.renoTextes.priseEnChargeBonusPassoire}</span> si votre habitation est classée F/G) pour un projet de
                    travaux d'amélioration de votre habitat (isolation, chauffage, ouvertures, etc.).
                </p>
                <p>
                    Cette aide peut aller jusqu'à :<br />
                    <ul className="bullet-list">
                        <li>
                            <span className="fw-bold">{renoDAmpleur.plafonds.gain2classes}</span> pour un gain de 2 classes,
                        </li>
                        <li>
                            <span className="fw-bold">{renoDAmpleur.plafonds.gain3classes}</span> pour un gain de 3 classes,
                        </li>
                        <li>
                            <span className="fw-bold">{renoDAmpleur.plafonds.gain4classes}</span> pour un gain de 4 classes.
                        </li>
                    </ul>
                    Si vous êtes intéressé par cette aide, qui nécessitera un reste à charge de{' '}
                    <span className="fw-bold">{renoDAmpleur.renoTextes.resteACharge}</span>, ce montant peut être financé par un prêt à la consommation si
                    nécessaire (ou un éco-prêt à taux zéro), veuillez valider cette étape avec votre conseiller.
                </p>
                <br />
                <p>
                    Un Accompagnateur Rénov' prendra contact avec vous afin d'ouvrir votre dossier auprès de MaPrimeRénov' et vous accompagnera tout au long de
                    ce projet.
                </p>
                <br />
                <p>
                    Les frais de <span className="fw-bold">2 000 €</span> liés à l'intervention de votre Accompagnateur Rénov' sont également pris en charge à
                    hauteur de "<span className="fw-bold">{renoDAmpleur.renoTextes.ecretement}</span>" par MaPrimeRénov'.
                </p>
                <br />
                <p>
                    Votre conseiller SOGYS vous accompagnera tout au long du projet et vous fera parvenir en temps voulu les devis pour la réalisation des
                    travaux préconisés.
                </p>
                <br />
                <p>
                    À ce stade, nous vous recommandons l'installation d'une centrale photovoltaïque pour produire votre propre énergie et réduire vos factures
                    d'électricité jusqu'à 70%.
                </p>
                <br />
                <p>Je souhaite recevoir la visite d'un Accompagnateur Rénov'.</p>
            </>
        );

        AlertSwal.fire({
            title: <strong>Vous êtes éligible à l'aide "Rénovation d'Ampleur"</strong>,
            html: <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>{content}</div>,
            width: 960,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-continue min-width',
                cancelButton: 'btn btn-retour min-width',
            },
        }).then((response) => {
            // Update reno d'ampleur  :
            const newRenoDampleur = { ...renoDAmpleur, marShouldContactCustomer: response.isConfirmed };
            setRenoDAmpleur(newRenoDampleur);

            // On sauvegarde l'état en base.
            const flowId = localStorage.getItem('flowId');
            if (flowId) api.sendRenovationAmpleur(flowId, newRenoDampleur);

            // Si c'est aps confirmé on arrête tout.
            if (response.isDenied || response.isDismissed) {
                setTicketIsLoading(false);
                return;
            }

            // On fait un gros clone profond, pour que tout soit en lecture écriture.
            const allThemeClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;

            // On garde les PV ET le changement comportemental.
            for (const theme of allThemeClone) {
                if (theme.themeType !== "Economie d'énergie") continue;
                if (!theme.subThemes) continue;
                for (const subTheme of theme.subThemes) {
                    const type = subTheme.subThemeType as themesService.SubThemeType;
                    if (type !== 'Photovoltaïque' && type !== 'Changement comportemental') {
                        // DAns ce qu'il reste, on décoche tout !
                        // TODO : sauf MMA Si il y a une remiseMMA.
                        for (const pack of subTheme.packages) {
                            pack.applicable = false;
                        }
                    }
                }
            }

            const messages = applyPackageSelectorSpecialRules(allThemeClone);
            showMessages(messages);
            // forcer le re-render de l'ecran conteneur.
            updateTicket(allThemeClone, setThemes, setTicket, aidesLocales).then(() => {
                setTicketIsLoading(false);
            });
        });
    };

    const showMessages = async (messages: Array<{ title: string; message: string }>): Promise<void> => {
        for (const mess of messages) {
            const swal: SweetAlertOptions = {
                title: mess.title,
                html: mess.message,
                showConfirmButton: true,
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            };
            await AlertSwal.fire(swal);
        }
    };

    const isAppointmentToday = isDeadLineAideOk();

    return (
        <div className="container container-audit">
            <h1 className="main-title-mini">Recommandation</h1>
            <SimulatorContainer />
            <div className="row pb-5">
                <div className="col-12 col-md-9">
                    {themesLoaded && <Themes changeThemes={changeThemes} currentThemeIndex={currentThemeIndex} themes={themes} />}
                </div>
                <div className="col-12 col-md-3">
                    <TicketComponent />
                </div>
            </div>
            {!isLoading() && isAppointmentToday && <Countdown date={recoAppointmentDate} />}
            {isLoading() && (
                <div className="loading">
                    <SVGLoader height={200} width={200} />
                </div>
            )}
        </div>
    );
};

export default AuditSimulator;
