import { Labelled, LabelledString } from '../../../services/tools/TypeHelper';
import { StylesConfig } from 'react-select';
import { format } from 'date-fns';
import { AuditType, FlowOutput, FlowStatusFilter, FullFlowStatus } from '../../../services/apiFlowService';

// Fake datas
export const tableStatus: Array<Labelled<FlowStatusFilter>> = [
    {
        label: 'Non envoyé',
        value: 'notSent',
    },
    {
        label: 'Envoyé sans rdv',
        value: 'sentNoRdv',
    },
    {
        label: 'Envoyé avec rdv',
        value: 'sentWithRdv',
    },
    {
        label: 'Commande finalisée',
        value: 'orderCompleted',
    },
    {
        label: 'Pré-visite finalisée',
        value: 'preVisitCompleted',
    },
];

/**
 * Pour le changement de couleurs du react-select
 */
export const panelStyles: StylesConfig<LabelledString, false> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '100px',
        height: '43px',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

export type FlowOutputRow = {
    createdAt: string;
    type: AuditType;
    customerName: string;
    state: FlowStatusFilter;
    select: string;
    url: string;
    id: string;
    comment: string;
    mar: boolean;
};

export const convertToRow = (flows: Array<FlowOutput>): Array<FlowOutputRow> => {
    const a: FlowOutputRow[] = flows.map((f) => {
        return {
            createdAt: format(new Date(f.updatedAt ?? f.createdAt), 'dd/MM/yyyy - kk:mm'),
            type: f.auditType,
            customerName: f?.customer?.userLastname ?? 'Non renseigné',
            state: statusToStatusFilter(f.state),
            select: '',
            url: '/espace-agent/list-audit/' + f.id,
            id: f.id,
            comment: f.comment ?? '',
            mar: f.mar !== null,
        };
    });
    return a;
};

const statusToStatusFilter = (status: FullFlowStatus): FlowStatusFilter => {
    switch (status) {
        case 'Created':
        case 'AuditSent':
        case 'RdvRequested':
            return 'notSent';

        case 'WithoutRdv':
            return 'sentNoRdv';

        case 'RdvSigned':
        case 'WithoutDevis':
        case 'WithoutCommand':
        case 'DevisSent':
            return 'sentWithRdv';

        case 'CommandSigned':
        case 'WithoutPrevisit':
            return 'orderCompleted';

        case 'WithoutInstallation':
        case 'WithInstallation':
            return 'preVisitCompleted';

        default:
            return 'notSent';
    }
};

export type PaginationRequest = {
    pageIndex: number;
    elementsPerPage: number;
};
