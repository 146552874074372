import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PV_FINALISATION, ROUTE_PV_PLANNING } from '../../../routing/paths';
import * as api from '../../../services/apiParticulierService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
import * as storageService from '../../../services/localStorageService';
import { getFlow } from '../../../services/apiFlowService';
import { dpToIcoll } from '../../../services/apiParticulierService';
import { useRecoilValue } from 'recoil';
import { mapState } from '../../../services/Recoil/Atom/map.atom';

// Icons
import { ReactComponent as IconTimeLapse } from '../../../assets/icons/pre-visite/icon-timelapse.svg';
import { formatNumberWithLeadingZero } from '../../../services/tools/auditTools';

const splitAddress = (address: string): [string, string] => {
    const parts = address.split(' ');
    const firstPart = parts.shift();
    const rest = parts.join(' ');

    return [firstPart || '', rest];
};

const convertBase64ToFile = (fileName: string, base64String: string) => {
    if (!base64String) {
        console.error(`La chaîne base64 est undefined pour le fichier ${fileName}`);
    }

    const [mimeType, base64Full] = base64String.split(';');
    if (!base64Full) {
        console.error(`Format base64 invalide pour le fichier ${fileName}`);
        return null; // Ou gérer autrement
    }
    const extension = mimeType.split(':')[1];
    const base64 = base64Full.split(',')[1];
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: extension });
    const file = new File([blob], fileName, { type: extension });

    return file;
};

type TimerList = {
    label: string;
    days: string;
};

interface UploadedImage {
    fileKey: string;
    url: string;
}

interface UploadOutput {
    success: Record<string, UploadedImage>;
    filerrors: string[];
}
interface LocalImage {
    value: string;
    value_label: string;
}

export interface DpPicturePayload {
    devisId: string;
    type: string;
    type_declaration_id: string;
    file: string; // Base64 string
    comment: string;
    section: string | undefined;
    feuille: string;
    parcelle: string | undefined;
    parcelle_area: string;
    coordinates: string;
    scale_origin: string;
    scale_edition: string;
    street_number: string;
    street: string;
    postal_code: string;
    city: string;
}

const Forecast: React.FC = () => {
    const { step1 } = storageService.getAudit();
    const map = useRecoilValue(mapState);
    const timerList: TimerList[] = [
        {
            label: 'Réservation\nde materiel',
            days: 'J 0',
        },
        {
            label: 'Réception\naccord mairie',
            days: 'J +30',
        },
        {
            label: `Materiel envoyé\nchez votre installeur\n(ou sur lieu de l'installation)`,
            days: 'J +35',
        },
        {
            label: "Matériel réceptionné\npar l'installeur\n(ou sur le lieu de l'installation)",
            days: 'J +37',
        },
        {
            label: 'Installation',
            days: 'J +45',
        },
    ];
    const { push } = useHistory();
    // Initialisation modal
    const AlertSwal = withReactContent(Swal);
    const preVisiteFromLocalStorage = storageService.getPrevisit();
    const flowId = localStorage.getItem('flowId')!;

    const dpPictures: Record<string, LocalImage> = {
        dp1Picture: preVisiteFromLocalStorage.dp1Picture,
        dp2Picture: preVisiteFromLocalStorage.dp2Picture,
        dp2bPicture: preVisiteFromLocalStorage.dp2bPicture,
    };

    // Check DP images
    const isEmpty = !Object.values(dpPictures).some((image) => image.value !== '');

    // Pictures from LocalStorage PreVisit
    const pvPictures: Record<string, LocalImage> = {
        circuitBreakerPicture: preVisiteFromLocalStorage.circuitBreakerPicture,
        doorwayPicture: preVisiteFromLocalStorage.doorwayPicture,
        roofShapePicture: preVisiteFromLocalStorage.roofShapePicture,
        edfMeterPicture: preVisiteFromLocalStorage.edfMeterPicture,
        electricMeterPicture: preVisiteFromLocalStorage.electricMeterPicture,
        singleStoreyHousePicture: preVisiteFromLocalStorage.singleStoreyHousePicture,
        inverterOrEnergyMeterRoomPicture: preVisiteFromLocalStorage.inverterOrEnergyMeterRoomPicture,
        outsideWallPicture: preVisiteFromLocalStorage.outsideWallPicture,
        panoramicBackPicture1: preVisiteFromLocalStorage.panoramicBackPicture1,
        panoramicBackPicture2: preVisiteFromLocalStorage.panoramicBackPicture2,
        panoramicBackPicture3: preVisiteFromLocalStorage.panoramicBackPicture3,
        panoramicFacePicture1: preVisiteFromLocalStorage.panoramicFacePicture1,
        panoramicFacePicture2: preVisiteFromLocalStorage.panoramicFacePicture2,
        panoramicFacePicture3: preVisiteFromLocalStorage.panoramicFacePicture3,
        roomTankInstalledPicture: preVisiteFromLocalStorage.roomTankInstalledPicture,
        houseRearFacePicture: preVisiteFromLocalStorage.houseRearFacePicture,
        dp5Picture: preVisiteFromLocalStorage.dp5Picture,
    };

    // Current user datas from step 1
    const postalCode = step1.userZipCode.value;
    const locality = step1.userLocality.value;
    const nonSplitAddress = step1.userAddress.value;
    const [number] = splitAddress(nonSplitAddress);
    const [, street] = splitAddress(nonSplitAddress);
    const adressNumber = number;
    const address = street;

    const handleDpPictures = async (devisId: number | null) => {
        if (!devisId) {
            console.error('Devis ID introuvable ou null');
            return;
        }

        try {
            for (const [key, value] of Object.entries(dpPictures)) {
                console.log(`Traitement de l'image : ${key}`);
                if (value.value.length === 0) {
                    console.error(`invalid value for '${key}'`);
                    continue;
                }

                let type;
                let scale;
                if (key === 'dp1Picture') {
                    type = 'dp1';
                    scale = '1/5000';
                } else if (key === 'dp2Picture') {
                    type = 'dp2';
                    scale = '1/500';
                } else {
                    type = 'dp2b';
                    scale = '1/200';
                }

                const data: DpPicturePayload = {
                    devisId: String(devisId),
                    type: type,
                    type_declaration_id: '1',
                    file: value.value,
                    comment: '',
                    section: map.feuille?.properties.section,
                    feuille: `${map.feuille?.properties.com_abs} ${map.feuille?.properties.section} ${formatNumberWithLeadingZero(
                        map.feuille?.properties.feuille ?? 0
                    )}`,
                    parcelle: map.parcel?.properties.numero,
                    parcelle_area: `${map.parcel?.properties.contenance}`,
                    coordinates: 'RGF93CC49',
                    scale_origin: `1/${map.feuille?.properties.echelle}`,
                    scale_edition: scale,
                    street_number: adressNumber,
                    street: address,
                    postal_code: postalCode,
                    city: locality,
                };

                // Envoyer l'image à l'API
                await dpToIcoll(data);
                // const response = await dpToIcoll(data);
                // console.log(`Réponse pour ${key}:`, response);
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi des images dpPictures:", error);
        }
    };

    const handleValidation = async () => {
        try {
            const formData = new FormData();
            AlertSwal.fire({
                title: 'Votre document de pré-visite est en cours de génération',
                html: <ModalCounter timer={30} />,
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
            });

            Object.entries(pvPictures).forEach(([key, value]) => {
                if (value.value.length === 0) {
                    console.error(`invalid value for '${key}'`);
                } else {
                    const file = convertBase64ToFile(value.value_label, value.value);
                    if (file) {
                        formData.append(key, file);
                    } else {
                        console.error(`Échec de la conversion du fichier pour '${key}'`);
                    }
                }
            });

            const data: UploadOutput = await api.uploadFiles(formData);

            Object.values(data.success).forEach((value: UploadedImage) => {
                preVisiteFromLocalStorage[value.fileKey].value = value.url;
            });

            await api.updatePrevisitData(flowId, preVisiteFromLocalStorage);
        } catch (error) {
            console.error('error', error);
        } finally {
            AlertSwal.close();
        }
    };

    const executeInOrder = async () => {
        try {
            // Step 1: send pv pictures
            await handleValidation();

            // Step 2: get devisId
            const response = await getFlow(flowId);
            const fetchedDevisId = response.devisId;

            // Check if devisId is set
            if (fetchedDevisId === null) {
                throw new Error('devisId est null');
            }

            // Step 3: send DP to iColl
            if (!isEmpty) {
                await handleDpPictures(fetchedDevisId);
                await api.notifyDP(Number(fetchedDevisId));
                console.log('Mail envoyé');
            }
        } catch (error) {
            console.error("Erreur lors de l'exécution des étapes:", error);
        }
    };

    useEffect(() => {
        executeInOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToFinalise = async () => {
        await api.withoutInstallationAppointment(flowId);
        push(ROUTE_PV_FINALISATION);
    };
    const navigateToPlanning = () => {
        push(ROUTE_PV_PLANNING);
    };

    return (
        <div className="container">
            <h1 className="main-title-mini">Prévision</h1>
            <div className="card card-audit-simulator prevision">
                <div className="card-header">
                    <IconTimeLapse width={60} height={60} fill="white" />
                    <h2>Pré visite technique</h2>
                </div>
                <div className="card-body">
                    <h5 className="card-body--sub-title text-center mb-5">
                        Félicitations, votre pré-visite est conforme à nos attentes.
                        <br />
                        Voici le suivi de votre installation
                    </h5>

                    <div className="timing mb-5">
                        <ul className="timing-list">
                            {timerList.map((element, index) => (
                                <li key={index} data-days={element.days}>
                                    {element.label}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <h5 className="card-body--sub-title text-center mb-5">Réserver une date d'installation</h5>
                    <div className="btn-grp justify-content-center">
                        <div className="text-center">
                            <button type="button" className={`btn btn-retour`} onClick={navigateToFinalise} disabled={preVisiteFromLocalStorage === undefined}>
                                Non
                            </button>
                        </div>

                        <div className="text-center">
                            <button
                                type="button"
                                className={`btn btn-continue`}
                                onClick={navigateToPlanning}
                                disabled={preVisiteFromLocalStorage === undefined}
                            >
                                Oui
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forecast;
