import { CONSTANTES_PHOTOVOLTAIQUES } from './constantesPhotovoltaiques';
import { Theme, isSubThemeType } from './theme';
import { PreconisationChauffage } from './calculPreconisationsCore';
import { BilanPhotovoltaique, SimulationPhotovoltaique, getPrixKwhRevente } from './calculPhotovoltaique';
import { DUREE_PROJETCTION_FINANCIERE, loadIndexations } from './calculEconomie';
import { copyEnergyPrice, DepenseParPoste, getEconomieBetween, inc, indexedAtYear } from './bilanEnergieService';
import { PRESQUE_ZERO, Stringified, currencyFormat, percentFormat } from '../tools/TypeHelper';

// Dans ce fichier, on prend la dépense initiale, et on applique les preco une apr une, dans l'ordre.
// La logique est simple.
export const applyPrecoHorsPv = (depenseInitiale: DepenseParPoste, themes: Array<Theme>): DepenseParPoste => {
    // On ne veut pas écraser la dépense initiale, on fait une copie.
    const depenseResult = depenseInitiale.copie();

    const ecoTheme = themes.find((e) => e.themeType === "Economie d'énergie");
    if (ecoTheme === undefined) {
        console.log('Bad theme configuration, should do an update in icoll back office.');
        return depenseInitiale;
    }
    const ecoSubThemes = ecoTheme.subThemes!;

    // On va boucler sur les preco.
    // Pour chacune on va mettre à jour les dépenses.
    for (const subTheme of ecoSubThemes) {
        // on applique pas les préco photovoltaique de la même façon que les autres.
        // TODO BATTERIE ??
        if (isSubThemeType(subTheme.subThemeType) && subTheme.subThemeType === 'Photovoltaïque') continue;

        for (const pack of subTheme.packages) {
            if (!pack.applicable) continue;

            // Ceinture et bretelle, c'est le même test.
            // on applique pas les préco photovoltaique de la même façon que les autres.
            // TODO BATTERIE ??
            if (pack.mainProduct.categorie_racine === 'Photovoltaïque') continue;

            const mainProduct = pack.mainProduct;
            // Si on a pas de cible de gain, mais qu'on a un gain, on passe au suivant.
            if (mainProduct.cibleGain === undefined && mainProduct.gain !== 0) continue;
            // Si on a un surcout sans savoir sur quoi l'appliquer on passe au suivant.
            if (mainProduct.cibleSurcout === undefined && mainProduct.surcout !== 0) continue;
            //console.log(pack.title + ' = ' + pack.mainProduct.cibleGain + ' => ' + pack.mainProduct.gain + ' + ( ' + pack.mainProduct.surcoutElectricte + ')');

            if (mainProduct.cibleGain === 'Chauffage') {
                // le but est de trouver la nouvelle dépense de chauffage par source.
                // On sait qu'on va gagner et/ou perdre sur le Poste Chauffage,
                // Mais ce qu'on veut c'est calculer la nouvelle dépense par source.

                // On calcule déjà le nouveau cout qu'on aura sur le chauffage
                const gain = depenseResult.chauffage.total() * mainProduct.gain;
                const surcout = depenseResult.chauffage.total() * mainProduct.surcout;
                const nouveau_cout_chauffage = depenseResult.chauffage.total() - gain + surcout;

                // Maintenant qu'on connait le nouveau cout global du Poste chauffage,
                // on recalcule la dépense par source avec une baisse sur chque type d'energie :
                let cout_fioul_intermediaire = depenseResult.chauffage.fioul * (1 - mainProduct.gain);
                let cout_gaz_intermediaire = depenseResult.chauffage.gaz * (1 - mainProduct.gain);
                let cout_bois_intermediaire = depenseResult.chauffage.bois * (1 - mainProduct.gain);
                let cout_electricite_intermediaire = depenseResult.chauffage.electricite * (1 - mainProduct.gain);

                // le nouveau cout d'electricité est le nouveau cout total du chauffage, moins le cout fossile.
                // AVANT : on considérait que le surcout était sur l'electricité. MAis en fait on a la cible du surcout.
                //const cout_electricite_intermediaire = nouveau_cout_chauffage - cout_gaz_intermediaire - cout_fioul_intermediaire - cout_bois_intermediaire;
                switch (mainProduct.cibleSurcout) {
                    case 'Fioul':
                        cout_fioul_intermediaire = nouveau_cout_chauffage - cout_gaz_intermediaire - cout_bois_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Gaz':
                        cout_gaz_intermediaire = nouveau_cout_chauffage - cout_fioul_intermediaire - cout_bois_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Bois':
                        cout_bois_intermediaire = nouveau_cout_chauffage - cout_fioul_intermediaire - cout_gaz_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Electricite':
                    case 'PAC':
                        cout_electricite_intermediaire = nouveau_cout_chauffage - cout_fioul_intermediaire - cout_gaz_intermediaire - cout_bois_intermediaire;
                        break;
                }

                // On remet dans les bonnes cases.
                depenseResult.chauffage.gaz = cout_gaz_intermediaire;
                depenseResult.chauffage.fioul = cout_fioul_intermediaire;
                depenseResult.chauffage.bois = cout_bois_intermediaire;
                depenseResult.chauffage.electricite = cout_electricite_intermediaire;
            }

            if (mainProduct.cibleGain === 'EauChaude') {
                // le but est de trouver la nouvelle dépense d'eau chaude par source.
                // On sait qu'on va gagner et/ou perdre sur le Poste Eau Chaude
                // Mais ce qu'on veut c'est calculer la nouvelle dépense par source.

                // On calcul déjà le nouveau cout qu'on aura sur l'eau chaude
                const gain = depenseResult.eauChaude.total() * mainProduct.gain;
                const surcout = depenseResult.eauChaude.total() * mainProduct.surcout;
                const nouveau_cout_eauChaude = depenseResult.eauChaude.total() - gain + surcout;

                // Maintenant qu'on connait le nouveau cout global du Poste EauChaude,
                // on recalcule la dépense par source, d'abord avec une baisse sur le fossile :
                let cout_fioul_intermediaire = depenseResult.eauChaude.fioul * (1 - mainProduct.gain);
                let cout_gaz_intermediaire = depenseResult.eauChaude.gaz * (1 - mainProduct.gain);
                let cout_bois_intermediaire = depenseResult.eauChaude.bois * (1 - mainProduct.gain);
                let cout_electricite_intermediaire = depenseResult.eauChaude.electricite * (1 - mainProduct.gain);

                // le nouveau cout d'electricité est le nouveau cout total de l'eau chaude, moins le cout fossile.
                // AVANT : on considérait que le surcout était sur l'electricité. MAis en fait on a la cible du surcout.
                // const cout_electricite_intermediaire = nouveau_cout_eauChaude - cout_gaz_intermediaire - cout_fioul_intermediaire - cout_bois_intermediaire;
                switch (mainProduct.cibleSurcout) {
                    case 'Fioul':
                        cout_fioul_intermediaire = nouveau_cout_eauChaude - cout_gaz_intermediaire - cout_bois_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Gaz':
                        cout_gaz_intermediaire = nouveau_cout_eauChaude - cout_fioul_intermediaire - cout_bois_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Bois':
                        cout_bois_intermediaire = nouveau_cout_eauChaude - cout_fioul_intermediaire - cout_gaz_intermediaire - cout_electricite_intermediaire;
                        break;
                    case 'Electricite':
                    case 'PAC':
                        cout_electricite_intermediaire = nouveau_cout_eauChaude - cout_fioul_intermediaire - cout_gaz_intermediaire - cout_bois_intermediaire;
                        break;
                }
                // On remet dans les bonnes cases.
                depenseResult.eauChaude.gaz = cout_gaz_intermediaire;
                depenseResult.eauChaude.fioul = cout_fioul_intermediaire;
                depenseResult.eauChaude.bois = cout_bois_intermediaire;
                depenseResult.eauChaude.electricite = cout_electricite_intermediaire;
            }
            if (mainProduct.cibleGain === 'Autres') {
                // le but est de trouver la nouvelle dépense par source.
                // On sait qu'on va gagner sur le Poste Autres, et perdre un peu sur le Poste Autres.
                // Mais ce qu'on veut c'est calculer la nouvelle dépense par source.

                // On calcule simplement le nouveau cout qu'on aura sur le poste
                const gain = depenseResult.autres.total() * mainProduct.gain;
                const surcout = depenseResult.autres.total() * mainProduct.surcout;
                const nouveau_cout_autres = depenseResult.autres.total() - gain + surcout;

                // Le cas particulier ici, c'est que dans la dépsnes autre, il n'y a touours qu'une seule source, donc le calcul est simple.
                // Les sources fossiles sont déjà à 0 et y restent.

                depenseResult.autres.electricite = nouveau_cout_autres;
            }

            // A la fin de ça :
            // si une énergie tombe à 0 (probable, puisque c'est le but), on supprime le prix son l'abonnement :
            if (depenseResult.fioulPrice && depenseResult.prixFioulHorsAbo() === 0) depenseResult.fioulPrice.subscriptionPrice = 0;
            if (depenseResult.electricitePrice && depenseResult.prixElectriciteHorsAbo() === 0) depenseResult.electricitePrice.subscriptionPrice = 0;
            if (depenseResult.boisPrice && depenseResult.prixBoisHorsAbo() === 0) depenseResult.boisPrice.subscriptionPrice = 0;
            if (depenseResult.gazPrice && depenseResult.prixGazHorsAbo() === 0) depenseResult.gazPrice.subscriptionPrice = 0;
        }
    }
    return depenseResult;
};

/**
 * Cette méthode applique toutes les économies comme spécifié dans un tableau disparu.
 * Les economies sont hard codées.
 * L'algo est simple : on annule tout le fossile, et on augmente l'electricité de 25% pour chaque annulation
 * Puis on réduit l'electricité également de 75% et on l'augmente de 25%
 *
 * @deprecated Cette méthode ne doit plus être utilisée, elle a été laissée à titre hsitorique
 * @param depenseInitiale
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const estimerDepenseApresTravaux_HardCoded_old = (depenseInitiale: DepenseParPoste): DepenseParPoste => {
    // A ce stade, on avait sorti le cout de l'abonnement.
    // On pourrait le réinjecter, en utilisant des  :
    // const gazPrices = getPricesWithSubscription("gaz", abonnementGazId);

    // On se fout des préco : C'est une estimation pure.
    // on enlève toutes les énergies fossiles, lorsqu'il y en a, et on remplace par + 25% d'énergie electrique.
    // On enlève 75% des electricités, on réaugmente de 25% (-75% + 25% ou -70% + 30%)

    let surcout_chauffage_electricity = 0;
    let gain_chauffage_electricity = 0;
    let surcout_eauchaude_electricity = 0;
    let gain_eauchaude_electricity = 0;
    let surcout_autres_electricity = 0;
    let gain_autres_electricity = 0;

    if (depenseInitiale.chauffage.fioul !== 0) {
        surcout_chauffage_electricity += depenseInitiale.chauffage.fioul * 0.25;
    }
    if (depenseInitiale.chauffage.gaz !== 0) {
        surcout_chauffage_electricity += depenseInitiale.chauffage.gaz * 0.25;
    }
    if (depenseInitiale.chauffage.bois !== 0) {
        surcout_chauffage_electricity += depenseInitiale.chauffage.bois * 0.25;
    }
    if (depenseInitiale.chauffage.electricite !== 0) {
        gain_chauffage_electricity += depenseInitiale.chauffage.electricite * 0.75;
        surcout_chauffage_electricity += depenseInitiale.chauffage.electricite * 0.25;
    }

    if (depenseInitiale.eauChaude.fioul !== 0) {
        surcout_eauchaude_electricity += depenseInitiale.eauChaude.fioul * 0.25;
    }
    if (depenseInitiale.eauChaude.gaz !== 0) {
        surcout_eauchaude_electricity += depenseInitiale.eauChaude.gaz * 0.25;
    }
    if (depenseInitiale.eauChaude.bois !== 0) {
        surcout_eauchaude_electricity += depenseInitiale.eauChaude.bois * 0.25;
    }
    if (depenseInitiale.eauChaude.electricite !== 0) {
        gain_eauchaude_electricity += depenseInitiale.eauChaude.electricite * 0.75;
        surcout_eauchaude_electricity += depenseInitiale.eauChaude.electricite * 0.25;
    }

    if (depenseInitiale.autres.electricite !== 0) {
        gain_autres_electricity += depenseInitiale.autres.electricite * 0.7;
        surcout_autres_electricity += depenseInitiale.autres.electricite * 0.3;
    }

    const result = new DepenseParPoste();
    // On injecte d'abord les abonnements.
    result.gazPrice = copyEnergyPrice(depenseInitiale.gazPrice);
    result.boisPrice = copyEnergyPrice(depenseInitiale.boisPrice);
    result.fioulPrice = copyEnergyPrice(depenseInitiale.fioulPrice);
    result.electricitePrice = copyEnergyPrice(depenseInitiale.electricitePrice);

    result.chauffage.bois = 0;
    result.chauffage.fioul = 0;
    result.chauffage.gaz = 0;
    result.chauffage.electricite = depenseInitiale.chauffage.electricite - gain_chauffage_electricity + surcout_chauffage_electricity;

    result.eauChaude.bois = 0;
    result.eauChaude.fioul = 0;
    result.eauChaude.gaz = 0;
    result.eauChaude.electricite = depenseInitiale.eauChaude.electricite - gain_eauchaude_electricity + surcout_eauchaude_electricity;

    result.autres.bois = 0;
    result.autres.fioul = 0;
    result.autres.gaz = 0;
    result.autres.electricite = depenseInitiale.autres.electricite - gain_autres_electricity + surcout_autres_electricity;

    // Si le total d'une énergie devient a zero (aux arrondis près)
    // on annule l'abonnement
    if (result.prixElectriciteHorsAbo() < PRESQUE_ZERO) result.gazPrice.subscriptionPrice = 0;
    if (result.prixGazHorsAbo() < PRESQUE_ZERO) result.boisPrice.subscriptionPrice = 0;
    if (result.prixFioulHorsAbo() < PRESQUE_ZERO) result.fioulPrice.subscriptionPrice = 0;
    if (result.prixBoisHorsAbo() < PRESQUE_ZERO) result.electricitePrice.subscriptionPrice = 0;

    return result;
};

/**
 * Cette méthode applique les économies, relative a des preconisations de chauffage uniquement.
 * Ces préco contiennent les données de d'économies qui étaeint hard codées en V1.
 * Puis on applique toutes les précos d'un coup
 * @param precoToApply Les préco de chauffage à appliquer. Calculée préalablement par l'appel de createPreconisationsChauffage
 * @param depenseInitiale La dépense d'energie initiale, avant l'application des précos
 * @returns La dépense d'énergie finale après application des préco.
 */
export const estimerDepenseApresTravaux = (precoToApply: Array<PreconisationChauffage>, depenseInitiale: DepenseParPoste): DepenseParPoste => {
    let gain_chauffage_Fioul = 0,
        gain_chauffage_Gaz = 0,
        gain_chauffage_Bois = 0,
        gain_chauffage_electricity = 0;
    let gain_eauchaude_Fioul = 0,
        gain_eauchaude_Gaz = 0,
        gain_eauchaude_Bois = 0,
        gain_eauchaude_electricity = 0;
    let gain_autres_electricity = 0;

    let surcout_chauffage_electricity = 0;
    let surcout_eauchaude_electricity = 0;
    let surcout_autres_electricity = 0;

    for (const p of precoToApply) {
        if (!p.applicable) continue;

        if (p.cibleGain === 'ChauffageFioul' && depenseInitiale.chauffage.fioul !== 0) {
            gain_chauffage_Fioul += depenseInitiale.chauffage.fioul * p.gain;
            surcout_chauffage_electricity += depenseInitiale.chauffage.fioul * p.surcoutElectricte;
        }
        if (p.cibleGain === 'ChauffageGaz' && depenseInitiale.chauffage.gaz !== 0) {
            gain_chauffage_Gaz += depenseInitiale.chauffage.gaz * p.gain;
            surcout_chauffage_electricity += depenseInitiale.chauffage.gaz * p.surcoutElectricte;
        }
        if (p.cibleGain === 'ChauffageBois' && depenseInitiale.chauffage.bois !== 0) {
            gain_chauffage_Bois += depenseInitiale.chauffage.bois * p.gain;
            surcout_chauffage_electricity += depenseInitiale.chauffage.bois * p.surcoutElectricte;
        }
        if (p.cibleGain === 'ChauffageElectricite' && depenseInitiale.chauffage.electricite !== 0) {
            gain_chauffage_electricity += depenseInitiale.chauffage.electricite * p.gain;
            surcout_chauffage_electricity += depenseInitiale.chauffage.electricite * p.surcoutElectricte;
        }

        if (p.cibleGain === 'EauChaudeFioul' && depenseInitiale.eauChaude.fioul !== 0) {
            gain_eauchaude_Fioul += depenseInitiale.eauChaude.fioul * p.gain;
            surcout_eauchaude_electricity += depenseInitiale.eauChaude.fioul * p.surcoutElectricte;
        }
        if (p.cibleGain === 'EauChaudeGaz' && depenseInitiale.eauChaude.gaz !== 0) {
            gain_eauchaude_Gaz += depenseInitiale.eauChaude.gaz * p.gain;
            surcout_eauchaude_electricity += depenseInitiale.eauChaude.gaz * p.surcoutElectricte;
        }
        if (p.cibleGain === 'EauChaudeBois' && depenseInitiale.eauChaude.bois !== 0) {
            gain_eauchaude_Bois += depenseInitiale.eauChaude.bois * p.gain;
            surcout_eauchaude_electricity += depenseInitiale.eauChaude.bois * p.surcoutElectricte;
        }
        if (p.cibleGain === 'EauChaudeElectricite' && depenseInitiale.eauChaude.electricite !== 0) {
            gain_eauchaude_electricity += depenseInitiale.eauChaude.electricite * p.gain;
            surcout_eauchaude_electricity += depenseInitiale.eauChaude.electricite * p.surcoutElectricte;
        }

        if (p.cibleGain === 'AutresElectricite' && depenseInitiale.autres.electricite !== 0) {
            gain_autres_electricity += depenseInitiale.autres.electricite * p.gain;
            surcout_autres_electricity += depenseInitiale.autres.electricite * p.surcoutElectricte;
        }
    }

    // Il est déjà inclus dans la depenseInitiale
    const result = new DepenseParPoste();
    result.gazPrice = copyEnergyPrice(depenseInitiale.gazPrice);
    result.boisPrice = copyEnergyPrice(depenseInitiale.boisPrice);
    result.fioulPrice = copyEnergyPrice(depenseInitiale.fioulPrice);
    result.electricitePrice = copyEnergyPrice(depenseInitiale.electricitePrice);

    // calcul des nouveaux couts.
    result.chauffage.fioul = depenseInitiale.chauffage.fioul - gain_chauffage_Fioul;
    result.chauffage.gaz = depenseInitiale.chauffage.gaz - gain_chauffage_Gaz;
    result.chauffage.bois = depenseInitiale.chauffage.bois - gain_chauffage_Bois;
    result.chauffage.electricite = depenseInitiale.chauffage.electricite - gain_chauffage_electricity + surcout_chauffage_electricity;

    result.eauChaude.fioul = depenseInitiale.eauChaude.fioul - gain_eauchaude_Fioul;
    result.eauChaude.gaz = depenseInitiale.eauChaude.gaz - gain_eauchaude_Gaz;
    result.eauChaude.bois = depenseInitiale.eauChaude.bois - gain_eauchaude_Bois;
    result.eauChaude.electricite = depenseInitiale.eauChaude.electricite - gain_eauchaude_electricity + surcout_eauchaude_electricity;

    result.autres.fioul = 0;
    result.autres.gaz = 0;
    result.autres.bois = 0;
    result.autres.electricite = depenseInitiale.autres.electricite - gain_autres_electricity + surcout_autres_electricity;

    // Si le total d'une énergie devient a zero (aux arrondis près)
    // on annule l'abonnement
    if (result.prixElectriciteHorsAbo() < PRESQUE_ZERO) result.gazPrice.subscriptionPrice = 0;
    if (result.prixGazHorsAbo() < PRESQUE_ZERO) result.boisPrice.subscriptionPrice = 0;
    if (result.prixFioulHorsAbo() < PRESQUE_ZERO) result.fioulPrice.subscriptionPrice = 0;
    if (result.prixBoisHorsAbo() < PRESQUE_ZERO) result.electricitePrice.subscriptionPrice = 0;

    return result;
};

/** economie percue grace aux PV, cumulée sur plusieurs années */
export type EconomiePV = {
    /** L'electricité cumulée sur la période qu'on n'achetera plus. (car autoconsomée) */
    autoconsomation: number;
    /** le gain produit par la revente d el'electricité qu'on a produit en surplus. */
    revente: number;
    /** total des gains (autoconso + revente) sur la période*/
    total: number;
};

/**
 * Ce type permet d'afficher le tableau de la projection financière par année de l'écran /simulator/recommandation, src/components/tab/SimulatorContainer.tsx
 *
 */
export type ProjectionFinanciereAnnuelle = {
    /** lannée pour cette économie, indexée à 0  */
    annee: number;

    /** L'electricité cumulée sur la période qu'on n'achetera plus. (car autoconsomée), pour l'année */
    autoconso: number;
    /** le gain produit par la revente d el'electricité qu'on a produit en surplus, pour l'année. */
    revente: number;
    /** le ratio d'autoconsomation pour l'année en pourcentage*/
    tauxAutoconso: number;

    economieHorsPV: number;

    /** le total de l'année (autoconso + revente + economie normale) */
    total: number;
    /** rendement de l'investissement sur l'année */
    rendement: number;
};

export type ProjectionFinanciere = {
    economieHorsPV: DepenseParPoste;
    economiePV: EconomiePV;
    projectionsAnnuelles: Array<ProjectionFinanciereAnnuelle>;
    rendement: number;
};

export type StringifiedProjectionFinanciere = {
    economieHorsPv: string;
    economiePv: Stringified<EconomiePV> & { rendement: string; totalAnnuel: string };
    projectionsAnnuelles: Array<Stringified<ProjectionFinanciereAnnuelle>>;
};

export const stringifyProjectionFinanciere = (input: ProjectionFinanciere | undefined): StringifiedProjectionFinanciere => {
    if (!input) {
        return {
            economiePv: { autoconsomation: '0 €', revente: '0 €', total: '0 €', rendement: '0 %', totalAnnuel: '0 €' },
            economieHorsPv: '0 €',
            projectionsAnnuelles: [],
        };
    }
    const result: StringifiedProjectionFinanciere = {
        economiePv: {
            autoconsomation: currencyFormat(input.economiePV.autoconsomation),
            revente: currencyFormat(input.economiePV.revente),
            total: currencyFormat(input.economiePV.total),
            totalAnnuel: currencyFormat(input.economiePV.total / (input.projectionsAnnuelles.length + 1)),
            rendement: percentFormat(input.rendement),
        },
        economieHorsPv: currencyFormat(input.economiePV.total + input.economieHorsPV.prixHorsAbo()),
        projectionsAnnuelles: input.projectionsAnnuelles.map((e) => {
            return {
                annee: (e.annee + 1).toString(),
                autoconso: currencyFormat(e.autoconso),
                revente: currencyFormat(e.revente),
                tauxAutoconso: percentFormat(e.tauxAutoconso),
                economieHorsPV: currencyFormat(e.economieHorsPV),
                total: currencyFormat(e.total),
                rendement: percentFormat(e.rendement),
            };
        }),
    };

    return result;
};

/**
 *  Cette fonction est le groupement de calculEconomiePhotovoltaique sur 25 ans et de calculEconomie_IndexationMultiple sur 25 ans.
 *  a été ajouté les lignes de comtpes par an
 * @param depenseInitiale la dépense d'énergie en € avant travaux
 * @param depenseFinaleApresPV la dépense d'énergie en € avant travaux HORS PV
 * @param bilan le bilan photovoltaique, tel que retourné par le SimulateurPV
 * @param resteAcharge le prix total de tous les travaux (PV inclus)
 * @param durationYear le nombre d'années à prendre en compte pour l'analyse (25 ans par defaut)
 * @returns Une projection financière sur la durée
 */
export const calculProjectionFinanciereGlobale = (
    depenseInitiale: DepenseParPoste,
    depenseFinaleApresTravaux: DepenseParPoste,
    simuPv: SimulationPhotovoltaique | undefined,
    bilan: BilanPhotovoltaique | undefined,
    resteAcharge: number,
    durationYear = DUREE_PROJETCTION_FINANCIERE
): ProjectionFinanciere => {
    // remarques liées à l'implémentation de cette fonction.
    //
    // Ce code a été écrit après
    // - calculEconomiePhotovoltaique // en défaut à date.
    // - calculEconomie_IndexationSimple
    // - calculEconomie_IndexationMultiple
    // C'est effectivement un merge de calculEconomie_IndexationMultiple ET calculEconomiePhotovoltaique
    // Il y a effectivement de la duplication de code entre ces trois fonctions.
    //
    // Les algorithmes et les résultats sont strictements identiques.
    //
    // optimisation : Je n'ai volontairement pas optimisé le calcul, pour privilégier la lisibilité de cette loooongue fonction.
    // dans le cas ou il n'y a pas de panneau solaire, on aura effectivement un bilan peuplé de zéro est des boucles pleines de multiplications par zéro.
    //
    // A noter qu'il y a deux _genre_ de calcul différent, celui lié aux travaux et celui lié au PV.
    // Celui lié aux travaux utilise des objets pour faire le somme de cumul, il est donc largement plus concis.

    if (depenseFinaleApresTravaux.electricitePrice === undefined) {
        console.log("Problème d'initialisation des constantes locales. calculProjectionFinanciereGlobale. electricitePrice === undefined");
        throw new Error("Problème d'initialisation des constantes locales. calculProjectionFinanciereGlobale. electricitePrice === undefined");
    }

    // def de quelques constantes locales
    const indexations = loadIndexations();
    let prixKWhRevente = 0;
    if (simuPv && simuPv.installation && simuPv.installation.panneau) {
        prixKWhRevente = getPrixKwhRevente(simuPv.installation.modeConsommation, simuPv.installation.panneau.puissance) ?? 0;
    }
    let prixKWh = depenseFinaleApresTravaux.electricitePrice?.unitPrice;

    // objets de sortie
    const annuelle = new Array<ProjectionFinanciereAnnuelle>();
    const ecoHorsPV_euro_Cumul: DepenseParPoste = new DepenseParPoste();

    // les Cumuls, (travaux hors PV) initialisé à 0
    ecoHorsPV_euro_Cumul.fioulPrice = copyEnergyPrice(depenseInitiale.fioulPrice);
    ecoHorsPV_euro_Cumul.electricitePrice = copyEnergyPrice(depenseInitiale.electricitePrice);
    ecoHorsPV_euro_Cumul.boisPrice = copyEnergyPrice(depenseInitiale.boisPrice);
    ecoHorsPV_euro_Cumul.gazPrice = copyEnergyPrice(depenseInitiale.gazPrice);

    if (ecoHorsPV_euro_Cumul.fioulPrice) ecoHorsPV_euro_Cumul.fioulPrice.subscriptionPrice = 0;
    if (ecoHorsPV_euro_Cumul.electricitePrice) ecoHorsPV_euro_Cumul.electricitePrice.subscriptionPrice = 0;
    if (ecoHorsPV_euro_Cumul.boisPrice) ecoHorsPV_euro_Cumul.boisPrice.subscriptionPrice = 0;
    if (ecoHorsPV_euro_Cumul.gazPrice) ecoHorsPV_euro_Cumul.gazPrice.subscriptionPrice = 0;

    const economiePV: EconomiePV = {
        autoconsomation: 0,
        revente: 0,
        total: 0,
    };
    // année 0 : l'économie hors PV.
    const ecoHorsPV_euro_Year0 = getEconomieBetween(depenseInitiale, depenseFinaleApresTravaux);
    // année 0 : l'economie par PV.
    // Sans bilanPv == pas de panneau, l'économie est à 0.
    const autoconso_Year0 = bilan ? bilan.autoconsomationEnergie : 0;
    const revente_Year0 = bilan ? bilan.reventeEnergie : 0;
    const autoconso_euro_Year0 = autoconso_Year0 * prixKWh; // Energie (ou 0) * prix du Kwh autocono
    const revente_euro_Year0 = revente_Year0 * prixKWhRevente; // Energie (ou 0) * prix du Kwh de revente

    // on sépare en deux, car le cacul change après 20 ans pour le PV.
    let twentyFirstYears = durationYear;
    if (durationYear > 20) twentyFirstYears = 20;

    let i = 0;
    for (i; i < twentyFirstYears; i++) {
        // economie hors PV (deux lignes, annéee  i et cumulation)
        // cumul += y0 * (1,05)^i
        const ecoHorsPV_euro_i = indexedAtYear(ecoHorsPV_euro_Year0, indexations, i);
        inc(ecoHorsPV_euro_Cumul, ecoHorsPV_euro_i);

        // economie PV :
        const autoconso_euro_i = autoconso_euro_Year0 * Math.pow(indexations.electricite_autoconsomation, i);
        const revente_euro_i = revente_euro_Year0 * Math.pow(indexations.electricite_revente, i);
        // Les 20 premières années : j'autoconsome,  et je revend le surplus
        economiePV.autoconsomation += autoconso_euro_i;
        economiePV.revente += revente_euro_i;

        // stockage de l'economie annuelle, pour l'ensemble.
        annuelle.push({
            annee: i,
            autoconso: autoconso_euro_i,
            revente: revente_euro_i,
            rendement: resteAcharge === 0 ? 0 : (autoconso_euro_i + revente_euro_i + ecoHorsPV_euro_i.prix()) / resteAcharge,
            tauxAutoconso: simuPv?.tauxAutoConsommation ?? 0,
            economieHorsPV: ecoHorsPV_euro_i.prix(),
            total: ecoHorsPV_euro_i.prix() + autoconso_euro_i + revente_euro_i,
        });
    }

    // a partir de la (20 ans) on autoconsomme tout, car on a plus le droit de revendre.
    // le taux d'autoconsommation max reste 70%, qu'on essayera d'atteindre.
    // mais si le taux effectif est plus petit, c'est ce qu'on prend.

    // nouveau taux d'auto consommation, en prenant le max possible :
    // on va autoconommer l'energie qu'on autoconommait la première année + l'energie qu'on revendait la première année (bilan)
    // contrairement au calcul optimal (SimulationPhotovoltaique.getAutoConsoOptimal), ici la consigne est claire : le max.
    // donc on prendra le min qu'entre deux valeurs (au lieu de 3)
    // (Ca serait le taux qu'on aurait eu en faisant Mode = 'Autoconsommation' l'année 0.)
    // le taux sera donc :
    let nouveauTaux = (autoconso_Year0 + revente_Year0) / depenseFinaleApresTravaux.consoElectricite();
    if (nouveauTaux > CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C) nouveauTaux = CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;

    const nouveu_year0 = nouveauTaux * depenseFinaleApresTravaux.consoElectricite();
    // comme il n'y a pas de revente en se fout du reste de la prod PV.

    if (durationYear > 20) {
        // Les années 21 et suivantes : J'autoconsome tout. car je n'ai plus le droit de revendre.
        // On ne réinitialise pas i !
        // donc l'indexation se poursuit naturellement
        for (i; i < durationYear; i++) {
            // economie hors PV (deux lignes, annéee  i et cumulation)
            // cumul += y0 * (1,05)^i
            const eco_horsPv_i = indexedAtYear(ecoHorsPV_euro_Year0, indexations, i);
            inc(ecoHorsPV_euro_Cumul, eco_horsPv_i);

            // economie PV :
            const autoconso_i = nouveu_year0 * prixKWh * Math.pow(indexations.electricite_autoconsomation, i);
            economiePV.autoconsomation += autoconso_i;

            // stockage de l'economie annuelle, pour l'ensemble.
            annuelle.push({
                annee: i,
                autoconso: autoconso_i,
                revente: 0,
                rendement: resteAcharge === 0 ? 0 : (autoconso_i + eco_horsPv_i.prix()) / resteAcharge,
                tauxAutoconso: nouveauTaux,
                economieHorsPV: eco_horsPv_i.prix(),
                total: eco_horsPv_i.prix() + autoconso_i,
            });
        }
    }
    economiePV.total = economiePV.autoconsomation + economiePV.revente;

    // Maintenant que j'ai toutes les valeurs, je peux calculer le gain total.
    // une fois que j'ai le gain total, je peux calculer le rendement
    // rendement = ((gainTotal - resteAcharge) / 25year / resteAcharge) * 100.0;

    // ATTENTION, ici on NE multiplie PAS par 100.
    // C'est l'affichage qui s'en chargera.

    // bref
    let rendement = 0;
    let gainTotal = economiePV.total + ecoHorsPV_euro_Cumul.prix();
    //console.log('rendement = ( ' + gainTotal + ') /  ' + durationYear + ' /  ' + resteAcharge);
    if (resteAcharge !== 0) {
        rendement = gainTotal / durationYear / resteAcharge; // NON * 100.0, le x100 est de l'afficahge, on garde un poucentage pour l'instant;
    }

    // et voila !

    return {
        economiePV,
        projectionsAnnuelles: annuelle,
        economieHorsPV: ecoHorsPV_euro_Cumul,
        rendement,
    };
};
