import * as api from '../../apiParticulierService';
import { canInstallPV } from '../../tools/auditTools';
import { comparePackagePuissance, filterMethod, logRef } from '../filterPreconisation';
import { PackageData, PackageData_PaneauPhotovoltaique } from '../package';
import { isSubThemeType, SubTheme, Theme } from '../theme';

// Les packages basse température ont -BT- dans leur reférence.
// const PV_MONTAGE_TOIT_MIDFIX = '-TOIT-';
const PV_MONTAGE_SOL_MIDFIX = '-SOL-';
// const PV_MONTAGE_TOIT_END = '-TOIT';
const PV_MONTAGE_SOL_END = '-SOL';

// #region filter PV

export const filterPV: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //console.log('passed in filterPV + package.len = ' + theme.packages.length);
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    // for (const pack of theme.packages) console.log('1) pack : ' + pack.title + '; puissance : ' + pack.mainProduct.puissance);
    if (subTheme.packages.length === 0) return subTheme;

    // Pour n'afficher qu'un seul panneau PV
    // const debugRef = 'CentralePV4,5kWC';
    // const DebugPack = subTheme.packages.find((p) => p.reference === debugRef);
    // if (DebugPack) {
    //     subTheme.packages = [DebugPack];
    //     return subTheme;
    // }

    // Si appartement, on retire les panneaux solaires :
    if (!canInstallPV(audit)) {
        subTheme.packages = Array<PackageData>();
        return subTheme;
    }
    logRef('tous les packages PV', subTheme.packages);
    // La règle :
    // - supprimer les PV >= 9kwc, si la maison n'a pas le triphasé.
    // - parmis les packs restant selectionner le bon selon la puissance.
    // electricMeter in src/pages/energy-system-informations/EnergySystemInformations.tsx
    if (audit.electricMeter === undefined || audit.electricMeter.value !== '2') {
        subTheme.packages = subTheme.packages.filter((pack) => {
            const puissance = pack.mainProduct.puissance! * pack.mainProduct.quantite;
            // doit retourner true pour garder, false pour supprimer.
            return puissance < 9000;
        });
    }

    // on tri dans l'ordre de puissance croissante
    subTheme.packages.sort(comparePackagePuissance);

    // On enregistre le dernier package (a ce moment seulement, après qu'on ait trié, fitlré )
    const lastPackage = subTheme.packages[subTheme.packages.length - 1];

    let found = false;
    // le besoin de l'utilisateur
    const besoinUtilisateurEuro = audit.energyCostsElectric === undefined ? 0 : +audit.energyCostsElectric.value;
    // Le besoin de l'utilisateur est transformé en puissance :
    // Mais on ne peut pas autoconsommer plus de 70% de son besoin
    const besoinUtilisateurPuissanceK = (0.7 * besoinUtilisateurEuro) / 0.258; // Cout divisé par prix du Kwh.
    let laPlusPetitePuissanceQuiCouvreLeBesoin = -1;

    // on prend la première puissance de PV qui couvre le besoin.
    // et on s'arrête de chercher, car les suivantes couvrent toutes le besoin.
    for (const pack of subTheme.packages) {
        if (pack.mainProduct.puissance === undefined) continue; // théoriquement n'arrive pas.
        // but => selectionner la bonne puissance selon le besoin de l'utilisateur
        // REMARQUE : la formule a été trouvé par calcul malin selon des tranches de prix données par ylan.
        // SAUF QUE Idealement, il faudrait appliquer ce calcul sur une dépense finale et non sur la dépense de l'audit !
        // La variable puissance est en wc

        // Ensuite, on connait le premier PV avec la bonne puissance
        // Si il y en a plusieurs, on prend celui qui est en montage sol, de préférence.

        const puissance = pack.mainProduct.puissance * pack.mainProduct.quantite;
        if (puissance >= besoinUtilisateurPuissanceK) {
            laPlusPetitePuissanceQuiCouvreLeBesoin = puissance;
            // console.log('found laPlusPetitePuissanceQuiCouvreLeBesoin = ' + laPlusPetitePuissanceQuiCouvreLeBesoin);
            found = true;
            // on s'arrête au premier, car sinon, on va selectionner tous les suivants.
            break;
        }
    }

    // Si pas trouvé, ca veut dire quoi ?
    // Ca veut dire que le besoin est supérieur à la plus grande puissance.
    // on selectionne le dernier PV, le plus puissant.

    // old : if (!found && subTheme.packages.length > 0) subTheme.packages[subTheme.packages.length - 1].applicable = true;
    if (!found && subTheme.packages.length > 0) {
        if (lastPackage && lastPackage.mainProduct.puissance) {
            laPlusPetitePuissanceQuiCouvreLeBesoin = lastPackage.mainProduct.puissance * lastPackage.mainProduct.quantite;
            // console.log('Not found laPlusPetitePuissanceQuiCouvreLeBesoin = ' + laPlusPetitePuissanceQuiCouvreLeBesoin);
            // console.log('          lastPackage = ' + lastPackage.reference);
        }
    }

    const packagesAvecLaBonnePuissance = subTheme.packages.filter((pack: PackageData) => {
        if (pack.mainProduct.puissance === undefined) return false; // théoriquement n'arrive pas.
        const puissance = pack.mainProduct.puissance * pack.mainProduct.quantite;
        // console.log('filtering : la bonne puissance : ' + puissance + ' / ' + laPlusPetitePuissanceQuiCouvreLeBesoin);
        return puissance === laPlusPetitePuissanceQuiCouvreLeBesoin;
    });

    logRef('packagesAvecLaBonnePuissance', packagesAvecLaBonnePuissance);

    // parmi les packageAvecLaBonnePuissance, si il y en a plusieurs, on choisit le premier qui se pose sur le toit, sinon, au sol, puis n'importe lequel.
    // A date, j'avais demandé à Nathaniel de spécifier _TOIT_ ou _SOL_ sur tous les packages pv. Mais il n'a mit que les _SOL_
    // Donc dans ce cas les PV avec montage TOIT, sont des NON _SOL.
    // donc la règle évolue légèrement : on prend le premier non _SOL_ (qui est un toit). ou le premier toi.

    // const firstToit = packagesAvecLaBonnePuissance.find((pack: PackageData) => {
    //     return pack.reference.includes(PV_MONTAGE_TOIT_MIDFIX) || pack.reference.endsWith(PV_MONTAGE_TOIT_END);
    // });
    const firstToit = packagesAvecLaBonnePuissance.find((pack: PackageData) => {
        return !pack.reference.includes(PV_MONTAGE_SOL_MIDFIX) && !pack.reference.endsWith(PV_MONTAGE_SOL_END);
    });
    const firstSol = packagesAvecLaBonnePuissance.find((pack: PackageData) => {
        return pack.reference.includes(PV_MONTAGE_SOL_MIDFIX) || pack.reference.endsWith(PV_MONTAGE_SOL_END);
    });

    //const first = packagesAvecLaBonnePuissance.length === 0 ? undefined : packagesAvecLaBonnePuissance[0];

    const selectedPackage = firstToit ?? firstSol; // ?? first;
    if (selectedPackage) selectedPackage.applicable = true;

    // Check si le resultat s'est propagé dans subtheme
    // sinon, on le fait à la main.
    // for (const pack of subTheme.packages) {
    //     if (pack.reference && pack.reference === selectedPackage?.reference) {
    //         pack.applicable = true;
    //     }
    // }

    return subTheme;
};

//#endregion

// #region garantie de revenu solaire

export const filterGarantieRvenuSolaire: filterMethod = async (audit: any, subTheme: SubTheme, themes: Array<Theme>): Promise<SubTheme> => {
    // console.log('passed in filterPV + package.len = ' + theme.packages.length);
    // si pas de package, on laisse tomber, c'est déjà filtré !
    // for (const pack of theme.packages) console.log('1) pack : ' + pack.title + '; puissance : ' + pack.mainProduct.puissance);
    if (subTheme.packages.length === 0) return subTheme;

    // Si appartement, on a déjà retiré les panneaux solaires :
    // On retire les assurances liées.
    if (!canInstallPV(audit)) {
        subTheme.packages = Array<PackageData>();
        return subTheme;
    }

    // ici par défaut on selectionne rien. et on affiche tout.
    const params = await api.getCustomerFlowParameters();
    if (!params.operationsCommerciales.remiseMMA) return subTheme;

    // Mais si une opération commerciale est en cours, on offre l'assurance, dans ce cas, on la selectionne.
    // le problème c'est qu'il faut selectionner en fonction de la puissance du panneau solaire choisit !
    // donc en fonction de la puissance du PV choisit
    let pvPower = 0;
    // Normalement, j'ai déjà selectionné le bon PV en amont.
    // Je récupère sa puissance.
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            if (isSubThemeType(subTheme.subThemeType)) {
                if (subTheme.subThemeType === 'Photovoltaïque') {
                    for (const pack of subTheme.packages) {
                        if (pack.applicable) {
                            const packPv = pack as PackageData_PaneauPhotovoltaique;
                            pvPower = (packPv.installationPV.panneau?.puissance ?? 0) / 1000;
                        }
                    }
                }
            }
        }
    }
    // Je rpend la première assurance qui est au moins égale à cette puissance.
    // elles sont rangée dans l'ordre croissant.
    let mmaPower: number = -1000;
    for (const mmaPack of subTheme.packages) {
        mmaPower = +mmaPack.reference.split('-')[1];
        if (mmaPower >= pvPower) {
            mmaPack.applicable = true;
            break;
        }
    }

    return subTheme;
};

//#endregion

// #region filter batterie

export const filterBatterie: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //console.log('passed in filterPV + package.len = ' + theme.packages.length);
    // si pas de package, on laisse tomber, c'est déjà filtré !
    // for (const pack of theme.packages) console.log('1) pack : ' + pack.title + '; puissance : ' + pack.mainProduct.puissance);
    if (subTheme.packages.length === 0) return subTheme;

    // Si appartement, on retire les batteries :
    if (!canInstallPV(audit)) {
        subTheme.packages = Array<PackageData>();
        return subTheme;
    }
    return subTheme;
};
//#endregion
