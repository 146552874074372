import React, { CSSProperties, useState } from 'react';
import { Field } from 'formik';
import { resizeImage } from '../../services/tools/auditTools';
import * as storageService from '../../services/localStorageService';

// Style
import './UploadInput.scss';

// Icon
import { ReactComponent as IconUpload } from '../../assets/icons/pre-visite/icon-upload.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/pre-visite/icon-check.svg';

interface UploadProps {
    name: string;
    label: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    disabled?: boolean;
    smallerLabel?: boolean;
    detailedLabel?: string;
    pictureFileName?: string;
    style?: CSSProperties;
    localStorage?: boolean;
}

const UploadInput: React.FC<UploadProps> = ({
    name,
    label,
    setFieldValue,
    disabled,
    smallerLabel,
    detailedLabel,
    pictureFileName,
    style,
    localStorage = true,
}) => {
    // Stocker le nom du fichier
    const [fileName, setFileName] = useState<string>(pictureFileName ?? '');
    // Mettre à jour cette variable si d'autres fichiers doivent/peuvent être upload
    const acceptedFiles: string = 'image/jpg, image/jpeg, image/png';

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        if (!file) {
            return;
        }

        setFileName(file.name);

        try {
            // Resize image to 500x500px
            const base64 = await resizeImage(file);

            // Save image as base64
            setFieldValue(name, base64);
            if (localStorage) {
                storageService.setPrevisitValue(name, base64, file.name);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div
            className={`custom-upload-field${disabled ? ' disabled' : ''}${smallerLabel ? ' smaller' : ''}${
                fileName || pictureFileName ? ' upload-filled' : ''
            }`}
            style={style}
        >
            <label htmlFor={name}>
                {label}
                {detailedLabel && <span>{detailedLabel}</span>}
            </label>
            <Field name={name} type="file" onChange={handleFileChange} accept={acceptedFiles} value={undefined} title={fileName} disabled={disabled} />
            <div className="icon">
                <IconUpload fill="#0072a2" />
            </div>
            {fileName && (
                <div className="icon-filled">
                    <IconCheck fill="white" />
                </div>
            )}
            {fileName && <span className="file-name">{fileName}</span>}
        </div>
    );
};

export default UploadInput;
