// Ce fichier permet de calculer la liste des preconisations, de chauffage et d'eau chaude, et "autres".
// Attention, ces précos sont partielles Elle ne représentent que les étapes 4-chauffage, 6-eau chaude et  8-autres
//
// Les précos V1 sont obsolètes, pour l'instant.
// Si les préco V1 étaient réintégrées, il faudrait faire attention, a l'ordre d'application selon les Steps !
//
// On se base uniquement sur les informations :
// "Sogys - regle de calcul des recommandations.xlsx
// https://wassafrance-my.sharepoint.com/:x:/g/personal/frederic_sommerlat_wassa_io/EbOwc3es_-VGhcxKz1_of3EBd6hrwQRqapgEDPA7FD-maw?e=57QeyM
//
// Les définitions des préco ont été téléchargées, comme les autres fichiers de définitions

import { AdvancedPosteDepense, PreconisationChauffage } from './calculPreconisationsCore';
import { Unites } from './products';

// Creer toutes les préconisations, en fonction des données de l'audit.
// Retourne un tableau de preconisationV2.
export const createPreconisationsChauffage = (audit: any): Array<PreconisationChauffage> => {
    const result = new Array<PreconisationChauffage>();

    result.push(...createPrecoChauffage(audit)); // step 4
    result.push(...createPrecoEauChaude(audit)); // step 6
    result.push(...createPrecoAccessoires(audit)); // step 8

    return result;
};

// inutile, mais utile pour la mise au point.
// Ne pas supprimer.
// retourne l'ensemble des préco sans se soucier de leur applicabilité.
export const createPreconisationsChauffage_fake = (audit: any): Array<PreconisationChauffage> => {
    const result = new Array<PreconisationChauffage>();

    result.push(getPrecoChauffageById('ChauffageElectricite')!);
    result.push(getPrecoChauffageById('ChauffageBois')!);
    result.push(getPrecoChauffageById('ChauffageGaz')!);
    result.push(getPrecoChauffageById('ChauffageFioul')!);
    result.push(getPrecoChauffageById('EauChaudeBois')!);
    result.push(getPrecoChauffageById('EauChaudeGaz')!);
    result.push(getPrecoChauffageById('EauChaudeFioul')!);
    result.push(getPrecoChauffageById('ChauffageElectricite')!);
    result.push(getPrecoChauffageById('AutresElectricite')!);
    return result;
};

// Obtenir les preco pour le systeme de chauffage.
const createPrecoChauffage = (audit: any): Array<PreconisationChauffage> => {
    const result = new Array<PreconisationChauffage>();

    // selon le type de chauffage, on sélectionne les préconisations.
    if (!audit.heaterType) {
        console.log('Audit.heaterType =   undefined');
        return result;
    }

    //label: 'Électrique',
    if (
        +audit.heaterType.value === 0 ||
        (audit.heaterType2 && +audit.heaterType2.value === 0) ||
        +audit.heaterType.value === 2 ||
        (audit.heaterType2 && +audit.heaterType2.value === 2) ||
        +audit.heaterType.value === 5 ||
        (audit.heaterType2 && +audit.heaterType2.value === 5)
    ) {
        const preco = getPrecoChauffageById('ChauffageElectricite');
        if (preco) {
            result.push(preco);
        }
    }
    //label: 'Bois',
    if (+audit.heaterType.value === 1 || (audit.heaterType2 && +audit.heaterType2.value === 1)) {
        const preco = getPrecoChauffageById('ChauffageBois');
        if (preco) {
            result.push(preco);
        }
    }

    //label: 'Elec. thermodynamique (PAC)',
    if (+audit.heaterType.value === 3 || (audit.heaterType2 && +audit.heaterType2.value === 3)) {
        const preco = getPrecoChauffageById('ChauffagePAC');
        if (preco) {
            result.push(preco);
        }
    }
    //label: 'Gaz',
    if (+audit.heaterType.value === 4 || (audit.heaterType2 && +audit.heaterType2.value === 4)) {
        const preco = getPrecoChauffageById('ChauffageGaz');
        // const preco = getPrecoV2ById("ChauffageGazPropane"); ?
        if (preco) {
            result.push(preco);
        }
    }
    //label: 'Fioul',
    if (+audit.heaterType.value === 6 || (audit.heaterType2 && +audit.heaterType2.value === 6)) {
        const preco = getPrecoChauffageById('ChauffageFioul');
        if (preco) {
            result.push(preco);
        }
    }

    return result;
};

// OObtenir els preco pour le ballon d'eau chaude
const createPrecoEauChaude = (audit: any): Array<PreconisationChauffage> => {
    const result = new Array<PreconisationChauffage>();
    // Selon le type d'eau chaude on selectionne les préconisation
    if (audit.heaterHotWaterIncluded.value === true) {
        // si l'eau chaude est liée au chauffage :
        if (!audit.heaterType) {
            console.log('Audit.heaterType =   undefined');
            return result;
        }
        //label: 'Bois',
        if (+audit.heaterType.value === 1 || (audit.heaterType2 && +audit.heaterType2.value === 1)) {
            const preco = getPrecoChauffageById('EauChaudeBois');
            if (preco) {
                result.push(preco);
            }
        }

        //label: 'Gaz',
        if (+audit.heaterType.value === 4 || (audit.heaterType2 && +audit.heaterType2.value === 4)) {
            const preco = getPrecoChauffageById('EauChaudeGaz');
            // const preco = getPrecoV2ById("EauChaudeGazPropane"); ?
            if (preco) {
                result.push(preco);
            }
        }
        //label: 'Fioul',
        if (+audit.heaterType.value === 6 || (audit.heaterType2 && +audit.heaterType2.value === 6)) {
            const preco = getPrecoChauffageById('EauChaudeFioul');
            if (preco) {
                result.push(preco);
            }
        }
    }

    // DAns tous les cas,  On aura quand même cette préco, car une aprtie de l'eau chaude est electrique
    const preco = getPrecoChauffageById('EauChaudeElectricite');
    if (preco) {
        result.push(preco);
    }

    return result;
};

// Obtenir les preco pour les accessoires a économie d'energie
const createPrecoAccessoires = (audit: any): Array<PreconisationChauffage> => {
    const result = new Array<PreconisationChauffage>();
    // La logique est simple, on propose toujours cette préco !
    const preco = getPrecoChauffageById('AutresElectricite');
    if (preco) result.push(preco);

    return result;
};

// read the localstorage file containing all preconisations from server,
// select the good one, from its id
// https://gitlab.wassa.fr/gse/simulator/gse-simulator-front/-/commit/2b6a80ebde6fac0cb0b51b2c71e86899461d98ac
const getPrecoChauffageById = (id: AdvancedPosteDepense): PreconisationChauffage | undefined => {
    const RECOMMANDATIONS = JSON.parse(localStorage.getItem('preconisationsChauffage')!);
    if (RECOMMANDATIONS) {
        for (const r of RECOMMANDATIONS!) {
            if (r.id === id) {
                //   console.log('getPrecoChauffageById returned ' + JSON.stringify(createPrecoChauffageFromFile(r)));
                return createPrecoChauffageFromFile(r);
            }
        }
    }
    console.log('getPrecoChauffageById returned undefined');

    return undefined;
};
const createPrecoChauffageFromFile = (entry: any): PreconisationChauffage => {
    const result = new PreconisationChauffage();
    result.id = entry.id;
    result.description = entry.description;
    result.gain = entry.gain;
    result.cibleGain = entry.id as AdvancedPosteDepense; //entry.targetGain as AdvancedPosteDepense;
    result.surcoutElectricte = entry.extraElectricCost;
    result.applicable = true;
    result.unites = entry.unites as Unites;
    return result;
};
