import { Unites } from './products';

const PosteDepenseNames = ['Chauffage', 'EauChaude', 'Autres'] as const;
export type PosteDepense = typeof PosteDepenseNames[number];

const EnergyTypesNames = ['Fioul', 'Gaz', 'Bois', 'Electricite', 'PAC'] as const;
export type EnergyTypes = typeof EnergyTypesNames[number];

export type AdvancedPosteDepense = `${PosteDepense}${EnergyTypes}`;

export class PreconisationChauffage {
    id: string = '';
    description: string = '';
    gain: number = 0;
    cibleGain: AdvancedPosteDepense = 'ChauffageFioul';
    surcoutElectricte: number = 0;
    applicable: boolean = true;
    log = () => {
        console.log('Preconisation (' + this.description + ')');
        console.log('  applicable = ' + this.applicable);
        //console.log('  Step = ' + this.step?.id);
        console.log('  id   = ' + this.id);
    };

    // Propriétés liés aux produits
    unites: Unites = 'aucune';
    quantiteRecommandee: number = 1;
}
