import { currencyFormat } from '../../tools/TypeHelper';

type categoryRevenu = 'tresModeste' | 'modeste' | 'intermediaire' | 'superieur';
type localisation = 'ileDeFrance' | 'ailleurs';

const getLocalisation = (department: string): localisation => {
    return department === '75' ||
        department === '77' ||
        department === '78' ||
        department === '91' ||
        department === '92' ||
        department === '93' ||
        department === '94' ||
        department === '95'
        ? 'ileDeFrance'
        : 'ailleurs';
};

export type SeuilsRevenus = { [cat in categoryRevenu]: number };
export type Plus = (nb: number) => SeuilsRevenus;

export const conditionsDeRevenus: { [loc in localisation]: { [nb in number]: SeuilsRevenus } & { plus: Plus } } = {
    ileDeFrance: {
        '1': { superieur: 40018, intermediaire: 28657, modeste: 23541, tresModeste: 0 },
        '2': { superieur: 58827, intermediaire: 42058, modeste: 34551, tresModeste: 0 },
        '3': { superieur: 70382, intermediaire: 50513, modeste: 41493, tresModeste: 0 },
        '4': { superieur: 82839, intermediaire: 58981, modeste: 48447, tresModeste: 0 },
        '5': { superieur: 94844, intermediaire: 67473, modeste: 55427, tresModeste: 0 },
        plus: (nb: number) => ({
            tresModeste: conditionsDeRevenus.ileDeFrance[5].tresModeste + (nb - 5) * 6970,
            modeste: conditionsDeRevenus.ileDeFrance[5].modeste + (nb - 5) * 8486,
            intermediaire: conditionsDeRevenus.ileDeFrance[5].intermediaire + (nb - 5) * 12006,
            superieur: conditionsDeRevenus.ileDeFrance[5].superieur + (nb - 5) * 12006,
        }),
    },
    ailleurs: {
        '1': { superieur: 30549, intermediaire: 21805, modeste: 17009, tresModeste: 0 },
        '2': { superieur: 44907, intermediaire: 31889, modeste: 24875, tresModeste: 0 },
        '3': { superieur: 54071, intermediaire: 38349, modeste: 29917, tresModeste: 0 },
        '4': { superieur: 63235, intermediaire: 44802, modeste: 34948, tresModeste: 0 },
        '5': { superieur: 72400, intermediaire: 51281, modeste: 40002, tresModeste: 0 },
        plus: (nb: number) => ({
            tresModeste: conditionsDeRevenus.ailleurs[5].tresModeste + (nb - 5) * 5045,
            modeste: conditionsDeRevenus.ailleurs[5].modeste + (nb - 5) * 6462,
            intermediaire: conditionsDeRevenus.ailleurs[5].intermediaire + (nb - 5) * 9165,
            superieur: conditionsDeRevenus.ailleurs[5].superieur + (nb - 5) * 9165,
        }),
    },
};

const getCategorieRevenus = (nbPersonnes: number, revenuAnnuel: number, department: string): categoryRevenu => {
    if (nbPersonnes <= 5) {
        //return revenuAnnuel <= conditionsDeRevenus[getLocalisation(department)][nbPersonnes] ? 'precaire' : 'classique';
        const cond: { [key in categoryRevenu]: number } = conditionsDeRevenus[getLocalisation(department)][nbPersonnes];
        if (revenuAnnuel > cond.superieur) return 'superieur';
        if (revenuAnnuel > cond.intermediaire) return 'intermediaire';
        if (revenuAnnuel > cond.modeste) return 'modeste';
        if (revenuAnnuel > cond.tresModeste) return 'tresModeste';
        return 'tresModeste';
    } else {
        //return revenuAnnuel <= conditionsDeRevenus[getLocalisation(department)].plus(nbPersonnes) ? 'precaire' : 'classique';
        const cond = conditionsDeRevenus[getLocalisation(department)].plus(nbPersonnes);
        if (revenuAnnuel > cond.superieur) return 'superieur';
        if (revenuAnnuel > cond.intermediaire) return 'intermediaire';
        if (revenuAnnuel > cond.modeste) return 'modeste';
        if (revenuAnnuel > cond.tresModeste) return 'tresModeste';
        return 'tresModeste';
    }
};

export const RENO_CONST = {
    plafond: {
        gain2classes: 40000,
        gain3classes: 50000,
        gain4classes: 70000,
        ecretement: null,
    },
    tresModeste: {
        gain2classes: 0.8,
        gain3classes: 0.8,
        gain4classes: 0.8,
        ecretement: 1,
    },
    modeste: {
        gain2classes: 0.6,
        gain3classes: 0.6,
        gain4classes: 0.6,
        ecretement: 0.8,
    },
    intermediaire: {
        gain2classes: 0.45,
        gain3classes: 0.5,
        gain4classes: 0.5,
        ecretement: 0.6,
    },
    supperieur: {
        gain2classes: 0.3,
        gain3classes: 0.35,
        gain4classes: 0.35,
        ecretement: 0.4,
    },
};

export const RENO_PLAFOND_TEXT: { gain2classes: string; gain3classes: string; gain4classes: string } = {
    gain2classes: currencyFormat(RENO_CONST.plafond.gain2classes, false),
    gain3classes: currencyFormat(RENO_CONST.plafond.gain3classes, false),
    gain4classes: currencyFormat(RENO_CONST.plafond.gain4classes, false),
};
export const RENO_REVENUS_TEXT: {
    [key in categoryRevenu]: { priseEnCharge: string; priseEnChargeBonusPassoire: string; resteACharge: string; ecretement: string };
} = {
    tresModeste: {
        priseEnCharge: '80%',
        priseEnChargeBonusPassoire: '90%',
        // de (1-0.9) * 40000 à (1-0.8) * 70000
        resteACharge: 'de 4 000 € à 14 000 €',
        ecretement: '100%',
    },
    modeste: {
        priseEnCharge: '60%',
        priseEnChargeBonusPassoire: '70%',
        // de (1-0.7) * 40000 à (1-0.6) * 70000
        resteACharge: 'de 12 000 € à 28 000 €',
        ecretement: '80%',
    },
    intermediaire: {
        priseEnCharge: 'de 45% à 50%',
        priseEnChargeBonusPassoire: '55% à 60%',
        // de (1-0,6 ) * 40000 à (1-0,45 ) * 70000
        resteACharge: 'de 16 000 € à 38 500 €',
        ecretement: '60%',
    },
    superieur: {
        priseEnCharge: 'de 30% à 35%',
        priseEnChargeBonusPassoire: '40% à 45%',
        // de (1-0,45) * 40000 à (1-0,30 ) * 70000
        resteACharge: 'de 22 000 € à 49 000 €',
        ecretement: '40%',
    },
};

export const BONIFICATION_PASSOIRE = 0.1;

// comment connaitre la classe ?

// On eleve reno globale, on garde reno ampleur.

// la surfade n'est plus un critere,
// le chauffage n'est plus un critere, seulement une des trois isolation à faire.

export type RenovationDAmpleur =
    | {
          elligible: false;
          marShouldContactCustomer: false;
      }
    | {
          elligible: true;
          categorieRevenus: categoryRevenu;
          marShouldContactCustomer: boolean;
          renoTextes: { priseEnCharge: string; priseEnChargeBonusPassoire: string; resteACharge: string; ecretement: string };
          plafonds: { gain2classes: string; gain3classes: string; gain4classes: string };
      };

export const isElligible = (audit: any): RenovationDAmpleur => {
    const notElligible: RenovationDAmpleur = { elligible: false, marShouldContactCustomer: false };
    // console.log('  => check is reno globale elligible');
    if (!audit) {
        // console.log('  => Not elligible : audit null');
        return notElligible;
    }
    // J'élimine tous les cas défavorables, au fur et a mesure
    // Si j'arrive en bas, c'est que je suis élligible.

    // si ce n'est PAS une maison individuelle => j'élimine
    if (!audit.projectType || !audit.projectType.value_label || audit.projectType.value_label !== 'Maison individuelle') {
        // console.log("  => Not elligible :!== 'Maison individuelle' ");
        return notElligible;
    }
    // si je ne suis PAS propriétaire => j'élimine
    if (!audit.projectStatus || !audit.projectStatus.value_label || audit.projectStatus.value_label !== 'Propriétaire') {
        // console.log("  => Not elligible : !== 'Propriétaire'");
        return notElligible;
    }
    // si la maisons a MOINS DE 15 ans => j'élimine
    if (!audit.houseAge || !audit.houseAge.value) {
        // console.log('  => Not elligible : !audit.houseAge.value ');
        return notElligible;
    }
    if (new Date().getFullYear() - +audit.houseAge.value < 15) {
        // console.log('  => Not elligible : maison récente');
        return notElligible;
    }

    // si le systeme de chauffage n'est NI gaz NI fioul => j'élimine
    // if (!audit.heaterType) {
    //     // console.log('  => Not elligible : no heater');
    //     return { elligible: false, marShouldContactCustomer:false };
    // }

    // if (audit.heaterType.value_label !== 'Gaz' && audit.heaterType.value_label !== 'Fioul') {
    //     // console.log('  => Not elligible : !fioul && !gaz ');
    //     return { elligible: false, marShouldContactCustomer:false };
    // }

    const comble = !audit.ceilingInsulationPresence || audit.ceilingInsulationPresence.value === true;
    //const rampant = !audit.rampantIsolated || audit.rampantIsolated.value === true;
    const mur = !audit.exteriorWallInsulationPresence || audit.exteriorWallInsulationPresence.value === true;
    const plancher = !audit.floorInsulationPresence || audit.floorInsulationPresence.value === true;

    // si on a toutes ces isolations, on est pas élligible
    //&& rampant
    if (comble && mur && plancher) {
        console.log("  => Not elligible : trop d'isolation ");
        return notElligible;
    }

    if (!audit.department || audit.department.value === undefined || audit.department.value.length < 2) {
        // console.log('  => Not elligible : !department');
        return notElligible;
    }
    const department = audit.department.value.slice(0, 2);

    let nombrePersonne = 1; // le déclarant
    if (audit.userMaritalStatus && audit.userMaritalStatus.value && audit.userMaritalStatus !== '1')
        // celibataire
        nombrePersonne++;
    if (audit.children && audit.children.value !== undefined) nombrePersonne += +audit.children.value;

    // Si le revenu n'est pas renseigné (on devrait prendre 0 ?) => j'élimine
    if (!audit.taxIncome || audit.taxIncome.value === undefined) {
        // console.log('  => Not elligible : !tax');
        return notElligible;
    }

    const revenu = +audit.taxIncome.value + (audit.taxIncome2 && audit.taxIncome2.value !== undefined ? +audit.taxIncome2.value : 0);

    const catRevenu = getCategorieRevenus(nombrePersonne, revenu, department);

    return {
        elligible: true,
        marShouldContactCustomer: true,
        categorieRevenus: catRevenu,
        renoTextes: RENO_REVENUS_TEXT[catRevenu],
        plafonds: RENO_PLAFOND_TEXT,
    };
};
